/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { apiSublos } from 'services/api'

const {
  sublosEmployeeReservationsDetailsStart,
  sublosEmployeeReservationsDetailsSuccess,
  sublosEmployeeReservationsDetailsError,
  sublosEmployeeReservationsDetailsClear
} = createActions({
  SUBLOS_EMPLOYEE_RESERVATIONS_DETAILS_START: () => {},
  SUBLOS_EMPLOYEE_RESERVATIONS_DETAILS_SUCCESS: data => ({ data }),
  SUBLOS_EMPLOYEE_RESERVATIONS_DETAILS_ERROR: error => ({ error }),
  SUBLOS_EMPLOYEE_RESERVATIONS_DETAILS_CLEAR: () => {}
})

const fetchSublosEmployeeReservationsDetails = ({
  companyCode,
  employeeCode,
  benefitType,
  ticketType
}) => async dispatch => {
  dispatch(sublosEmployeeReservationsDetailsStart())
  try {
    const response = await apiSublos.get(
      apiRoutes.SUBLOS_GET_RESERVATIONS_BENEFIT_TYPES({
        companyCode,
        employeeCode,
        benefitType,
        ticketType
      })
    )
    dispatch(sublosEmployeeReservationsDetailsSuccess(response.data))
  } catch (error) {
    dispatch(sublosEmployeeReservationsDetailsError(error.response ? error.response.data : error))
  }
}

export {
  fetchSublosEmployeeReservationsDetails,
  sublosEmployeeReservationsDetailsSuccess,
  sublosEmployeeReservationsDetailsError,
  sublosEmployeeReservationsDetailsStart,
  sublosEmployeeReservationsDetailsClear
}
