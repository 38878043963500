/* eslint-disable import/no-cycle */

const startPaymentMessage = (target, isConfirm) =>
  `Start payment ${isConfirm ? 'Confirmation' : ''} on ${target}`

const successPaymentMessage = (target, isConfirm) =>
  `Successful payment ${isConfirm ? 'Confirmation' : ''} on ${target}`

const errorPaymentMessage = (target, isConfirm) =>
  `Error during payment ${isConfirm ? 'Confirmation' : ''} on ${target}`

export const TARGET = {
  CLIENT: 'Client',
  SERVER: 'Server'
}

export const LEVEL = {
  INFO: 'INFO',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
}

export const PROCESS_TYPE = {
  TOKEN_GENERATION: `[Process: Generation-token - level: ${LEVEL.ERROR} ]`,
  RECOVER_COOKIES_IN_SSR: 'Recover-cookies-in-ssr',
  SAVE_COOKIES_IN_SSR: 'Save-cookies-in-ssr',

  //Payment
  PURCHASE: 'Purchase',
  GENERATE_RESERVATION: 'Generate-reservation',
  GENERATE_PAYMENT_BOOKING: 'Generate-payment-booking',
  SUBLOS: 'Sublos',
  EXCHANGES: 'Exchanges',
  ANCILLARIES: 'Ancillaries',
  CHALLENGE: '3P-challenge',
  RETRY_GLOBAL_EXTERNAL_PAYMENT: 'Retry-global-external-payment',
  RETRY_GLOBAL_PAYMENT: 'Retry-global-payment',

  //Reservation information
  GET_RESERVATION: 'Get-reservation',
  CHECK_3P_RESERVATION: 'Check-3p-reservation',
  CHECK_MP_RESERVATION: 'Check-mercado-pago-reservation',

  //Reservation confirmation
  RESERVATION_CONFIRMATION: 'Reservation-confirmation',
  CONFIRM_3P_LOCAL_RESERVATION: '3P-local-reservation-confirmation',
  CONFIRM_3P_GLOBAL_RESERVATION: '3P-global-reservation-confirmation',
  EXECUTE_GLOBAL_REDIRECT_SERVER_SIDE: 'Execute_global_redirect_server_side'
}

export const DESCRIPTION = {
  TOKEN_GENERATION_ERROR: 'Token generation error',
  RECOVER_COOKIES: 'Recover cookies',
  COOKIES_SUCCESSFULLY_RETRIEVED: 'Cookies successfully retrieved',
  ERROR_RETRIEVING_COOKIE: 'Error retrieving cookie',

  //payment
  START_PAYMENT_CLIENT: startPaymentMessage(TARGET.CLIENT),
  SUCCESS_PAYMENT_CLIENT: successPaymentMessage(TARGET.CLIENT),
  ERROR_PAYMENT_CLIENT: errorPaymentMessage(TARGET.CLIENT),

  //Reservation
  CHECK_RESERVATION: 'Check reservation status',
  GET_RESERVATION: 'Get reservation details',

  //Redirect to arsa
  REDIRECTED_TO_ARSA: 'Redirected to ARSA',
  REDIRECTED_FROM_MERCADO_PAGO: 'Redirected from Mercado pago',
  REDIRECTED_FROM_3DS_GLOBAL: 'Redirected from 3DS global',
  REDIRECTED_FROM_3DS_LOCAL: 'Redirected from 3DS local',
  REDIRECTED_FROM_PAGO_MIS_CUENTAS: 'Redirected from Pago mis cuentas',

  //3p
  CHECK_3P_CHALLENGE: 'Check 3P Challenge',
  START_PAYMENT_SERVER_CONFIRMATION: startPaymentMessage(TARGET.SERVER, true),
  SUCCESS_PAYMENT_SERVER_CONFIRMATION: successPaymentMessage(TARGET.SERVER, true),
  ERROR_PAYMENT_SERVER_CONFIRMATION: errorPaymentMessage(TARGET.SERVER, true)
}
