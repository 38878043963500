export default {
  'general.payment-data.booking-title': 'Pagar mi reserva',
  'general.payment-data.tabs.pay-now': 'Pagar ahora',
  'general.payment-data.tabs.hold-for': 'Mantener tarifa por {{timeLimit}}hs',
  'general.payment.bnpl-alert':
    'Abonando {{currency}} {{amount}} más, te garantizamos la tarifa de tu vuelo por {{time}} hs.',
  'general.payment.bnpl-alert.free':
    'Sin necesidad de abonar cargos extra, te garantizamos la tarifa de tu vuelo por {{time}} hs.',
  'general.payment-data.your-payment': 'Tu pago',
  'general.payment-data.we-are-sorry': '¡Lo sentimos!',
  'general.payment-data.error-card': 'Error en la tarjeta',
  'general.payment-data.error-wrong-amount': 'Error se debe salir e iniciar el proceso nuevamente',
  'general.payment-data.select-card-to-complete-payment':
    'Seleccioná tu tarjeta y completá los datos de pago',
  'general.payment-data.pay-booking': 'Pagar reserva',
  'general.payment-data.see-one-more-option': 'Ver 1 opción más',
  'general.payment-data.see-more-options': 'Ver {{options}} opciones más',
  'general.payment-data.see-one-less-option': 'Ver 1 opción menos',
  'general.payment-data.see-less-options': 'Ver {{options}} opciones menos',
  'general.payment-data.complete-your-payment-data': 'Completá con tus datos de pago',
  'general.payment-data.billing-header': 'Facturación',
  'general.payment-data.billing-subtitle': 'Completá los datos de facturación',
  'general.payment-data.fixed-installments.choose-your-card':
    'Seleccioná tu tarjeta y completá los datos de pago.',
  'general.payment-data.fixed-installments.total-to-pay': 'Total a abonar',
  'general.payment-data.fixed-installments.interest-amount': 'Interes: {{interestRate}}% = ',
  'general.payment-data.fixed-installments.total-financial-statement':
    'Costo financiero total (CFT): {{totalFinancialStatement}}%',
  'general.payment-data.one-installment-number': '1',
  'general.payment-data.installment': 'Cuota',
  'general.payment-data.installment.other': 'Cuotas',
  'general.payment-data.installment-with-amount': '{{count}} Cuota',
  'general.payment-data.installment-with-amount.other': '{{count}} Cuotas',
  'general.payment-data.through': 'a través de',
  'general.payment-data.fixed': 'fija',
  'general.payment-data.fixed.other': 'fijas',
  'general.payment-data.without-interest': 'sin interés',
  'general.payment-data.second-card-amount': 'Total a pagar con la segunda tarjeta ',
  'general.payment-data.selected-option-payment': 'Seleccionaste tu pago ',
  'general.payment-data.selected-option-payment-installments':
    '{{installments}} cuotas sin interés',
  'general.payment-data.selected-option-payment-one-installment-free': '1 cuota sin interés',
  'general.payment-data.selected-option-with': 'con',
  'general.payment-data.credit-card-option': 'Pagá con Tarjeta de Crédito',
  'general.payment-data.debit-card-option': 'Pagá con Tarjeta de Débito',
  'general.payment-data.card-option': 'Pagá con Tarjeta de',
  'general.payment-data.credit-option': 'Crédito',
  'general.payment-data.debit-option': 'Débito',
  'general.payment-data.first-card-option': 'Tarjeta 1',
  'general.payment-data.first-card-amount-option': 'Importe a pagar con la tarjeta 1',
  'general.payment-data.second-card-option': 'Tarjeta 2',
  'general.payment-data.select-your-card-complete-data':
    'Seleccioná tu tarjeta y completá los datos de pago.',
  'general.payment-data.debit-card-option-description':
    'Para operaciones con tarjetas de débito que superen los 100.000 pesos sugerimos validar previamente la transacción con su banco emisor',
  'general.payment-data.first-card-option-description':
    'Decidí el monto a pagar y completá los datos de pago. El monto restante se cobrará en la segunda tarjeta.',
  'general.payment-data.second-card-option-description':
    'Seleccioná tu tarjeta y completá los datos de pago.',
  'general.payment-data.second-card-option-same-holder':
    'Recordá que ambas tarjetas deben pertenecer al mismo titular.',
  'general.payment-data.cash-option': 'Pagá en efectivo',
  'general.payment-data.selected-option': 'Seleccionaste',
  'general.payment-data.cash': 'Efectivo',
  'general.payment-data.change-payment-option': 'Cambiar método de pago',
  'general.payment-data.finish-purchase': 'Finalizar Compra',
  'general.payment-data.document-number-short': 'Nº Documento',
  'general.payment-data.card.fiscal-status': 'Situación Fiscal',
  'general.payment-data.card.card': 'Tarjeta',
  'general.payment-data.card.card-number': 'Número de Tarjeta',
  'general.payment-data.card.expiration-date-format': 'Vencimiento (mes/año)',
  'general.payment-data.card.expiration-date-pattern': 'Debes indicar una fecha válida',
  'general.payment-data.card.month-placeholder': 'MM',
  'general.payment-data.card.year-placeholder': 'AAAA',
  'general.payment-data.card.card-holder': 'Titular de la Tarjeta',
  'general.payment-data.card.card-holder.two-cards': 'Nombre y apellido del titular',
  'general.payment-data.card.holder-name': 'Nombre y Apellido del pagador',
  'general.payment-data.card.holder-name.two-cards': 'Como figura en la tarjeta',
  'general.payment-data.card.helper-info': 'Como figura en el plástico',
  'general.payment-data.card.year-security-code': 'Código de Seguridad',
  'general.payment-data.card.validation.credit-card-error': 'Podés ingresar otro método de pago.',
  'general.payment-data.card.wrong-bin.error': 'Verificá la financiación seleccionada.',
  'general.payment-data.card.wrong-bin.change-payment-type': 'Cambiar medio de pago',
  'general.payment-data.card.wrong-bin.message.url': `El número de la tarjeta ingresada no corresponde al medio de pago seleccionado o no posee la financiación elegida. Si el error persiste por favor contactate con nuestro número de <a href="https://api.whatsapp.com/send?phone=541149404798">WhatsApp</a>. <a href="https://www.aerolineas.com.ar/es-ar/reservasservicios/bancos?utm_source=Website&utm_medium=Banners%20fijos%20inferiores&utm_campaign=Hola%20Cuotas&artid=aeswbic008" target="_blank">Ver condiciones</a>`,
  'general.payment-data.card.bins.better-financing':
    'La tarjeta ingresada posee financiación en {{installments}} cuotas sin interés',
  'general.payment-data.card.security-code-modal.cvv': 'CVV',
  'general.payment-data.card.security-code-modal.amex-cvc-description':
    'Código de 4 dígitos ubicado al frente de tu tarjeta.',
  'general.payment-data.card.security-code-modal.default-cvc-description':
    'Código de 3 dígitos ubicado al dorso de tu tarjeta.',
  'general.payment-data.card.security-code-modal.title': 'Código de seguridad',
  'general.payment-data.card.card-code.required': 'Debes indicar la tarjeta.',
  'general.payment-data.card.card-number.required': 'No olvides de indicar el número de tarjeta.',
  'general.payment-data.card.validation.card-number-pattern': `El número de tarjeta requiere {{digitCount}} dígitos.`,
  'general.payment-data.card.validation.expiration-date.required':
    'No olvides de indicar la fecha de vencimiento de la tarjeta.',
  'general.payment-data.card.validation.expiration-date-month.pattern':
    'Debes indicar el mes de vencimiento con 2 dígitos.',
  'general.payment-data.card.validation.expiration-date-year.pattern':
    'Debes indicar el año de vencimiento con 4 dígitos.',
  'general.payment-data.card.validation.cvc.required': 'Debes indicar el código de seguridad.',
  'general.payment-data.card.validation.cvc-pattern':
    'Debes ingresar un código de seguridad válido.',
  'general.payment-data.card.validation.holder-name.required':
    'No olvides indicar el nombre del titular de la tarjeta.',
  'general.payment-data.card.validation.holder-name.pattern':
    'Verificá que el nombre del titular de la tarjeta contenga solo letras.',
  'general.payment-data.card.validation.holder-name.pattern-max':
    'El nombre del titular debe tener hasta 26 caracteres.',
  'general.payment-data.card.validation.address-number-required':
    'No olvides indicar el número de la calle.',
  'general.payment-data.card.validation.address-number-pattern':
    'Verificá que el número de la calle un dato válido.',
  'general.payment-data.card.validation.address-street-length':
    'La calle debe tener hasta 25 caracteres.',
  'general.payment-data.card.validation.address-number-length':
    'El número de calle debe tener hasta {{digitCount}} caracteres.',
  'general.payment-data.card.validation.address-floor-length':
    'El piso debe tener hasta {{digitCount}} digitos.',
  'general.payment-data.card.validation.address-floor-pattern':
    'Verificá que el piso sea un dato válido',
  'general.payment-data.card.validation.address-city-pattern-max':
    'La ciudad debe tener hasta 30 caracteres',
  'general.payment-data.card.validation.address-city-pattern-min':
    'La ciudad debe tener mínimo 2 caracteres',
  'general.payment-data.card.validation.tax-payer-category-required':
    'No olvides indicar la Situación Fiscal',
  'general.payment-data.card.validation.tax-payer-number-required':
    'No olvides indicar el número de la Situacion Fiscal',
  'general.payment-data.card.validation.tax-payer-number-length':
    'Verificá que sea un número válido.',
  'general.payment-data.card.validation.apartment-pattern':
    'Verificá que el departamento sea un dato válido.',
  'general.payment-data.first-card-amount.required':
    'Debes indicar el monto a pagar con la primera tarjeta.',
  'general.payment-data.first-card-amount.maximum.error':
    'El monto ingresado debe ser menor a {{currency}} {{maximumAmount}}',
  'general.payment-data.first-card-amount.minimum.error':
    'El monto ingresado debe ser mayor a {{currency}} {{minimumPayment}}',
  'general.payment-data.first-card-minimum-amount': 'Monto mínimo a pagar:',
  'general.payment-data.loader-title-step-1': 'Estamos procesando tu compra…',
  'general.payment-data.loader-title-step-2': 'Estamos revisando los datos…',
  'general.payment-data.loader-title-step-3': 'Estamos generando la reserva…',
  'general.payment-data.loader-title-step-4': 'Estamos emitiendo tus boletos…',
  'general.payment-data.loader-title-step-5': 'Unos segundos más ¡tu compra está casi lista!',
  'general.payment-data.loader-title-step-6': 'El proceso está demorando mas de lo esperado.',
  'general.payment-data.loader-step-1': '¡Por favor no cierres esta ventana! ',
  'general.payment-data.loader-step-2':
    'Por favor, no cierres esta ventana y revisa tu casilla de correo.',
  'general.payment-data.loader-title': 'Estamos procesando su compra y puede demorar unos minutos.',
  'general.payment-data.loader':
    'No cierre la ventana de su navegador, y si durante el proceso pierde conexión o tiene algún error, revise su casilla de email y verifique si recibió un correo con la emisión exitosa de su ticket (Revise también la carpeta de SPAM) antes de realizar una nueva compra.<br>Ante cualquier duda comuníquese con nuestro WhatsApp <a href="https://api.whatsapp.com/send?phone=541149404798" target="_blank">+541149404798</a>',
  'general.payment-data.cash.payer-name': 'Nombre del pagador',
  'general.payment-data.cash.payer-name-placeholder': 'Ingresá el nombre.',
  'general.payment-data.cash.payer-lastname': 'Apellido del pagador',
  'general.payment-data.cash.payer-lastname-placeholder': 'Ingresá el apellido.',
  'general.payment-data.cash.payer-email-placeholder': 'Ingresá tu correo electrónico',
  'general.payment-data.cash.going-to-pay': 'Vas a abonar',
  'general.payment-data.cash.in-cash': 'en efectivo',
  'general.payment-data.authorization-failure':
    'Tu pago no pudo ser efectuado. Por favor intentá nuevamente o seleccioná un medio de pago diferente. Si el error persiste, por favor contactate a nuestro número de WhatsApp al +54 9 11 4940 4798.',
  'general.payment-data.global-payment-retrial-0': 'Lo sentimos, no se puedo procesar el pago.',
  'general.payment-data.global-payment-retrial-1': 'Por favor, intente nuevamente.',
  'general.payment-data.global-payment-retrial-button': 'Reintentar',
  'general.payment-data.3p-payment.redirect-warning': 'Aviso de Redirección',
  'general.payment-data.3p-payment.redirect-message':
    'Te redireccionaremos a otra página para que puedas realizar el pago de tu reserva.',
  'general.payment-data.3p-payment.access-button': 'Ingresar',
  'general.pagination.next': 'Siguiente',
  'general.payment-data.billing.mercado-pago.title':
    'Ahora podés pagar tus pasajes a través de Mercado Pago',
  'general.payment-data.billing.mercado-pago.subtitle': `Luego de completar los datos de facturación, <b>te redireccionaremos a Mercado Pago</b> donde podrás abonar tu compra.`,
  'general.payment-data.billing.mercado-pago.canceled-purchase-request-alert': `<p><b>Recordá que tenés {{time}} min para realizar el pago. </b>De lo contrario, tu solicitud de compra será cancelada.</p>`,
  'general.payment-data.instructions.mercado-pago.title':
    '¡Ya casi estás! Ahora solo te falta realizar el pago',
  'general.payment-data.instructions.mercado-pago.subtitle':
    'Recordá que debés hacer el pago antes de las {{time}} del {{date}}. De lo contrario, tu solicitud será cancelada.',
  'general.payment-data.instructions.mercado-pago.title-step':
    'Instrucciones para realizar el pago',
  'general.payment-data.instructions.mercado-pago.one-step':
    'Hacé click en Ir a pagar para acceder a Mercado Pago.',
  'general.payment-data.instructions.mercado-pago.two-step': 'Iniciá sesión y confirmá el pago',
  'general.payment-data.instructions.mercado-pago.three-step':
    '¡Listo! Cuando se procese el pago, recibirás un email de confirmación de la compra.',
  'general.payment-data.instructions.mercado-pago.go-to-pay': 'Ir a pagar',
  'general.payment.issue-reservation.error-title': 'Lo sentimos, no pudimos emitir tu reserva...',
  'general.payment.status-reservation.check-email':
    'Lamentamos informarte que, debido a un error inesperado, no hemos podido confirmar la emisión de tu reserva en este momento. Te recomendamos revisar tu casilla de correo electrónico para verificar el estado actualizado de tu reserva.',

  /** These are gds error keys and cannot be modified **/
  'gds.booking.error.authorizationFailure':
    'Tu pago no pudo ser efectuado. Por favor intentá nuevamente o seleccioná un medio de pago diferente. Si el error persiste, por favor contactate a nuestro número de WhatsApp al +54 9 11 4940 4798.',
  'gds.booking.error.wrongAmount': 'La financiación elegida no aplica para tu tarjeta.',
  'gds.booking.error.invalid-installment-card':
    'La financiación seleccionada no está disponible en este momento, intente mas tarde.',
  'gds.booking.error.invalidInstallmentDetails':
    'La financiación seleccionada no está disponible en este momento, intente mas tarde.',
  'gds.booking.error.invalid-card-number': 'El número de tarjeta es inválido.',
  'gds.booking.error.invalidRequest': 'Los datos ingresados no fueron procesados correctamente',
  'booking.document.document-number.not-valid': 'El CUIT/CUIL ingresado no es válido',
  'gds.booking.error.internalServer': 'Ha ocurrido un error, tu pago no pudo ser efectuado.',
  'gds.booking.error.insufficientFunds': 'DECLINADA - No Autorizada. Fondos insuficientes.',
  'gds.booking.error.invalidCard': 'DECLINADA - No Autorizada. Tarjeta inválida',
  'gds.booking.error.invalidChecksum': '¡Ha ocurrido un error!',
  'gds.booking.error.unauthorizedCard':
    'Autorización rechazada. Por favor reintentá con otro medio de pago.',
  'gds.booking.error.expiredCard':
    'Autorización rechazada. El vencimiento ingresado no es correcto o la tarjeta está vencida. Por favor reintentá el pago.',
  'gds.booking.error.invalidSecurityCardCode':
    'Autorización rechazada. El código de seguridad ingresado no es correcto. Por favor reintentá el pago.',
  'gds.booking.error.processingErrorInServiceProvider':
    'Hubo un problema con tu autorización. Comunicate con tu tarjeta de crédito o reintentá con otro medio de pago.',
  'gds.booking.issue-reservation.error':
    '¡Pero no te preocupes! Verás tu dinero reflejado en tu cuenta de Mercado Pago en unos minutos.',
  'gds.booking.refund-payment.error': `Por favor, en caso de no ver reflejada la compra en tu cuenta de Mercado Pago comunicate con nuestro call center.</br><b>Número de operación{{one}}: {{operationNumber}}</b>`,
  'gds.booking.refund-payment.error-second-operation-number':
    '</br><b>Número de operación 2: {{operationNumberTwo}}</b>',
  'gds.booking.booking-already-issued':
    'Lamentamos informarte que, debido a un error inesperado, no hemos podido confirmar la emisión de tu reserva en este momento. Te recomendamos revisar tu casilla de correo electrónico para verificar el estado actualizado de tu reserva.',
  'gds.booking.error.purchasing-challenge-failure':
    'La compra no pudo ser autenticada. Por favor intente nuevamente ingresando el código validador correspondiente; eligiendo otro medio de pago o comuníquese con su banco / Tarjeta. '
  /***/
}
