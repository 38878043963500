/* eslint-disable import/no-cycle */
/* eslint-disable import/order */
import NAMESPACES from 'localization/constants/namespaces'
import { BranchesPage } from 'pages'
import { arrayToLowerCase } from 'helpers/utils'
import { SUPPORTED_EN, SUPPORTED_ES } from 'localization/constants/languages'
export const langPathRegex = `/:langId([a-zA-Z]{2}-[a-zA-Z]{2})?`

export default {
  BRANCHES_ES: {
    LANG_URL: `${langPathRegex}/sucursales-y-aeropuertos`,
    component: BranchesPage,
    title: 'metadata.title.form.sales-offices',
    description: 'metadata.description.form.sales-offices',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true,
    namespaces: [NAMESPACES.SEO_METADATA, NAMESPACES.BRANCHES],
    cultures: arrayToLowerCase(SUPPORTED_ES)
  },
  BRANCHES_EN: {
    LANG_URL: `${langPathRegex}/branches-and-airports`,
    component: BranchesPage,
    title: 'metadata.title.form.sales-offices',
    description: 'metadata.description.form.sales-offices',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true,
    namespaces: [NAMESPACES.SEO_METADATA, NAMESPACES.BRANCHES],
    cultures: arrayToLowerCase(SUPPORTED_EN)
  },
  BRANCHES_PT: {
    LANG_URL: `${langPathRegex}/sucursais-e-aeroportos`,
    component: BranchesPage,
    title: 'metadata.title.form.sales-offices',
    description: 'metadata.description.form.sales-offices',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true,
    namespaces: [NAMESPACES.SEO_METADATA, NAMESPACES.BRANCHES],
    cultures: ['pt-br']
  },
  BRANCHES_IT: {
    LANG_URL: `${langPathRegex}/filiali-e-aeroporti`,
    component: BranchesPage,
    title: 'metadata.title.form.sales-offices',
    description: 'metadata.description.form.sales-offices',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true,
    namespaces: [NAMESPACES.SEO_METADATA, NAMESPACES.BRANCHES],
    cultures: ['it-eu', 'it-it']
  },
  BRANCHES_FR: {
    LANG_URL: `${langPathRegex}/agences-et-aeroports`,
    component: BranchesPage,
    title: 'metadata.title.form.sales-offices',
    description: 'metadata.description.form.sales-offices',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true,
    namespaces: [NAMESPACES.SEO_METADATA, NAMESPACES.BRANCHES],
    cultures: ['fr-fr']
  }
}
