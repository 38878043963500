import axios from 'axios'
import CONFIG from 'config'

let apiArPlus = null

const setArPlusToken = apiToken => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('__ACCESS_TOKEN_ARPLUS__', apiToken)
  }

  return apiToken
}

// TODO: When implementing 'miles +', we should revise this and the file contained at
// services folder, and hopefully unify code.
const createARPlusAPI = apiToken => {
  if (apiArPlus) {
    return apiArPlus
  }

  apiArPlus = axios.create({
    baseURL: CONFIG.API_BASE_URL,
    headers: {
      common: {
        Authorization: `Bearer ${setArPlusToken(apiToken)}`
      },
      get: {
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      },
      post: {
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      }
    }
  })

  return apiArPlus
}

export default createARPlusAPI
