/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  travelAssistanceStart,
  travelAssistanceSuccess,
  travelAssistanceError,
  travelAssistancePostStart,
  travelAssistancePostSuccess,
  travelAssistancePostError,
  clearTravelAssistance,
  clearTravelAssistancePost
} = createActions({
  TRAVEL_ASSISTANCE_START: () => {},
  TRAVEL_ASSISTANCE_SUCCESS: data => ({ data }),
  TRAVEL_ASSISTANCE_ERROR: error => ({ error }),
  TRAVEL_ASSISTANCE_POST_START: () => {},
  TRAVEL_ASSISTANCE_POST_SUCCESS: data => ({ data }),
  TRAVEL_ASSISTANCE_POST_ERROR: error => ({ error }),
  CLEAR_TRAVEL_ASSISTANCE: () => ({}),
  CLEAR_TRAVEL_ASSISTANCE_POST: () => ({})
})

const travelAssistanceFetch = pnr => async dispatch => {
  dispatch(travelAssistanceStart())

  try {
    const response = await api.get(apiRoutes.TRAVEL_ASSISTANCE(pnr))
    dispatch(travelAssistanceSuccess(response.data))
  } catch (error) {
    dispatch(travelAssistanceError(error.response ? error.response.data : error))
  }
}

const travelAssistancePost = formValues => async dispatch => {
  dispatch(travelAssistancePostStart())
  try {
    const response = await api.post(apiRoutes.TRAVEL_ASSISTANCE_CHECKOUT, formValues)
    dispatch(travelAssistancePostSuccess(response.data))
  } catch (error) {
    dispatch(travelAssistancePostError(error.response ? error.response.data : error))
  }
}

export {
  travelAssistanceFetch,
  travelAssistancePost,
  travelAssistanceStart,
  travelAssistanceSuccess,
  travelAssistanceError,
  travelAssistancePostStart,
  travelAssistancePostSuccess,
  travelAssistancePostError,
  clearTravelAssistance,
  clearTravelAssistancePost
}
