export default {
  'international-refund-form.title': 'Devolución de tickets',
  'international-refund-form.info-warning': '',
  'international-refund-form.info-text':
    'Para solicitar la devolución de tu ticket te pedimos que antes de completar la información del siguiente formulario, tengas en cuenta que:',
  'international-refund-form.info-text.details-0':
    'La devolución podrás pedirla únicamente dentro de la validez del ticket. La misma es de 12 meses desde la fecha de emitido el mismo.',
  'international-refund-form.info-text.details-1':
    'Sólo se procesarán devoluciones de tickets adquiridos a través de bocas de venta propias de Aerolíneas (web, Call center, WhatsApp, Aeropuertos y Sucursales).',
  'international-refund-form.info-text.details-2':
    'Si el ticket lo compraste en una agencia de viajes, es necesario que te contactes con la misma para solicitar la devolución.',
  'international-refund-form.info-text.details-3':
    'La devolución se procesará únicamente si la tarifa emitida lo permite y se aplicarán siempre las penalidades correspondientes según la regulación de la misma. Tené en cuenta que ante una combinación de distintos tipos de tarifas en un mismo billete, se aplicarán las condiciones de devolución de la tarifa más restrictiva.',
  'international-refund-form.info-text.details-4':
    "Si tu ticket lo adquiriste vía web, deberás completar el formulario seleccionando siempre el país de residencia, que tendrá que ser el mismo que el elegido al momento de la compra. Este formulario aplica únicamente para devolución de tickets comprados en el sitio web de <a href='https://www.aerolineas.com.ar/' target='_blank/'>www.aerolineas.com.ar</a>",
  'international-refund-form.info-text.details-5':
    'Es importante que ingreses tus datos en el formulario con exactitud. En caso de surgir alguna consulta puntual, te contactaremos a tu casilla de e-mail.',
  'international-refund-form.info-text.extra': '',
  'international-refund-form.full-name.label': 'Nombre y Apellido que figura en el ticket:',
  'international-refund-form.full-name.placeholder': 'Ingresar nombre y apellido',
  'international-refund-form.validation.contact-name.pattern':
    'Debes ingresar un nombre y apellido válidos',
  'international-refund-form.validation.full-name-required':
    'Debe ingresar al menos un nombre y apellido que figura en el ticket',
  'international-refund-form.ticket-numbers.label': 'Número de ticket a devolver:',
  'international-refund-form.validation.ticket-numbers-required':
    'Debe ingresar al menos un número de ticket',
  'international-refund-form.validation.ticket-number-invalid':
    'Debe ingresar un número de ticket válido',
  'international-refund-form.ticket-numbers.placeholder': 'Ingresar número de ticket',
  'international-refund-form.reservation-code': '6 letras solamente',
  'international-refund-form.reservation-code.label': 'Código de reserva:',
  'international-refund-form.reservation-code.placeholder': 'Ingresar código de reserva',
  'international-refund-form.validation.reservation-code-pattern':
    'Solo 6 letras y sin caracteres especiales',
  'international-refund-form.validation.reservation-code-required':
    'Debe ingresar un código de reserva',
  'international-refund-form.flight-date': 'Fecha de vuelo:',
  'international-refund-form.validation.flight-date-required': 'Debe ingresar la fecha de vuelo',
  'international-refund-form.validation.flight-date-invalid': 'Fecha inválida',
  'international-refund-form.refund-reason': 'Motivo por el cual solicita la devolución:',
  'international-refund-form.validation.refund-reason-required': 'Debe seleccionar un motivo',
  'international-refund-form.reason-for-return.label': 'Seleccionar',
  'international-refund-form.refund-reasons.cancel':
    'Cancelación/reprogramación de vuelo involuntario',
  'international-refund-form.refund-reasons.reschedule': 'Reprogramación de vuelo',
  'international-refund-form.refund-reasons.airport-change': 'Cambios de aeropuertos',
  'international-refund-form.refund-reasons.sickness': 'Familiar enfermo',
  'international-refund-form.refund-reasons.death': 'Fallecimiento',
  'international-refund-form.refund-reasons.personal-issues': 'Motivo personal',
  'international-refund-form.refund-reasons.other': 'Otro',
  'international-refund-form.section-return.label': 'Tramo sobre el que solicita la devolución:',
  'international-refund-form.section-return.placeholder': 'Ingresar origen-destino',
  'international-refund-form.error-one-required': 'Debe indicar un tramo',
  'international-refund-form.full-ticket': 'Billete completo',
  'international-refund-form.payer-name.label': 'Nombre y Apellido del pagador:',
  'international-refund-form.payer-name.placeholder': 'Ingresar nombre del pagador',
  'international-refund-form.validation.payer-name-required': 'Debe ingresar el nombre del pagador',
  'international-refund-form.validation.payer-name-pattern': 'Ingrese sólo letras en el nombre',
  'international-refund-form.city.label': 'Lugar de residencia:',
  'international-refund-form.validation.residence-required': 'Debe ingresar el lugar de residencia',
  'international-refund-form.city.placeholder': 'Ingresar ciudad',
  'international-refund-form.email.label': 'Email:',
  'international-refund-form.email.placeholder': 'Ingresar email',
  'international-refund-form.validation.email-pattern': 'Debe ingresar un email válido',
  'international-refund-form.validation.email-required': 'Debe ingresar una dirección de email',
  'international-refund-form.phone-number.label': 'Teléfono:',
  'international-refund-form.phone-number.placeholder': 'Ingresar teléfono',
  'international-refund-form.validation.phone-pattern': 'Verificá que el teléfono sea válido.',
  'international-refund-form.validation.phone-required': 'Debe ingresar un número de teléfono',
  'international-refund-form.observations.label':
    'Observaciones/comentarios (Máximo 150 caracteres):',
  'international-refund-form.observations.placeholder': 'Observaciones (máximo 150 caracteres)',
  'international-refund-form.validation.observations.max-length':
    'Puedes introducir 150 caracteres máximo',
  'international-refund-form.disclaimer':
    'Conozco y acepto las posibles penalidades o retenciones de tasas, fees y/o cargos de gestión que puedan existir para la devolución del billete.',
  'international-refund-form.validation.accept-conditions-required':
    'Debe aceptar los términos para poder continuar',
  'international-refund-form.disclaimer-contact-text-left':
    '(de lo contrario deberá contactarse a través de nuestro',
  'international-refund-form.disclaimer-contact-link-whatsapp-text': 'Whatsapp +',
  'international-refund-form.disclaimer-contact-link-whatsapp-number': '5491149404798',
  'international-refund-form.disclaimer-contact-text-right':
    'de Lun a Vie de 9 a 18hs o con nuestros canales de atención: ',
  'international-refund-form.disclaimer-contact-text-link':
    'https://www.aerolineas.com.ar/canales-de-atencion',
  'international-refund-form.result-success.title': 'Devolución de tickets',
  'international-refund-form.result-error.title': 'Devolución de tickets',
  'international-refund-form.result-success.description':
    'Gracias por contactarnos. En breve recibirás un mail con el número de tu solicitud de devolución.',
  'international-refund-form.result-error.description':
    'Ha ocurrido un error inesperado. Por favor, intente nuevamente.',
  'international-refund-form.result-success.button': 'Aceptar',
  'international-refund-form.result-error.button': 'Aceptar',
  'international-refund-form.submit-button.label': 'Aceptar',
  'international-refund-form.observations.remain-characters': 'Restan {{value}} caracteres',
  'international-refund-form.observations.remain-character': 'Resta {{value}} carácter'
}
