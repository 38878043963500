export default {
  'agreements.tabs.request': 'Solicitud de acuerdos',
  'agreements.tabs.refund': 'Devolución de tickets',
  'agreements.checkbox.accept-info':
    'He leído y acepto la política de privacidad publicada en la siguiente <a href="https://www.aerolineas.com.ar/legales?tab=politicaprivacidad" target="página">página</a>',
  'agreements.collapsible.content':
    'Mediante el presente dejo constancia que he sido debidamente informado respecto de la finalidad para la cual han sido recabados mis datos personales y quiénes pueden ser sus destinatarios, como así también del derecho que me asiste a tener acceso a los mismos, como así a peticionar su rectificación y/o supresión, todo ello conforme a lo normado en la Ley Nacional N° 25326 (Protección de Datos Personales) y su Decreto Reglamentario N° 1558/01.',
  'agreements.info-text.title':
    'Le ofrecemos la posibilidad de suscribirse al Acuerdo Corporativo para que su empresa pueda optimizar costos con excelentes beneficios.',
  'agreements.info-text.details-0':
    'Diseñado para organizar sus viajes de negocios en forma directa o a través de su agente de viajes.',
  'agreements.info-text.details-1':
    'Descuentos a destinos corporativos de la red de Aerolineas Argentinas y Austral.',
  'agreements.info-text.details-2':
    'Recompensamos su preferencia sumando puntos en forma individual en el Programa ARPLUS, si el personal de su empresa está asociado al mismo.',
  'agreements.info-text.details-3':
    'Atención personalizada de un Ejecutivo de Cuentas para atender sus necesidades y evaluar conjuntamente posibilidades de negocios.',
  'agreements.login.title': 'Mi cuenta corporativa',
  'agreements.login.username-label': 'Usuario',
  'agreements.login.username-placeholder': 'ar.all@valtech.com',
  'agreements.login.document-label': 'Documento asociado a tu cuenta',
  'agreements.login.document-placeholder': '30111222',
  'agreements.login.password-label': 'Clave',
  'agreements.login.password-placeholder': '••••••',
  'agreements.login.forgot-username': 'Olvidé mi usuario',
  'agreements.login.forgot-username.toggle': 'Cambiar a "Olvidé mi usuario"',
  'agreements.login.forgot-password': 'Olvidé mi clave',
  'agreements.login.forgot-password.toggle': 'Cambiar a "Olvidé mi clave"',
  'agreements.login.forgot-title': 'Recuperar mis credenciales corporativas',
  'agreements.login.forgot-submit': 'Recuperar {{credentialName}}',
  'agreements.login.forgot-success': 'Operación realizada en forma correcta!',
  'agreements.login.forgot-error_404': 'No se encontró el usuario indicado',
  'agreements.login.forgot-error': 'Oops, hubo un error',
  'core.token-service.auth-login.username-password.wrong': 'La clave ingresada es incorrecta.',
  'agreements.login-validation.username-pattern': 'Verificá que sea un email válido.',
  'agreements.login-validation.username-empty': 'Debes ingresar el usuario.',
  'agreements.login-validation.password-empty': 'Debes ingresar la contraseña.',
  'agreements.login-validation.document-pattern': 'El documento debe contenter sólo números.',
  'agreements.login-validation.document-empty': 'Debes ingresar un documento.',
  'agreements.request.submit-text': 'Enviar solicitud',
  'agreements.request.feedback-success-title': '¡Solicitud realizada con éxito!',
  'agreements.request.feedback-default-error-title':
    'Oops, hubo un error al procesar tu solicitud.',
  'Agreement.already-exists': 'Ya existe una solicitud de acuerdo con el cuit {{cuit}}',
  'agreement.agreement.region-detail.is-required': 'Oops, hubo un error al procesar tu solicitud.',
  'agreement.market-validation.minimum-amount':
    'Los montos ingresados no cumplen con los requisitos para obtener un acuerdo corporativo',
  'agreements.request.feedback-button-text': 'Cerrar',
  'agreements.request.helper-information':
    'Si está interesado en acceder a nuestros descuentos corporativos, por favor complete la siguiente solicitud y nos contactaremos a la brevedad para informarle el resultado de nuestro análisis:',
  'agreements.request.enterprise-title': 'Datos de la empresa',
  'agreements.request.enterprise-name.label': 'Empresa',
  'agreements.request.enterprise-name.placeholder': 'Razón social',
  'agreements.request.enterprise-name.required': 'No olvides ingresar el nombre de la empresa',
  'agreements.request.enterprise-name.pattern': 'Sólo puedes ingresar letras',
  'agreements.request.enterprise-cuit.label': 'CUIT',
  'agreements.request.enterprise-cuit.placeholder': 'Ingrese los 11 nros del CUIT sin guiones',
  'agreements.request.enterprise-tax-id.label': 'Identificador tributario',
  'agreements.request.enterprise-tax-id.placeholder': 'Ingrese su identificador tributario',
  'agreements.request.enterprise-tax-id.max': 'Ingrese 18 caracteres máximo',
  'agreements.request.enterprise-tax-id.required':
    'No olvides ingresar el identificador tributario',
  'agreement.company.cuit.not-null': 'El campo Identificación Tributaria no puede estar vacío',
  'agreement.company.cuit.format.invalid':
    'El campo Identificación Tributaria tiene un máximo de 18 caracteres',
  'agreements.request.enterprise-cuit.required': 'No olvides ingresar el cuit de la empresa',
  'agreements.request.enterprise-cuit.pattern': 'Verificá que sea un número válido',
  'agreements.request.enterprise-activity.label': 'Actividad',
  'agreements.request.enterprise-activity.required':
    'No olvides ingresar el nombre de la actividad',
  'agreements.request.enterprise-activity.pattern': 'Sólo puedes ingresar letras',
  'agreements.request.enterprise-web.label': 'Web',
  'agreements.request.enterprise-web.required': 'No olvides ingresar la url de la empresa',
  'agreements.request.enterprise-city.pattern': 'Ingresa una ciudad válida',
  'agreements.request.enterprise-city.required': 'No olvides ingresar la ciudad',
  'agreements.request.enterprise-street.pattern': 'Por favor ingresa un calle válida',
  'agreements.request.enterprise-street.required': 'No olvides ingresar la calle',
  'agreements.request.enterprise-number.pattern': 'Sólo puedes ingresar números',
  'agreements.request.enterprise-number.required': 'No olvides ingresar el número',
  'agreements.request.enterprise-floor.pattern': 'Verifica que el piso sea válido',
  'agreements.request.enterprise-apartment.pattern': 'Verifica que el departamento sea válido',
  'agreements.request.enterprise-zip-code.label': 'Código Postal',
  'agreements.request.enterprise-zip-code.pattern': 'Verifica que el código postal sea válido',
  'agreements.request.enterprise-zip-code.required': 'Debes ingresar el código postal',
  'agreements.request.contact-title': 'Datos de contacto',
  'agreements.request.contact-name.pattern': 'Ingresa sólo letras en el nombre',
  'agreements.request.contact-name.required': 'El nombre es un campo requerido',
  'agreements.request.contact-lastname.pattern': 'Ingresa sólo letras en el apellido',
  'agreements.request.contact-lastname.required': 'No olvides ingresar el apellido',
  'agreements.request.contact-job-title.label': 'Puesto laboral',
  'agreements.request.contact-job-title.required': 'No olvides indicar tu puesto laboral',
  'agreements.request.contact-job-title.pattern': 'El puesto laboral sólo admite letras',
  'agreements.request.contact-agreement': 'No olvides aceptar los terminos y condiciones',
  'agreements.request.contact-direct-travel-management.label':
    '¿Gestiona sus viajes de forma directa con AR/AU?',
  'agreements.request.contact-direct-travel-agency.name.label':
    'Indique los nombres de las agencias separados por comas:',
  'agreements.request.contact-direct-travel-agency.name.required':
    'Indique el nombre de la/s agencia/s',
  'agreements.request.contact-direct-travel-agency.name.pattern': 'Ingrese sólo letras',
  'agreements.request.contact-direct-travel-agency.name.placeholder': 'Nombre de la agencia',
  'agreements.request.travels-title': 'Viajes',
  'agreements.request.travels.helper-information':
    'Para facilitar nuestra evaluación, agradeceremos completar estas planillas con datos precisos acerca del gasto total anual en viajes corporativos en cada una de las regiones señaladas.',
  'agreements.request.travels.CABOTAGE-title': 'Cabotaje',
  'agreements.request.travels.REGIONAL-title': 'Regional',
  'agreements.request.travels.INTERNATIONAL-title': 'Internacional',
  'agreements.request.travels.origin.label': 'Origen',
  'agreements.request.travels.origin.placeholder': 'Ciudad de origen',
  'agreements.request.travels.origin.required': 'Origen requerido',
  'agreements.request.travels.destination.label': 'Destino',
  'agreements.request.travels.destination.placeholder': 'Ciudad de destino',
  'agreements.request.travels.destination.required': 'Destino requerido',
  'agreements.request.travels.route-row.add': 'Agregar otra ruta',
  'agreements.request.travels.route-row.remove': 'Eliminar ruta',
  'agreements.request.travels.anual-spending.label': 'Gasto anual',
  'agreements.request.travels.anual-spending.required': 'No olvides completar el gasto anual',
  'agreements.request.travels.anual-spending.pattern':
    'Monto incorrecto. Recuerde indicar los decimales con punto',
  'agreements.request.travels.CABOTAGE-currency': 'ARS',
  'agreements.request.travels.REGIONAL-currency': 'USD',
  'agreements.request.travels.INTERNATIONAL-currency': 'USD',
  'agreements.register-user.title': 'Generar usuario/contraseña',
  'agreements.register-user.helper-information':
    'Bienvenido a nuestra web para Clientes Corporativos. Por favor complete el presente formulario para generar su usuario y contraseña y habilitar la compra de sus pasajes con descuento a través de nuestra sección "Acuerdos Corporativos".  Almacenaremos sus datos personales en nuestra base de datos con el fin de garantizar su acceso y la recuperación de su usuario/contraseña en el caso de olvido o pérdida.',
  'agreements.register-user.labels.acceptPromotions': 'Acepto recibir promociones por SMS',
  'agreements.register-user.labels.acceptNewsletter':
    'Acepto recibir comunicaciones en mi cuenta de e-mail',
  'agreements.register-user.labels.acceptPersonalDataUse':
    'He leído y acepto la política de privacidad publicada en la siguiente página:  <a href="https://www.aerolineas.com.ar/legales?tab=politicaprivacidad">https://www.aerolineas.com.ar/legales</a>',
  'agreements.register-user.submit-response-ok-title': '¡Tu solicitud se procesó con éxito!',
  /* This multiple lines key could be improved when modals resolve their own paragraphs */
  'agreements.register-user.submit-response-ok-msg-mobile-0': 'Te enviamos un mail para',
  'agreements.register-user.submit-response-ok-msg-mobile-1': 'que confirmes tu cuenta.',
  'agreements.register-user.submit-response-ok-msg-mobile-2':
    'Verificá que <a>aerolineas_PLUS@aerolineas.com.ar</a> se encuentre como correo seguro.',
  'agreements.register-user.submit-response-ok-msg-desktop-0':
    'Te enviamos un mail para que confirmes tu cuenta.',
  'agreements.register-user.submit-response-ok-msg-desktop-1':
    'Verificá que <a>aerolineas_PLUS@aerolineas.com.ar</a> se encuentre como correo seguro.',
  'agreement.employee.not-found-msg-mobile-0':
    'El usuario no ha sido creado porque se encontraron datos inválidos.',
  'agreement.employee.not-found-msg-desktop-0':
    'El usuario no ha sido creado porque se encontraron datos inválidos.',
  'agreement.service.auth-service.create-user.reset-password-msg-mobile-0':
    'Te enviamos un mail para que',
  'agreement.service.auth-service.create-user.reset-password-msg-mobile-1':
    'restablezcas la contraseña de la cuenta. ',
  'agreement.service.auth-service.create-user.reset-password-msg-mobile-2':
    'Verificá que <a>aerolineas_PLUS@aerolineas.com.ar</a> se encuentre como correo seguro.',
  'agreement.service.auth-service.create-user.reset-password-msg-desktop-0':
    'Te enviamos un mail para que restablezcas la contraseña de la cuenta. ',
  'agreement.service.auth-service.create-user.reset-password-msg-desktop-1':
    'Verificá que <a>aerolineas_PLUS@aerolineas.com.ar</a> se encuentre como correo seguro.',
  'agreement.employee.not-found-title': 'Lo sentimos, tu solicitud no pudo ser procesada',
  'agreement.service.auth-service.create-user.reset-password-title': '¡Este usuario ya existe!',
  'agreements.register-user.submit-response-error-title':
    'Lo sentimos, tu solicitud no pudo ser procesada',
  'agreements.register-user.submit-response-error-msg': 'Intenta de nuevo más tarde.',
  'agreements.register-user-response.back-button-label': 'Volver al inicio',
  'agreements.validation.name-pattern': 'Ingresa sólo letras en el nombre.',
  'agreements.validation.name-required': 'El nombre es un campo requerido.',
  'agreements.validation.lastname-pattern': 'Ingresa sólo letras en el apellido.',
  'agreements.validation.lastname-required': 'El apellido es un campo requerido.',
  'agreements.validation.document-pattern': 'El documento debe contenter sólo números.',
  'agreements.validation.document-required': 'Debes ingresar un documento.',
  'agreements.validation.cellphone-pattern': 'Verificá que el teléfono contenga sólo números.',
  'agreements.validation.email-pattern': 'Verificá que sea un email válido.',
  'agreements.validation.email-required': 'Debes ingresar un correo electrónico para contactarte.',
  'agreements.validation.acceptPersonalDataUse-required':
    'No olvides aceptar la política de uso de datos personales.',
  'agreements.refund-request.information.refund-due':
    'Recuerde que podrá utilizar su ticket por un período de 12 meses a partir de la fecha de compra. Por favor, complete la siguiente información para iniciar la solicitud de devolución de tickets. Confirme que la tarifa abonada permite reembolsos.',
  'agreements.refund-request.information.important': 'IMPORTANTE',
  'agreements.refund-request.information.important-details':
    'Usted deberá completar el formulario seleccionando el mismo país de residencia que eligió al momento de comprar el Ticket.',
  'agreements.refund-request.information.service':
    'El servicio de solicitud de devolución está habilitado exclusivamente para tickets adquiridos en nuestra página aerolineas.com, país ARGENTINA y con tarjeta de crédito.',
  'agreements.refund-request.information.cash-payment':
    'EMISIONES VÍA PAGO FÁCIL/PAGO MIS CUENTAS: Si tu tarifa admite devolución, la misma debe solicitarse a través del formulario online creado para tal fin. En caso de tratarse de un pago realizado con la modalidad Pago Fácil o Pago Mis Cuentas, ya que el reintegro se realizará en efectivo, recibirás por mail el número de voucher para presentar la devolución en la Oficina de Ventas más cercana. En caso de montos superiores a ARS 10.000 te contactaremos para coordinar el lugar y fecha en que te presentarás, de esta manera nos asegurarnos que la oficina cuente con el efectivo necesario.',
  'agreements.refund-request.information.contact':
    'La devolución se procesará únicamente si la tarifa emitida lo permite y se aplicarán siempre las penalidades correspondientes según la regulación de la misma. Tené en cuenta que ante una combinación de distintos tipos de tarifas en un mismo billete, se aplicarán las condiciones de devolución de la tarifa más restrictiva. El cargo de gestión (fee de emisión) no posee devolución. La devolución de la percepción a cuenta del Impuesto a los Bienes Personales/Ganancias (S5) que fuera derogada por la RG 5463/23 sólo puede reclamarse a la AFIP. Si tu ticket lo adquiriste vía web, deberás completar el formulario seleccionando siempre el país de residencia, que tendrá que ser el mismo que el elegido al momento de la compra. Nota: este formulario es únicamente para tickets comprados en ARGENTINA. Es importante que ingreses tus datos en el formulario con exactitud. En caso de surgir alguna consulta puntual, te contactaremos a tu casilla de e-mail.',
  'agreements.refund-request.result-success.title': 'Nuestro equipo está procesando tu solicitud.',
  'agreements.refund-request.result-success.description':
    'Te enviaremos un e-mail con la resolución de tu caso.'
}
