/* eslint-disable import/no-cycle */
import loadableDelay from 'hoc/loadableDelay'

export const ArPlusPage = loadableDelay(() => import('./ArPlusPage'))

export const ArPlusDashboardPage = loadableDelay(() => import('./DashboardPage'))

export const ArPlusLoginPage = loadableDelay(() => import('./LoginPage'))

export const ForgottenPasswordPage = loadableDelay(() => import('./ForgottenPasswordPage'))

export const RecoverMembershipCode = loadableDelay(() => import('./RecoverMembershipCode'))

export const ArplusLastOperationsPage = loadableDelay(() => import('./LastOperationsPage'))

export const RegisterArPlusPage = loadableDelay(() => import('./RegisterArPlusPage'))

export const PreRegisterArPlusPage = loadableDelay(() => import('./PreRegisterArPlusPage'))

export const PaymentMilesPage = loadableDelay(() => import('./PaymentMilesPage'))

export const AccountEvaluationPage = loadableDelay(() => import('./AccountEvaluationPage'))

export const TransferMilesPage = loadableDelay(() => import('./TransferMilesPage'))

export const ElitePartnersPage = loadableDelay(() => import('./ElitePartnersPage'))

export const AccountDataPage = loadableDelay(() => import('./AccountDataPage'))

export const FirstAccessPage = loadableDelay(() => import('./FirstAccessPage'))

export const SetFirstAccessPasswordPage = loadableDelay(() =>
  import('./SetFirstAccessPasswordPage')
)

export const RecoverMailPage = loadableDelay(() => import('./RecoverMailPage'))

export const ArplusCreditMilesPage = loadableDelay(() => import('./CreditMilesPage'))

export const ArplusGiftMilesPage = loadableDelay(() => import('./GiftMilesPage'))

export const UnsubscribeArplusPage = loadableDelay(() => import('./UnsubscribeConfirmationPage'))

export const ArplusRecoverEmailPage = loadableDelay(() => import('./RecoverEmailPage'))

export const ArplusRecoverEmailConfirmationPage = loadableDelay(() =>
  import('./RecoverEmailConfirmationPage')
)

export const ChangePasswordPage = loadableDelay(() => import('./ChangePasswordPage'))

export const VerifyYourAccountPage = loadableDelay(() => import('./VerifyYourAccountPage'))
