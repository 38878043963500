export default {
  'fiscal-credit.banner-title': 'Constancia de crédito fiscal',
  'fiscal-credit.form-description-0':
    'Aerolíneas Argentinas S.A., se encuentra exceptuada de cumplir con el Régimen de Facturación establecido en la RG 1415, dado que estamos comprendidos en el Anexo I, inciso E) de la mencionada Resolución.',
  'fiscal-credit.form-description-1':
    'Si Usted adquirió un ticket de cabotaje, el cual se encuentra gravado por el impuesto IVA, deberá solicitar la “Constancia de Crédito Fiscal”, según lo establecido en el Anexo IV, punto B), inciso 4., de la RG 1415. Será enviada una constancia por cada documento emitido completando el siguiente formulario. La Constancia le será enviada a su dirección de correo electrónico dentro de las próximas 48 hs.',
  'fiscal-credit.form-description-2':
    'En cambio si adquirió, un ticket internacional o de cabotaje con origen y/o destino Tierra del Fuego, el ticket o el recibo remitido por mail, es documento válido, asimilable a una factura.',
  'fiscal-credit.form-subtitle': 'Datos del pagador',
  'fiscal-credit.form.ticket-numbers.label': 'Número de ticket',
  'fiscal-credit.form.ticket-numbers.placeholder': 'Ingresar número de ticket',
  'fiscal-credit.form.cuit.label': 'CUIT de la empresa solicitante',
  'fiscal-credit.form.cuit.placeholder': 'Ingresar CUIT de la empresa',
  'fiscal-credit.form.company-name.label': 'Razón social',
  'fiscal-credit.form.company-name.placeholder': 'Ingresar razón social',
  'fiscal-credit.form.address.label': 'Domicilio',
  'fiscal-credit.form.address.placeholder': 'Ingresar domicilio',
  'fiscal-credit.form.phone-number.label': 'Teléfono',
  'fiscal-credit.form.phone-number.placeholder': 'Ingresar teléfono',
  'fiscal-credit.form.e-mail.label': 'Email',
  'fiscal-credit.form.e-mail.placeholder': 'Ingresar email',
  'fiscal-credit.form.submit-button.label': 'Aceptar',
  'fiscal-credit.form.validation-schema.ticket-number-pattern':
    'El número de ticket debe comenzar con 044',
  'fiscal-credit.form.validation-schema.ticket-number-required':
    'Debes ingresar un número de ticket',
  'fiscal-credit.form.validation-schema.ticket-number-min-numbers':
    'El número de ticket/VCR debe tener 13 dígitos',
  'fiscal-credit.form.validation-schema.ticket-number-max-numbers':
    'El número de ticket/VCR debe tener 13 dígitos',
  'fiscal-credit.form.validation-schema.cuit-pattern': 'Verificá que sea un CUIT válido',
  'fiscal-credit.form.validation-schema.cuit-required': 'Debes ingresar un número de CUIT',
  'fiscal-credit.form.validation-schema.cuit-max-length': 'El número de CUIT debe tener 11 dígitos',
  'fiscal-credit.form.validation-schema.company-name-pattern':
    'Verificá que sea una razón social válida',
  'fiscal-credit.form.validation-schema.company-name-required':
    'Debes ingresar la razón social de la empresa',
  'fiscal-credit.form.validation-schema.address-pattern': 'Verificá que sea un domicilio válido',
  'fiscal-credit.form.validation-schema.address-required': 'Debes ingresar un domicilio',
  'fiscal-credit.form.validation-schema.phone-number-pattern':
    'El teléfono puede contener sólo números y el signo +',
  'fiscal-credit.form.validation-schema.phone-number-required': 'Debes ingresar un teléfono',
  'fiscal-credit.form.validation-schema.email-pattern': 'Verificá que sea un email válido',
  'fiscal-credit.form.validation-schema.email-required': 'Debes ingresar un correo electrónico',
  'fiscal-credit.modal.title': 'Constancia de Crédito Fiscal',
  'fiscal-credit.modal.button': 'Aceptar',

  //backend responses
  'loyalty.service.tax-credit.receipt-issued':
    'Comprobante ya emitido, se enviará copia del Original.',
  'loyalty.service.tax-credit.issued-with-delay':
    'Te recordamos que la recepción del mail con la constancia demora 24hs. Te sugerimos revisar la carpeta correo no deseado.',
  'loyalty.service.tax-credit.not-generated':
    'Por errores en el proceso no se genera comprobante, inténtelo nuevamente.',
  'loyalty.service.tax-credit.error': 'Ha ocurrido un error.',
  'loyalty.service.tax-credit.bad-request-exception': 'Ha ocurrido un error.',
  'loyalty.service.tax-credit.exception': 'Ha ocurrido un error.'
}
