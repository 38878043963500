/* eslint-disable import/order */
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider, StyleSheetManager } from 'styled-components'
import { PersistGate } from 'redux-persist/integration/react'
import { useSSR } from 'react-i18next'
import { shouldForwardProp } from 'helpers/styled-components'
import { saveTokenFromCookies } from 'server/utils/tokenUtils'
import dayjs from 'dayjs'
import { loadableReady } from '@loadable/component'

import { configAxiosInterceptors } from 'services/api'
import configureStore from 'helpers/configureStore'
import theme from 'styles/theme'

import App from './App'
import { TransitionLoader } from 'components/loaders'

import 'localization/i18n'

//TODO: Replace 'es' with value sent by server
dayjs.locale('es')

if (module.hot) {
  module.hot.accept('./App', () => {
    const NewApp = require('./App').default
    render(NewApp)
  })
}

if (document.readyState !== 'loading') {
  render(App)
} else {
  document.addEventListener('DOMContentLoaded', function() {
    render(App)
  })
}

function render(Root) {
  const { store, persistor } = configureStore(window.__PRELOADED_STATE__)

  const token = {
    access_token: window.__ACCESS_TOKEN__,
    expires_in: window.__TOKEN_EXPIRATION__
  }
  // Save the token in cookies - client side
  token.access_token && saveTokenFromCookies(token)

  //Config global interceptors
  configAxiosInterceptors(store)

  loadableReady(() => {
    const BaseApp = props => {
      useSSR(window.initialI18nStore, window.initialLanguage)
      return (
        <Suspense fallback={<TransitionLoader />}>
          <BrowserRouter>
            <PersistGate loading={null} persistor={persistor}>
              {props.children}
            </PersistGate>
          </BrowserRouter>
        </Suspense>
      )
    }
    ReactDOM.hydrate(
      // Necessary to use StyleSheetManager due to shouldForwardProp no longer being provided by default
      // Major migration to transient props ($prefix) on all props would be needed
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <Provider store={store}>
          <ThemeProvider theme={{ ...theme, root: window.root }}>
            <BaseApp>{Root}</BaseApp>
          </ThemeProvider>
        </Provider>
      </StyleSheetManager>,
      document.getElementById('root')
    )
  })
}
