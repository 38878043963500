/* eslint-disable import/no-cycle */
import { matchPath } from 'react-router-dom'

import { fetchArplusForServer } from 'actions/cms/arplus/home-data'
import { fetchDarkSiteForServer } from 'actions/cms/darkSite'
import { fetchHeaderForServer } from 'actions/cms/header'
import { fetchLandingsForServer } from 'actions/cms/landings'
import { fetchSlidersForServer } from 'actions/cms/slider'
import GDS_MESSAGES from 'constants/gdsMessages'
import { PROCESS_TYPE, LEVEL, DESCRIPTION } from 'constants/log'
import { FORMTRANSLATION_KEYS_FOR_FORMS } from 'constants/reservationServiceForm'
import ROUTES from 'constants/routes'
import { getCookie } from 'helpers/cookies'
import log from 'helpers/logs'
import { isConfirmationStep } from 'helpers/url/checkout'
import { isExternalPaymentMercadoPago, isExternalPayment3P } from 'helpers/url/externalPayment'
import { isEmpty } from 'helpers/utils'
import { setLanguageInRoute } from 'localization/helpers'
import COOKIE_NAMES from 'server/utils/cookieNames'

export const getStoreForUrl = (
  pathname,
  initialLanguage,
  staticRoute,
  { headers: { cookie }, i18n },
  purchasingChallenge
) => {
  const promisesList = []

  // Store property mappings
  const DEFAULT_REDUX_PROPERTY = {
    ARPLUS_DATA: 'arplusData',
    LANDINGS: 'landings',
    SLIDERS: 'sliders',
    HEADER: 'header',
    DARK_SITE: 'darkSite',
    RESERVATION: 'reservation',
    PAYMENT_DATA_FORMIK: 'paymentDataFormik',
    EXTERNAL_PAYMENT: 'externalPayment',
    PURCHASE: 'purchase',
    PURCHASING_CHALLENGE: 'purchasingChallenge'
  }

  // Helper function to add promises with names
  const addPromise = (name, promise) => promisesList.push({ name, promise })

  const createMappedCookiePromise = mapper =>
    new Promise((resolve, reject) => {
      const data = mapPurchaseResult(cookie)
      if (data) {
        return resolve(mapper ? mapper(data) : data)
      } else {
        return reject(new Error(DESCRIPTION.ERROR_RETRIEVING_COOKIE))
      }
    })

  // Handle dark site fetch
  addPromise(
    DEFAULT_REDUX_PROPERTY.DARK_SITE,
    fetchDarkSiteForServer(i18n)
      .then(result => Promise.resolve(result))
      .catch(error => Promise.reject(error))
  )

  // Handle global retrial check
  const isGlobalRetrial = matchPath(pathname, ROUTES.CHECKOUT_RETRY.LANG_URL)
  if (isGlobalRetrial) {
    addPromise(
      DEFAULT_REDUX_PROPERTY.PAYMENT_DATA_FORMIK,
      createMappedCookiePromise(someData => someData.data.paymentDataFormik)
    )

    addPromise(
      DEFAULT_REDUX_PROPERTY.RESERVATION,
      createMappedCookiePromise(someData => ({
        data: { reservationCode: someData.data.reservationCode }
      }))
    )
  }

  /** @description All routes common store setup */
  if (staticRoute) {
    const homeOrPressComm =
      staticRoute.name === ROUTES.HOME.name || staticRoute.name === ROUTES.PRESS_COMMUNICATION.name

    if (staticRoute.header || homeOrPressComm) {
      addPromise(
        DEFAULT_REDUX_PROPERTY.HEADER,
        fetchHeaderForServer(i18n)
          .then(result => Promise.resolve(result))
          .catch(error => Promise.reject(error))
      )
    }

    if (staticRoute.banner || homeOrPressComm) {
      addPromise(
        DEFAULT_REDUX_PROPERTY.SLIDERS,
        fetchSlidersForServer(i18n)
          .then(result => Promise.resolve(result))
          .catch(error => Promise.reject(error))
      )
    }

    if (staticRoute.name === ROUTES.ARPLUS_LOGIN.name) {
      addPromise(
        DEFAULT_REDUX_PROPERTY.ARPLUS_DATA,
        fetchArplusForServer(i18n)
          .then(result => Promise.resolve(result))
          .catch(error => Promise.reject(error))
      )
    }
  } else {
    // Handle landing page scenario
    addPromise(
      DEFAULT_REDUX_PROPERTY.HEADER,
      fetchHeaderForServer(i18n)
        .then(result => Promise.resolve(result))
        .catch(error => Promise.reject(error))
    )

    addPromise(
      DEFAULT_REDUX_PROPERTY.SLIDERS,
      fetchSlidersForServer(i18n)
        .then(result => Promise.resolve(result))
        .catch(error => Promise.reject(error))
    )

    addPromise(
      DEFAULT_REDUX_PROPERTY.LANDINGS,
      fetchLandingsForServer({ status: 1 }, i18n)
        .then(result => Promise.resolve(filterLandingsFromStore(pathname, result, initialLanguage)))
        .catch(error => Promise.reject(error))
    )
  }

  // Handle special cases based on pathname
  if (matchPath(pathname, ROUTES.EXTERNAL_PAYMENT.LANG_URL)) {
    if (isExternalPaymentMercadoPago({ pathname })) {
      addPromise(
        DEFAULT_REDUX_PROPERTY.EXTERNAL_PAYMENT,
        createMappedCookiePromise(someData => ({
          data: {
            reservationCode: someData.data.reservationCode,
            lastName: someData.data?.lastName
          }
        }))
      )
    } else {
      addPromise(
        DEFAULT_REDUX_PROPERTY.PURCHASE,
        createMappedCookiePromise(someData => ({
          data: { reservationCode: someData.data.reservationCode }
        }))
      )
    }
  }

  if (
    matchPath(pathname, ROUTES.CHECKOUT.LANG_URL) &&
    isConfirmationStep(matchPath(pathname, ROUTES.CHECKOUT.LANG_URL))
  ) {
    addPromise(
      DEFAULT_REDUX_PROPERTY.RESERVATION,
      createMappedCookiePromise(someData => someData)
    )
  }

  // Handle purchasing challenge
  if (purchasingChallenge?.error) {
    addPromise(DEFAULT_REDUX_PROPERTY.PURCHASE, Promise.resolve(purchasingChallenge))
  } else if (purchasingChallenge?.data) {
    addPromise(DEFAULT_REDUX_PROPERTY.PURCHASING_CHALLENGE, Promise.resolve(purchasingChallenge))
  }

  // Execute all promises in parallel without interrupting if any fail
  return Promise.allSettled(promisesList.map(item => item.promise)).then(results => {
    const dataToClient = {}
    const dataLog = {}

    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        dataToClient[promisesList[index].name] = result.value
        dataLog[promisesList[index].name] = result.value
      } else {
        dataLog[promisesList[index].name] =
          result.reason?.message || DESCRIPTION.ERROR_RETRIEVING_COOKIE
      }
    })

    const isExternalPayment =
      isExternalPaymentMercadoPago({ pathname }) || isExternalPayment3P({ pathname })

    if (isExternalPayment) {
      log.register({
        level: LEVEL.INFO,
        description: DESCRIPTION.RECOVER_COOKIES,
        processType: PROCESS_TYPE.RECOVER_COOKIES_IN_SSR,
        apiRoute: pathname,
        pnrOrShoppingId: '',
        data: dataLog
      })
    }

    return dataToClient
  })
}

export const getComponentForUrl = pathname => {
  const componentMatches = Object.keys(ROUTES).map(route =>
    matchPath(pathname, { path: ROUTES[route].LANG_URL, exact: true }) ? ROUTES[route] : null
  )

  return componentMatches.filter(component => !isEmpty(component)).pop()
}

export const getFormTitleForPathname = pathname => {
  const lastIndex = pathname.lastIndexOf('/')
  const slicedLastIndex = pathname.slice(lastIndex + 1)
  return slicedLastIndex && FORMTRANSLATION_KEYS_FOR_FORMS[slicedLastIndex]
}

export const getLandingForPathName = (pathname, landings, language) =>
  ((landings && landings.data) || []).find(({ landingPageUrl }) => {
    const pathWithLanguage = setLanguageInRoute(landingPageUrl, language)
    return matchPath(pathname, { path: pathWithLanguage, exact: true })
  })

export const getBodyColor = store => {
  if (!isEmpty(store?.darkSite)) {
    if ((store.darkSite.data || []).find(({ darkSiteEnabled }) => darkSiteEnabled)) {
      return 'style="filter: grayscale(100%)"'
    }
  }

  return ''
}

// Ensures there's no possibility of having an unnecessary endless array of landings
// at client side. It needs HybridLink to force landing links being catched at ssr
// instead of client side react-router.
export const filterLandingsFromStore = (matchedPath, landings, language) => {
  const foundLanding = getLandingForPathName(matchedPath, landings, language)
  if (foundLanding) {
    return { data: landings.data.filter(landingItem => landingItem.nid === foundLanding.nid) }
  }
  // if did fetch landings but path does not match any of them there's no need to have them
  // inside redux client store.
  return []
}

/**
 * Helps mobile exchanges redirection not to fail. :)
 */
export const mapExchangesDirective = (store, cookieHeaders) => {
  const cookie = getCookie(cookieHeaders, COOKIE_NAMES.MOBILE_ENTRY_EXCHANGES)
  const { lastName, pnr } = (cookie && JSON.parse(cookie)) || {}

  if (lastName && pnr) {
    store.reservation = {
      data: {
        isMobileEntryPoint: true,
        passengersData: [{ lastName }],
        bookingMetadata: { reservationCode: pnr }
      }
    }
  }
}

const parseCookieResult = (cookieHeaders, nameCookie) => {
  const cookie = getCookie(cookieHeaders, nameCookie)
  const data = (cookie && JSON.parse(cookie)) || {}
  if (cookie) {
    return { data }
  }
}

/**
 * Redirect to checkout confirmation page with purchase data OR global payment retrial page
 */
export const mapPurchaseResult = cookieHeaders =>
  parseCookieResult(cookieHeaders, COOKIE_NAMES.EXTERNAL_PAYMENT)

export const isGlobalRetrialError = error =>
  error.response &&
  error.response.status === 400 &&
  error.response.data &&
  error.response.data.reservationCode &&
  error.response.data.description &&
  error.response.data.description[0] &&
  error.response.data.description[0] === GDS_MESSAGES.CL_GLOBAL_PAYMENT_ERROR_INVALID_CARD

export const isGlobalPaymentError = error =>
  error.response &&
  error.response.status === 500 &&
  error.response.data &&
  error.response.data.statusCode === 500 &&
  error.response.data.errorMessage === GDS_MESSAGES.PAYMENT_FAILURE

export const flightSummaryInvalidRequest = error =>
  error.statusCode === 400 && error.errorMessage === GDS_MESSAGES.FLIGHT_INVALID_REQUEST
