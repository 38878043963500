export default {
  'sublos.header.greeting': '¡Hola',
  'sublos.header.close': 'Cerrar sesión',
  'sublos.login-page.welcome.greeting': '¡Buenos días!',
  'sublos.login-page.welcome.title': 'Disfrutá de beneficios exclusivos',
  'sublos.login-page.welcome.link':
    'https://ftarplus.aerolineas.com.ar/Intranet/Images/Instructivo.pdf',
  'sublos.login-page.welcome.paragraph':
    'Si tenes problemas para ingresar al sistema por una discrepancia o duda en los datos solicitados, comunicate con <strong>sublos@aerolineas.com.ar</strong>. Para tu tranquilidad, los datos enviados a través de internet se encuentran protegidos y encriptados. Comprobá siempre el certificado de un sitio antes de introducir cualquier información sensible.',
  'sublos.login-page.welcome.footer-text':
    'Este sitio contiene información reservada cuyo uso es exclusivo de Aerolíneas Argentinas. La publicación, uso, copia e impresión total o parcial de la información queda prohibida y su divulgación se encuentra sancionada por ley. Visualización optimizada para resolución de pantalla de 1024X768 y en el caso de Internet Explorer 8.0 o superiores.',
  'sublos.login-page.login-with-token-error':
    'Lo sentimos, ha ocurrido un error al iniciar sesion desde Aeroweb',
  'sublos.login-page.form.company.label': 'Empresa',
  'sublos.login-page.form.company.placeholder': 'Seleccionar una opción',
  'sublos.login-page.form.record.label': 'Legajo',
  'sublos.login-page.form.record.placeholder': 'Ingresá tu número de legajo',
  'sublos.login-page.form.admissionMonth.label': 'Fecha de ingreso',
  'sublos.login-page.form.admissionMonth.month.label': 'Mes',
  'sublos.login-page.form.admissionMonth.year.label': 'Año',
  'sublos.login-page.form.documentType.label': 'Tipo',
  'sublos.login-page.form.documentType.placeholder': 'Seleccionar',
  'sublos.login-page.form.documentNumber.label': 'Número de documento',
  'sublos.login-page.form.documentNumber.placeholder': 'Ingresá tu DNI',
  'sublos.login-page.form.birthday.label': 'Fecha de nacimiento',
  'sublos.login-page.form.birthday.placeholder': 'Fecha de nacimiento',
  'sublos.login-page.form.button.label': 'INGRESAR',
  'sublos.login-page.form.validation.field-required': 'Este campo es requerido',
  'sublos.login-page.form.validation.record.typeError': 'Debe ingresar caracteres númericos',
  'sublos.login-page.form.validation.record.min-number-required': 'Deben ser mínimo 5 digitos',
  'sublos.login-page.form.validation.record.max-number-required': 'Deben ser máximo 7 digitos',
  'sublos.login-page.form.validation.documentNumber.typeError':
    'Debe ingresar caracteres alfanuméricos',
  'sublos.login-page.form.validation.documentNumber.min-number-required':
    'Deben ser mínimo 7 digitos',
  'sublos.login-page.form.validation.documentNumber.max-number-required':
    'Deben ser máximo 20 digitos',
  'sublos.login-page.form.result-error.regular': 'Lo sentimos, ha ocurrido un error, por favor ',
  'sublos.login-page.form.result-error.bold': 'comuniquese con sublos@aerolineas.com',
  'sublos.login-page.form.login-error.regular': 'Los datos ingresados no coiciden. ',
  'sublos.login-page.form.login-error.bold': 'Por favor, verifique e intente nuevamente.',
  'sublos.stepper.step': 'Paso',
  'sublos.stepper.of': 'de',
  'sublos.main-page.step.back-button.label': 'Volver',
  'sublos.main-page.step.user-data.title': 'Datos del titular',
  'sublos.main-page.step.payment-data.title': 'Datos del pago',
  'sublos.main-page.step.user-data.text-1':
    'Para solicitar un pasaje a una persona que no integre su grupo familiar debe solicitarlo como ',
  'sublos.main-page.step.user-data.text-2': 'No Name',
  'sublos.main-page.step.user-data.text-3':
    ', para ello primero debes gestionar el pasaje del Titular y su familia. Dentro de las 6 horas siguientes deberá solicitar el pasaje del No Name asociado al titular.',
  'sublos.main-page.step.user-data.form.ref': 'Campos obligatorios',
  'sublos.main-page.step.user-data.form.label.fullname': 'Nombre y apellido',
  'sublos.main-page.step.user-data.form.label.contract': 'Convenio',
  'sublos.main-page.step.user-data.form.label.record': 'Legajo',
  'sublos.main-page.step.user-data.form.label.marital-status': 'Estado civil',
  'sublos.main-page.step.user-data.form.label.seniority': 'Antigüedad',
  'sublos.main-page.step.user-data.form.label.business-mail': 'Mail empresarial',
  'sublos.main-page.step.user-data.form.label.personal-mail': 'Mail particular',
  'sublos.main-page.step.user-data.form.placeholder.personal-mail': 'Ingresá tu mail',
  'sublos.main-page.step.user-data.form.error.valid-mail.personal-mail':
    'Debe ingresar una dirección de correo válida',
  'sublos.main-page.step.user-data.form.error.no-business-mail.personal-mail':
    'La dirección de correo no puede pertenecer al dominio aerolineas.com.ar',
  'sublos.main-page.step.user-data.form.label.phone': 'Teléfono',
  'sublos.main-page.step.user-data.form.error-only-numbers':
    'Este campo sólo puede contener números',
  'sublos.main-page.step.user-data.form.error-valid-phone':
    'Debe ingresar un número de teléfono válido',
  'sublos.main-page.step.user-data.form.placeholder.phone': 'Ingresá tu número de teléfono',
  'sublos.main-page.step.user-data.form.label.cellphone': 'Celular',
  'sublos.main-page.step.user-data.form.placeholder.cellphone': 'Ingresá tu número de celular',
  'sublos.main-page.step.user-data.form.label.alternative-phone': 'Teléfono alternativo',
  'sublos.main-page.step.user-data.form.placeholder.alternative-phone':
    'Ingresá tu número de teléfono',
  'sublos.main-page.step.user-data.form.label.traveler-person': 'Persona que viaja',
  'sublos.main-page.step.user-data.form.label.traveler-person.famlily': 'Titular/Familiar',
  'sublos.main-page.step.user-data.form.label.traveler-person.no-name': 'No Name',
  'sublos.main-page.step.user-data.form.validation.field-required': 'Este campo es requerido',
  'sublos.main-page.step.user-data.form.validation.fullName-pattern-fullAlphabetic':
    'Ingresa solo caracteres alfabeticos',
  'sublos.main-page.step.user-data.ticket-type.title': 'Tipo de pasaje',
  'sublos.main-page.step.user-data.ticket-type.text-1':
    'Si tenes alguna duda con respecto a los tipos de pasajes que podes solicitar para vos o tu familia, recorda leer la seccion ',
  'sublos.main-page.step.user-data.ticket-type.text-2': '"Condiciones de Pasajes"',
  'sublos.main-page.step.user-data.ticket-type.text-3':
    ' donde se detalla, segun el convenio, que tipos de pasajes dispones. Cualquier consulta, el mail destinado para tal fin es ',
  'sublos.main-page.step.user-data.ticket-type.text-4': 'sublos@aerolineas.com.ar',
  'sublos.main-page.step.user-data.ticket-type.select.label': 'Tipo de pasaje',
  'sublos.main-page.step.user-data.ticket-type.select.placeholder': 'Seleccionar una opcion',
  'sublos.main-page.step.user-data.continue.button': 'Continuar',
  'sublos.main-page.step.user-data.warning.title': 'Recuerda:',
  'sublos.main-page.step.user-data.warning.text':
    '<p><b>Recuerda:</b> Para solicitar el pasaje de un No Name primero debes gestionar el pasaje del títular y dentro de las 6 horas siguientes solicitar dicho pasaje asociando al pasaje del titular. El titular y el No Name deben viajar en el misma fecha, vuelo y avión.</p>',
  'sublos.flight-offer-card.leg': 'Tramo',
  'sublos.flight-offer-card.edit': 'Editar búsqueda',
  'sublos.flight-offer-card.select': 'Seleccionar vuelo',
  'sublos.flight-offer-card.capacity': 'Ver capacidad',
  'sublos.flight-offer-card.no-stops': 'Sin escalas',
  'sublos.flight-offer-card.stops': '{{stopNumber}}° escala',
  'sublos.flight-offer-card.stopover-time': 'de espera (cambio de avión)',
  'sublos.flight-offer-card.selected': 'Seleccionado',
  'sublos.flight-offer-card.seat': 'asiento disponible',
  'sublos.flight-offer-card.seats': 'asientos disponibles',
  'sublos.flight-offer-card.count-plural-seats': 'asientos',
  'sublos.flight-offer-card.count-seats': 'asiento',
  'sublos.home.timer': 'Tiempo restante para terminar su reserva:',
  'sublos.main-page.step.select-stretch.title': 'Selección de tramos',
  'sublos.main-page.step.select-stretch.text':
    'Seleccioná un vuelo por tramos y una ruta por pedido. Recuerda que solo se podrá tomar un vuelo por tramo y un ida y vuelta por pedido. O sea que no se puede pasar dos veces por el destino desde donde se inicia la ruta.',
  'sublos.main-page.step.select-stretch.stretch': 'Tramo',
  'sublos.main-page.step.select-stretch.form.date.label': 'Fecha',
  'sublos.main-page.step.select-stretch.form.origin.label': 'Origen',
  'sublos.main-page.step.select-stretch.form.origin.placeholder': 'Seleccionar un origen',
  'sublos.main-page.step.select-stretch.form.destination.label': 'Destino',
  'sublos.main-page.step.select-stretch.form.destination.placeholder': 'Seleccionar un destino',
  'sublos.main-page.step.select-stretch.form.delete-stretch.button': 'Eliminar tramo',
  'sublos.main-page.step.select-stretch.form.add-stretch.button': 'Agregar otro tramo',
  'sublos.main-page.step.select-stretch.form.add-stretch-limit.button': ' (MAX 4)',
  'sublos.main-page.step.select-stretch.form.continue.button': 'Continuar',
  'sublos.main-page.step.select-no-name-passanger.title': 'Seleccione los pasajeros',
  'sublos.main-page.step.select-no-name-passanger.no-name': 'No Name',
  'sublos.main-page.step.select-no-name-passanger.header.text-1':
    'En caso de corresponder, debes aguardar hasta 20 minutos desde solicitado el pasaje del titular para poder visualizar la opción “Asociado al titular”. El pasajero No Name ',
  'sublos.main-page.step.select-no-name-passanger.header.text-2':
    'debe viajar en el mismo vuelo que el titular.',
  'sublos.main-page.step.select-passengers.form.name.label': 'Nombre',
  'sublos.main-page.step.select-passengers.form.name.placeholder': 'Ingresá el nombre',
  'sublos.main-page.step.select-passengers.form.lastName.label': 'Apellido',
  'sublos.main-page.step.select-passengers.form.lastName.placeholder': 'Ingresá el apellido',
  'sublos.main-page.step.select-no-name-passanger.form.date-of-birth.label': 'Fecha de nacimiento',
  'sublos.main-page.step.select-no-name-passanger.form.associated.label': 'Asociado al titular',
  'sublos.main-page.step.select-no-name-passanger.form.associated.placeholder': 'Seleccionar',
  'sublos.main-page.step.select-no-name-passanger.form.info-text-1':
    'Al enviar el pedido acepta los términos y condiciones de uso. En caso de viajar con un menor de 0 a 4 años inclusive, al momento del embarque deberá presentarse indefectiblemente con un ticket de pasajero adulto asociado (no incluye pasaje extra crew).',
  'sublos.main-page.step.select-no-name-passanger.form.info-text-2':
    'En caso de tener algún inconveniente en el proceso de reserva y pago, deberás aguardar cómo mínimo 5 minutos antes de intentar nuevamente el mismo.',
  'sublos.main-page.step.select-no-name-passanger.form.continue-button': 'Continuar',
  'sublos.main-page.step.select-no-name-passanger.form.continue-button-2':
    'volver a la selección de tramos',
  'sublos.main-page.step.select-no-name-passanger.form.add-no-name': 'Agregar otro No name',
  'sublos.main-page.step.select-no-name-passanger.form.add-no-name-limit':
    ' (MAX. {{maximumNoNamesAllowed}})',
  'sublos.main-page.step.select-no-name-passanger.form.delete-no-name': 'Eliminar No name',
  'sublos.modal.cancel-reservation-request.title': 'Cancelación de solicitud',
  'sublos.modal.cancel-reservation-request.description':
    'La solicitud de reserva en este momento se encuentra <strong>abierta</strong>, si desea volver a un paso anterior, se procede a <strong>cancelar la solicitud</strong>',
  'sublos.modal.cancel-reservation-generated.title': 'Cancelación reserva',
  'sublos.modal.cancel-reservation.button-yes': 'Si, cancelar',
  'sublos.modal.cancel-reservation.button-no': 'Volver',
  'sublos.modal.cancel-reservation.title': '¡Atención!',
  'sublos.main-page.step.payment-data.to-pay': 'Pagar',
  'sublos.modal.cancel-reservation.description':
    'Estás a punto de cancelar el proceso.  ¿Estás seguro?',
  'sublos.modal.cancel-reservation-generated.description':
    'En este momento tiene una <strong>reserva pendiente de pago</strong>, si desea volver a un paso anterior, se procede a <strong>cancelar la reserva</strong>',
  'sublos.modal.aeroweb-login.title': '¡Atención!',
  'sublos.modal.aeroweb-login.subtitle':
    'Te redireccionaremos a la Aeroweb para que puedas ingresar.',
  'sublos.modal.aeroweb-login.button.accept': 'Aceptar',
  'sublos.modal.aeroweb-login.button.cancel': 'Cancelar',
  'sublos.button.cancel-reservation-request-and-go-back': 'Cancelar la solicitud y volver',
  'sublos.button.cancel-reservation-request-and-go-home': 'Cancelar la reserva y volver al inicio',
  'sublos.main-page.step.select-no-name-passanger.modal.title':
    'No hay un vuelo asociado al titular',
  'sublos.main-page.step.select-no-name-passanger.modal.text':
    'Para solicitar el pasaje de un No Name primero debes gestionar el pasaje del títular y <span>dentro de las 6 horas</span> siguientes solicitar dicho pasaje asociando al pasaje del titular. El titular y el No Name deben viajar en la misma fecha, vuelo y avión.',
  'sublos.main-page.step.select-passengers.title': 'Seleccione los pasajeros',
  'sublos.main-page.step.select-passengers.basic.title': 'Grupo familiar básico',
  'sublos.main-page.step.select-passengers.enabled.title': 'Grupo familiar habilitado',
  'sublos.main-page.step.select-passengers.form.selected.label': 'Viaja',
  'sublos.main-page.step.select-passengers.form.relationship.label': 'Beneficiario',
  'sublos.main-page.step.select-passengers.form.fullName.label': 'Apellido y Nombre',
  'sublos.main-page.step.select-passengers.form.fullName.placeholder':
    'Ingresá el apellido y nombre',
  'sublos.main-page.step.select-passengers.form.condition.label': 'Condición / Validez',
  'sublos.main-page.step.select-passengers.form.condition.placeholder': 'Seleccione la condición',
  'sublos.main-page.step.select-passengers.form.year.label': 'Año vacacional',
  'sublos.main-page.step.select-passengers.form.year.placeholder': 'Seleccionar',
  'sublos.main-page.step.select-passengers.form.document.label': 'Número de documento',
  'sublos.main-page.step.select-passengers.form.document-type.label': 'Tipo de documento',
  'sublos.main-page.step.select-passengers.form.document-type.placeholder':
    'Seleccione tipo de documento',
  'sublos.main-page.step.select-passengers.form.document.placeholder': 'Ingresá el número',
  'sublos.main-page.step.select-passengers.form.document.bottom-label':
    'Ingresá el número sin puntos ni guiones.',
  'sublos.main-page.step.select-passanger.form.continue-button': 'Continuar',
  'sublos.main-page.step.flight-selection.title': 'Selección de vuelos',
  'sublos.main-page.step.flight-selection.summary-segment': 'Resumen de los tramos',
  'sublos.main-page.step.flight-selection.summary-segment-show': 'Ver',
  'sublos.main-page.step.flight-selection.continue-button': 'Continuar',
  'sublos.main-page.step.flight-selection.modal.title':
    'No hay vuelos disponibles para los tramos buscados',
  'sublos.main-page.step.flight-selection.modal.text':
    'Por favor, vuelve a realizar una nueva búsqueda de tramos para poder continuar con la reserva del pasaje.',
  'sublos.main-page.step.flight-selection.modal.button.text': 'Volver a seleccionar tramos',
  'sublos.main-page.step.flight-selection.no-flight-available.text.label':
    'No hay vuelos disponibles para este tramo. Por favor <span>vuelva a seleccionar los tramos</span> para continuar.',
  'sublos.main-page.step.confirmation.title': 'Datos de confirmación de la reserva',
  'sublos.main-page.step.confirmation.passengers-header': 'Pasajeros',
  'sublos.main-page.step.confirmation.itinerary-header': 'Tu itinerario',
  'sublos.main-page.step.confirmation.stretch': 'Tramo',
  'sublos.main-page.step.confirmation.take-off': 'Sale',
  'sublos.main-page.step.confirmation.arrives': 'Llega',
  'sublos.main-page.step.confirmation.flight-time.label': 'Tiempo de Vuelo:',
  'sublos.main-page.step.confirmation.flight-number.label': 'N de Vuelo:',
  'sublos.main-page.step.confirmation.button-confirm': 'Confirmar datos',
  'sublos.main-page.step.confirmation.button-go-back': 'Volver',
  'sublos.main-page.step.button-cancel-booking': 'Cancelar solicitud',
  'sublos.main-page.step.confirmation.details.fare-to-pay': 'Importe a pagar',
  'sublos.main-page.step.confirmation.details.total-fare.amount': 'Precio total final',
  'sublos.main-page.step.confirmation.details.total-fare.title': 'Resumen de tu compra',
  'sublos.main-page.step.confirmation.details.total-to-pay': 'Total a pagar',
  'sublos.main-page.step.confirmation.details.warning':
    'Para confirmar los boletos se debe realizar el pago. Si no se abona los mismos serán cancelados.',
  'sublos.main-page.step.confirmation.details.currency-ar.label': 'ARS',
  'sublos.main-page.step.confirmation.details.adult-amount': 'Cantidad de adultos',
  'sublos.main-page.step.confirmation.details.adult-fare': 'Importe por adulto',
  'sublos.main-page.step.confirmation.details.child-fare': 'Importe por niño',
  'sublos.main-page.step.confirmation.details.inf-fare': 'Importe por infante',
  'sublos.main-page.step.confirmation.details.child-amount': 'Cantidad de chicos',
  'sublos.main-page.step.confirmation.details.total-fare': 'Importe total',
  'sublos.main-page.step.confirmation.warning-text':
    'Tu reserva fue procesada exitosamente, para confirmarla efectivamente <span>debes continuar con el pago.</span>',
  'sublos.main-page.step.confirmation.successful-button': 'Continuar',
  'sublos.main-page.step.confirmation.reservation.details': 'Detalle de tu reserva',
  'sublos.main-page.code-reservation-note':
    'Para confirmar los boletos se debe realizar el pago. Si no se abona los mismos serán cancelados.',
  'sublos.main-page.step.purchase.title': 'Confirmación de compra',
  'sublos.main-page.step.confirmation.success-text':
    'Tu compra fue procesada exitosamente. Te enviaremos los tickets electrónicos para tu viaje por correo.',
  'sublos.main-page.step.confirmation.error-text':
    'Lo sentimos, no se pudo procesar el pago. <span>Por favor, intente nuevamente dentro de 1 hora.',
  'sublos.main-page.step.confirmation.button-go-back-home': 'Volver al inicio',
  'sublos.main-page.step.confirmation.modal-error.title':
    'El tipo de pasaje seleccionado no permite realizar la búsqueda de vuelos',
  'sublos.main-page.step.confirmation.modal-error.text':
    'Por el tipo de pasaje escogido en el paso 1 no se pueden viajar a los destinos seleccionados. Para continuar con la reserva del pasaje vuelva a realizar una nueva búsqueda de tramos o cambie su elección de tipo de pasaje.',
  'sublos.main-page.step.confirmation.modal-error.button': 'Volver a seleccionar tramos',
  'sublos.form.validation-schema.origin-empty': 'Debes ingresar origen',
  'sublos.form.validation-schema.destination-empty': 'Debes ingresar destino',
  'sublos.form.validation-schema.date-empty': 'Debes ingresar fecha',
  'sublos.form.validation-schema.date-invalid': 'Debes ingresar una fecha válida',
  'sublos.form.result-error.title': 'Lo sentimos, ha ocurrido un error',
  'sublos.form.result-error.subtitle':
    'En este momento no podemos procesar su solicitud, por favor intente más tarde.',
  'sublos.retry-send-request.modal.title':
    'Lo sentimos, ha ocurrido un error, por favor inténtelo de nuevo.',
  'sublos.retry-send-passenger-validation.modal.title':
    'Lo sentimos, ha ocurrido un error en al procesar la <strong>validación de pasajeros</strong>.',
  'sublos.form.result-error-flight-selection.title':
    'Lo sentimos, ha ocurrido un error en al procesar la selección de vuelo.',
  'sublos.retry-send-request.modal.text':
    'Puedes intentar enviar la solicitud nuevamente o cancelar.',
  'sublos.button.go-home': 'Volver al inicio',
  'sublos.button.retry': 'Reintentar',
  'sublos.button.return-to-passengers-select': 'Volver a la selección de pasajeros',
  'sublos.button.cancel': 'Cancelar',
  'sublos.website-in-progress.title': 'Lo sentimos, este sitio está en mantenimiento',
  'sublos.website-in-progress.subtitle': 'Te pedimos disculpas, volvé a intentar más tarde.',
  'sublos.time-out.title': 'El tiempo de espera se agotó',
  'sublos.time-out.description':
    'Los veinte minutos disponibles para terminar tu reserva se agotaron. Volvé a iniciar la solicitud poniendo especial atención en el temporizador en la parte lateral de la pantalla.',
  'sublos.session-expired.title': 'Expiró tu sesión',
  'sublos.session-expired.subtitle':
    'El tiempo de tu sesión expiró, para continuar con la compra de pasajes volvé a iniciar sesión.',
  'sublos.session-expired.button': 'Volver a iniciar sesión',
  'sublos.main-page.step.passengers-documentation.title': 'Documentación de los pasajeros',
  'sublos.main-page.step.passengers-documentation.passenger-title':
    'Pasajero {{passengerNumber}}: {{passengerFullname}}',
  'sublos.main-page.step.passengers-documentation.form.document-type.label': 'Tipo de documento',
  'sublos.main-page.step.passengers-documentation.form.document-type.placeholder':
    'Seleccione una opción',
  'sublos.main-page.step.passengers-documentation.form.document-number.label':
    'Número de documento',
  'sublos.main-page.step.passengers-documentation.form.document-number.placeholder':
    'Ingrese número de documento',
  'sublos.main-page.step.passengers-documentation.form.document-issuing-country.label':
    'País de emisión',
  'sublos.main-page.step.passengers-documentation.form.document-issuing-country.placeholder':
    'Seleccione un país',
  'sublos.main-page.step.passengers-documentation.form.expiration-date.label':
    'Fecha de expiración',
  'sublos.main-page.step.passengers-documentation.form.nationality.label': 'Nacionalidad',
  'sublos.main-page.step.passengers-documentation.form.nationality.placeholder':
    'Seleccione una opción',
  'sublos.main-page.step.passengers-documentation.form.birthdate.label': 'Fecha de nacimiento',
  'sublos.main-page.step.passengers-documentation.form.gender.label': 'Sexo',
  'sublos.main-page.step.passengers-documentation.form.gender-male.label': 'Masculino',
  'sublos.main-page.step.passengers-documentation.form.gender-female.label': 'Femenino',
  'sublos.main-page.step.passengers-documentation.form.complementary-documentation.title':
    'Documentación complementaria',
  'sublos.main-page.step.passengers-documentation.form.resident-information':
    'Información de residencia',
  'sublos.main-page.step.passengers-documentation.form.country.label': 'País',
  'sublos.main-page.step.passengers-documentation.form.country.placeholder': 'Seleccioná el país',
  'sublos.main-page.step.passengers-documentation.form.province.label': 'Provincia',
  'sublos.main-page.step.passengers-documentation.form.province.placeholder':
    'Seleccioná la provincia',
  'sublos.main-page.step.passengers-documentation.form.city.label': 'Ciudad',
  'sublos.main-page.step.passengers-documentation.form.city.placeholder': 'Seleccioná la ciudad',
  'sublos.main-page.step.passengers-documentation.form.address.label': 'Domicilio',
  'sublos.main-page.step.passengers-documentation.form.address.placeholder': 'Ingresá el domicilio',
  'sublos.main-page.step.passengers-documentation.form.zip-code.label': 'Código postal',
  'sublos.main-page.step.passengers-documentation.form.zip-code.placeholder': 'Ingresá el C.P.',
  'sublos.main-page.step.passengers-documentation.form.destination-address.title':
    'Información donde pasará la cuarentena',
  'sublos.main-page.step.passengers-documentation.form.emergency-contact.title':
    'Contacto de emergencia',
  'sublos.main-page.step.passengers-documentation.form.emergency-contact.name.label':
    'Apellido y Nombre',
  'sublos.main-page.step.passengers-documentation.form.emergency-contact.name.placeholder':
    'Ingresá el apellido y nombre',
  'sublos.main-page.step.passengers-documentation.form.emergency-contact.relationship.label':
    'Parentesco',
  'sublos.main-page.step.passengers-documentation.form.emergency-contact.relationship.placeholder':
    'Ingresá el parentesco',
  'sublos.main-page.step.passengers-documentation.form.emergency-contact.phone-number.label':
    'Número de teléfono',
  'sublos.main-page.step.passengers-documentation.form.emergency-contact.phone-number.placeholder':
    'Ingresá el número de teléfono',
  'sublos.main-page.step.passengers-documentation.form.continue-button.label': 'Continuar',
  'sublos-reservation-validation-unable-record-reservation-ticket-abandonment':
    'WEB-PASARALE NO SE HA PODIDO REGISTRAR RESERVA-TICKET/ABANDONO PASAJE.',
  'sublos-reservation-validation-data-does-not-match':
    'LOS DATOS NO COINCIDEN CON LOS REGISTRADOS EN LA ORDEN DE TITULAR ASOCIADA. VERIFIQUE FECHAS E ITINERARIO.',
  'sublos-reservation-validation-previous-applications-in-process':
    'EXISTEN SOLICITUDES ANTERIORES EN PROCESO. INTENTE MAS TARDE.',
  'sublos-reservation-validation-insufficient-parameters': 'PARAMETROS INSUFICIENTES.',
  'sublos-reservation-validation-holder-does-not-have-necessary-conditions':
    'EL TITULAR NO CUMPLE CON LAS CONDICIONES NECESARIAS DE VACACIONES O DE SU FECHA DE REGRESO.',
  'sublos-reservation-validation-basic-family-members-has-been-exceeded':
    'SE HA SUPERADO LA CANTIDAD DE FAMILIARES BASICOS.',
  'sublos-reservation-validation-holder-wrong-civil-status-conditions':
    'EL TITULAR NO CUMPLE CON LAS CONDICIONES NECESARIAS DE ESTADO CIVIL.',
  'sublos-reservation-validation-beneficiary-age-error':
    'NO CUMPLE CON LAS CONDICIONES NECESARIAS - LA EDAD DEL BENEFICIARIO DEBE SER MENOR O IGUAL A 12 AÑOS.',
  'sublos-reservation-validation-ticket-request-by-email':
    'LA SOLICITUD DE PASAJES DEBERÁ REALIZARLA ENVIANDO UN MAIL A SUBLOS@AEROLINEAS.COM.AR',
  'sublos-reservation-validation-beneficiary-inconsistency':
    'INCONSISTENCIA DE BENEFICIARIO DETECTADA (PAS017)- REPORTE EL ERROR AL ADMINISTRADOR.',
  'sublos-reservation-validation-beneficiary-age-must-be-less':
    'NO CUMPLE CON LAS CONDICIONES NECESARIAS - LA EDAD DEL BENEFICIARIO DEBE SER MENOR.',
  'sublos-reservation-validation-beneficiary-age-must-be-less-21':
    'NO CUMPLE CON LAS CONDICIONES NECESARIAS - LA EDAD DEL BENEFICIARIO DEBE SER MENOR O IGUAL A 21 AÑOS.',
  'sublos-reservation-validation-age-must-be-25':
    'NO CUMPLE CON LAS CONDICIONES NECESARIAS - LA EDAD DEL BENEFICIARIO DEBE SER IGUAL O MAYOR A 25 AÑOS.',
  'sublos-reservation-validation-age-must-be-21-25':
    'NO CUMPLE CON LAS CONDICIONES NECESARIAS - LA EDAD DEL BENEFICIARIO DEBE SER IGUAL O MAYOR A 21/25 AÑOS.',
  'sublos-reservation-validation-insufficient-conditions-with-holder':
    'NO CUMPLE CON LAS CONDICIONES NECESARIAS - ACOMPAÑADO POR EL TITULAR.',
  'sublos-reservation-validation-insufficient-conditions-seniority-vacation-category':
    'EL TITULAR NO CUMPLE CON LAS CONDICIONES NECESARIAS DE ANTIGUEDAD, CATEGORIA Y/O VACACIONES.',
  'sublos-reservation-validation-insufficient-conditions-seniority-vacation':
    'EL TITULAR NO CUMPLE CON LAS CONDICIONES NECESARIAS DE ANTIGUEDAD Y/O VACACIONES.',
  'sublos-reservation-validation-insufficient-conditions-seniority-category':
    'EL TITULAR NO CUMPLE CON LAS CONDICIONES NECESARIAS DE ANTIGUEDAD O CATEGORIA.',
  'sublos-reservation-validation-holder-must-not-have-children':
    'EL TITULAR NO CUMPLE CON LAS CONDICIONES NECESARIAS - EL TITULAR NO DEBE TENER HIJOS CARGADOS EN SISTEMA.',
  'sublos-reservation-validation-holder-must-have-children':
    'EL TITULAR NO CUMPLE CON LAS CONDICIONES NECESARIAS - EL TITULAR DEBE TENER HIJOS CARGADOS EN SISTEMA.',
  'sublos-reservation-validation-holder-must-have-partner':
    'EL TITULAR NO CUMPLE CON LAS CONDICIONES NECESARIAS - EL TITULAR DEBE TENER CONYUGE/CONVIVIENTE CARGADO EN SISTEMA.',
  'sublos-reservation-validation-insufficient-conditions-associated':
    'EL TITULAR NO CUMPLE CON LAS CONDICIONES NECESARIAS - AGREMIADO.',
  'sublos-reservation-validation-beneficiary-insufficient-conditions':
    'EL BENEFICIARIO NO CUMPLE CON LAS CONDICIONES NECESARIAS.',
  'sublos-reservation-validation-holder-insufficient-conditions':
    'EL TITULAR NO CUMPLE CON LAS CONDICIONES NECESARIAS.',
  'sublos-reservation-validation-basic-quote-sold-out': 'CUPO DEL BÁSICO AGOTADO PARA EL PERIODO.',
  'sublos-reservation-validation-disabled-by-previous-application':
    'EL USO DE UN BENEFICIO ANTERIOR INHABILITA EL USO DE LA SOLICITUD ACTUAL.',
  'sublos-reservation-validation-number-tickets-exceeded':
    'SE HA SUPERADO LA CANTIDAD DE PASAJES PERMITIDOS.',
  'sublos-reservation-validation-holder-does-not-have-necessary-condition-infant-adult':
    'NO CUMPLE CON LA CONDICIÓN DE INFANTE ACOMPAÑADO DE ADULTO',
  'sublos-reservation-validation-ticket-out-of-period':
    'EL PASAJE SELECCIONADO ESTA FUERA DEL PERIODO DE USO.',
  'sublos-reservation-validation-no-name-origin-destination-error':
    'ORIGEN Y/O DESTINO NO SON VALIDOS. Recuerde que el NN debe iniciar y terminar ruta en ciudad de trabajo del titular.',
  'sublos-reservation-validation-benefit-duplicity-previous':
    'DUPLICIDAD DE BENEFICIOS DETECTADA EN LEGAJO ANTERIOR.',
  'sublos-reservation-validation-no-name-cannot-be-familiar':
    'EL NN INGRESADO NO PUEDE SER FAMILIAR DEL TITULAR.',
  'sublos-reservation-validation-no-name-disabled':
    'EL NN INGRESADO ES FAMILIAR INHABILITADO DE OTRO LEGAJO.',
  'sublos-reservation-validation-cannot-detect-holder': 'NO SE PUEDE DETECTAR TITULAR.',
  'sublos-reservation-validation-passenger-disability':
    'EL PASAJERO SELECCIONADO TIENE UNA INHABILITACION DE LEGAJO PROPIO.',
  'sublos-reservation-validation-vacation-request-error':
    'NO SE DISPONE DE LA ANTIGÜEDAD SUFICIENTE PARA SOLICITAR VACACIONAL',
  'sublos-reservation-validation-ticket-origin-invalid':
    'EL ORIGEN DEL PASAJE NO ES VALIDO. Recuerde que el titular debe iniciar ruta en su ciudad de trabajo.',
  'sublos-reservation-validation-beneficiary-end-date-error':
    'EL BENEFICARIO INGRESADO TIENE FECHA DE FIN.',
  'sublos-reservation-validation-beneficiary-death-date-error':
    'EL BENEFICARIO INGRESADO TIENE FECHA DE FALLECIMIENTO.',
  'sublos-reservation-validation-seniority-required-error':
    'NO CUENTA CON LA ANTIGÜEDAD MÍNIMA REQUERIDA.',
  'sublos-reservation-validation-rule-conditions-error':
    'HAY DEFINIDAS MAS DE UNA REGLA PARA LAS CONDICIONES. COMUNIQUESE CON ADM.',
  'sublos-reservation-validation-nro-order-error':
    'ERROR AL OBTENER NRO DE ORDEN O REGISTRAR EL BENEFICIO.',
  'sublos-reservation-validation-rule-application-error':
    'NO ES POSIBLE DETERMINAR UNA REGLA PARA LA SOLICITUD.',
  'sublos-reservation-validation-insufficient-seniority-holidays':
    'NO SE DISPONE DE LA ANTIGÜEDAD SUFICIENTE O DE VACACIONES CARGADAS.',
  'sublos-reservation-validation-no-agreement-for-holder': 'NO EXISTE UN CONVENIO PARA EL LEGAJO.',
  'sublos-reservation-validation-benefit-duplicity': 'DUPLICIDAD DE BENEFICIOS DETECTADA.',
  'sublos-reservation-validation-holder-disabled-for-period':
    'TITULAR INHABILITADO PARA EL PERIODO.',
  'sublos-reservation-validation-operation-not-defined':
    'RESULTADO OPERACION SIN DEFINIR O TRUNCA.',
  'sublos-reservation-validation-not-reservation-ticket':
    'NO SE HA EFECTUADO REGISTRACION DE RESERVA/TICKET.',
  'sublos-reservation-validation-abandoned-ticket': 'WEB-PASARELA PASAJE ABANDONADO ',
  'sublos-reservation-validation-issued-ticket': 'WEB-PASARELA PASAJE EMITIDO',
  'sublos-reservation-validation-robot-not-sent': 'ROBOT.TRANSACCION NO ENVIADA A MPOL.',
  'sublos-reservation-validation-order-web-rejected':
    'SE HA RECHAZADO ALGUNA SOLICITUD DE LA ORDEN_WEB ',
  'sublos-reservation-validation-removal-petition-by-user':
    'BAJA DE SOLICITUD DE AUTOGESTION EFECTUADA POR EL USUARIO ',
  'sublos.general.form.phone-input.error.invalid': 'El número de teléfono no es válido',
  'sublos.general.form.phone-input.country-code.subtext': 'Código de país',
  'sublos.general.form.phone-input.phone-number.subtext': 'Código de área y número',
  'sublos.main-page.step.select-passengers.form.condition.field-required':
    'Este campo es requerido',
  'sublos.main-page.step.select-no-name-passanger.form.field-required': 'Este campo es requerido',
  'sublos.reservation-service.create-reservation.server-error':
    'Ha ocurrido un error en la creación de la reserva.',
  'reservation.service.error': 'Lo sentimos! Ha ocurrido un error al generar la reserva.',
  'sublos.price-quote-reservation.not-found': 'Ha ocurrido un problema al realizar la cotización.',
  'sublos.price-quote-reservation.error': 'Ha ocurrido un problema al realizar la cotización.',
  'sublos.general.service-exception':
    'Ha ocurrido un error inesperado al intentar generar la reserva.',
  'sublos.reservation-service.add-remark.server-error':
    'Ha ocurrido un error al intentar agregar el remark.',
  'sublos.reservation-service.get-reservation.server-error':
    'Lo sentimos, ha ocurrido un error al intentar recuperar información de la reserva.',
  'gds.reservation.error.notFound': 'Ha ocurrido un error al intentar generar la reserva.',
  'sublos.reservation-service.add-ssr.server-error':
    'Lo sentimos! Ha ocurrido un error al intentar agregar la documentación de los pasajeros.',
  'sublos-reservation-validation-vacation-ticket-not-round-trip':
    'Para pasajes vacacionales debe seleccionar tramos Round-trip',
  'reservation.ssr-info.invalid-segments':
    'Lo sentimos! Ha ocurrido un error al intentar agregar la documentación de los pasajeros.',
  'sublos.payment-connector.error.unableToCreateCardDebt':
    'Lo sentimos, ha ocurrido un error al intentar confirmar la reserva.',
  'sublos.issue-reservation.error':
    'Lo sentimos, ha ocurrido un error al intentar confirmar la reserva.',
  'sublos.confirm-reservation.vcr-amount': 'Lo sentimos, no se ha podido confirmar la reserva.',
  'sublos.confirm-ticket.error': 'Ha ocurrido un error al confirmar la reserva.',
  'sublos.booking-details-request.market.not-blank':
    'Lo sentimos! Ha ocurrido un problema al intentar seleccionar el vuelo.',
  'gds.passenger.ssr-preference.not-allowed':
    'Ha ocurrido un error al agregar la documentación de pasajeros a la reserva.'
}
