/* eslint-disable import/no-cycle */
import React from 'react'

import loadable from '@loadable/component'

import { ChunkLoader } from 'components/loaders'

const LOADABLE_DELAY = 300

const loadableDelay = importFunc =>
  loadable(
    () =>
      new Promise(resolve =>
        setTimeout(() => importFunc().then(mod => resolve(mod)), LOADABLE_DELAY)
      ),
    { fallback: <ChunkLoader fullScreen /> }
  )

export default loadableDelay
