/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { buildURLEncoded } from 'helpers/url'
import api, { apiSublos } from 'services/api'

const {
  fetchCountriesStart,
  fetchCountriesSuccess,
  fetchCountriesError,
  resetCountries
} = createActions({
  FETCH_COUNTRIES_START: () => {},
  FETCH_COUNTRIES_SUCCESS: countries => countries,
  FETCH_COUNTRIES_ERROR: error => error,
  RESET_COUNTRIES: error => error
})

const fetchCountries = pageInfo => {
  const queryParams = buildURLEncoded('', pageInfo)

  return async dispatch => {
    dispatch(fetchCountriesStart())

    try {
      const countries = await api.get(`${apiRoutes.COUNTRIES}${queryParams}`)
      dispatch(fetchCountriesSuccess(countries))
    } catch (error) {
      dispatch(fetchCountriesError(error))
    }
  }
}

const fetchSublosCountries = pageInfo => {
  const queryParams = buildURLEncoded('', pageInfo)

  return async dispatch => {
    dispatch(fetchCountriesStart())

    try {
      const countries = await apiSublos.get(`${apiRoutes.COUNTRIES}${queryParams}`)
      dispatch(fetchCountriesSuccess(countries))
    } catch (error) {
      dispatch(fetchCountriesError(error))
    }
  }
}

export {
  fetchCountriesStart,
  fetchCountriesSuccess,
  fetchCountriesError,
  resetCountries,
  fetchCountries,
  fetchSublosCountries
}
