/* eslint-disable import/no-cycle */
import http from 'http'
import https from 'https'

import axios from 'axios'

import { catchApiError } from 'actions/apiError'
import CONFIG from 'config'
import { getLSValue } from 'helpers/localStorage'
import { LANGUAGE_TAGS } from 'localization/constants/languages'
import { getChannelFromTag, getSublosChannelFromTag } from 'localization/helpers'
import i18n from 'localization/i18n'
import { applyAuthorizationHeaders } from 'server/services/auth'
import { isTokenExpired } from 'server/utils/tokenUtils'

import apiArPlus, { setApiArPlusInterceptors } from './apiArPlus'
import apiBooking, { setApiBookingInterceptors } from './apiBooking'
import apiSublos, { setApiSublosInterceptors } from './apiSublos'
import cmsApi, { ssrCmsApi, setCmsApiInterceptors, setCmsLanguageParams } from './cmsApi'

function getCurrentToken() {
  if (typeof window !== 'undefined') return window.__ACCESS_TOKEN__
  return ''
}

let apiLog, apiLanguageDefaultAR, api, ssrApi, formDataApi
const createApis = () => {
  apiLog = axios.create({
    baseURL: CONFIG.API_BASE_URL,
    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true }),
    headers: {
      common: {
        Authorization: `Bearer ${getCurrentToken()}`,
        'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID
      },
      get: {
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      },
      post: {
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      }
    }
  })

  apiLanguageDefaultAR = axios.create({
    baseURL: CONFIG.API_BASE_URL,
    headers: {
      common: {
        Authorization: `Bearer ${getCurrentToken()}`,
        'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID
      },
      get: {
        'Accept-Language': LANGUAGE_TAGS.ONLY_ARG_LANG
      },
      post: {
        'Accept-Language': LANGUAGE_TAGS.ONLY_ARG_LANG
      }
    }
  })

  api = axios.create({
    baseURL: CONFIG.API_BASE_URL,
    headers: {
      common: {
        Authorization: `Bearer ${getCurrentToken()}`,
        'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID
      },
      get: {
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      },
      post: {
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      }
    }
  })

  ssrApi = axios.create({
    baseURL: CONFIG.API_SSR_BASE_URL,
    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true }),
    headers: {
      common: {
        'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID,
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      }
    }
  })

  /* API to be used if form involves sending files to BE */
  formDataApi = axios.create({
    baseURL: CONFIG.API_BASE_URL,
    headers: {
      common: {
        Authorization: `Bearer ${getCurrentToken()}`,
        'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID,
        'Content-Type': 'multipart/form-data'
      },
      get: {
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      },
      post: {
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      }
    }
  })
}

var apiArPlusIdToken = null
const createARPlusAPI = () => {
  if (apiArPlusIdToken) {
    return apiArPlusIdToken
  }

  return (apiArPlusIdToken = axios.create({
    baseURL: CONFIG.API_BASE_URL,
    headers: {
      common: {
        Authorization: `Bearer ${getLSValue('access_token')}`,
        'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID
      },
      get: {
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      },
      post: {
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      }
    }
  }))
}

if (typeof document == 'undefined' || document.readyState !== 'loading') {
  createApis()
} else {
  document.addEventListener('DOMContentLoaded', function() {
    createApis()
  })
}

/**
 * Set authToken header based.
 * @param {object} commonHeaders - ref to defaults.headers.common
 * @param {string} accessToken
 * @todo could be reused in setApiInterceptors
 */
export const setTokenHeader = (commonHeaders, accessToken) => {
  commonHeaders['Authorization'] = `Bearer ${accessToken}`
  return commonHeaders
}

/**
 * Set language headers based on current i18next initialized instance.
 * @param {object} config
 */
const setLanguageHeaders = (config, i18nInstance = i18n) => {
  if (i18nInstance.isInitialized) {
    const newChannel =
      config?.headers?.common?.['X-Channel-Id'] === CONFIG.DEFAULT_SUBLOS_CHANNEL_ID
        ? getSublosChannelFromTag(i18nInstance.language.toUpperCase())
        : getChannelFromTag(i18nInstance.language.toUpperCase())
    config.headers['X-Channel-Id'] = newChannel
    config.headers['Accept-Language'] = i18nInstance.language
  }
  return config
}

/**
 * Meant for client side reacting to 502/503 errors in a generic way. (AAW-3971)
 * @param {object} reduxStoreInstance
 * @param {number} errorStatus
 */
function specialErrorTreatment({ getState, dispatch }, error) {
  // Prevents to dispatch multiple chained actions as we only need to react to first error occurrence.
  const shouldDispatch =
    !getState().catchedApiError &&
    (error?.response?.status === 502 || error?.response?.status === 503)
  if (shouldDispatch) {
    dispatch(catchApiError(error?.response?.status))
    throw new Error('Oops http error ', error?.response?.status)
  }
}

/**
 * Implement Request Retry
 * @param {object} api
 * @param {object} initializedStore
 * @param {object} error
 * @param {number} retryCount - Number of retries
 * @param {number} retryDelay - Delay between retries in milliseconds
 */
const retryRequest = async ({
  api,
  initializedStore,
  error,
  retryCount = 3,
  retryDelay = 2000
}) => {
  const config = error.config
  config.retryCount = config.retryCount || 0

  if (config.retryCount >= retryCount) {
    specialErrorTreatment(initializedStore, error)
    return Promise.reject(error)
  }

  config.retryCount += 1
  await new Promise(resolve => setTimeout(resolve, retryDelay))

  return api(config)
}

// API instance interceptors
function setApiInterceptors(reduxStoreInstance) {
  api.interceptors.request.use(async config => {
    const withLangHeaders = setLanguageHeaders(config)

    await applyAuthorizationHeaders(withLangHeaders.headers)

    return withLangHeaders
  })

  api.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config

      error.response && specialErrorTreatment(reduxStoreInstance, error)

      /**
       * This ensures the token is updated when the user navigates back to a previous page,
       * preventing authentication errors with an old token.
       */
      if (typeof window !== 'undefined' && window.__TOKEN_EXPIRATION__) {
        isTokenExpired(window.__TOKEN_EXPIRATION__) && window.location.reload()
      }

      if (error.response.status === 401 && !originalRequest.skipRetry) {
        originalRequest.skipRetry = true

        const accessToken = getCurrentToken()

        api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`

        return axios(originalRequest)
      }

      return Promise.reject(error)
    }
  )
}

/**
 * (AAW-3971) When redux store instance is created, this function must be
 * called in order to capture general 502/503 errors.
 * Should be used just on client side. If needed similar behaviour for ssr api
 * instances, should be done apart replacing and NOT adding interceptors.
 * @param {object} initializedStore
 */
function configAxiosInterceptors(initializedStore) {
  const interceptorSetters = [
    setApiInterceptors,
    setApiBookingInterceptors,
    setApiArPlusInterceptors,
    setApiSublosInterceptors,
    setCmsApiInterceptors
  ]
  interceptorSetters.forEach(setter => setter(initializedStore))
}

export {
  api as default,
  apiLog,
  apiLanguageDefaultAR,
  ssrApi,
  createARPlusAPI,
  cmsApi,
  ssrCmsApi,
  apiBooking,
  apiArPlus,
  apiSublos,
  formDataApi,
  configAxiosInterceptors,
  specialErrorTreatment,
  getCurrentToken,
  setLanguageHeaders,
  setCmsLanguageParams,
  retryRequest
}
