/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { buildURLEncoded } from 'helpers/url'
import api from 'services/api'

const {
  fetchProvincesStart,
  fetchProvincesSuccess,
  fetchBranchesProvincesSuccess,
  fetchProvincesError
} = createActions({
  FETCH_PROVINCES_START: () => {},
  FETCH_PROVINCES_SUCCESS: provinces => provinces,
  FETCH_BRANCHES_PROVINCES_SUCCESS: provinces => provinces,
  FETCH_PROVINCES_ERROR: error => error
})

const getProvincesOfCountry = (countryId, pageInfo = null) => {
  return async dispatch => {
    dispatch(fetchProvincesStart())

    try {
      const urlProvincesSearch = `/${countryId}/provinces`
      const queryParams = buildURLEncoded(`${apiRoutes.COUNTRIES}${urlProvincesSearch}`, pageInfo)
      const provinces = await api.get(queryParams)
      dispatch(fetchProvincesSuccess({ provinces, countryId }))
    } catch (error) {
      dispatch(fetchProvincesError({ error, countryId }))
    }
  }
}

const getCountryProvincesWithBranches = (type, country) => {
  return async dispatch => {
    dispatch(fetchProvincesStart())

    try {
      const provinces = await api.get(`${apiRoutes.PROVINCES_BRANCHES(type, country)}`)
      dispatch(fetchBranchesProvincesSuccess(provinces))
    } catch (error) {
      dispatch(fetchProvincesError(error))
    }
  }
}

export {
  fetchProvincesStart,
  fetchProvincesSuccess,
  fetchBranchesProvincesSuccess,
  fetchProvincesError,
  getProvincesOfCountry,
  getCountryProvincesWithBranches
}
