export default {
  'checkout.log-error.button-go-back.message':
    'No olvides revisar tu casilla de correo (incluyendo la carpeta de Spam o No Deseados) para verificar que tu compra se efectuó correctamente. ¿Desea salir de la página?',
  'checkout.passenger-data.validation.fields-with-errors':
    'Verificá que los siguientes campos contengan datos válidos',
  'checkout.passenger-data.passenger-data-title': 'Datos de pasajero',
  'checkout.passenger-data.reservation-data-title': 'Datos de reserva',
  'checkout.passenger-data.passenger-logged': 'Yo soy el pasajero',
  'checkout.passenger-data.name-placeholder': 'Ingresá el nombre del pasajero.',
  'checkout.passenger-data.lastname-placeholder': 'Ingresá el apellido del pasajero.',
  'checkout.passenger-data.birthdate': 'Fecha de Nacimiento',
  'checkout.passenger-data.day-placeholder': 'Día',
  'checkout.passenger-data.month-placeholder': 'Mes',
  'checkout.passenger-data.year-placeholder': 'Año',
  'checkout.passenger-data.gender': 'Sexo',
  'checkout.passenger-data.gender-female': 'Femenino',
  'checkout.passenger-data.gender-male': 'Masculino',
  'checkout.passenger-data.relationship': 'Parentesco',
  'checkout.passenger-data.relationship-placeholder': 'Ingresá el parentesco.',
  'checkout.passenger-data.emergency-contact-placeholder': 'Ingresá el nombre del contacto.',
  'checkout.passenger-data.area-code-placeholder': 'Ej. 11',
  'checkout.passenger-data.email-placeholder': 'Este email va a quedar asociado a tu reserva.',
  'checkout.passenger-data.confirm-email': 'Confirmá tu email',
  'checkout.passenger-data.confirm-email-placeholder': 'Confirmá tu correo electrónico.',
  'checkout.passenger-data.add-phone': 'Agregar otro teléfono',
  'checkout.passenger-data.remove-phone': 'Eliminar teléfono',
  'checkout.passenger-data.marketing-engagement':
    'Acepto recibir comunicaciones en mi cuenta de e-mail',
  'checkout.passenger-data.residence-address': 'Informacion de residencia',
  'checkout.passenger-data.destination-address': 'Información donde pasará la cuarentena',
  'checkout.passenger-data.use-same-residence-address':
    'Los datos de residencia son los mismos que el primer pasajero.',
  'checkout.passenger-data.use-same-destination-address':
    'Los datos de destino son los mismos que el primer pasajero.',
  'checkout.passenger-data.validation.repeated-document-number':
    'El campo Número de Documento no debe ser igual entre dos o más pasajeros.',
  'checkout.passenger-data.id-placeholder': 'Nº de doc.',
  'checkout.passenger-data.nationality': 'Nacionalidad',
  'checkout.passenger-data.document-issuing-country': 'País de Emisión',
  'checkout.passenger-data.document-expiration-date': 'Fecha de Caducidad',
  'checkout.passenger-data.frequent-passenger': 'Este pasajero es viajero frecuente.',
  'checkout.passenger-data.passenger-special-assistance':
    'Este pasajero necesita asistencia especial.',
  'checkout.passenger-data.which-airline': '¿De qué Aerolínea?',
  'checkout.passenger-data.membership-number': 'Número de Socio',
  'checkout.passenger-data.membership-number-placeholder': 'Ingresá el número de socio',
  'checkout.passenger-data.special-foods': 'Comidas Especiales',
  'checkout.passenger-data.special-services': 'Servicios Especiales',
  'checkout.passenger-data.address-country-placeholder': 'Seleccionar un país',
  'checkout.passenger-data.address-province-placeholder': 'Seleccionar una provincia',
  'checkout.passenger-data.address-street-placeholder': 'Ingresá el domicilio',
  'checkout.passenger-data.address-zip-code-placeholder': 'Ej. C1234ABC',
  'checkout.passenger-data.information-secure-flight': 'Information Secure Flight',
  'checkout.passenger-data.compensation-number': 'Número de indemnización',
  'checkout.passenger-data.has-redress-number': 'Tiene Nº REDRESS',
  'checkout.passenger-data.redress-number-description':
    'El Redress es un Número que puede ser solicitado en los Aeropuertos de Estados Unidos y que ayudará a identificar la identidad y datos personales del pasajero.',
  'checkout.passenger-data.redress-more-information': 'Más información disponible acá',
  'checkout.passenger-data.emergency-contact': 'Contacto de Emergencia',
  'checkout.passenger-data.validation.address-state.required': 'Debes indicar una provincia.',
  'checkout.passenger-data.validation.address-state.pattern':
    'Verificá que la provincia indicada sea válida.',
  'checkout.passenger-data.validation.emails.pattern':
    'Verificá que ambos emails indicados sean iguales.',
  'checkout.passenger-data.validation.birthdate-day.required': 'Debes indicar el día.',
  'checkout.passenger-data.validation.birthdate-month.required': 'Debes indicar el mes.',
  'checkout.passenger-data.validation.birthdate-year.required': 'Debes indicar el año.',
  'checkout.passenger-data.validation.birthdate.required':
    'No olvides indicar la fecha de nacimiento.',
  'checkout.passenger-data.validation.birthdate-range.required':
    'La fecha de nacimiento no corresponde con el tipo de pasajero',
  'checkout.passenger-data.validation.birthdate.pattern': 'La fecha de nacimiento es incorrecta',
  'checkout.passenger-data.validation.gender.required': 'Debes indicar el sexo del pasajero',
  'checkout.passenger-data.validation.phone.required': 'Debes indicar el tipo del teléfono',
  'checkout.passenger-data.validation.doc-expiration-date.required':
    'Debes indicar la fecha de caducidad.',
  'checkout.passenger-data.validation.doc-expiration-date.pattern':
    'Tu documento se encuentra vencido.',
  'checkout.passenger-data.validation.issuing-country.required':
    'Debes indicar el país de emisión del documento.',
  'checkout.passenger-data.validation.nationality.required':
    'Debes indicar la nacionalidad del pasajero.',
  'checkout.passenger-data.validation.airline.required':
    'Debes indicar la aerolínea que frecuentas.',
  'checkout.passenger-data.validation.membership-number.required':
    'No olvides ingresar el número de socio.',
  'checkout.passenger-data.validation.membership-number.pattern':
    'Verificá que el número de socio sea válido.',
  'checkout.passenger-data.validation.compensation-number.required': 'No olvides indicar el número',
  'checkout.passenger-data.validation.compensation-number.pattern':
    'Verificá que el número sea válido.',
  'checkout.passenger-data.validation.special-preferences.required':
    'No olvides indicar las comidas o servicios especiales.',
  'checkout.passenger-data.age-warning-modal.line-0':
    'Pasajeros menores de 16 años viajando solos en vuelos operados por otra compañía',
  'checkout.passenger-data.age-warning-modal.line-1':
    'distinta a Aerolíneas Argentinas deberán contactarse con la respectiva línea aérea',
  'checkout.passenger-data.age-warning-modal.line-2':
    'e informarse sobre la normativa que aplica a menores viajando sin acompañantes',
  'checkout.passenger-data.age-warning-modal.line-full':
    'Pasajeros menores de 16 años viajando solos en vuelos operados por otra compañía distinta a Aerolíneas Argentinas deberán contactarse con la respectiva línea aérea e informarse sobre la normativa que aplica a menores viajando sin acompañantes',
  'checkout.passenger-data.age-warning-modal.continue': 'Continuar',
  'checkout.passenger-data.age-warning-modal.go-back-to-results': 'Volver a mis resultados',
  'checkout.purchase-confirmation.disclaimer':
    'Aerolíneas Argentinas S.A., se encuentra exceptuada de cumplir con el Régimen de Facturación establecido en la RG 1415, dado que estamos comprendidos en el Anexo I, inciso e) de la mencionada Resolución.Si Usted adquirió, un pasaje internacional o pasaje de cabotaje con origen y/o destino Tierra del Fuego, el recibo remitido por mail, es documento válido, asimilable a una factura.Si Usted adquirió un pasaje de cabotaje, el cual se encuentra gravado por IVA, deberá solicitar la Constancia de Crédito Fiscal, según lo establecido en el Anexo IV, punto B),' +
    ' 4., de la RG 1415. Deberá solicitar una constancia por cada documento emitido. Para solicitar la Constancia de Crédito Fiscal acceda  ',
  'checkout.purchase-confirmation.disclaimer.link': 'desde aquí. ',
  'checkout.modal-time-out.full-height-title': 'Checkout',
  'checkout.modal-time-out.message':
    'El tiempo de completar tu compra ha finalizado.<br>Podés volver a los resultados o realizar una nueva búsqueda.',
  'checkout.reservation-warning.go-to-my-booking': 'Mi Reserva',
  'checkout.stepbar.passenger-data': '1. Datos del pasajero',
  'checkout.stepbar.payment-data': '2. Datos de Pago',
  'checkout.stepbar.purchase-confirmation': '3. Confirmación de compra',
  'checkout.collapsible-miles.title': 'Tu canje para el vuelo',
  'checkout.collapsible-miles.info-redeem-miles.title': 'Canjeá millas',
  'checkout.collapsible-miles.info-redeem-miles.description':
    'Seleccioná la cantidad de millas que querés canjear para tu vuelo. Tené en cuenta que tanto las Tasas e impuestos como los Cargos siempre deben ser abonados en pesos.',
  'checkout.collapsible-miles.rangeSelector.button.label': 'Aplicar',
  'checkout.checkout.collapsible-miles.switch.label': 'Pagar vuelo en Millas',
  'checkout.miles-redeemed.title': 'Estas son tus millas',
  'checkout.miles-redeemed.title.passenger': 'Tus millas AR Plus',
  'checkout.miles-redeemed.total-miles': 'Total de millas',
  'checkout.miles-redeemed.expiration': 'Vencimiento',
  'checkout.payment-methods.select-option': 'Seleccioná la opción de pago que utilizarás',
  'checkout.payment-methods.one-card': '1 Tarjeta',
  'checkout.payment-methods.one-card-description': 'Débito o crédito',
  'checkout.payment-methods.two-card-description':
    'Crédito - Crédito | Débito - Débito | Débito - Crédito',
  'checkout.payment-methods.two-cards': ' 2 Tarjetas',
  'checkout.payment-methods.cash': 'Efectivo',
  'checkout.payment-methods.mercadoPago': 'Mercado Pago',
  'checkout.payment-methods.mercadoPago-description': 'Dinero en cuenta, crédito y débito',
  'checkout.payment-methods.switch.label': 'Mismo titular que Tarjeta 1',
  'checkout.modal-mercadoPago.full-height-title': 'Lo sentimos, tu reserva expiró',
  'checkout.modal-mercadoPago.message':
    'Tu compra fue cancelada ya que plazo para realizar el pago se ha vencido. Por favor, intentá realizar la compra nuevamente.',
  'checkout.modal-mercadoPago.button': 'Volver a intentar',
  'checkout.passenger.passenger-index.not-null': 'Se requiere el campo índice de pasajero',
  'checkout.passenger.passenger-index.range':
    'El campo índice de pasajero debe ser un número entre 0 y 9',
  'checkout.passenger.birth-date.format': 'Nacimiento',
  'checkout.passenger.birth-date.not-empty': 'El campo fecha de nacimiento es requerido',
  'checkout.passenger.birth-date.not-future':
    'El campo fecha de nacimiento debe ser una fecha pasada',
  'checkout.passenger.gender.not-null': 'Sexo',
  'checkout.passenger.redress-number.pattern':
    'El número redress debe contener entre 8 y 13 dígitos',
  'checkout.passenger.passenger-type.not-null':
    'Se requiere indicar el tipo de pasajero (adulto, niño, bebé)',
  'checkout.passenger.arrival-date.not-empty': 'Se requiere la fecha de llegada',
  'checkout.passenger.first-name.not-empty': 'Nombre',
  'checkout.passenger.first-name.pattern': 'Nombre',
  'checkout.passenger.last-name.not-empty': 'Apellido',
  'checkout.passenger.last-name.pattern': 'Apellido',
  'checkout.passenger.child-age.format':
    'Los niños que cumplen 12 años durante el viaje, deben pagar tarifa de adulto por todo el viaje.',
  'checkout.passenger.infant-age.format':
    'Los bebés que cumplen 2 años durante el viaje, deben pagar tarifa de niño por todo el viaje y ocupar un asiento.',
  'checkout.passenger.date.format': 'Fecha de nacimiento',
  'checkout.passenger.residential-destination-address.format': 'Dirección',
  'checkout.passenger.arrival-date.format': 'La fecha de llegada no tiene un formato válido',
  'checkout.document.nationality.not-empty': 'Nacionalidad',
  'checkout.document.document-number.not-empty': 'Número de Documento',
  'checkout.document.document-type.not-empty': 'Tipo de Documento',
  'checkout.document.expiration-date.format': 'Fecha de expiración',
  'checkout.document.expiration-date.future': 'Fecha de expiración',
  'checkout.document.issuing-country.not-empty': 'País de Emisión',
  'checkout.document.expiration-date.required': 'Fecha de expiración del documento',
  'checkout.document.document-number.format': 'Número de documento',
  'checkout.document.expiration-date.range': 'Fecha de expiración inválida.',
  'checkout.shopping-id.pattern': 'El formato del shoppingId es inválido',
  'checkout.shopping-id-or-pnr.required': 'Se requiere un shopping id o código de reserva.',
  'checkout.reservation-code.pattern': 'El formato del código de reserva es inválido',
  'checkout.update.passenger.same-type-name.format':
    'No puede haber mas de 3 pasajeros de igual rango de edad, nombre y apellido',
  'checkout.update.passenger.passengers-data.not-empty': 'Debe haber al menos 1 pasajero',
  'checkout.visa.issuing-country.not-empty': 'Se requiere indicar el país emisor de la visa',
  'checkout.visa.country-of-birth.not-empty': 'Se requiere indicar el país de nacimiento',
  'checkout.visa.country-of-birth.pattern':
    'El país de nacimiento debe contener solo caracteres alfabéticos',
  'checkout.visa.issue-date.not-empty': 'Se requiere indicar la fecha de emisión',
  'checkout.visa.issue-date.format-date':
    'El formato de fecha de emisión no tiene el formato requerido',
  'checkout.visa.document-number.not-empty': 'Se requiere indicar el número de visa',
  'checkout.visa.document-number.pattern': 'El número de visa no tiene el formato requerido',
  'checkout.residential-address.country.not-empty': 'Se requiere indicar el país de residencia',
  'checkout.residential-address.country.pattern':
    'El país de residencia no tiene el formato requerido ',
  'checkout.residential-address.street.not-empty': 'Se requiere la dirección de residencia',
  'checkout.residential-address.street.pattern':
    'La calle de residencia no tiene el formato requerido',
  'checkout.residential-address.city.not-empty': 'Se requiere la ciudad de residencia',
  'checkout.residential-address.city.pattern':
    'La ciudad de residencia no tiene el formato requerido',
  'checkout.residential-address.state.not-empty': 'Se requiere el estado o provincia de residencia',
  'checkout.residential-address.state.pattern':
    'El estado o provincia de residencia no tiene el formato requerido',
  'checkout.residential-address.zip-code.not-empty': 'Se requiere código postal de residencia',
  'checkout.residential-address.zip-code.pattern':
    'El código postal de residencia no tiene el formato requerido',
  'checkout.destination-address.country.not-empty': 'Se requiere indicar el país de destino',
  'checkout.destination-address.country.pattern':
    'El país de destino no tiene el formato requerido ',
  'checkout.destination-address.street.not-empty': 'Se requiere la dirección de destino',
  'checkout.destination-address.street.pattern':
    'La calle de destino no tiene el formato requerido',
  'checkout.destination-address.city.not-empty': 'Se requiere la ciudad de destino',
  'checkout.destination-address.city.pattern': 'La ciudad de destino no tiene el formato requerido',
  'checkout.destination-address.state.not-empty': 'Se requiere el estado o provincia de destino',
  'checkout.destination-address.state.pattern':
    'El estado o provincia de destino no tiene el formato requerido',
  'checkout.destination-address.zip-code.not-empty': 'Se requiere código postal de destino',
  'checkout.destination-address.zip-code.pattern':
    'El código postal de destino no tiene el formato requerido',
  'checkout.contact-information.emails.not-empty': 'Se requiere un email de contacto',
  'checkout.contact-information.email.pattern': 'Verifica que sea un e-mail válido',
  'checkout.contact-information.phone.required': 'Número de teléfono del contacto de emergencia',
  'checkout.phone.number.pattern': 'El número de teléfono debe contener dígitos',
  'checkout.phone.country-code.not-null': 'Se requiere el código telefónico de país',
  'checkout.phone.area-code.pattern': 'Se requiere el código telefónico de área',
  'checkout.emergency-contact.name.not-empty': 'Se requiere un nombre del contacto de emergencia',
  'checkout.emergency-contact.name.pattern': 'Nombre',
  'checkout.emergency-contact.country.not-empty': 'Se requiere un país del contacto de emergencia',
  'checkout.emergency-contact.area-code.not-empty':
    'Se requiere un código de área del contacto de emergencia',
  'checkout.emergency-contact.area-code.pattern': 'Código de área',
  'checkout.emergency-contact.phone.not-empty':
    'Se requiere un teléfono del contacto de emergencia',
  'checkout.emergency-contact.relationship.not-empty':
    'Se requiere un parentesco del contacto de emergencia',
  'checkout.emergency-contact.relationship.pattern': 'Parentesco',
  'checkout.special-service.code.not-empty': 'Se requiere un servicio especial',
  'checkout.frequent-flyer-information.airline.not-empty':
    'Se requiere una aerolínea de pasajero frecuento',
  'checkout.frequent-flyer-information.number.not-empty':
    'Se requiere un número de pasajero frequente',
  'checkout.frequent-flyer-information.number.pattern':
    'El número de pasajero frecuente no tiene el formato requerido',
  'checkout.error-purchase.we-had-a-problem': 'Tuvimos un problema con tu solicitud',
  'checkout.error-purchase.your-reservation-was-generated':
    'Pero no te preocupes, ¡tu reserva fue generada!',
  'checkout.error-purchase.contact-center':
    'Por favor contactate con nosotros para brindarte más información. <br> <b>Contact center</b>: 54 (11) 5199 3555 <b>WhatsApp</b>: +54-11-4940-4798',
  'checkout.error-purchase.reservation-code': 'Código de reserva: {{pnr}}',
  'checkout.error-purchase.your-back-home': 'Volver al inicio'
}
