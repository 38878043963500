export default {
  'scheduled-flights.search-box.header-mobile': 'Consultar horarios',
  'scheduled-flights.grid-columns.header': 'Días',
  'scheduled-flights.grid-rows.header': 'Vuelos',
  'scheduled-flights.grid-itinerary.aircraft-info-not-available': 'Información no disponible',
  'scheduled-flights.grid-itinerary.see-details': 'Ver detalles',
  'scheduled-flights.modal.header': 'Detalle de vuelo',
  'scheduled-flights.modal.airline-details': 'Operador por:',
  'scheduled-flights.modal.first-stop': '(1er escala)',
  'scheduled-flights.no-results-error.header': '¡Lo sentimos! No tenemos resultados para vos.',
  'scheduled-flights.no-results-error.first-line':
    'No se encontraron vuelos con los datos que ingresaste.',
  'scheduled-flights.no-results-error.second-line': 'Intentá nuevamente con otra fecha de salida.',
  'scheduled-flights.banner.title': 'Horarios de vuelos programados',
  'scheduled-flights.banner.subtitle-0':
    'Consultá los horarios de salida y arribo de los vuelos de Aerolíneas',
  'scheduled-flights.banner.subtitle-1': 'Argentinas, del día de hoy y hasta 11 meses en avance.',
  'scheduled-flights.container.page-text': 'Los horarios son locales de cada país/ciudad.',
  'scheduled-flights.container.page-text-mobile':
    'Podés ver los horarios de vuelo del dia de hoy y hasta 11 meses en avance. Los horarios son locales de cada país/ciudad.',
  'scheduled-flights.search-box.input-origin': 'Ingresá el origen',
  'scheduled-flights.search-box.input-destiny': 'Ingresá el destino',
  'scheduled-flights.search-box.date-placeholder': 'DD/MM/AAAA',
  'scheduled-flights.search-box.date-placeholder-short': 'DD/MM/AA',
  'scheduled-flights.search-box.search-button': 'Consultar',
  'scheduled-flights.search-box.search-button-mobile': 'Buscar vuelos'
}
