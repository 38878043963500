/* eslint-disable import/no-cycle */
import { getMask, isMasked } from 'components/forms/helpers/mask'
import CONFIG from 'config'
import CURRENCY from 'constants/currencies'
import {
  PERSIST_LAST_LANG_CODE,
  I18_NEXT_LNG,
  IS_EXTERNAL_PAYMENT_3P_EXCHANGE
} from 'constants/localStorage'
import MEDIA_TYPES from 'constants/mediaTypes'
import { PASSENGER_TYPES, PASSENGER_TYPE_VALIDATIONS } from 'constants/passengerTypes'
import { HOLD_OPTION_MATCH, PAYMENT_METHODS } from 'constants/payment'
import PRODUCTS from 'constants/products'
import { SEARCH_BOX_TABS, SEARCH_BOX_TABS_3P } from 'constants/searchBox'
import { __SUBLOS_PERSIST_VALIDATION__ } from 'constants/sublos/localStorageKeys'
import { TRIP_TYPES } from 'constants/tripTypes'
import { getCookie } from 'helpers/cookies'
import { getDayJsObject } from 'helpers/dates'
import { getLSValue, removeLSValue, setLSValue } from 'helpers/localStorage'
import { getUrlParams } from 'helpers/url'
import { parseLangCode } from 'helpers/url/parseUrl'
import { LANGUAGE_TAGS } from 'localization/constants/languages'
import COOKIE_NAMES from 'server/utils/cookieNames'

export const regionToCountry = language => {
  switch (language) {
    case LANGUAGE_TAGS.ITALY:
      return LANGUAGE_TAGS.ITALIAN_LOCALE
    case LANGUAGE_TAGS.ESPAÑA:
      return LANGUAGE_TAGS.SPANISH
    case LANGUAGE_TAGS.ESPAÑA_EN:
      return LANGUAGE_TAGS.ENGLISH_EUROPE
    default:
      return language
  }
}

export const onEnterKeyPress = (event, callback) => {
  if (event.key === 'Enter') {
    event.preventDefault()
    callback()
  }
}

export const flightTitle = (flightType, index, t, legsLength) => {
  if (flightType === TRIP_TYPES.MULTIDESTINY)
    return t('label.leg-with-number', { legNumber: index + 1 })
  if (
    flightType == TRIP_TYPES.ONE_WAY ||
    (index === 0 && flightType == TRIP_TYPES.ROUND_TRIP && legsLength > 1)
  )
    return t('label.one-way-trip.uppercase')

  return t('label.return-trip.uppercase')
}

export const arraysAreEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b)
}

export const isEmpty = obj => !obj || Object.keys(obj).length === 0

export const numberWithDot = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')

export const getLanguageSelector = (_, props) => props?.i18n?.language

export const numberWithDec = (x, language = CONFIG.DEFAULT_LOCALE, minFracDigits = 2) =>
  x && isNumber(x)
    ? x.toLocaleString(regionToCountry(language), { minimumFractionDigits: minFracDigits })
    : x

export const isNumber = x => !isNaN(x) && isFinite(x)

export const formatMinutesToHours = x => {
  const minutes = x % 60
  const hours = (x - minutes) / 60
  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`
}
export const isDesktopOrWide = mediaType =>
  mediaType === MEDIA_TYPES.DESKTOP || mediaType === MEDIA_TYPES.WIDESCREEN

export const isMobileOrTablet = mediaType =>
  mediaType === MEDIA_TYPES.MOBILE || mediaType === MEDIA_TYPES.TABLET

export const isMobile = mediaType => mediaType === MEDIA_TYPES.MOBILE

export const isTablet = mediaType => mediaType === MEDIA_TYPES.TABLET

export const minutesToHours = minutes => `${(minutes - (minutes % 60)) / 60}h ${minutes % 60}m`

// Convert string to Title Case
export const toTitleCase = string =>
  string.toLowerCase().replace(/^(.)|\s(.)/g, $1 => $1.toUpperCase())

export const capitalize = s => s.toLowerCase().replace(/\b./g, a => a.toUpperCase())

export const formatDate = date => {
  if (!date) {
    return ''
  }

  const parsedDate = getDayJsObject(date)
  return `${parsedDate.date()}/${parsedDate.month()}/${parsedDate.year()}`
}

export const formatDepartureDate = (date, t) => {
  const parsedDate = getDayJsObject(date)

  return `${parsedDate.date()} ${t('label.of')} ${t(`label.months-${parsedDate.month()}`)} ${t(
    'label.of'
  )} ${parsedDate.year()}`
}

export const toTesteableId = value =>
  value &&
  value
    .toString()
    .toLowerCase()
    .replace('_', '-')

/**
 * Formik Functions Validate
 * Set field touched status when an onChange occurs
 * @param {event} ev
 * @param {formik props} props
 * @param {field name} name
 */

export const handleValueChange = (ev, props, name, value) => {
  const { setFieldTouched, handleChange, setFieldValue } = props
  setFieldTouched(name || ev.target.name, true, true)

  ev && handleChange(ev)
  name && setFieldValue(name, value)
}

export const passengersCount = flightsSearchData => {
  const { adt = 0, chd = 0, inf = 0 } = flightsSearchData

  return parseInt(adt, 10) + parseInt(chd, 10) + parseInt(inf, 10)
}

export const getPayOfflineName = paymentCode => {
  switch (paymentCode) {
    case 'OP-102':
      return 'Pago Facil'
    case 'OP-104':
      return 'Pago Mis Cuentas'
    case 'OP-106':
      return 'Red Link'
    default:
      return ''
  }
}

/** passengerTypes that already come as *_SHORT are coming from the missingInfo endpoint (Otras Bocas)*/
export const mapPassengerTypeToAbbr = passengerType => {
  switch (passengerType) {
    case PASSENGER_TYPES.CHD:
    case PASSENGER_TYPES.CHD_SHORT:
    case PASSENGER_TYPES.CNN_SHORT:
      return PASSENGER_TYPES.CHD_SHORT

    case PASSENGER_TYPES.INF:
    case PASSENGER_TYPES.INF_SHORT:
      return PASSENGER_TYPES.INF_SHORT

    case PASSENGER_TYPES.ADT:
    case PASSENGER_TYPES.ADT_SHORT:
      return PASSENGER_TYPES.ADT_SHORT

    default:
      return undefined
  }
}

export const getPassengerTypeValidation = type => {
  const typeAbbr = mapPassengerTypeToAbbr(type)
  return PASSENGER_TYPE_VALIDATIONS[typeAbbr]
}

export const getFirstAndLast = array => {
  if (!Array.isArray(array)) {
    return []
  }

  const [first] = array
  const last = array[array.length - 1]

  return [first, last]
}

export const maskCreditCard = (creditCard, maskArray, digitCountArray) => {
  if (isEmpty(creditCard) || creditCard.length < 4) {
    return ''
  }
  //Workarounds masking already masked creditCards
  if (isMasked(creditCard, maskArray)) {
    return creditCard
  }

  const usedMask = getMask(creditCard, digitCountArray, maskArray)
  const ccNumberLengthRest = creditCard.length - 4
  const last = creditCard.substring(ccNumberLengthRest).split('')

  let counter = 3
  const result = usedMask?.split('')

  for (let i = usedMask.length - 1; i >= 0; i--) {
    if (usedMask[i] === '9') {
      if (counter < 0) {
        result[i] = '-'
      } else {
        result[i] = last[counter]
        counter--
      }
    }
  }

  return result.join('')
}

export const resetCreditCardData = (paymentData, maskArray, digitCountArray) => ({
  ...paymentData,
  creditCard_number: maskCreditCard(paymentData.creditCard_number, maskArray, digitCountArray),
  creditCard_cvc: ''
})

export const replaceLast = (value, find, replace) => {
  const index = value.lastIndexOf(find)

  if (index >= 0) {
    return value.substring(0, index) + replace + value.substring(index + find.length)
  }

  return value
}

export const getOrdinal = number => {
  switch (number) {
    case 1:
      return '1er'
    case 2:
      return '2do'
    case 3:
      return '3er'
    case 4:
      return '4to'
    case 5:
      return '5to'
    case 6:
      return '6to'
    case 7:
      return '7mo'
    case 8:
      return '8vo'
    case 9:
      return '9no'
    default:
      return ''
  }
}

export const setLandingPageTab = tab => tab && tab.length > 0 && setLSValue('landing-page-tab', tab)

export const getLandingPageTab = () => getLSValue('landing-page-tab')

export const setArtId = location => {
  const { artid } = getUrlParams(location.search.split('?').pop())
  if (artid) {
    setLSValue('referrer', artid)
  }
}

export const updateArtId = (prevLocation, currentLocation) => {
  const cookieArtId = document.cookie && getCookie(document.cookie, COOKIE_NAMES.EXTERNAL_ART_ID)

  getLSValue('referrer')
    ? prevLocation?.search !== currentLocation?.search && setArtId(currentLocation)
    : cookieArtId
    ? setArtId({ search: `artid=${cookieArtId}` })
    : setArtId(currentLocation)
}

export const setSublosPersistValidation = validation =>
  setLSValue(__SUBLOS_PERSIST_VALIDATION__, validation)

export const getSublosPersistValidation = () => getLSValue(__SUBLOS_PERSIST_VALIDATION__)

export const removeSublosPersistValidation = () => removeLSValue(__SUBLOS_PERSIST_VALIDATION__)

export const toCamelCase = str => {
  var arr = str.toLowerCase().split(/[_-]/)
  var newStr = ''
  for (var i = 1; i < arr.length; i++) {
    newStr += arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }
  return arr[0] + newStr
}

export const isDiferredPaymentType = reservationPaymentType =>
  reservationPaymentType === PAYMENT_METHODS.OFFLINE

export const isDefaultCurrency = currency => currency === CURRENCY.ARS

//En el endpoint de flexcalendar el currency es MILES,
// mientras que en checkout el currency es FFCURRENCY, AMBOS son millas.
export const isMilesCurrency = currency =>
  currency === CURRENCY.FFCURRENCY || currency === CURRENCY.MILES

export const getContext = currency => (isMilesCurrency(currency) ? 'ffcurrency' : 'lcurrency')

export const getMsgFare = (t, total, currency, language) => {
  const localCurrency = Array.isArray(total)
    ? total.find(({ currency }) => currency !== CURRENCY.FFCURRENCY)
    : total
  const miles = Array.isArray(total)
    ? total.find(({ currency }) => currency === CURRENCY.FFCURRENCY)
    : 0

  if ((isEmpty(total) && typeof total === 'object') || total === 0) {
    return 0
  }

  if (localCurrency && miles) {
    return t('label.currencies', {
      context: 'both-currencies',
      miles: miles.amount,
      localCurrency: localCurrency.currency,
      amount: numberWithDec(localCurrency.amount, language)
    })
  }

  if (localCurrency && !miles) {
    return t('label.currencies', {
      context: 'local-currency',
      localCurrency: currency === CURRENCY.FFCURRENCY ? localCurrency.currency : currency,
      amount:
        currency === CURRENCY.FFCURRENCY
          ? numberWithDec(localCurrency.amount, language)
          : numberWithDec(total, language)
    })
  }

  if (!localCurrency && miles) {
    return t('label.currencies', {
      context: 'ffcurrency',
      miles: miles.amount
    })
  }
}

export const punctualityIndexFlightsInfoArr = legs =>
  legs.flatMap(({ segments }) =>
    segments.map(
      ({ airline, flightNumber, origin, destination }) =>
        `${airline}-${flightNumber}-${origin}-${destination}`
    )
  )

export const convertMbToBytes = (mb, tofix = null) =>
  tofix ? parseFloat((mb * 1e6).toFixed(tofix)) : mb * 1e6

export const convertBytesToMb = (bytes, tofix = null) =>
  tofix ? parseFloat((bytes / 1e6).toFixed(tofix)) : bytes / 1e6

export const convertFilesToB64 = files => {
  if (isEmpty(files)) return Promise.resolve()

  let promises = []
  Array.from(files || {}).forEach(file => {
    promises.push(
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve({ file, b64: reader.result })
        reader.onerror = error => reject(error)
      })
    )
  })
  return Promise.all(promises)
}

export const mergeWithoutDuplicatesByKey = (A = [], B = [], key) =>
  A.concat(B.filter(item_B => key && !A.find(item_A => item_A[key] === item_B[key])))

export const substractObject = (ob, list) => (list || []).filter(item => !Object.is(item, ob))

export const getBrowserMetadata = () => {
  const { appCodeName, appName, appVersion, userAgent, platform, userAgentData } = navigator

  return {
    browserMetadata: {
      ...(appCodeName ? { appCodeName } : {}),
      ...(appName ? { appName } : {}),
      ...(appVersion ? { appVersion } : {}),
      ...(userAgent ? { userAgent } : {}),
      ...(platform ? { platform } : {}),
      ...(userAgentData ? { mobile: userAgentData.mobile } : {})
    }
  }
}

export const getBrowserDetails = () => {
  const { navigator, screen } = window || {}

  return {
    browserDetails: {
      browserJavaEnabled: navigator.javaEnabled(),
      browserJavascriptEnabled: navigator.cookieEnabled,
      browserScreenColorDepth: (screen.colorDepth || screen.pixelDepth || '').toString(),
      browserScreenHeight: Math.min(screen.height, 1000),
      browserScreenWidth: Math.min(screen.width, 1000),
      browserTimeZoneOffset: new Date().getTimezoneOffset(),
      challengeWindowSize: 5, // Full screen
      browserLanguageCode: getCurrentCulture()
    }
  }
}

export const decodeBuffToB64 = (data, encoding = 'ascii') => {
  const buff = new Buffer(data || '', 'base64')
  return buff.toString(encoding)
}

export const formatToCapitalizationCase = str => {
  return str.toLowerCase().replace(/\b(\w)/g, x => x.toUpperCase())
}

export const addPositiveDecimals = (a = 0, b = 0, fix = 2) =>
  parseFloat((Math.max(a, 0) + Math.max(b, 0)).toFixed(fix))

export const bnplEnabled = holdOptions =>
  (holdOptions || []).some(
    tab =>
      tab === HOLD_OPTION_MATCH.PAYMENT_24.CONFIG ||
      tab === HOLD_OPTION_MATCH.PAYMENT_24.ID ||
      tab === HOLD_OPTION_MATCH.PAYMENT_48.CONFIG ||
      tab === HOLD_OPTION_MATCH.PAYMENT_48.ID
  )

export const getProduct = currency => {
  switch (true) {
    case isMilesCurrency(currency):
      return PRODUCTS.MILES
    default:
      return PRODUCTS.SHOPPING
  }
}

export const setIsExternalPayment3pExchange = () =>
  setLSValue(IS_EXTERNAL_PAYMENT_3P_EXCHANGE, 'true')

export const getIsExternalPayment3pExchange = () =>
  getLSValue(IS_EXTERNAL_PAYMENT_3P_EXCHANGE) || ''

export const removeIsExternalPayment3pExchange = () =>
  removeLSValue(IS_EXTERNAL_PAYMENT_3P_EXCHANGE) || ''

export const removeLastLangCode = () => removeLSValue(PERSIST_LAST_LANG_CODE) || ''

export const getLastLangCode = () => getLSValue(PERSIST_LAST_LANG_CODE) || ''

export const setLastLangCode = langCode => setLSValue(PERSIST_LAST_LANG_CODE, langCode)

export const getCurrentCulture = () => getLSValue(I18_NEXT_LNG) || ''

export const saveLastCultureVisitedToLS = () => {
  if (typeof window === 'undefined') return
  const currentCulture = getCurrentCulture().toLowerCase()
  const lastCulture = getLastLangCode().toLowerCase()

  if (lastCulture && currentCulture !== lastCulture) {
    setLastLangCode(parseLangCode(lastCulture))
  }
}

export const isCurrentLanguage = currentLanguage =>
  (getCurrentCulture() || '').toLocaleLowerCase() === currentLanguage.toLocaleLowerCase()

export const buildSearchBoxTabs = lng => {
  let tabs = {}

  let tabsToMap = hideAnalitycsInTabs(lng) ? SEARCH_BOX_TABS : SEARCH_BOX_TABS_3P

  Object.keys(tabsToMap).forEach(
    (key, index) => (tabs = { ...tabs, [key]: { url: tabsToMap[key], index } })
  )
  return tabs
}

export const showAreaCode = lng =>
  ![
    LANGUAGE_TAGS.BOLIVIA,
    LANGUAGE_TAGS.COLOMBIA,
    LANGUAGE_TAGS.ESPAÑA,
    LANGUAGE_TAGS.FRANCE,
    LANGUAGE_TAGS.ITALY,
    LANGUAGE_TAGS.MEXICO,
    LANGUAGE_TAGS.ESPAÑA_EN,
    LANGUAGE_TAGS.URUGUAY,
    LANGUAGE_TAGS.GREAT_BRITAIN,
    LANGUAGE_TAGS.SPANISH_USA,
    LANGUAGE_TAGS.ENGLISH_USA,
    LANGUAGE_TAGS.PARAGUAY
  ].includes(lng)

export const shouldNotRenderAnalitycsParams = lng =>
  ![
    LANGUAGE_TAGS.DEFAULT,
    LANGUAGE_TAGS.BOLIVIA,
    LANGUAGE_TAGS.BRAZIL,
    LANGUAGE_TAGS.CHILE,
    LANGUAGE_TAGS.COLOMBIA,
    LANGUAGE_TAGS.ESPAÑA,
    LANGUAGE_TAGS.ESPAÑA_EN,
    LANGUAGE_TAGS.ITALY,
    LANGUAGE_TAGS.MEXICO,
    LANGUAGE_TAGS.PARAGUAY,
    LANGUAGE_TAGS.PERU,
    LANGUAGE_TAGS.URUGUAY
  ].includes(lng)

export const hideAnalitycsInTabs = lng =>
  ![
    LANGUAGE_TAGS.DEFAULT,
    LANGUAGE_TAGS.ENGLISH,
    LANGUAGE_TAGS.BOLIVIA,
    LANGUAGE_TAGS.BRAZIL,
    LANGUAGE_TAGS.CHILE,
    LANGUAGE_TAGS.COLOMBIA,
    LANGUAGE_TAGS.ESPAÑA,
    LANGUAGE_TAGS.FRANCE,
    LANGUAGE_TAGS.ITALY,
    LANGUAGE_TAGS.MEXICO,
    LANGUAGE_TAGS.PARAGUAY,
    LANGUAGE_TAGS.PERU,
    LANGUAGE_TAGS.ESPAÑA_EN,
    LANGUAGE_TAGS.URUGUAY,
    LANGUAGE_TAGS.GREAT_BRITAIN,
    LANGUAGE_TAGS.SPANISH_USA,
    LANGUAGE_TAGS.ENGLISH_USA
  ].includes(lng)

export const shouldInsertGtmTags = lng =>
  [
    LANGUAGE_TAGS.DEFAULT,
    LANGUAGE_TAGS.ENGLISH,
    LANGUAGE_TAGS.BRAZIL,
    LANGUAGE_TAGS.CHILE,
    LANGUAGE_TAGS.BOLIVIA,
    LANGUAGE_TAGS.COLOMBIA,
    LANGUAGE_TAGS.ESPAÑA,
    LANGUAGE_TAGS.FRANCE,
    LANGUAGE_TAGS.ITALY,
    LANGUAGE_TAGS.MEXICO,
    LANGUAGE_TAGS.PARAGUAY,
    LANGUAGE_TAGS.PERU,
    LANGUAGE_TAGS.ESPAÑA_EN,
    LANGUAGE_TAGS.URUGUAY,
    LANGUAGE_TAGS.GREAT_BRITAIN,
    LANGUAGE_TAGS.ENGLISH_USA,
    LANGUAGE_TAGS.SPANISH_USA
  ].includes(lng)

export const is3PCountry = lng =>
  [
    LANGUAGE_TAGS.MEXICO,
    LANGUAGE_TAGS.PERU,
    LANGUAGE_TAGS.SPANISH_USA,
    LANGUAGE_TAGS.ENGLISH_USA,
    LANGUAGE_TAGS.BOLIVIA,
    LANGUAGE_TAGS.COLOMBIA,
    LANGUAGE_TAGS.GREAT_BRITAIN,
    LANGUAGE_TAGS.FRANCE,
    LANGUAGE_TAGS.ESPAÑA,
    LANGUAGE_TAGS.ESPAÑA_EN,
    LANGUAGE_TAGS.ITALY,
    LANGUAGE_TAGS.PARAGUAY,
    LANGUAGE_TAGS.URUGUAY
  ].includes(lng)

export const arrayToLowerCase = arr => {
  return arr.map(value => {
    return value.toLowerCase()
  })
}

export const showMercadoPagoShopping = (
  language,
  isCheckoutRoute,
  isShoppingMiles,
  showMercadoPagoMethod
) => {
  const isMercadoPagoValidLanguageTag =
    language === LANGUAGE_TAGS.DEFAULT || language === LANGUAGE_TAGS.ENGLISH

  return (
    isMercadoPagoValidLanguageTag &&
    showMercadoPagoMethod &&
    isCheckoutRoute &&
    !isShoppingMiles &&
    CONFIG.ENABLE_MERCADO_PAGO
  )
}

export const showTwoCardsShopping = (language, isShoppingMiles) => {
  const isTwoCardsValidLanguageTag = language === LANGUAGE_TAGS.DEFAULT

  return CONFIG.ENABLE_TWO_CARDS_SHOPPING && isTwoCardsValidLanguageTag && !isShoppingMiles
}
