import { handleActions } from 'redux-actions'

import {
  fetchProvincesStart,
  fetchProvincesSuccess,
  fetchBranchesProvincesSuccess,
  fetchProvincesError
} from 'actions/catalog/provinces'

const stateDefault = {
  data: null,
  error: null,
  isFetching: false,
  provincesCollection: [],
  countriesSearched: []
}

const reducer = handleActions(
  {
    [fetchProvincesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true,
      provincesCollection: state.provincesCollection ? [...state.provincesCollection] : [],
      countriesSearched: state.countriesSearched ? [...state.countriesSearched] : []
    }),
    [fetchProvincesSuccess]: (
      state,
      {
        payload: {
          provinces: { data },
          countryId
        }
      }
    ) => ({
      ...state,
      data,
      error: null,
      isFetching: false,
      selectedCountry: countryId,
      provincesCollection: [...state.provincesCollection, ...data.resources],
      countriesSearched: [...state.countriesSearched, countryId]
    }),
    [fetchBranchesProvincesSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchProvincesError]: (state, { payload: { error, countryId } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false,
      selectedCountry: countryId,
      provincesCollection: [...state.provincesCollection],
      countriesSearched: [...state.countriesSearched]
    })
  },
  stateDefault
)

export default reducer
