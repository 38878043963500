/* eslint-disable import/no-cycle */
import CONFIG from 'config'
import API_ROUTES from 'constants/apiRoutes'
import api from 'services/api'

import { commonOptions, backendConnectorCommon } from './common'

const backendConnectorConfigOptions = {
  ...backendConnectorCommon,
  loadPath: (lngs, namespace) => `${API_ROUTES.LOCALIZATION}/${lngs}_${namespace}`,
  request: async function(options, url, payload, callback) {
    try {
      if (!CONFIG.ENABLE_BO_TRANSLATIONS)
        throw new Error('Backoffice translations disabled  - Loading static keys')

      const result = await api.get(url)

      return callback(null, {
        status: result.status,
        data: result.data && result.data.translations
      })
    } catch (error) {
      return callback(
        {
          status: error.response && error.response.status,
          data: (error.response && error.response.data) || error.message
        },
        null
      )
    }
  }
}

export const clientOptions = {
  ...commonOptions,
  appendNamespaceToCIMode: true,
  // avoid loading namespaces again
  ns: [],
  // Plugins Options: Load translations from backend
  backend: backendConnectorConfigOptions,
  react: {
    useSuspense: true
  }
}
