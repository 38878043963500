import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { getQueryParamByLegs } from 'helpers/sublos/services'
// eslint-disable-next-line import/no-cycle
import { apiSublos } from 'services/api'

const {
  sublosGetReservationOffersStart,
  sublosGetReservationOffersSuccess,
  sublosGetReservationOffersError,
  sublosGetReservationOffersClear
} = createActions({
  SUBLOS_GET_RESERVATION_OFFERS_START: () => {},
  SUBLOS_GET_RESERVATION_OFFERS_SUCCESS: data => ({ data }),
  SUBLOS_GET_RESERVATION_OFFERS_ERROR: error => ({ error }),
  SUBLOS_GET_RESERVATION_OFFERS_CLEAR: () => {}
})

const fetchReservationOffers = ({
  legs,
  confirmPlaced,
  paxQuantity,
  cabinClass
}) => async dispatch => {
  dispatch(sublosGetReservationOffersStart())
  try {
    const response = await apiSublos.get(
      apiRoutes.SUBLOS_GET_RESERVATION_OFFERS(
        getQueryParamByLegs({ legs, confirmPlaced, paxQuantity, cabinClass })
      )
    )
    dispatch(sublosGetReservationOffersSuccess(response.data))
  } catch (error) {
    dispatch(sublosGetReservationOffersError(error.response ? error.response.data : error))
  }
}

export {
  fetchReservationOffers,
  sublosGetReservationOffersStart,
  sublosGetReservationOffersSuccess,
  sublosGetReservationOffersError,
  sublosGetReservationOffersClear
}
