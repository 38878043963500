/* eslint-disable import/no-cycle */
import axios from 'axios'

import CONFIG from 'config'
import { getBookingToken } from 'helpers/sessionStorage'
import { applyAuthorizationHeaders } from 'server/services/auth'
import { getCurrentToken, specialErrorTreatment, setLanguageHeaders } from 'services/api'

const apiBooking = axios.create({
  baseURL: CONFIG.API_BASE_URL,
  headers: {
    common: {
      Authorization: `Bearer ${getCurrentToken()}`
    },
    get: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    },
    post: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    }
  }
})

//APIBOOKING instance interceptors
const setApiBookingInterceptors = initializedStore => {
  apiBooking.interceptors.request.use(async function(config) {
    const withLangHeaders = setLanguageHeaders(config)
    const bookingToken = getBookingToken()

    if (bookingToken !== null) {
      withLangHeaders.headers['X-Booking-Session'] = `Bearer ${bookingToken}`
    }

    await applyAuthorizationHeaders(withLangHeaders.headers)

    return withLangHeaders
  })

  apiBooking.interceptors.response.use(
    response => response,
    error => {
      const { config, response } = error

      error.response && specialErrorTreatment(initializedStore, response && response.status)

      const originalRequest = config

      if (response && response.status === 400) {
        return axios(originalRequest)
      } else {
        return Promise.reject(error)
      }
    }
  )
}

export { apiBooking as default, setApiBookingInterceptors }
