export default {
  'passenger-data.validation.fields-with-errors':
    'Verificá que los siguientes campos contengan datos válidos',
  'passenger-data.form.title': 'Datos de pasajero',
  'passenger-data.reservation-data-title': 'Datos de reserva',
  'passenger-data.name-placeholder': 'Ingresá el nombre del pasajero.',
  'passenger-data.lastname-placeholder': 'Ingresá el apellido del pasajero.',
  'passenger-data.birthdate': 'Fecha de Nacimiento',
  'passenger-data.day-placeholder': 'Día',
  'passenger-data.month-placeholder': 'Mes',
  'passenger-data.year-placeholder': 'Año',
  'passenger-data.gender': 'Sexo',
  'passenger-data.gender-female': 'Femenino',
  'passenger-data.gender-male': 'Masculino',
  'passenger-data.relationship': 'Parentesco',
  'passenger-data.relationship-placeholder': 'Ingresá el parentesco.',
  'passenger-data.emergency-contact-placeholder': 'Ingresá el nombre del contacto.',
  'passenger-data.area-code-placeholder': 'Ej. 11',
  'passenger-data.email-placeholder': 'Este email va a quedar asociado a tu reserva.',
  'passenger-data.confirm-email': 'Confirmá tu email',
  'passenger-data.confirm-email-placeholder': 'Confirmá tu correo electrónico.',
  'passenger-data.add-phone': 'Agregar otro teléfono',
  'passenger-data.remove-phone': 'Eliminar teléfono',
  'passenger-data.marketing-engagement': 'Acepto recibir comunicaciones en mi cuenta de e-mail',
  'passenger-data.residence-address': 'Informacion de residencia',
  'passenger-data.destination-address': 'Información donde pasará la cuarentena',
  'passenger-data.use-same-residence-address':
    'Los datos de residencia son los mismos que el primer pasajero.',
  'passenger-data.use-same-destination-address':
    'Los datos de destino son los mismos que el primer pasajero.',
  'passenger-data.validation.repeated-document-number':
    'El campo Número de Documento no debe ser igual entre dos o más pasajeros.',
  'passenger-data.id-placeholder': 'Nº de doc.',
  'passenger-data.nationality': 'Nacionalidad',
  'passenger-data.document-issuing-country': 'País de Emisión',
  'passenger-data.document-expiration-date': 'Fecha de Caducidad',
  'passenger-data.frequent-passenger': 'Este pasajero es viajero frecuente.',
  'passenger-data.passenger-special-assistance': 'Este pasajero necesita asistencia especial.',
  'passenger-data.which-airline': '¿De qué Aerolínea?',
  'passenger-data.membership-number': 'Número de Socio',
  'passenger-data.membership-number-placeholder': 'Ingresá el número de socio',
  'passenger-data.special-foods': 'Comidas Especiales',
  'passenger-data.special-services': 'Servicios Especiales',
  'passenger-data.address-country-placeholder': 'Seleccionar un país',
  'passenger-data.address-province-placeholder': 'Seleccionar una provincia',
  'passenger-data.address-street-placeholder': 'Ingresá el domicilio',
  'passenger-data.address-zip-code-placeholder': 'Ej. C1234ABC',
  'passenger-data.information-secure-flight': 'Information Secure Flight',
  'passenger-data.compensation-number': 'Número de indemnización',
  'passenger-data.has-redress-number': 'Tiene Nº REDRESS',
  'passenger-data.redress-number-description':
    'El Redress es un Número que puede ser solicitado en los Aeropuertos de Estados Unidos y que ayudará a identificar la identidad y datos personales del pasajero.',
  'passenger-data.redress-more-information': 'Más información disponible acá',
  'passenger-data.emergency-contact': 'Contacto de Emergencia',
  'passenger-data.validation.address-state.required': 'Debes indicar una provincia.',
  'passenger-data.validation.address-state.pattern':
    'Verificá que la provincia indicada sea válida.',
  'passenger-data.validation.emails.pattern': 'Verificá que ambos emails indicados sean iguales.',
  'passenger-data.validation.birthdate-day.required': 'Debes indicar el día.',
  'passenger-data.validation.birthdate-month.required': 'Debes indicar el mes.',
  'passenger-data.validation.birthdate-year.required': 'Debes indicar el año.',
  'passenger-data.validation.birthdate.required': 'No olvides indicar la fecha de nacimiento.',
  'passenger-data.validation.birthdate-range.required':
    'La fecha de nacimiento no corresponde con el tipo de pasajero',
  'passenger-data.validation.birthdate.pattern': 'La fecha de nacimiento es incorrecta',
  'passenger-data.validation.gender.required': 'Debes indicar el sexo del pasajero',
  'passenger-data.validation.phone.required': 'Debes indicar el tipo del teléfono',
  'passenger-data.validation.doc-expiration-date.required': 'Debes indicar la fecha de caducidad.',
  'passenger-data.validation.doc-expiration-date.pattern': 'Tu documento se encuentra vencido.',
  'passenger-data.validation.issuing-country.required':
    'Debes indicar el país de emisión del documento.',
  'passenger-data.validation.nationality.required': 'Debes indicar la nacionalidad del pasajero.',
  'passenger-data.validation.airline.required': 'Debes indicar la aerolínea que frecuentas.',
  'passenger-data.validation.membership-number.required': 'No olvides ingresar el número de socio.',
  'passenger-data.validation.membership-number.pattern':
    'Verificá que el número de socio sea válido.',
  'passenger-data.validation.compensation-number.required': 'No olvides indicar el número',
  'passenger-data.validation.compensation-number.pattern': 'Verificá que el número sea válido.',
  'passenger-data.validation.special-preferences.required':
    'No olvides indicar las comidas o servicios especiales.',
  'passenger-data.age-warning-modal.line-0':
    'Pasajeros menores de 16 años viajando solos en vuelos operados por otra compañía',
  'passenger-data.age-warning-modal.line-1':
    'distinta a Aerolíneas Argentinas deberán contactarse con la respectiva línea aérea',
  'passenger-data.age-warning-modal.line-2':
    'e informarse sobre la normativa que aplica a menores viajando sin acompañantes',
  'passenger-data.age-warning-modal.line-full':
    'Pasajeros menores de 16 años viajando solos en vuelos operados por otra compañía distinta a Aerolíneas Argentinas deberán contactarse con la respectiva línea aérea e informarse sobre la normativa que aplica a menores viajando sin acompañantes',
  'passenger-data.age-warning-modal.continue': 'Continuar',
  'passenger-data.age-warning-modal.go-back-to-results': 'Volver a mis resultados',
  'checkout.purchase-confirmation.disclaimer':
    'Aerolíneas Argentinas S.A., se encuentra exceptuada de cumplir con el Régimen de Facturación establecido en la RG 1415, dado que estamos comprendidos en el Anexo I, inciso e) de la mencionada Resolución.Si Usted adquirió, un pasaje internacional o pasaje de cabotaje con origen y/o destino Tierra del Fuego, el recibo remitido por mail, es documento válido, asimilable a una factura.Si Usted adquirió un pasaje de cabotaje, el cual se encuentra gravado por IVA, deberá solicitar la Constancia de Crédito Fiscal, según lo establecido en el Anexo IV, punto B), inciso 4., de la RG 1415. Deberá solicitar una constancia por cada documento emitido. Para solicitar la Constancia de Crédito Fiscal acceda  ',
  'checkout.purchase-confirmation.disclaimer.link': 'desde aquí. ',
  'checkout.modal-time-out.full-height-title': 'Checkout',
  'checkout.modal-time-out.message':
    'El tiempo de completar tu compra ha finalizado.<br>Podés volver a los resultados o realizar una nueva búsqueda.',
  'checkout.modal-time-out-shopping-id.message':
    'El tiempo de completar tu compra ha finalizado.<br>Podés volver a realizar una nueva búsqueda.',
  'checkout.reservation-warning.go-to-my-booking': 'Mi Reserva',
  'reservation.missing-info.passenger.validation':
    'La lista de pasajeros debe incluir a un adulto mayor como acompañante.'
}
