/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { apiSublos } from 'services/api'

const {
  sublosFlightInventoryStart,
  sublosFlightInventorySuccess,
  sublosFlightInventoryError,
  sublosFlightInventoryClear
} = createActions({
  SUBLOS_FLIGHT_INVENTORY_START: () => {},
  SUBLOS_FLIGHT_INVENTORY_SUCCESS: data => ({ data }),
  SUBLOS_FLIGHT_INVENTORY_ERROR: error => ({ error }),
  SUBLOS_FLIGHT_INVENTORY_CLEAR: () => {}
})

const fetchSublosFlightInventory = ({
  origin,
  destination,
  departureDate,
  flightNumber,
  shoppingId
}) => async dispatch => {
  dispatch(sublosFlightInventoryStart())
  try {
    const response = await apiSublos.get(
      apiRoutes.SUBLOS_GET_FLIGHT_INVENTORY({
        origin,
        destination,
        departureDate,
        flightNumber,
        shoppingId
      })
    )
    dispatch(sublosFlightInventorySuccess(response.data))
  } catch (error) {
    dispatch(sublosFlightInventoryError(error.response ? error.response.data : error))
  }
}

export {
  fetchSublosFlightInventory,
  sublosFlightInventoryStart,
  sublosFlightInventorySuccess,
  sublosFlightInventoryError,
  sublosFlightInventoryClear
}
