/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { buildURLEncoded } from 'helpers/url'
import api from 'services/api'

const { fetchBranchStart, fetchBranchSuccess, fetchBranchError } = createActions({
  FETCH_BRANCH_START: () => {},
  FETCH_BRANCH_SUCCESS: brands => brands,
  FETCH_BRANCH_ERROR: error => error
})

const fetchBranches = (pageInfo = null) => {
  return async dispatch => {
    dispatch(fetchBranchStart())

    try {
      const queryParams = buildURLEncoded(`${apiRoutes.BRANCHES}`, pageInfo)
      const branches = await api.get(queryParams)
      dispatch(fetchBranchSuccess(branches))
    } catch (error) {
      dispatch(fetchBranchError(error))
    }
  }
}

export { fetchBranchStart, fetchBranchSuccess, fetchBranchError, fetchBranches }
