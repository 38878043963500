export default {
  'contests.banner.title': 'Concursos',
  'contests.address.title': 'Informes',
  'contests.address.content':
    'Av. Rafael Obligado s/n, Terminal 4, 5° Piso, Aeroparque Jorge Newbery, Ciudad Autónoma de Buenos Aires.',
  'contests.information.title': 'Información',
  'contests.contests-tab': 'Concursos',
  'contests.finished-contests-tab': 'Concursos finalizados',
  'contests.order-contests': 'Ordenar fecha de cierre por:',
  'contests.order-contests.recent-contests-label': 'Más recientes',
  'contests.order-contests.older-contests-label': 'Más antiguos',
  'contests.contest-card.deadline': 'Fecha de cierre:',
  'contests.contest-card.title':
    'El grupo Aerolíneas Argentinas invita a participar del concurso de precios de:',
  'contests.contest-card.button': 'Ver más',
  'contests.error-modal.title': 'Lo sentimos, algo salió mal…',
  'contests.error-modal.content':
    'No pudimos completar la operación, por favor intentá nuevamente.',
  'contests.error-modal.button': 'Volver a intentar',
  'contests.no-contests-error.title': 'Lo sentimos',
  'contests.no-contests-error.secondary-title': 'No hay concursos disponibles',
  'contests.contest-details-modal.title': 'Detalle',
  'contests.contest-details-modal.contract-number': 'Número de contratación',
  'contests.contest-details-modal.company': 'Empresa Contratante',
  'contests.contest-details-modal.deadline': 'Fecha de cierre',
  'contests.contest-details-modal.file': 'Pliego',
  'contests.contest-details-modal.no-file': 'No presenta',
  'contests.contest-details-modal.details': 'Detalle del Concurso'
}
