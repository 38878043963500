import { handleActions } from 'redux-actions'

import {
  fetchAncillariesEvaluationStart,
  fetchAncillariesEvaluationSuccess,
  fetchAncillariesEvaluationError
} from 'actions/rules/ancillariesEvaluation'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchAncillariesEvaluationStart]: state => ({
      ...state,
      data: {},
      error: null,
      isFetching: true
    }),
    [fetchAncillariesEvaluationSuccess]: (state, { payload: { data } }) => {
      return {
        ...state,
        data,
        error: null,
        isFetching: false
      }
    },
    [fetchAncillariesEvaluationError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    })
  },
  defaultState
)

export default reducer
