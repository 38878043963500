/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { apiSublos } from 'services/api'

const {
  sublosGetDestinationsStart,
  sublosGetDestinationsSuccess,
  sublosGetDestinationsError,
  sublosGetDestinationsClear
} = createActions({
  SUBLOS_GET_DESTINATIONS_START: () => {},
  SUBLOS_GET_DESTINATIONS_SUCCESS: data => ({ data }),
  SUBLOS_GET_DESTINATIONS_ERROR: error => ({ error }),
  SUBLOS_GET_DESTINATIONS_CLEAR: () => {}
})

const fetchDestinations = () => async dispatch => {
  dispatch(sublosGetDestinationsStart())
  try {
    const response = await apiSublos.get(apiRoutes.SUBLOS_GET_DESTINATIONS)
    dispatch(sublosGetDestinationsSuccess(response.data))
  } catch (error) {
    dispatch(sublosGetDestinationsError(error.response ? error.response.data : error))
  }
}

export {
  fetchDestinations,
  sublosGetDestinationsStart,
  sublosGetDestinationsSuccess,
  sublosGetDestinationsError,
  sublosGetDestinationsClear
}
