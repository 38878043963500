/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import PropTypes from 'prop-types'

import API_ROUTES from 'constants/apiRoutes'
import { DESCRIPTION, LEVEL, PROCESS_TYPE } from 'constants/log'
import { negotiateToken } from 'server/services/auth'
import { apiLog, setTokenHeader } from 'services/api'

const extractErrorLocation = error => {
  const location = error.stack?.split('\n')[1]?.trim()
  return location ? `| Error at: ${location}` : ''
}

const mapStatusCode = statusCode => {
  const messages = {
    400: '400: Bad Request - The server could not understand the request due to invalid syntax.',
    401: '401: Unauthorized - The client must authenticate itself to get the requested response.',
    403: '403: Forbidden - The client does not have access rights to the content.',
    404: '404: Not Found - The server cannot find the requested resource.',
    408: '408: Request Timeout - The server timed out waiting for the request.',
    429: '429: Too Many Requests - The user has sent too many requests in a given amount of time.',
    500: '500: Internal Server Error - The server has encountered a situation it doesn’t know how to handle.',
    502: '502: Bad Gateway - The server, while acting as a gateway, received an invalid response.',
    503: '503: Service Unavailable - The server is not ready to handle the request.',
    504: '504: Gateway Timeout - The server, while acting as a gateway, did not get a response in time.',
    0: '0: Network Error - There was a network issue (DNS, connection lost, etc.).'
  }

  return messages[statusCode] || 'An unexpected error occurred.'
}

const generateLogMessage = ({
  processType,
  level,
  description,
  apiRoute,
  pnrOrShoppingId,
  errorLocation
}) => {
  const processString = `Process: ${processType}`
  const levelString = `level: ${level}`
  const descriptionString = description ? `description: ${description}` : ''
  const apiRouteString = `| apiRoute: ${apiRoute || 'N/A'}`
  const pnrOrShoppingIdString = `${pnrOrShoppingId ? `| PNR/ShoppingID: ${pnrOrShoppingId}` : ''}`

  // eslint-disable-next-line max-len
  return `[${processString} - ${levelString} ] ${descriptionString} ${pnrOrShoppingIdString} ${apiRouteString} ${errorLocation ||
    ''}`
}

const parseError = error => {
  const result = {
    fileName: error?.fileName || '',
    message: error?.message || '',
    status: error?.status || '',
    lineNumber: error?.lineNumber || '',
    stack: error?.stack?.toString() || '',
    errorLocation: (error?.stack && extractErrorLocation(error)) || ''
  }

  if (error?.response) {
    const { status, statusText, config } = error.response
    result.status = status || null
    result.statusText = statusText || null
    result.url = config?.url || null
    result.message = mapStatusCode(status)
  }

  return result
}

const parseResponse = response => ({
  status: response.status,
  statusText: response.statusText,
  data: response.data,
  url: response?.config?.url,
  language: (response?.config?.header && response.config.header['Accept-Language']) || '',
  channelId: (response?.config?.header && response.config.header['X-Channel-Id']) || ''
})

const mapLoggedResponse = (level, data) => {
  let loggedResponse = {}
  switch (level) {
    case LEVEL.INFO:
      loggedResponse = data || {}
      break
    case LEVEL.ERROR:
      loggedResponse = parseError(data)
      break
    case LEVEL.SUCCESS:
      loggedResponse = parseResponse(data)
      break
  }

  return { ...loggedResponse, message: loggedResponse.message || '' }
}

export const registerBasicLog = async ({
  data,
  level,
  apiRoute,
  description,
  processType,
  pnrOrShoppingId,
  isServerSide
}) => {
  try {
    const log = mapLoggedResponse(level, data)
    const message = generateLogMessage({
      level,
      processType,
      description,
      apiRoute,
      pnrOrShoppingId,
      errorLocation: log.errorLocation
    })

    if (isServerSide) {
      const info = {
        timestamp: new Date(),
        level: 'INFO',
        msg: message,
        details: log
      }

      try {
        // eslint-disable-next-line no-console
        console.error(JSON.stringify(info))
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(info)
      }
    } else {
      const buff = Buffer.from(JSON.stringify(log))
      const response = buff.toString('base64')
      const payload = {
        message,
        response,
        shoppingId: pnrOrShoppingId
      }

      const { access_token } = await negotiateToken()

      setTokenHeader(apiLog.defaults.headers.common, access_token)

      apiLog.post(API_ROUTES.LOGS_PURCHASE, payload)
    }
  } catch (error) {
    // TODO implement client-side logging
    // eslint-disable-next-line no-console
    console.error('Error while registering index:', error.message)
  }
}

registerBasicLog.propTypes = {
  ssrApi: PropTypes.object,
  isServerSide: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  level: PropTypes.oneOf(Object.values(LEVEL)),
  apiRoute: PropTypes.string,
  description: PropTypes.oneOf(Object.values(DESCRIPTION)).isRequired,
  processType: PropTypes.oneOf(Object.values(PROCESS_TYPE)).isRequired,
  pnrOrShoppingId: PropTypes.string.isRequired
}
