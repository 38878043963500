import React, { Component } from 'react'

/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import Icon, { IconNames } from 'components/icons'
import Message from 'components/message'
import { StyledLabel } from 'components/styled-input-label'
import theme from 'components/theme'
import { common as e2eClasses } from 'constants/e2eClasses'
import { toTesteableId } from 'helpers/utils'

const Wrapper = styled.div`
  width: ${({ quarterWidth, halfWidth }) => (quarterWidth ? '25%' : halfWidth ? '50%' : '100%')};
`

const IconContainer = styled.div`
  ${({ disabled }) => !disabled && `cursor: pointer;`}
  position: absolute;
  top: 0;
  bottom: 0;
  ${({ iconPadding }) => iconPadding && `padding: ${iconPadding};`}
  ${({ right }) => (right ? 'right: 0;' : 'left: 0;')};
`

const IconWithMargin = styled(Icon)`
  margin: ${({ smallPadding }) => (smallPadding ? '6px' : '10px')};
  &:hover {
    fill: ${({ theme }) => theme.colors.blue};
  }
`

const StyledContainer = styled.div`
  position: relative;
`

const getBorderColor = ({ focus, error, dateError, theme }) => {
  if (focus) {
    return theme.colors.blue
  }

  if (error || dateError) {
    return theme.colors.red
  }

  return theme.colors.grayLight
}

const getBoxShadow = ({ boxShadow, focus, search, dateError, flatStyle, theme }) => {
  if (search && focus && flatStyle && !boxShadow) {
    return `0 2px 4px 0 ${theme.shadow.black09}`
  }

  if (focus && boxShadow) {
    return `0 2px 10px 0 ${theme.shadow.blue26}, 0 2px 5px 0 ${theme.shadow.blue06}`
  }

  if (focus) {
    return `0 2px 10px 0 ${theme.shadow.black26}, 0 2px 5px 0 ${theme.shadow.black06};`
  }

  if (dateError && flatStyle) {
    return `0 2px 4px 0 ${theme.shadow.black10}`
  }

  if ((search && !flatStyle) || boxShadow || (dateError && !flatStyle)) {
    return `0 2px 10px 0 ${theme.shadow.black26}, 0 2px 5px 0 ${theme.shadow.black06}`
  }

  return 'none'
}

const InputContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  ${({ inputContainerHeight }) => inputContainerHeight && `height: ${inputContainerHeight}`};
  ${({ flatStyle, search }) => !(flatStyle && search) && `padding: 1px;`};
  border-radius: 3px;
  box-shadow: ${props => getBoxShadow(props)};
  border: 1px solid ${props => getBorderColor(props)};

  ${({ search, focus, flatStyle, error, dateError, boxShadow }) =>
    search &&
    !boxShadow &&
    !error &&
    !dateError &&
    !flatStyle &&
    !focus &&
    css`
      border: none;
    `};

  ${({ light, disabled }) =>
    light &&
    disabled &&
    `
      padding: 0;
      border: none;
    `};
`

const StyledInput = styled.input.attrs(props => ({
  className: `${e2eClasses.input}${props.e2eClass || toTesteableId(props.name || '')}${
    props.index + 1 ? `-${props.index + 1}` : ''
  }`,
  ...(props.maxLength && { maxlength: props.maxLength })
}))`
   width:  ${({ theme, value, placeholder }) =>
     !value &&
     (placeholder || '').length > 0 &&
     theme?.root?.style.getPropertyValue('--show-arsa-keys-is-activated') === 'true'
       ? `${placeholder.length + 10}ch`
       : '100%'};
  border: none;
  outline: 0;
  padding: ${({ smallPadding }) => (smallPadding ? '9px 14px' : '13px 14px')};
  font-size: 16px;
  ${({ leftIcon }) => leftIcon && 'padding-left: 45px;'};
  ${({ rightIcon }) => rightIcon && 'padding-right: 45px;'};
  letter-spacing: 0.3px;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.regular};
  ${({ dateError, flatStyle, theme }) =>
    (!dateError || (dateError && flatStyle)) &&
    `border: 1px solid ${theme.colors.transparent};
  `};
  ${({ light }) =>
    light &&
    `
    font-size: 14px;
    border: none;
  `};

  ${({ small, placeholderFontSize }) =>
    small &&
    `
    font-size: 14px;

    &::placeholder {
      font-size: ${placeholderFontSize || '12px'};
    }
  `};

  &::placeholder {
    color: ${({ theme }) => theme.colors.doveGray};
    font-family: ${({ theme }) => theme.fonts.regular};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grayLighter};
    border: solid 1px #e6e1e1;
    ${({ light, smallPadding }) => light && !smallPadding && 'padding: 15px 14px'};
  }

  /* Remove arrows from input with type number*/
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }

  ${({ success }) =>
    success &&
    css`
      color: ${({ theme }) => theme.colors.green};
      border-color: ${({ theme }) => theme.colors.green};

      &:focus {
        border-color: ${({ theme }) => theme.colors.green};
      }
    `};

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.red};
    `};

  ${({ search, disabled }) => search && !disabled && `cursor: pointer;`}

  // remove border without overriding previous conditions
  ${({ disableBorder }) => disableBorder && `border: none;`}
`

const ErrorContainer = styled.div.attrs(props => ({
  className: `${e2eClasses.inputError}${toTesteableId(props.e2eClass || '')}`
}))`
  ${({ withPaddingError }) =>
    withPaddingError &&
    css`
      padding: 14px 0;
    `};
`

class Input extends Component {
  state = {
    type: this.props.type,
    inputFocus: false,
    passwordVisible: false
  }

  static propTypes = { type: PropTypes.string }

  static defaultProps = { type: 'input' }

  componentDidMount() {
    this.messageId = uuid()
  }

  focus = () => {
    this.input.focus()
  }

  blur = () => {
    this.input.blur()
  }

  switchPasswordVisibility = () => {
    this.setState({
      passwordVisible: !this.state.passwordVisible
    })
  }

  renderMessage = (content, type) => (
    <ErrorContainer
      e2eClass={this.props.e2eClass || this.props.name}
      withPaddingError={this.props.withPaddingError}
    >
      <Message e2eClass={this.props.e2eClass} type={type} id={this.messageId}>
        {content}
      </Message>
    </ErrorContainer>
  )

  setInputFocus = (inputFocus = false) => {
    this.setState({ inputFocus }, () => {
      if (inputFocus) {
        this.props.onFocus && this.props.onFocus()
      }
    })
  }

  getRightIcon = type => {
    if (type === 'password') {
      const iconName = this.state.passwordVisible
        ? IconNames.EyeSettings
        : IconNames.EyeSettingsOutline

      return (
        <IconContainer right onClick={() => this.switchPasswordVisibility()}>
          <IconWithMargin
            name={IconNames[iconName]}
            color={theme.colors.gray}
            smallPadding={this.props.smallPadding}
          />
        </IconContainer>
      )
    }

    return <IconContainer right>{this.props.rightIcon}</IconContainer>
  }

  render() {
    const {
      e2eClass,
      type,
      success,
      error,
      label,
      search,
      dateError,
      flatStyle,
      leftIcon,
      leftIconLight,
      light,
      disableIndex,
      labelFontSize,
      iconPadding,
      disabled,
      maxLength,
      role = 'combobox',
      halfWidth,
      quarterWidth,
      inputContainerHeight
    } = this.props
    const isTextType = (type === 'password' && this.state.passwordVisible) || type === 'text'
    const isPasswordType = type === 'password'
    return (
      <Wrapper halfWidth={halfWidth} quarterWidth={quarterWidth}>
        {label && (
          <StyledLabel
            e2eClass={e2eClass}
            fontSize={labelFontSize}
            light={light}
            forId={this.props.id}
          >
            {label}
          </StyledLabel>
        )}
        <StyledContainer>
          <InputContainer
            boxShadow={this.props.boxShadow}
            error={error}
            focus={this.state.inputFocus}
            search={search}
            dateError={dateError}
            ref={el => (this.container = el)}
            flatStyle={flatStyle}
            light={light}
            disabled={disabled}
            inputContainerHeight={inputContainerHeight}
          >
            {leftIcon && (
              <IconContainer iconPadding={iconPadding} disabled={disabled}>
                {light ? leftIconLight : leftIcon}
              </IconContainer>
            )}
            <StyledInput
              id={this.props.id}
              tabIndex={disableIndex ? -1 : 0}
              {...this.props}
              {...this.state}
              onFocus={() => this.setInputFocus(true)}
              onBlur={event => {
                this.setInputFocus()
                this.props.onBlur && this.props.onBlur(event)
              }}
              ref={el => (this.input = el)}
              type={isTextType ? 'text' : type}
              maxLength={maxLength}
              role={isTextType || isPasswordType ? null : role}
              aria-expanded={role == 'combobox' ? this.state.inputFocus : undefined}
              aria-invalid={!!error}
              aria-describedby={error ? this.messageId : undefined}
            />
            {this.getRightIcon(type)}
          </InputContainer>
          {success && this.renderMessage(success, 'formInputSuccess')}
          {error && this.renderMessage(error, 'formInputError')}
        </StyledContainer>
      </Wrapper>
    )
  }
}

export default Input

export class InputSearchDate extends Input {
  static defaultProps = {
    search: true,
    leftIcon: <IconWithMargin name={IconNames.Calendar} size={30} color={theme.colors.blue} />,
    leftIconLight: <IconWithMargin name={IconNames.Calendar} size={25} color={theme.colors.blue} />,
    small: true,
    autoComplete: 'off'
  }
}

export class InputSearchDateForm extends Input {
  static defaultProps = {
    search: false,
    rightIcon: <IconWithMargin name={IconNames.Calendar} size={30} color={theme.colors.blue} />
  }
}

export class InputSearchFrom extends Input {
  static defaultProps = {
    search: true,
    leftIcon: <IconWithMargin name={IconNames.FlightTakeOff} size={30} color={theme.colors.blue} />
  }
}

export class InputSearchTo extends Input {
  static defaultProps = {
    search: true,
    leftIcon: (
      <IconWithMargin name={IconNames.AirplaneLandingRight} size={30} color={theme.colors.blue} />
    )
  }
}
