/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types'

import { LEVEL, PROCESS_TYPE, DESCRIPTION } from 'constants/log'
import { registerBasicLog } from 'helpers/logs/helper'

const parsePaymentResponse = (response, level) => {
  let parseLevel = level
  const { status, data } = response

  if (status === 200 && !data?.success) {
    parseLevel = LEVEL.ERROR
  }

  return { level: parseLevel }
}

const log = {
  //generic
  register: params => registerBasicLog(params),

  //payment client side
  startPaymentClient: params =>
    registerBasicLog({
      ...params,
      level: LEVEL.INFO,
      description: DESCRIPTION.START_PAYMENT_CLIENT
    }),
  successPaymentClient: params =>
    registerBasicLog({
      ...params,
      ...parsePaymentResponse(params.data, LEVEL.SUCCESS),
      description: DESCRIPTION.SUCCESS_PAYMENT_CLIENT
    }),
  errorPaymentClient: params =>
    registerBasicLog({
      ...params,
      level: LEVEL.ERROR,
      description: DESCRIPTION.ERROR_PAYMENT_CLIENT
    }),

  //payment server side
  redirectToArsa: params =>
    registerBasicLog({
      ...params,
      isServerSide: true,
      level: params.level || LEVEL.INFO,
      description: DESCRIPTION.REDIRECTED_TO_ARSA,
      processType: PROCESS_TYPE.EXECUTE_GLOBAL_REDIRECT_SERVER_SIDE
    }),
  check3pChallenge: params =>
    registerBasicLog({
      ...params,
      isServerSide: true,
      level: params.level || LEVEL.INFO,
      description: DESCRIPTION.CHECK_3P_CHALLENGE,
      processType: PROCESS_TYPE.CHALLENGE
    }),
  startPaymentServerConfirmation: params =>
    registerBasicLog({
      ...params,
      isServerSide: true,
      level: LEVEL.INFO,
      description: params.description || DESCRIPTION.START_PAYMENT_SERVER_CONFIRMATION,
      processType: PROCESS_TYPE.RESERVATION_CONFIRMATION
    }),
  successPaymentServerConfirmation: params =>
    registerBasicLog({
      ...params,
      isServerSide: true,
      level: LEVEL.SUCCESS,
      description: params.description || DESCRIPTION.SUCCESS_PAYMENT_SERVER_CONFIRMATION,
      processType: PROCESS_TYPE.RESERVATION_CONFIRMATION
    }),
  errorPaymentServerConfirmation: params =>
    registerBasicLog({
      ...params,
      isServerSide: true,
      level: LEVEL.ERROR,
      description: params.description || DESCRIPTION.ERROR_PAYMENT_SERVER_CONFIRMATION,
      processType: PROCESS_TYPE.RESERVATION_CONFIRMATION
    })
}

log.propTypes = {
  register: PropTypes.func,
  startPaymentClient: PropTypes.func,
  successPaymentClient: PropTypes.func,
  errorPaymentClient: PropTypes.func,
  check3pChallenge: PropTypes.func,
  startPaymentServerConfirmation: PropTypes.func,
  successPaymentServerConfirmation: PropTypes.func,
  errorPaymentServerConfirmation: PropTypes.func
}

export default log
