/* eslint-disable max-len */
const SERVICE = {
  //V2
  BOOKING: '/v2/booking',
  LOYALTY: '/v2/loyalty',
  GDS_LOYALTY: '/v2/gds-loyalty',

  //V1
  CATALOG: '/v1/catalog',
  FLIGHTS: '/v1/flights',
  RULES: '/v1/rules',
  AGREEMENT: '/v1/agreement',
  SUGGEST: '/v1/suggest',
  RESERVATION: '/v1/reservation',
  CHECKOUT: '/v1/checkout',
  AUTH: '/v1/auth',
  PAYMENTMETHODS: '/v1/paymentMethods',
  FORMS: '/v1/forms',
  SUBLOS: '/v1/sublos',
  PROVINCES: '/v1/provinces',
  CAMPAIGN: '/v1/campaign',
  DOCUMENT_TYPE: '/v1/documentTypes',
  SPECIAL_SERVICE: '/v1/specialServiceRequest',
  LOCALIZATION: '/v1/localization',
  TRAVEL_ASSISTANCE: '/v1/travel-assistance'
}

export default {
  //Individuals V1 TODO: check deprecated endpoints
  POSTS: '/v1/posts',
  ROUTES: '/v1/routes',
  DESTINATIONS: '/v1/destinations',
  INDEX: '/v1/index',
  SCHEDULED_FLIGHTS: '/v1/schedule/',
  HOLD_PAYMENT_OPTIONS: '/v1/bank-promotions/paymentOptions/holdBooking',

  //logs
  LOGS_CHANNEL: `${SERVICE.BOOKING}/client-errors/channel-error`,
  LOGS_PURCHASE: `${SERVICE.BOOKING}/client-errors/purchase`,

  //used on ssrApi
  LOCALIZATION: `${SERVICE.LOCALIZATION}/languageBundles`,

  PROVINCES: `${SERVICE.PROVINCES}`,
  CAMPAIGNS: `${SERVICE.CAMPAIGN}/campaigns`,
  DOCUMENT_TYPE: `${SERVICE.DOCUMENT_TYPE}`,
  SPECIAL_SERVICE: `${SERVICE.SPECIAL_SERVICE}`,

  //not a service
  CONTACT_INFORMATION: `/contactInformation`,

  //Google Recaptcha
  GOOGLE_RECAPTCHA_VALIDATION_ENDPOINT: 'https://www.google.com/recaptcha/api/siteverify',

  //Booking
  RESERVATIONS: `${SERVICE.BOOKING}/reservations`,
  HOLD_OPTIONS: `${SERVICE.BOOKING}/holdOptions`,
  HOLD_OPTIONS_CONFIRMATION: `${SERVICE.BOOKING}/holdOptions/confirmation`,
  RESERVATION_CONFIRMATION: `${SERVICE.BOOKING}/reservations/confirmation`,
  RESERVATION_CONFIRMATION_3P: `${SERVICE.BOOKING}/reservations/global-confirmation`,
  RESERVATIONS_PURCHASE_SESSION: `${SERVICE.BOOKING}/reservations/purchase-session`,
  RESERVATION_CONFIRMATION_LOCAL: `${SERVICE.BOOKING}/reservations/local-confirmation`,
  ANCILLARIES_PURCHASE: `${SERVICE.BOOKING}/ancillaries`,
  ANCILLARIES_3P_LOCAL_CONFIRMATION: `${SERVICE.BOOKING}/ancillaries/confirmation`,
  EXCHANGES: `${SERVICE.BOOKING}/reservations/exchanges`,
  EXCHANGES_3P_LOCAL_CONFIRMATION: `${SERVICE.BOOKING}/reservations/exchange-confirmation`,
  BOOKING_AUTH: `${SERVICE.BOOKING}/auth`,
  BOOKING_PAYMENT: `${SERVICE.BOOKING}/reservations/extensions`,
  BOOKING_PAYMENT_CONFIRMATION: `${SERVICE.BOOKING}/reservations/extension-confirmation`,
  BOOKING_EXTERNAL_MERCADO_PAGO: (pnr, lastName) =>
    `${SERVICE.BOOKING}/external/mercado-pago-confirmation?reservationCode=${pnr}&lastName=${lastName}`,
  RESERVATION_EXTERNAL: (pnr, lastName, preferenceId) =>
    `${SERVICE.BOOKING}/external?reservationCode=${pnr}&lastName=${lastName}&fopCode=MP&preferenceId=${preferenceId}`,
  GLOBAL_PAYMENT: pnr => `${SERVICE.BOOKING}/reservations/${pnr}/issue`,
  GLOBAL_RESERVATIONS: pnrOrShoppingId =>
    `${SERVICE.BOOKING}/reservations/${pnrOrShoppingId}/response`,
  FARE_RULES: pnr => `${SERVICE.BOOKING}/reservations/${pnr}/fareRules`,

  //Catalog
  CABINS: `${SERVICE.CATALOG}/cabins`,
  CHARGES: `${SERVICE.CATALOG}/charges`,
  CHANNELS: `${SERVICE.CATALOG}/channels`,
  AIRCRAFTS: `${SERVICE.CATALOG}/aircrafts`,
  AIRLINES: `${SERVICE.CATALOG}/airlines`,
  AIRPORTS: `${SERVICE.CATALOG}/airports`,
  BRANDS: `${SERVICE.CATALOG}/brands`,
  CITIES: `${SERVICE.CATALOG}/cities`,
  COUNTRIES: `${SERVICE.CATALOG}/countries`,
  COUNTRIES_BRANCHES: type => `${SERVICE.CATALOG}/branches/${type}/countries`,
  PROVINCES_BRANCHES: (type, country) =>
    `${SERVICE.CATALOG}/branches/${type}/countries/${country}/provinces`,
  CITIES_BRANCHES: (type, country) =>
    `${SERVICE.CATALOG}/branches/${type}/countries/${country}/cities`,
  BRANCHES: `${SERVICE.CATALOG}/branches`,
  DOCUMENT_TYPES: `${SERVICE.CATALOG}/documentTypes`,
  PAYMENT_OPTION_ORDER: `${SERVICE.CATALOG}/paymentOption`,
  SPECIAL_REQUESTS_ALL: `${SERVICE.CATALOG}/specialServiceRequests`,
  PASSENGERS_TYPE: `${SERVICE.CATALOG}/passengerTypes`,
  SUBLOS_LOGIN_MODAL: `${SERVICE.CATALOG}/webPageVisibility/sublosLogin`,
  PUNCTUALITY_INDEX: flightInfo => `${SERVICE.CATALOG}/flightPunctuality/${flightInfo}`,
  CONTESTS: (order, available, offset) =>
    `${SERVICE.CATALOG}/contest?limit=6&offset=${offset}&sort=deadline&order=${order}&available=${available}`,
  CONTEST_PDF: (contractNumber, nameFile) =>
    `${SERVICE.CATALOG}/contest/${contractNumber}/pdf?nameFile=${nameFile}`,

  //Flights
  FLIGHTS: `${SERVICE.FLIGHTS}`,
  FLIGHTS_STATUS: `${SERVICE.FLIGHTS}/status`,
  FLIGHTS_SCHEDULE: `${SERVICE.FLIGHTS}/schedule`,
  FLIGHTS_SUMMARY: `${SERVICE.FLIGHTS}/summary`,
  FLIGHTS_OFFERS: `${SERVICE.FLIGHTS}/offers`,
  FLIGHTS_OFFERS_EXCHANGES: `${SERVICE.FLIGHTS}/offers/exchanges`,
  FLIGHTS_OFFERS_EXCHANGES_SUMMARY: `${SERVICE.FLIGHTS}/offers/exchanges/summary`,
  FLIGHT_OFFERS_MATRIX: `${SERVICE.FLIGHTS}/offers/matrix`,
  FLIGHTS_ORIGINAL_FARES: pnr => `${SERVICE.FLIGHTS}/originalFare?shoppingId=${pnr}`,

  //Rules
  RULES: `${SERVICE.RULES}`,
  SEARCH_BOX_RULES: `${SERVICE.RULES}/shopping/searchbox`,
  DOCUMENT_TYPE_RULES: `${SERVICE.RULES}/checkout/documents`,
  PASSENGER_DATA_RULES: `${SERVICE.RULES}/checkout/passengerData`,
  SPECIAL_REQUESTS_RULES: `${SERVICE.RULES}/checkout/specialServiceRequest`,
  NOTIFICATIONS: `${SERVICE.RULES}/notifications/evaluation`,
  ANCILLARIES_EVALUATION: `${SERVICE.RULES}/checkout/ancillaries/evaluation`,

  //Agreement
  CORPORATE_AGREEMENTS_REGISTER: `${SERVICE.AGREEMENT}/employees/authentications`,
  CORPORATE_TICKET_REFUND: `${SERVICE.AGREEMENT}/ticketsReturn`,
  CORPORATE_AGREEMENTS: `${SERVICE.AGREEMENT}/agreements/request`,
  REPENTANCE_TICKET_REFUND: `${SERVICE.AGREEMENT}/regretRequests`,
  CORPORATE_PASSWORD_RESET: userEmail =>
    `${SERVICE.AGREEMENT}/employees/${userEmail}/reset-password`,
  CORPORATE_USER_EMAIL_RESET: document => `${SERVICE.AGREEMENT}/employees/${document}/forgot-user`,

  //suggest
  SUGGESTIONS: `${SERVICE.SUGGEST}/suggestions/destinations`,
  SUGGESTIONS_IATA_CODES: `${SERVICE.SUGGEST}/destinations`,

  //loyalty
  LOGIN: `${SERVICE.LOYALTY}/accounts/`,
  PROFILE: `${SERVICE.LOYALTY}/members/`,
  AR_PLUS_GET_MEMBER_INFO: `${SERVICE.LOYALTY}/members/`,
  COUNTRIES_ARPLUS_AR_PLUS: `${SERVICE.LOYALTY}/formInfo/countries`,
  PROVINCES_ARPLUS_AR_PLUS: `${SERVICE.LOYALTY}/formInfo/provinces`,
  FIRST_ACCESS_AR_PLUS: `${SERVICE.LOYALTY}/members/firstAccess`,
  FIRST_ACCESS_SET_PASSWORD_AR_PLUS: `${SERVICE.LOYALTY}/members/firstAccessConfirm`,
  IDENTIFICATIONS_TYPE: `${SERVICE.LOYALTY}/formInfo/identificationsType`,
  MEMBERS_AR_PLUS: `${SERVICE.LOYALTY}/members`,
  PRE_REGISTRATION_AR_PLUS: `${SERVICE.LOYALTY}/members/preRegistration`,
  CANCELLATION_IN_24hs: `${SERVICE.LOYALTY}/claim/cancellation`,
  CLAIM_RESERVATION: `${SERVICE.LOYALTY}/claim/reservation`,
  CLAIM_CONTACT_US: `${SERVICE.LOYALTY}/claim/contactUs`,
  CLAIM_CONTACT_US_REASONS_SUGGESTIONS: `${SERVICE.LOYALTY}/claim/contactUs/suggestions`,
  REQUEST_TUAVTA_REFUND: `${SERVICE.LOYALTY}/claim/TUAVTA`,
  BAGGAGE: `${SERVICE.LOYALTY}/claim/baggage`,
  AR_PLUST_CHANGE_ACCOUNT_PASSWORD: `${SERVICE.LOYALTY}/accounts/changePassword`,
  AR_PLUS_RECOVER_EMAIL_CONFIRMATION: `${SERVICE.LOYALTY}/forgotEmail/registration`,
  EVALUATE_MEMBER_ELIGIBILITY_AR_PLUS: `${SERVICE.LOYALTY}/pointsRedemptionRequests/evaluateDestinationMemberEligibility`,
  PROMO_MILES_AND_BONUS_AR_PLUS: `${SERVICE.LOYALTY}/pointsRedemptionRequests/promoMilesAndBonus`,
  PAYMENT_MILES_AR_PLUS: `${SERVICE.LOYALTY}/miles/purchase`,
  AR_PLUS_AIRLINE_COMPANIES: `${SERVICE.LOYALTY}/partners/aereo`,
  AR_PLUS_COMPANIES_AND_SERVICES: `${SERVICE.LOYALTY}/partners/partner`,
  AR_PLUS_OFFLINE_AEREO_CONFIRMATION: `${SERVICE.LOYALTY}/pointsRedemptionRequests/offlineAereoConfirmation`,
  AR_PLUS_OFFLINE_PARTNER_CONFIRMATION: `${SERVICE.LOYALTY}/partners/offlinePartnerConfirmation`,
  TRANSFER_MILES_AR_PLUS: `${SERVICE.LOYALTY}/miles/transfer`,
  GIFT_MILES_AR_PLUS: `${SERVICE.LOYALTY}/miles/gift`,
  AR_PLUS_FORGOT_MEMBERSHIP_CODE: ({ identificationType, numberOfIdentification, dateOfBirth }) =>
    `${SERVICE.LOYALTY}/members/forgotMembershipCode?identificationType=${identificationType}&numberOfIdentification=${numberOfIdentification}&dateOfBirth=${dateOfBirth}`,
  AR_PLUS_LAST_OPERATIONS: (memberShipCode, selectedDate, lastDayOfMonthDate) =>
    `${SERVICE.LOYALTY}/operations/${memberShipCode}?dateFrom=${selectedDate}&dateTo=${lastDayOfMonthDate}`,
  AR_PLUS_LOGIN: memberShipCode => `${SERVICE.LOYALTY}/accounts/${memberShipCode}/auth`,
  FORGOTTEN_PASSWORD_AR_PLUS: memberNumber =>
    `${SERVICE.LOYALTY}/accounts/${memberNumber}/password`,
  CLAIM_CONTACT_US_EXPERIENCE_PLACES: suggestionId =>
    `${SERVICE.LOYALTY}/claim/contactUs/experiencePlace?suggestionId=${suggestionId}`,
  CLAIM_CONTACT_US_ABOUT: experiencePlaceId =>
    `${SERVICE.LOYALTY}/claim/contactUs/suggestionData?experiencePlaceId=${experiencePlaceId}`,
  VALIDATE_EMAIL: email => `${SERVICE.LOYALTY}/members/validateEmailAddress?email=${email}`,
  AR_PLUS_RESEND_EMAIL: email => `${SERVICE.LOYALTY}/accounts/${email}/validateIdentity`,
  AR_PLUS_VERIFY_ACCOUNT: token => `${SERVICE.LOYALTY}/accounts/validateIdentity?token=${token}`,
  AR_PLUS_ACCOUNT_EVALUATION: memberCode =>
    `${SERVICE.LOYALTY}/members/${memberCode}/category?membershipCode=${memberCode}`,
  AR_PLUS_RECOVER_EMAIL_WITH_MEMBERSHIP_CODE: (email, memberNumber) =>
    `${SERVICE.LOYALTY}/forgotEmail/validation?email=${email}&membershipCode=${memberNumber}`,
  AR_PLUS_RECOVER_EMAIL_WITH_DOCUMENT: (email, identificationType, numberOfIdentification) =>
    `${SERVICE.LOYALTY}/forgotEmail/validation?email=${email}&documentType=${identificationType}&documentNumber=${numberOfIdentification}`,
  EVALUATE_MEMBER_ELIGIBILITY_AR_PLUS_FIRST_CALL: validateCode =>
    `${SERVICE.LOYALTY}/pointsRedemptionRequests/evaluateDestinationMemberEligibility?validateCode=${validateCode}`,
  AR_PLUS_REDEEM_POINTS: memberShipCode =>
    `${SERVICE.LOYALTY}/pointsRedemptionRequests/${memberShipCode}`,
  AR_PLUS_CREDIT_MILES_ORIGIN: airline =>
    `${SERVICE.LOYALTY}/formInfo/airports?airlineCode=${airline}`,
  AR_PLUS_CREDIT_MILES_DESTINATION: (airline, origin) =>
    `${SERVICE.LOYALTY}/formInfo/airports?airlineCode=${airline}&airportCode=${origin}`,
  MILES_COST_AR_PLUS: (miles, operationType) =>
    `${SERVICE.LOYALTY}/miles/mile-cost?miles=${miles}&operationType=${operationType}`,
  AR_PLUS_UNSUBSCRIBE_CONFIRMATION: membershipCode =>
    `${SERVICE.LOYALTY}/members/${membershipCode}/unsubscribe`,
  AR_PLUS_UNSUBSCRIBE_FIRST_CALL: memberShipCode =>
    `${SERVICE.LOYALTY}/members/${memberShipCode}/unsubscribe`,
  FISCAL_CREDIT: `${SERVICE.LOYALTY}/taxCreditReceipt`,

  //GDS-Loyalty
  MILES_LOGIN: `${SERVICE.GDS_LOYALTY}/login`,
  LOGIN_PROFILE: `${SERVICE.GDS_LOYALTY}/profile/`,
  MILES_POST_FARE_SLIDER: `${SERVICE.GDS_LOYALTY}/paymentOptions`,
  MILES_FARE_SLIDER: shoppingId => `${SERVICE.GDS_LOYALTY}/paymentOptions?shoppingId=${shoppingId}`,

  //reservation
  PASSENGERS_UPDATE_MISSING_INFO: pnr => `${SERVICE.RESERVATION}/pnr/${pnr}`,
  PASSENGERS_MISSING_INFO: pnr => `${SERVICE.RESERVATION}/pnr/${pnr}/missingInfo`,
  SUBLOS_GET_RESERVATION_OFFERS: query => `${SERVICE.RESERVATION}/offers?${query}`,
  SUBLOS_GET_FLIGHT_INVENTORY: ({ origin, destination, departureDate, flightNumber, shoppingId }) =>
    `${SERVICE.RESERVATION}/inventory?origin=${origin}&destination=${destination}&departureDate=${departureDate}&flightNumber=${flightNumber}&shoppingId=${shoppingId}`,
  SPECIAL_SERVICES: pnr => `${SERVICE.RESERVATION}/pnr/${pnr}/ssr`,
  RESERVATION_PNR: pnr => `${SERVICE.RESERVATION}/pnr/${pnr}/email`,

  //Checkout
  ANCILLARIES: `${SERVICE.CHECKOUT}/ancillaries`,
  ANCILLARIES_SUMMARY: `${SERVICE.CHECKOUT}/ancillaries/summary`,
  PASSENGERS: `${SERVICE.CHECKOUT}/passengers`,
  SEATS: `${SERVICE.CHECKOUT}/seats`,

  //Auth
  OAUTH: `${SERVICE.AUTH}/token`,
  CORPORATE_AGREEMENTS_LOGIN: `${SERVICE.AUTH}/login/corporate`,

  //paymentMethods
  PAYMENT_METHODS: `${SERVICE.PAYMENTMETHODS}/data/paymentMethods/`,
  GET_ISSUERS: `${SERVICE.PAYMENTMETHODS}/data/admin/issuers`,
  PAYMENT_OPTIONS: `${SERVICE.PAYMENTMETHODS}/rules/paymentOptions/evaluation`,

  //Forms
  VALIDATE_REFUND_TICKET: `${SERVICE.FORMS}/refund/validate`,
  REQUEST_REFUND_TICKET: `${SERVICE.FORMS}/refund`,
  REFUND: (pnr, refundId) => `${SERVICE.FORMS}/refund?pnr=${pnr}&refundId=${refundId}`,
  NEWSLETTER_REGISTRATION: `${SERVICE.FORMS}/newsletters-users`,
  NEWSLETTER_UNSUBSCRIBE: email => `${SERVICE.FORMS}/newsletters-users/${email}`,

  //Sublos
  SUBLOS_COMPANIES: `${SERVICE.SUBLOS}/data/companies`,
  SUBLOS_DOCUMENT_TYPES: `${SERVICE.SUBLOS}/data/identificationTypes`,
  SUBLOS_LOGIN: `${SERVICE.SUBLOS}/login/form`,
  SUBLOS_LOGIN_WITH_TOKEN: `${SERVICE.SUBLOS}/login`,
  SUBLOS_GET_DESTINATIONS: `${SERVICE.SUBLOS}/data/destinations`,
  SUBLOS_GET_BENEFIT_TYPES: ({ companyCode, employeeCode, passengerType }) =>
    `${SERVICE.SUBLOS}/companies/${companyCode}/employees/${employeeCode}/benefitTypes?passengerType=${passengerType}`,
  SUBLOS_GET_FAMILY_MEMBER: ({ companyCode, employeeCode, benefitType, ticketType, shoppingId }) =>
    `${SERVICE.SUBLOS}/companies/${companyCode}/employees/${employeeCode}/passengers/family-members?benefitType=${benefitType}&ticketType=${ticketType}&shoppingId=${shoppingId}`,
  SUBLOS_GET_NO_NAME_CONDITIONS: ({ companyCode, employeeCode, benefitType, ticketType }) =>
    `${SERVICE.SUBLOS}/companies/${companyCode}/employees/${employeeCode}/passengers/no-names?benefitType=${benefitType}&ticketType=${ticketType}`,
  SUBLOS_GET_RESERVATIONS_BENEFIT_TYPES: ({ companyCode, employeeCode, benefitType, ticketType }) =>
    `${SERVICE.SUBLOS}/companies/${companyCode}/employees/${employeeCode}/reservations?benefitType=${benefitType}&ticketType=${ticketType}`,
  SUBLOS_BOOKING_CONFIRMATION: ({ companyCode, employeeCode }) =>
    `${SERVICE.SUBLOS}/companies/${companyCode}/employees/${employeeCode}/reservations/validation`,
  SUBLOS_GET_DATE_VALIDATION: ({ companyCode, employeeCode, benefitType, ticketType }) =>
    `${SERVICE.SUBLOS}/companies/${companyCode}/employees/${employeeCode}/benefitTypes/days?benefitType=${benefitType}&ticketOption=${ticketType}`,
  SUBLOS_RESERVATIONS: ({ companyCode, employeeCode }) =>
    `${SERVICE.SUBLOS}/companies/${companyCode}/employees/${employeeCode}/reservations`,
  SUBLOS_RESERVATIONS_CONFIRMATION: ({ companyCode, reservationCode, employeeCode }) =>
    `${SERVICE.SUBLOS}/companies/${companyCode}/employees/${employeeCode}/reservations/${reservationCode}/confirmation`,
  SUBLOS_CANCEL_BOOKING_CONFIRMATION: ({ companyCode, employeeCode, shoppingId, orderId }) =>
    `${SERVICE.SUBLOS}/companies/${companyCode}/employees/${employeeCode}/reservations/validation?shoppingId=${shoppingId}${orderId}`,
  SUBLOS_RESERVATIONS_PASSENGER_DETAILS: ({ companyCode, reservationCode, employeeCode }) =>
    `${SERVICE.SUBLOS}/companies/${companyCode}/employees/${employeeCode}/reservations/${reservationCode}/passenger-details`,

  //Travel assistance
  TRAVEL_ASSISTANCE: pnr => `${SERVICE.TRAVEL_ASSISTANCE}/travel-quotes?reservationCode=${pnr}`,
  TRAVEL_ASSISTANCE_CHECKOUT: `${SERVICE.TRAVEL_ASSISTANCE}/travel-quotes`
}
