export default {
  'booking.ancillaries-selection.modal-login-award-booking.title': '¡Ups! Necesitas iniciar sesión',
  'booking.ancillaries-selection.modal-login-award-booking.description':
    'Para continuar con tu compra usando Millas, es necesario que inicies sesión. ¿Te gustaría hacerlo ahora?',
  'booking.ancillaries-selection.modal-login-award-booking.accept-button.label': 'Iniciar Sesión',
  'booking.ancillaries-selection.modal-login-award-booking.cancel-button.label': 'Cancelar',
  'booking.reservation.administrator-title': 'Administrá tu reserva',
  'booking.modal-time-out.message':
    'El tiempo para gestionar tu reserva ha finalizado. Podés volver a tu reserva o intentarlo nuevamente.',
  'booking.modal-time-out.full-height-title': 'Mi Reserva',
  'booking.modal-time-out.retry-button': 'Reintentar',
  'booking.special-requests.special-meals': 'Comidas Especiales',
  'booking.special-requests.select-only-one': 'Podés seleccionar sólo una opción',
  'booking.special-requests.special-services': 'Servicios Especiales',
  'booking.special-requests.select-multi': 'Podés seleccionar más de una opción',
  'booking.special-requests.disclaimer':
    'Aquellos pasajeros que soliciten una silla de ruedas hasta el asiento, deberán comunicarse al número de WhatsApp: +54 11 4940-4798, con una anticipación mínima de 48 hs. previas a la partida del vuelo.',
  'booking.special-requests.title': 'Realizar pedidos especiales',
  'booking.special-requests.you-are-about-to-leave':
    'Estás a punto de irte sin guardar tu selección de pedidos de servicios especiales.',
  'booking.special-requests.selection-is-saved-on-accept':
    '¿Estás seguro? Recordá que tu selección se guardará al presionar Aceptar',
  'booking.special-requests.error':
    '¡Lo sentimos! Se produjo un error al procesar tu solicitud. Por favor, intentá más tarde.',
  'booking.seat-selection.exit-slot': 'SALIDA',
  'booking.seat-selection.do-it-later': 'Hacerlo más tarde',
  'booking.seat-selection.not-available': 'Selección de asientos no disponible para este vuelo.',
  'booking.seat-selection.you-can-do-it-later': '¡No te preocupes! Podrás hacerlo más tarde.',
  'booking.seat-selection.seat': 'Asiento',
  'booking.seat-selection.dont-close-page':
    'Por favor, no cierres esta pantalla mientras cargamos los asientos.',
  'booking.seat-selection.outbound-flights': 'Vuelos de ida',
  'booking.seat-selection.inbound-flights': 'Vuelos de vuelta',
  'booking.seat-selection.seatmap.selection-with-errors':
    '¡Lo sentimos! Algunos de los asientos que seleccionaste no están disponibles.',
  'booking.seat-selection.seatmap.you-are-about-to-leave':
    'Estás a punto de irte sin guardar tu selección de asientos.',
  'booking.seat-selection.seatmap.are-you-sure': '¿Estás seguro?',
  'booking.seat-selection.seatmap.exit': 'Salir',
  'booking.seat-selection.seatmap.save-selection': 'Guardar selección',
  'booking.seat-selection.seatmap.title': 'Selección de asiento/s',
  'booking.seat-selection.seatmap.references': 'Referencias',
  'booking.seat-selection.seatmap.seat-not-available': 'No disponible',
  'booking.seat-selection.seatmap.seat-available': 'Disponible',
  'booking.seat-selection.seatmap.seat-selected': 'Asiento seleccionado',
  'booking.seat-selection.seatmap.kitchen': 'Cocina',
  'booking.seat-selection.seatmap.toilette': 'Toilette',
  'booking.seat-selection.seatmap.moises': 'Moises',
  'booking.seat-selection.seatmap.seat-near-the-wings': 'Sector cercano a las alas',
  'booking.seat-selection.seatmap.aisle': 'Pasillo',
  'booking.seat-selection.seatmap.modal-title': '¡Listo!',
  'booking.seat-selection.travelling-with-infants-message':
    'Si viajas con un bebé podés asignar tu asiento a traves de nuestro Whatsapp +541149404798 o en el aeropuerto el dia del vuelo.',
  'booking.seat-selection.error.go-to-myb': 'Ir a mi reserva',
  'booking.seat-selection.purchase-success-description':
    'Tu asiento se seleccionó correctamente. A continuación te mostramos el detalle. ¡Gracias por elegir Aerolíneas Argentinas!',
  'booking.seat-selection.purchase-error-title':
    'Lo sentimos, no pudimos emitir tu compra de asientos...',
  'booking.seat-selection.purchase-error.description':
    '¡Pero no te preocupes! Verás tu dinero reflejado en tu cuenta en unos minutos. En caso contrario, comunícate con nuestro call center.',
  'booking.seat-selection.seat-is-no-longer-available':
    'El asiento seleccionado ya no se encuentra disponible',
  'booking.seat-selection.select-your-seat':
    'Seleccioná los asientos que desees para todos los pasajeros de la reserva',
  'booking.seat-selection.traveling-with-infant-description':
    'Al viajar con un bebé, debés seleccionar asientos que posean doble máscara de oxígeno:<ul><li><b>Embraer E90:</b> asientos “Ventana” del lado derecho.</li><li><b>Boeing 73W,738 y 7M8:</b> Asientos medios de ambos lados.</li><li><b>Airbus 330 y 340:</b> Asientos ventana de ambos lados.</li></ul>',
  'booking.seat-selection.plane': 'Avión',
  'booking.reserve-administrator.refund-options': 'Solicitar devolución',
  'booking.reserve-administrator.refund-options-url':
    'https://www.aerolineas.com.ar/es-ar/reservas_servicios/devolucion',
  'booking.reserve-administrator.exchange-options': 'Opciones de cambio de vuelo',
  'booking.reserve-administrator.check-in-options': 'Hacer web check-in',
  'booking.reserve-administrator.seat-selection-options': 'Seleccionar asiento',
  'booking.reserve-administrator.passenger-options': 'Realizar pedidos especiales',
  'booking.reserve-administrator.additional-bags-options': 'Equipaje adicional',
  'booking.reserve-administrator.special-bags-options': 'Equipaje especial',
  'booking.reserve-administrator.vip-lounge-options': 'Salón Cóndor (Ezeiza)',
  'booking.reserve-administrator.travel-assistance-options': 'Asistencia de viaje',
  'booking.flight-content.your-flight': 'Tu Vuelo',
  'booking.flight-content.terminal': 'Terminal',
  'booking.flight-content.gate': 'Puerta',
  'booking.flight-content.dont-forget-to-communicate': 'No olvides comunicarte al',
  'booking.flight-content.phone-number': ' 54 (11) 5199 3555',
  'booking.flight-content.anticipation':
    ', con una anticipación mínima de 48 hs. previas a la partida del vuelo.'
}
