/* eslint-disable import/no-cycle */
import loadableDelay from 'hoc/loadableDelay'

export const HomePage = loadableDelay(() => import('./HomePage'))

export const FlexDatesCalendarPage = loadableDelay(() => import('./FlexDatesCalendarPage'))

export const FlightOffersPage = loadableDelay(() => import('./FlightOffersPage'))

export const ContestsPage = loadableDelay(() => import('./ContestsPage'))

export const CheckoutPage = loadableDelay(() => import('./CheckoutPage'))

export const AncillariesPage = loadableDelay(() => import('./AncillariesPage'))

export const MyReservationPage = loadableDelay(() => import('./MyReservationPage'))

export const NoMatchPage = loadableDelay(() => import('./NoMatchPage'))

export const SeatsSelectionPage = loadableDelay(() => import('./SeatsSelectionPage'))

export const FrequentlyAskedQuestionsPage = loadableDelay(() =>
  import('./FrequentlyAskedQuestionsPage')
)

export const FlightStatusPage = loadableDelay(() => import('./FlightStatusPage'))

export const FiscalCreditPage = loadableDelay(() => import('./FiscalCreditPage'))

export const ExchangesPage = loadableDelay(() => import('./ExchangesPage'))

export const FrequentlyAskedQuestionsResultsPage = loadableDelay(() =>
  import('./FrequentlyAskedQuestionsResultsPage')
)

export const LandingPage = loadableDelay(() => import('./LandingPage'))

export const ExchangesPaymentErrorPage = loadableDelay(() => import('./ExchangesPaymentErrorPage'))

export const CheckoutErrorPage = loadableDelay(() => import('./CheckoutErrorPage'))

export const ScheduledFlights = loadableDelay(() => import('./ScheduledFlights'))

export const SpecialRequestsPage = loadableDelay(() => import('./SpecialRequestsPage'))

export const CommonErrorPage = loadableDelay(() => import('./CommonErrorPage'))

export const FlightsOffersErrorPage = loadableDelay(() => import('./FlightsOffersErrorPage'))

export const PressCommunicationPage = loadableDelay(() => import('./PressCommunicationPage'))

export const MyReservationErrorPage = loadableDelay(() => import('./MyReservationErrorPage'))

export const CorporateAgreementsPage = loadableDelay(() => import('./CorporateAgreementsPage'))

export const EventsPage = loadableDelay(() => import('./EventsPage'))

export const CorporateAgreementsRegisterUserPage = loadableDelay(() =>
  import('./CorporateAgreementsRegisterUserPage')
)

export const BookingPaymentPage = loadableDelay(() => import('./BookingPaymentPage'))

export const PressReleasesPage = loadableDelay(() => import('./PressReleasesPage'))

export const CheckoutGlobalRetry = loadableDelay(() => import('./Checkout3PGlobalPaymentRetry'))

export const RefundFormPage = loadableDelay(() => import('./RefundFormPage'))

export const RefundStatusPage = loadableDelay(() => import('./RefundStatusPage'))

export const RefundRequestFormPage = loadableDelay(() => import('./RefundRequestFormPage'))

export const PassengersMissingInfo = loadableDelay(() => import('./PassengersMissingInfo'))

export const ReservationServicePage = loadableDelay(() => import('./ReservationServicePage'))

export const ExternalPayment = loadableDelay(() => import('./ExternalPayment'))

export const TravelAssistancePage = loadableDelay(() => import('./TravelAssistancePage'))

export const AppLinkRedirect = loadableDelay(() => import('./AppLinkRedirect'))

export const NewsletterPage = loadableDelay(() => import('./Newsletter'))

export const BranchesPage = loadableDelay(() => import('./BranchesPage'))
