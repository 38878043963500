export default {
  'general.refund-page.main-banner.title': 'Devolución de tickets',
  'general.refund-page.info-text':
    'Para solicitar la devolución de tu ticket te pedimos que antes de completar la información del siguiente formulario, tengas en cuenta que:',
  'general.refund-page.info-text.details-0':
    'El botón de arrepentimiento que regula la Resolución 424/20 de la secretaria de Comercio, no aplica a los servicios de transporte aéreo de pasajeros, equipajes y carga, de acuerdo a lo dispuesto por la Resolución Anac 329/2020.',
  'general.refund-page.info-text.details-1':
    'Sólo podrás pedir la devolución dentro del período de validez del ticket (12 meses desde su fecha de emisión).',
  'general.refund-page.info-text.details-2':
    'Sólo se procesarán devoluciones de tickets adquiridos a través de bocas de venta propias de Aerolíneas (web, Call center, WhatsApp, Aeropuertos y Sucursales). Si compraste el ticket en una agencia de viajes, es necesario que la contactes para solicitar la devolución.',
  'general.refund-page.info-text.details-3':
    'La devolución se procesará únicamente si la tarifa emitida lo permite y se aplicarán siempre las penalidades según las condiciones de la tarifa que hubieras adquirido. Tené en cuenta que ante una combinación de distintos tipos de tarifas en un mismo billete, se aplicarán las condiciones de devolución de la tarifa más restrictiva.',
  'general.refund-page.info-text.details-4':
    'Si adquiriste tu ticket vía web deberás completar el formulario seleccionando siempre el país de residencia, que debe coincidir con el elegido al momento de la compra.',
  'general.refund-page.info-text.details-5':
    'Es importante que ingreses tus datos en el formulario con exactitud. En caso de surgir alguna consulta puntual, te contactaremos a tu casilla de e-mail.',
  'general.refund-page.info-text.details-6':
    'La devolución de la percepción a cuenta del Impuesto a los Bienes Personales/Ganancias (S5) que fuera derogada por la RG 5463/23 sólo puede reclamarse a la AFIP.',
  'general.refund-form.feedback.error-title': 'Oops, hubo un error al procesar tu solicitud',
  'general.refund-form.feedback.success-title': '¡Solicitud realizada con éxito!',
  'general.refund-form.feedback.button-text': 'Cerrar',
  'general.refund-form.full-name.label': 'Nombre y apellido que figura en el ticket',
  'general.refund-form.full-name.placeholder': 'Nombre y apellido',
  'general.refund-form.plus-partner-number.label': 'Número de Socio Plus',
  'general.refund-form.plus-partner-number.placeholder': 'Número de Socio Plus',
  'general.refund-form.ticket-numbers.label': 'Número de ticket/VCR a devolver',
  'general.refund-form.ticket-numbers.placeholder': 'Número de ticket',
  'general.refund-form.multiple-inputs-info': 'En caso de ser más de uno separar con una barra “/”',
  'general.refund-form.reservation-code.label': 'Código de reserva',
  'general.refund-form.reservation-code.placeholder': 'Ingresar código',
  'general.refund-form.section-return.label': 'Tramo sobre el que solicita la devolución',
  'general.refund-form.section-return.placeholder': 'Seleccionar',
  'general.refund-form.payer-name.label': 'Nombre del pagador',
  'general.refund-form.payer-name.placeholder': 'Nombre del pagador',
  'general.refund-form.payer-lastName.label': 'Apellido del pagador',
  'general.refund-form.payer-lastName.placeholder': 'Apellido del pagador',
  'general.refund-form.city.label': 'Lugar de residencia',
  'general.refund-form.city.placeholder': 'Seleccionar',
  'general.refund-form.dni.label': 'DNI del pagador',
  'general.refund-form.dni.placeholder': 'DNI del pagador',
  'general.refund-form.bank-entity.label': 'Entidad bancaria',
  'general.refund-form.bank-entity.placeholder': 'Seleccionar',
  'general.refund-form.cbu-number.label': 'Número de CBU',
  'general.refund-form.cbu-number.placeholder': 'Número de CBU',
  'general.refund-form.alias.label': 'Alias',
  'general.refund-form.alias.placeholder': 'Alias',
  'general.refund-form.email.label': 'E-Mail',
  'general.refund-form.email.placeholder': 'Dirección de e-mail',
  'general.refund-form.confirmation-email.label': 'Confirmar e-mail',
  'general.refund-form.confirmation-email.placeholder': 'Dirección de e-mail',
  'general.refund-form.phone-number.label': 'Teléfono Fijo',
  'general.refund-form.cellphone-number.label': 'Teléfono Móvil',
  'general.refund-form.phone-number.placeholder': 'Teléfono fijo',
  'general.refund-form.cellphone-number.placeholder': 'Teléfono móvil',
  'general.refund-form.document-type.label': 'CUIT / CUIL / CDI',
  'general.refund-form.document-type.placeholder': 'Número de CUIT / CUIL / CDI',
  'general.refund-form.account-number.label': 'Número de cuenta ',
  'general.refund-form.account-number.placeholder': 'Número de cuenta',
  'general.refund-form.account-type.label': 'Tipo de cuenta (Caja de Ahorro, Cta. Cte.)',
  'general.refund-form.account-type.placeholder': 'Seleccionar',
  'general.refund-form.observations.label': 'Observaciones/comentarios (Máximo 150 carácteres)',
  'general.refund-form.observations.placeholder': 'Comentarios (máximo 150 caracteres)',
  'general.refund-form.reason-for-return.label': 'Seleccionar',
  'general.refund-form.refund-reasons.cancel': 'Cancelación de vuelo',
  'general.refund-form.refund-reasons.reschedule': 'Reprogramación de vuelo',
  'general.refund-form.refund-reasons.airport-change': 'Cambios de aeropuerto',
  'general.refund-form.refund-reasons.sickness': 'Familiar enfermo',
  'general.refund-form.refund-reasons.death': 'Fallecimiento',
  'general.refund-form.refund-reasons.personal-issues': 'Motivo personal',
  'general.refund-form.refund-reasons.other': 'Otros',
  'general.refund-form.account-type.label-no-options': 'No hay opciones',
  'general.refund-form.account-type.current-account': 'Cuenta Corriente',
  'general.refund-form.account-type.savings-bank': 'Caja de ahorro',
  'general.refund-form.account-type.single-account': 'Cuenta Única',
  'general.refund-form.document-type.option-1': '20',
  'general.refund-form.document-type.option-2': '27',
  'general.refund-form.document-type.option-3': '30',
  'general.refund-form.document-type.number-0': '0',
  'general.refund-form.document-type.number-1': '1',
  'general.refund-form.document-type.number-2': '2',
  'general.refund-form.document-type.number-3': '3',
  'general.refund-form.document-type.number-4': '4',
  'general.refund-form.document-type.number-5': '5',
  'general.refund-form.document-type.number-6': '6',
  'general.refund-form.document-type.number-7': '7',
  'general.refund-form.document-type.number-8': '8',
  'general.refund-form.document-type.number-9': '9',
  'general.refund-form.flight-date': 'Fecha de vuelo',
  'general.refund-form.flight-date.placeholder': 'DD/MM/AAAA',
  'general.refund-form.refund-reason': 'Motivo por el cual solicita la devolución',
  'general.refund-form.reservation-code': '6 letras solamente',
  'general.refund-form.ticket-numbers': '13 números solamente',
  'general.refund-form.full-ticket': 'Billete completo',
  'general.refund-form.bank-data-description-1':
    'Solicitamos por favor complete los siguientes datos bancarios para poder realizar el reembolso de su dinero, en caso de tener alguna dificultad para reintegrarlo en la forma de pago original.',
  'general.refund-form.bank-data-description-2':
    'Sugerimos copiar/pegar desde la información suministrada por el banco, a fin de evitar errores de tipeo.',
  'general.refund-form.disclaimer':
    ' Conozco y acepto las posibles penalidades o retenciones de tasas que puedan existir para la devolución del billete.',
  'general.refund-form.disclaimer-contact-text-left':
    ' (de lo contrario deberá contactarse a través de nuestro ',
  'general.refund-form.disclaimer-contact-text-right':
    'de Lun a Vie de 9 a 18hs o bien al Call Center: 54 (11) 5199 3555 de Lun a Vie de 8 a 20hs. Sáb, Dom y feriados de 8 a 14 hs.) (Hora local de Argentina)',
  'general.refund-form.disclaimer-contact':
    '(De lo contrario deberá contactarse con el Call Center local).',
  'general.refund-form.disclaimer-link': 'Condiciones',
  'general.refund-form.submit-button.label': 'Aceptar',
  'general.refund-form.validation.contact-name.pattern':
    'Debes ingresar un nombre y apellido valido',
  'general.refund-form.validation.full-name-required':
    'Debes ingresar el nombre y apellido del pasajero',
  'general.refund-form.validation.ticket-number.pattern':
    'Debes ingresar un numero de ticket valido',
  'general.refund-form-more-than-13-characters.validation.ticket-number.pattern':
    'Debes ingresar un numero de ticket valido',
  'general.refund-form.validation.ticket-numbers-repeated':
    'No se permiten dos pasajeros con un mismo ticket',
  'general.refund-form.validation.ticket-numbers-required':
    'Debes ingresar un número/s de ticket/s para reembolso',
  'general.refund-form.validation.plus-partner-number.min-numbers':
    'El número de Socio Plus debe tener 8 números',
  'general.refund-form.validation.ticket-min-numbers':
    'El número de ticket/VCR debe tener 13 dígitos',
  'general.refund-form.validation.ticket-max-numbers':
    'El número de ticket/VCR debe tener 13 dígitos',
  'general.refund-form.validation.ticket-vcr-validation':
    'El número de ticket/VCR debe comenzar con 044',
  'general.refund-form-min.validation.ticket-numbers':
    'Debes ingresar un número/s de ticket/s para reembolso',
  'general.refund-form-matches.validation.ticket-numbers':
    'Debes ingresar un número/s de ticket/s para reembolso',
  'general.refund-form.validation.reservation-code-required': 'Debes ingresar un código de reserva',
  'general.refund-form.validation.reservation-code-pattern':
    'Solo 6 letras y sin caracteres especiales',
  'general.refund-form.add-passenger': 'Agregar pasajero',
  'general.refund-form.remove-passenger': 'Remover pasajero',
  'general.refund-form.validation.flight-date-required': 'Debes ingresar fecha de vuelo',
  'general.refund-form.validation.refund-reason-required':
    'Debes ingresar un motivo por el cual solicita la devolución',
  'general.refund-form.validation.account-type-required': 'Debes ingresar un tipo de cuenta',
  'general.refund-form.validation.document-type-required':
    'Debes ingresar un número de CUIT / CUIL / CDI',
  'general.refund-form.validation.document-type-max': 'Debe contener 7 u 8 números.',
  'general.refund-form.validation.cuit-format': 'Debe tener el siguiente formato XX-XXXXXXXX-X.',
  'general.refund-form.validation.dni-required': 'Debes ingresar un DNI',
  'general.refund-form.validation.dni-required.numbers': 'El DNI debe contener 7 u 8 números.',
  'general.refund-form.validation.bank-entity-required': 'Debes elegir una opción',
  'general.refund-form.validation.cbu-number': 'El CBU debe contener 22 números.',
  'general.refund-form.validation.cbu-number-max':
    'El número de CBU debe contener hasta 22 números.',
  'general.refund-form.validation.cbu-number-required': 'Debes ingresar un CBU',
  'general.refund-form.validation.alias-required': 'Debes ingresar un alias.',
  'general.refund-form.validation.account-number-required': 'Debes ingresar un número de cuenta',
  'general.refund-form.validation.account-number-only-numbers':
    'El número de cuenta debe contener sólo números.',
  'general.refund-form.validation.legs-required':
    'Debes ingresar el tramo sobre el que solicita la devolución',
  'general.refund-form.validation.payer-name-required': 'Debes ingresar el nombre del pagador',
  'general.refund-form.validation.payer-name-pattern': 'Ingresa sólo letras en el nombre',
  'general.refund-form.validation.payer-last-name-required':
    'Debes ingresar el apellido del pagador',
  'general.refund-form.validation.payer-last-name-pattern': 'Ingresa sólo letras en el apellido',
  'general.refund-form.validation.residence-required': 'Debes ingresar el lugar de residencia',
  'general.refund-form.validation.email-pattern': 'Verificá que sea un email válido.',
  'general.refund-form.validation.email-required':
    'Debes ingresar un correo electrónico para contactarte.',
  'general.refund-form.validation.phone-required': 'Debes ingresar un telefono.',
  'general.refund-form.validation.phone-pattern': 'Verificá que el teléfono contenga sólo números.',
  'general.refund-form.validation.accept-conditions-required': 'Debes aceptar las condiciones.',
  'general.refund-form.validation.observations.max-length': 'Debes ingresar 150 caracteres máximo.',
  'general.refund-form.result-success.title': 'Nuestro equipo está procesando tu solicitud.',
  'general.refund-form.result-success.description':
    'Hemos registrado su pedido bajo el número <b>{{requestNumber}}</b>. En breve recibirá un mail con el número de su pedido. En función a lo solicitado, analizaremos su caso y procederemos, de corresponder, al reembolso correspondiente. En caso de requerir algún dato adicional, lo estaremos contactando a su mail. Tener en cuenta que debido a la crisis sanitaria por Covid 19, el volumen de devoluciones ha aumentado considerablemente y es por esto que el proceso de reembolso solicitado podrá sufrir demoras de hasta 60 días.',
  'general.refund-form.result-success.go-to-home-button': 'Volver a inicio',
  'general.refund-form.reason-for-return.label-no-options': 'No hay opciones',
  'general.refund-status-form.ticket-number.label': 'Número de ticket',
  'general.refund-status-form.refund-id.label': 'Número de solicitud',
  'general.refund-status-form.ticket-number.placeholder': 'Ingresar número',
  'general.refund-status-form.banner': 'Devolución de tu ticket',
  'general.refund-status-form.information':
    'Para conocer el estado de tu pedido de devolución, ingresá tu código de reserva y tu número de solicitud.',
  'general.refund-status-form.validation.ticket-numbers-required':
    'Debes ingresar un número de solicitud para reembolso',
  'general.refund-status-form.validation.reservation-code-required':
    'Debes ingresar un código de reserva para reembolso',
  'retrieve-refund-form.pnr.not-empty': 'Debes ingresar un código de reserva para reembolso',
  'general.refund-status-itinerary.flight-date': 'Fecha de vuelo:',
  'general.refund-status-itinerary.origin': 'Origen:',
  'general.refund-status-itinerary.destination': 'Destino:',
  'general.refund-form.bank-entity.label-no-options': 'No hay opciones',
  'general.refund-form.document-type.label-no-options': 'No hay opciones',
  'general.refund-form.city.label-no-options': 'No hay opciones',
  'general.refund-form.section-return.label-no-options': 'No hay opciones',
  'general.refund-form.modal-refund-deadline.title': '¡Atención!',
  'general.refund-form.modal-refund-deadline.subtitle':
    'No es posible gestionar la devolución de la tarifa ya que se ha vencido el plazo anual de vigencia de tu ticket.',
  'general.refund-form.modal-refund-deadline.text':
    'Únicamente tenés disponible el reintegro de tasas e impuestos que correspondan según la legislación vigente. Si querés seguir adelante con la solicitud de devolución de tasas e impuestos, por favor aceptá y seguí completando el formulario de devolución.',
  'general.refund-form.modal-refund-deadline.accept': 'Aceptar',
  'general.refund-form.modal-refund-deadline.cancel': 'Cancelar',
  'general.refund-form.refund-success.title':
    'Hemos registrado su pedido bajo el número {{requestNumber}}',
  'general.refund-form.refund-success.description':
    'En breve recibirá un mail con el número de su pedido. En función a lo solicitado, analizaremos su caso y procederemos, de corresponder, al reembolso correspondiente. En caso de requerir algún dato adicional, lo estaremos contactando a su mail.',
  'general.refund-form.refund-success.button': 'Volver al inicio',
  'general.refund-form.modal-refund-error.title': '¡Atención!',
  'general.refund-form.modal-refund-error.accept': 'Aceptar',
  'refund.form.document-vcr-validator.ticket-no-name-match':
    'El nombre que ingresaste no coincide.',
  'refund.form.document-vcr-validator.reservation-no-match':
    'El código de reserva que ingresaste no coincide.',
  'refund.form.document-vcr-validator.ticket-number-no-match':
    'El número de ticket que ingresaste no coincide.',
  'refund.form.service.validate-ticket.client-exception':
    'El número de ticket o de reserva no es valido.',
  'refund.form.passenger-data.tickets-are-equals':
    'El número de ticket ingresado no puede ser igual al ingresado para otro pasajero dentro de este mismo formulario. La numeración de ticket es única para cada pasajero.',
  'refund.form.service.validate-ticket.general-exception': 'Lo sentimos, ha ocurrido un error',
  'refund.form.account-consultation.cbu-not-banked': 'El CBU es incorrecto',
  'refund.form.document-vcr-validator.ticket-IATA-contact-agency':
    'El ticket ingresado corresponde a una emisión realizada en Agencia de Viajes. Para gestionar el reembolso debe ponerse en contacto con la misma agencia emisora.',
  'refund.form.validate-vcr-request.ticket-number-issuance.not-valid':
    'El ticket ingresado corresponde a una emisión realizada fuera de Argentina, ya sea Sucursales o WEB del Exterior. Para gestionar el reembolso debe ingresar su solicitud a través de la WEB del país en el que realizó la compra de su ticket.  En caso de haber sido adquirido en una agencia de viajes del exterior, deberá ponerse en contacto con la misma',
  'refund.form.document-vcr-validator.ticket-without-ok-segment':
    'Su ticket no posee ningun tramo disponible para devolución.',
  'refund.form.validate-vcr-request.reservation-code.pattern-or-size': 'Código de reserva inválido',
  'refund.form.segments.not-empty-or-null': 'Debe seleccionar al menos un tramo',
  'refund.form.segment-status.not-valid': 'El tramo seleccionado no es válido'
}
