export default {
  'shopping.search-box-container.flight-status': 'Estado de vuelo',
  'shopping.search-box-container.check-in': 'Check in',
  'shopping.search-box-container.my-reservation': 'Mi reserva',
  'shopping.search-box-container.flights': 'Vuelos',
  'shopping.search-box-container.validation.pnr.required': 'Ingrese un codigo de PNR',
  'shopping.search-box-container.validation.last-name.required': 'Ingrese su apellido',
  'shopping.search-box-container.pnr-placeholder': 'Ej: XDGUIT',
  'shopping.search-box-container.search-my-reservation': 'Buscar mi reserva',
  'shopping.search-box-container.search-my-reservation.uppercase': 'BUSCAR MI RESERVA',
  'shopping.search-box-container.pnr-not-available':
    '¡Lo sentimos! El código de reserva ingresado no está disponible',
  'shopping.search-box-container.pnr-not-available.leave-comments':
    'Podés dejarnos un comentario haciendo click ',
  'shopping.search-box-container.pnr-not-available.here': 'acá',
  'shopping.search-box-container.pnr-not-available.options':
    ' o ingresar a las siguientes opciones:',
  'shopping.search-box-container.pnr-not-available.returns': 'Solicitar devolución de pasajes',
  'shopping.search-box-container.pnr-not-available.request-receipt':
    'Solicitar comprobante de Crédito Fiscal',
  'shopping.search-box-container.begin-check-in': 'Comenzar Check In',
  'shopping.search-box-container.begin-check-in.uppercase': 'COMENZAR CHECK IN'
}
