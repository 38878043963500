import { handleActions } from 'redux-actions'

import {
  newsletterRegistrationStart,
  newsletterRegistrationSuccess,
  newsletterRegistrationError,
  clearNewsletterRegistration,
  newsletterUnsubscribeStart,
  newsletterUnsubscribeSuccess,
  newsletterUnsubscribeError,
  clearNewsletterUnsubscribe
} from 'actions/newsletter'

const defaultState = {
  registrationData: null,
  unsubscribeData: null,
  registrationError: null,
  unsubscribeError: null,
  isFetchingRegistration: false,
  isFetchingUnsubscribe: false
}

const reducer = handleActions(
  {
    [newsletterRegistrationStart]: state => ({
      ...state,
      registrationData: null,
      unsubscribeData: null,
      registrationError: null,
      unsubscribeError: null,
      isFetchingRegistration: true,
      isFetchingUnsubscribe: false
    }),
    [newsletterRegistrationSuccess]: (state, { payload: { data } }) => ({
      ...state,
      registrationData: data,
      unsubscribeData: null,
      registrationError: null,
      unsubscribeError: null,
      isFetchingRegistration: false,
      isFetchingUnsubscribe: false
    }),
    [newsletterRegistrationError]: (state, { payload: { error } }) => ({
      ...state,
      registrationData: null,
      unsubscribeData: null,
      registrationError: error,
      unsubscribeError: null,
      isFetchingRegistration: false,
      isFetchingUnsubscribe: false
    }),
    [clearNewsletterRegistration]: () => ({ ...defaultState }),
    [newsletterUnsubscribeStart]: state => ({
      ...state,
      registrationData: null,
      unsubscribeData: null,
      registrationError: null,
      unsubscribeError: null,
      isFetchingRegistration: false,
      isFetchingUnsubscribe: true
    }),
    [newsletterUnsubscribeSuccess]: (state, { payload: { data } }) => ({
      ...state,
      registrationData: null,
      unsubscribeData: data,
      registrationError: null,
      unsubscribeError: null,
      isFetchingRegistration: false,
      isFetchingUnsubscribe: false
    }),
    [newsletterUnsubscribeError]: (state, { payload: { error } }) => ({
      ...state,
      registrationData: null,
      unsubscribeData: null,
      registrationError: null,
      unsubscribeError: error,
      isFetchingRegistration: false,
      isFetchingUnsubscribe: false
    }),
    [clearNewsletterUnsubscribe]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
