/* eslint-disable import/no-cycle */
import React from 'react'

import { default as styled } from 'styled-components'

import animation from 'animations/transition.json'

import AnimationLoader from '../animation-loader'

export const ChunkLoaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height || '500px'};
  ${({ fullScreen }) =>
    fullScreen &&
    `
    height: 100vh;
    z-index: 10000;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  `}
`

const ChunkLoader = props => (
  <ChunkLoaderWrapper {...props}>
    <AnimationLoader animationData={animation} fixedHeight={150} />
  </ChunkLoaderWrapper>
)

export default ChunkLoader
