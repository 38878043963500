import { handleActions } from 'redux-actions'

import {
  fiscalCreditStart,
  fiscalCreditSuccess,
  fiscalCreditError,
  clearFiscalCredit
} from 'actions/fiscalCredit'

const defaultState = {
  fiscalCreditData: null,
  fiscalCreditError: null,
  isFetchingFiscalCredit: false
}

const reducer = handleActions(
  {
    [fiscalCreditStart]: state => ({
      ...state,
      fiscalCreditData: null,
      fiscalCreditError: null,
      isFetchingFiscalCredit: true
    }),
    [fiscalCreditSuccess]: (state, { payload: { data } }) => ({
      ...state,
      fiscalCreditData: data,
      fiscalCreditError: null,
      isFetchingFiscalCredit: false
    }),
    [fiscalCreditError]: (state, { payload: { error } }) => ({
      ...state,
      fiscalCreditData: null,
      fiscalCreditError: error,
      isFetchingFiscalCredit: false
    }),
    [clearFiscalCredit]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
