export default {
  'events.tab-name.detail': 'Detalle',
  'events.tab-name.calendar': 'Calendario',
  'events.tab-name.detail-0':
    '<strong>Aerolíneas Argentinas y el Turismo de Reuniones</strong><br><br>Nuestro departamento de “Congresos y Eventos” trabaja de forma conjunta con profesionales del segmento de reuniones, tales como organizadores de Congresos, Ferias, Exposiciones, Simposios, Jornadas, Convenciones, Workshops, Viajes de Incentivo y otros tipos de encuentros. También lo hace con las Instituciones Públicas, Mixtas y Privadas que representan a ese sector. Somos el socio ideal para el éxito de los encuentros, porque llegamos a más ciudades en Argentina que cualquier otra línea aérea y conectamos con toda la región, además de Europa y Estados Unidos.',
  'events.tab-name.detail-1':
    '<strong>Beneficios para el Organizador</strong><br><br>Brindamos atención personalizada para los organizadores de encuentros, ofreciéndoles descuentos y beneficios especiales, extensivos a sus invitados y disertantes. Nuestros Ejecutivos analizan las características del evento (cantidad de participantes, sede, anticipación a realización, si requiere inscripción) y en función de ellas solicitan como contrapartida la designación de Aerolíneas Argentinas como Transportador Oficial exclusivo. El evento debe contar con una página web oficial que pueda vincularse a nuestra plataforma de reserva y venta.',
  'events.tab-name.detail-2':
    '<strong>Beneficios para los Participantes</strong><br><br>Los participantes acceden a los descuentos utilizando un código propio de cada evento que les será informado por el organizador. Si son socios de nuestro programa Aerolíneas Plus, obtienen además las millas correspondientes a las tarifas/vuelos elegidos. Para obtener más información, puede contactarnos por e-mail a la siguiente dirección: <a href="mailto:congress@aerolineas.com.ar">congress@aerolineas.com.ar</a>',
  'events.search-box.event-dropdown.label': 'Congresos y Eventos',
  'events.search-box.event-dropdown.placeholder': 'Seleccionar',
  'events.search-box.conference-code.label': 'Código',
  'events.search-box.conference-code.placeholder': 'Ingresar código',
  'events.search-box.conference-code.required': 'No olvides ingresar el código',
  'events.search-box.conference-code.pattern': 'Código inválido',
  'events.list.item.date': 'Fecha de realización: ',
  'events.list.item.place': 'Lugar: ',
  'events.list.all-items': 'Todos'
}
