/* eslint-disable import/no-cycle */
import { getCookie } from 'helpers/cookies'
import { hexDecode, hexEncode } from 'helpers/encode'
import COOKIE_NAMES from 'server/utils/cookieNames'
// tokenCache and its expiration date in global variables
let tokenCache = null
let tokenCacheExpiration = null

const encodeToken = unencodedToken => hexEncode(unencodedToken)

const decodeToken = encodedToken => hexDecode(encodedToken)

// Determines if the environment is a browser or not
const isBrowser = typeof window !== 'undefined'

// Determines if the browser is inside an iframe
const isIframe = isBrowser && window !== window.parent

const canSaveCookiesInClient = isBrowser && !isIframe

const isTokenExpired = expirationEpoch => Math.floor(Date.now() / 1000) > expirationEpoch

const setTokenCache = (token, tokenEpochTime) => {
  tokenCache = token
  tokenCacheExpiration = tokenEpochTime
}

const cleanTokenCache = () => {
  tokenCache = null
  tokenCacheExpiration = null
}

const getTokenCache = () => {
  if (tokenCacheExpiration && !isTokenExpired(tokenCacheExpiration))
    return {
      tokenCache,
      tokenCacheExpiration
    }

  cleanTokenCache()

  return {}
}

const calculateExpirationEpochTime = secondsUntilExpiration => {
  const currentEpochTime = Math.floor(Date.now() / 1000)
  return currentEpochTime + secondsUntilExpiration
}

const generateEncodedTokenInfo = token => {
  const stringifiedToken = JSON.stringify({
    access_token: token.access_token,
    expires_in: token.expires_in
  })

  const encodedToken = encodeToken(stringifiedToken)
  const expirationDate = new Date(token.expires_in * 1000)

  return {
    encodedToken,
    expirationDate
  }
}

const setTokenFromCookiesServer = (res, token) => {
  const { encodedToken, expirationDate } = generateEncodedTokenInfo(token)

  res?.cookie(COOKIE_NAMES.TOKEN, encodedToken, {
    expires: expirationDate,
    httpOnly: true,
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'None'
  })
}

const setTokenFromCookiesClient = token => {
  const { encodedToken, expirationDate } = generateEncodedTokenInfo(token)

  document.cookie = `${
    COOKIE_NAMES.TOKEN
  }=${encodedToken}; expires=${expirationDate?.toUTCString()}; path=/; ${
    process.env.NODE_ENV === 'production' ? 'Secure; ' : ''
  }SameSite=None`

  window.__ACCESS_TOKEN__ = token.access_token
  window.__TOKEN_EXPIRATION__ = token.expires_in
}

const getTokenFromCookiesServer = cookieHeader => {
  if (!cookieHeader) return null

  const cookies = cookieHeader.split(';')

  for (let cookie of cookies) {
    const [name, value] = cookie.trim().split('=')

    if (name === COOKIE_NAMES.TOKEN) {
      const token = JSON.parse(decodeToken(value))

      if (token?.expires_in && !isTokenExpired(token.expires_in)) return token

      return null
    }
  }
  return null
}

const getTokenFromCookiesClient = cookies => {
  const newCookie = cookies || document?.cookie
  try {
    if (!newCookie) return null
    const encodedTokenCookie = getCookie(newCookie, COOKIE_NAMES.TOKEN)

    if (!encodedTokenCookie) return null

    const decodedToken = decodeToken(encodedTokenCookie)
    const token = decodedToken ? JSON.parse(decodedToken) : null

    if (token?.expires_in && !isTokenExpired(token.expires_in)) return token

    return null
  } catch {
    return null
  }
}

const saveTokenFromCookies = (token, res) => {
  if (canSaveCookiesInClient) {
    // If we are in the browser, check that it's not inside an iframe
    setTokenFromCookiesClient(token)
  } else {
    // server-side
    setTokenFromCookiesServer(res, token)
  }
}

const retrieveTokenFromCookies = cookies => {
  if (canSaveCookiesInClient) {
    // If we are in the browser, check that it's not inside an iframe
    return getTokenFromCookiesClient(cookies)
  } else {
    // server-side
    return getTokenFromCookiesServer(cookies)
  }
}

export {
  saveTokenFromCookies,
  retrieveTokenFromCookies,
  getTokenCache,
  cleanTokenCache,
  setTokenCache,
  calculateExpirationEpochTime,
  isTokenExpired,
  tokenCacheExpiration,
  canSaveCookiesInClient
}
