import { handleActions } from 'redux-actions'

import {
  travelAssistancePostStart,
  travelAssistancePostSuccess,
  travelAssistancePostError,
  clearTravelAssistancePost
} from 'actions/travelAssistance'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [travelAssistancePostStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [travelAssistancePostSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [travelAssistancePostError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error: error,
      isFetching: false
    }),
    [clearTravelAssistancePost]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
