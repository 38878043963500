/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  fetchPaymentOptionOrderStart,
  fetchPaymentOptionOrderSuccess,
  fetchPaymentOptionOrderError,
  paymentOptionOrderClear
} = createActions({
  FETCH_PAYMENT_OPTION_ORDER_START: () => {},
  FETCH_PAYMENT_OPTION_ORDER_SUCCESS: paymentOptionOrder => paymentOptionOrder,
  FETCH_PAYMENT_OPTION_ORDER_ERROR: error => error,
  PAYMENT_OPTION_ORDER_CLEAR: () => {}
})

const fetchPaymentOptionOrder = () => {
  return async dispatch => {
    dispatch(fetchPaymentOptionOrderStart())
    try {
      const paymentOptionOrder = await api.get(`${apiRoutes.PAYMENT_OPTION_ORDER}`)
      dispatch(fetchPaymentOptionOrderSuccess(paymentOptionOrder))
    } catch (error) {
      dispatch(fetchPaymentOptionOrderError(error))
    }
  }
}

export {
  fetchPaymentOptionOrderStart,
  fetchPaymentOptionOrderSuccess,
  fetchPaymentOptionOrderError,
  paymentOptionOrderClear,
  fetchPaymentOptionOrder
}
