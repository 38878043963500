/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  fetchBranchesCountriesStart,
  fetchBranchesCountriesSuccess,
  fetchBranchesCountriesError
} = createActions({
  FETCH_BRANCHES_COUNTRIES_START: () => {},
  FETCH_BRANCHES_COUNTRIES_SUCCESS: countries => countries,
  FETCH_BRANCHES_COUNTRIES_ERROR: error => error
})

const fetchCountriesWithBranches = type => {
  return async dispatch => {
    dispatch(fetchBranchesCountriesStart())

    try {
      const countries = await api.get(`${apiRoutes.COUNTRIES_BRANCHES(type)}`)
      dispatch(fetchBranchesCountriesSuccess(countries))
    } catch (error) {
      dispatch(fetchBranchesCountriesError(error))
    }
  }
}

export {
  fetchBranchesCountriesStart,
  fetchBranchesCountriesSuccess,
  fetchBranchesCountriesError,
  fetchCountriesWithBranches
}
