/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import cmsApiRoutes from 'constants/cmsApiRoutes'
import { logError } from 'helpers/log'
import { cmsApi, ssrCmsApi } from 'services/api'

const { fetchHeaderStart, fetchHeaderSuccess, fetchHeaderError } = createActions({
  FETCH_HEADER_START: () => {},
  FETCH_HEADER_SUCCESS: data => ({ data }),
  FETCH_HEADER_ERROR: error => ({ error })
})

const fetchHeader = () => {
  return async dispatch => {
    dispatch(fetchHeaderStart())

    try {
      const header = await cmsApi.get(cmsApiRoutes.HEADER)
      dispatch(fetchHeaderSuccess(header))
    } catch (error) {
      dispatch(fetchHeaderError(error))
    }
  }
}

const fetchHeaderForServer = i18n => {
  return new Promise((resolve, reject) => {
    ssrCmsApi
      .get(cmsApiRoutes.HEADER, { i18n })
      .then(response => {
        resolve({
          data: response?.data,
          error: null,
          isFetching: false
        })
      })
      .catch(error => {
        error.fileName = 'header.js'
        error.lineNumber = '28'
        logError(
          `Error when getting ${ssrCmsApi.defaults.baseURL + cmsApiRoutes.HEADER}`,
          '',
          error
        )
        reject({
          error,
          data: null,
          isFetching: false
        })
      })
  })
}

export { fetchHeaderStart, fetchHeaderSuccess, fetchHeaderError, fetchHeader, fetchHeaderForServer }
