export default {
  'newsletter-form.banner-title': 'Newsletter',
  'newsletter-form.title':
    'Suscribite a nuestro newsletter y recibí las mejores ofertas y promociones.',
  'newsletter-form.description-0':
    'Aerolíneas Argentinas te acerca periódicamente su newsletter, con las últimas ¡Ofertas y Promociones!',
  'newsletter-form.description-1':
    'Si deseás recibirlo, por favor ingresá los datos solicitados a continuación, para incorporarte a nuestra base de datos:',
  'newsletter-form.lastName.label': 'Apellido',
  'newsletter-form.lastName.placeholder': 'Ingresar apellido',
  'newsletter-form.name.label': 'Nombre',
  'newsletter-form.name.placeholder': 'Ingresar nombre',
  'newsletter-form.birthday.label': 'Fecha de nacimiento',
  'newsletter-form.birthday.placeholder': 'Fecha de nacimiento',
  'newsletter-form.email.label': 'Email',
  'newsletter-form.email.placeholder': 'Ingresa un email',
  'newsletter-form.disclaimer':
    'Mediante el presente dejo constancia que he sido debidamente informado respecto de la finalidad para la cual han sido recabados mis datos personales y quiénes pueden ser sus destinatarios, como así también del derecho que me asiste a tener acceso a los mismos, como así a peticionar su rectificación y/o supresión, todo ello conforme a lo normado en la Ley Nacional N° 25326 (Protección de Datos Personales) y su Decreto Reglamentario N° 1558/01.',
  'newsletter-form.submit-button.label': 'Aceptar',
  'newsletter-form.validation-schema.lastName-pattern': 'Ingresa solo caracteres alfabeticos',
  'newsletter-form.validation-schema.lastName-required': 'No olvides indicar el apellido',
  'newsletter-form.validation-schema.name-pattern': 'Ingresa solo caracteres alfabeticos',
  'newsletter-form.validation-schema.name-required': 'No olvides indicar el nombre',
  'newsletter-form.validation-schema.birthday-invalid': 'Fecha inválida',
  'newsletter-form.validation-schema.birthday-required':
    'No olvides indicar tu fecha de nacimiento',
  'newsletter-form.validation-schema.email-pattern': 'Debes ingresar un email válido',
  'newsletter-form.validation-schema.email-required': 'No olvides ingresar el email',
  'newsletter-form.validation-schema.accept-conditions-required':
    'Debes aceptar los términos y Condiciones',
  'newsletter-form.unsubscribe.button': 'Desuscripción',
  'newsletter-form.unsubscribe.description':
    'Si no deseas seguir recibiendo nuestro Newsletter, por favor ingresá tu dirección de email a continuación:',
  'newsletter-form.unsubscribe.email.label': 'Email',
  'newsletter-form.unsubscribe.email.placeholder': 'Ingresa un email',
  'newsletter-form.unsubscribe.submit-button.label': 'Desuscribirse',
  'newsletter-form.success-title': 'Suscripción al Newsletter de Aerolíneas Argentinas.',
  'newsletter-form.success-description':
    'Te suscribiste correctamente. Recibirás un email de confirmación en tu casilla de correo. Gracias',
  'newsletter-form.success-button': 'Aceptar',
  'newsletter-form.unsubscribe.success-title':
    'Suscripción al Newsletter de Aerolíneas Argentinas.',
  'newsletter-form.unsubscribe.success-description': 'Se desuscribió correctamente',
  'newsletter-form.unsubscribe.success-button': 'Aceptar',
  'newsletter-form.modal-error.title': 'Suscripción al Newsletter',
  'newsletter-form.modal-error.description': 'Ha ocurrido un error en la suscripción: ',
  'newsletter-form.modal-error.description-unsubscribe':
    'Ha ocurrido un error en la desuscripción: ',
  'newsletter-form.modal-error.button': 'Aceptar',

  // Backend error keys
  'newsletters-users-service.locale-header.not-empty': 'Por favor intente nuevamente',
  'newsletters-users-service.subscribe.already-exists':
    'El email proporcionado ya se encuentra suscripto',
  'refund.form.notification.internal-server-error': 'Por favor intente nuevamente',
  'newsletters-users-service.entity-not-found': 'El correo no se encuentra suscripto'
}
