import { handleActions } from 'redux-actions'

import {
  changeFirstCardPaymentMethodState,
  changeSecondCardPaymentMethodState,
  resetPaymentMethodState,
  resetSecondPaymentMethodState,
  fetchPaymentOptionsValidationsStart,
  fetchPaymentOptionsValidationsError,
  fetchPaymentOptionsValidationsSuccess,
  setPaymentMethod,
  setFirstCardPaymentMethodMasks,
  setSecondCardPaymentMethodMasks,
  clearPaymentMethodSelection
} from 'actions/paymentMethodSelection'

const defaultCardState = {
  selectedType: null,
  selectedPaymentMethod: null,
  selectedIssuer: null,
  selectedInstallment: null,
  paymentValidations: undefined,
  totalInterest: null
}

const defaultState = {
  ...defaultCardState,
  secondCardPaymentMethod: {
    ...defaultCardState
  },
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [changeFirstCardPaymentMethodState]: (
      state,
      { payload: { type, method, issuer, installment, rate, totalInterest, masks } }
    ) => ({
      ...state,
      selectedType: type,
      selectedIssuer: issuer,
      selectedPaymentMethod: method,
      selectedInstallment: installment,
      selectedRate: rate,
      totalInterest,
      masks
    }),
    [changeSecondCardPaymentMethodState]: (
      state,
      { payload: { type, method, issuer, installment, rate, totalInterest, masks } }
    ) => ({
      ...state,
      secondCardPaymentMethod: {
        selectedType: type,
        selectedIssuer: issuer,
        selectedPaymentMethod: method,
        selectedInstallment: installment,
        selectedRate: rate,
        totalInterest,
        masks
      }
    }),
    [resetPaymentMethodState]: ({ paymentValidations, ...state }) => ({
      ...state,
      selectedType: null,
      selectedPaymentMethod: null,
      selectedIssuer: null,
      selectedInstallment: null,
      totalInterest: null,
      paymentValidations
    }),
    [resetSecondPaymentMethodState]: state => ({
      ...state,
      secondCardPaymentMethod: {
        selectedType: null,
        selectedPaymentMethod: null,
        selectedIssuer: null,
        selectedInstallment: null,
        paymentValidations: undefined,
        totalInterest: null
      }
    }),
    [fetchPaymentOptionsValidationsStart]: state => ({
      ...state,
      isFetching: true
    }),
    [fetchPaymentOptionsValidationsSuccess]: (state, { payload: { response } }) => ({
      ...state,
      paymentValidations: response,
      isFetching: false
    }),
    [fetchPaymentOptionsValidationsError]: (state, { payload: { error } }) => ({
      ...state,
      error
    }),
    [setPaymentMethod]: (state, { payload: { paymentMethod } }) => ({
      ...state,
      selectedPaymentMethod: paymentMethod
    }),
    [setFirstCardPaymentMethodMasks]: (state, { payload: { masks } }) => ({
      ...state,
      masks
    }),
    [setSecondCardPaymentMethodMasks]: (state, { payload: { masks } }) => ({
      ...state,
      secondCardPaymentMethod: {
        ...state.secondCardPaymentMethod,
        masks
      }
    }),
    [clearPaymentMethodSelection]: () => defaultState
  },
  defaultState
)

export default reducer
