export default {
  'usaForms.notification-close': 'Cerrar',
  'usaForms.go-to-home-button': 'Volver a inicio',
  'usaForms.cancellation-in-24hs.title': 'Cancelación dentro de las 24 horas',
  'usaForms.cancellation.title': 'Anulación de pasajes',
  'usaForms.cancellation-in-24hs.informative-description-1':
    'Podrá cancelar su compra dentro de las 24 horas de haber hecho la reserva original con una devolución completa del valor pagado, siempre que la reserva se haya hecho siete (7) días o más antes de la fecha programada de su vuelo.',
  'usaForms.cancellation-in-24hs.informative-description-2':
    'Las cancelaciones hechas a tiempo podrán realizarse a través de la página web de Aerolíneas Argentinas. Le informamos que si cancela un tramo de un itinerario de ida y vuelta cuyo valor estaba sujeto a la compra de un boleto de ida y vuelta, deberá cancelar todo el viaje y volver a comprar su boleto.',
  'usaForms.cancellation-in-24hs.informative-description-3':
    'En las próximas 72 horas será contactado por correo electrónico informando el resultado de su solicitud.',
  'usaForms.cancellation-in-24hs.informative-description-4':
    'Mediante el presente dejo constancia que he sido debidamente informado respecto de la finalidad para la cual han sido recabados mis datos personales y quiénes pueden ser sus destinatarios, como así también del derecho que me asiste a tener acceso a los mismos, como así a peticionar su rectificación y/o supresión, todo ello conforme a lo normado en la Ley Nacional N° 25326 (Protección de Datos Personales) y su Decreto Reglamentario N° 1558/01.',
  'usaForms.cancellation-in-24hs.informative-description-5': 'ABOUT SSL CERTIFICATES',
  'usaForms.cancellation-in-24hs.ticket-numbers.label': 'Número de ticket',
  'usaForms.cancellation-in-24hs.ticket-numbers.placeholder': 'Ingresar número de ticket',
  'usaForms.cancellation-in-24hs.reservation-code.label': 'Código de reserva',
  'usaForms.cancellation-in-24hs.reservation-code.placeholder': 'Ingresar código de reserva',
  'usaForms.cancellation-in-24hs.full-name.label': 'Nombre y Apellido',
  'usaForms.cancellation-in-24hs.full-name.placeholder':
    'Ingresa el nombre y apellido del pasajero',
  'usaForms.cancellation-in-24hs.phone.label': 'Télefono',
  'usaForms.cancellation-in-24hs.phone.placeholder': 'Ingresar número de télefono',
  'usaForms.cancellation-in-24hs.email.label': 'E-mail',
  'usaForms.cancellation-in-24hs.email.placeholder': 'Ingresar E-mail',
  'usaForms.cancellation-in-24hs.i-accept-the': 'Acepto los',
  'usaForms.cancellation-in-24hs.terms-and-conditions': 'Términos y Condiciones.',
  'usaForms.cancellation-in-24hs.button-send': 'Enviar',
  'usaForms.cancellation-in-24hs.validation.full-name-pattern':
    'Debes ingresar un nombre y apellido valido',
  'usaForms.cancellation-in-24hs.validation.full-name-required':
    'El nombre y apellido es requerido',
  'usaForms.cancellation-in-24hs.validation.ticket-number.pattern':
    'Debes ingresar un numero de ticket valido',
  'usaForms.cancellation-in-24hs-more-than-13-characters.validation.ticket-number.pattern':
    'Debes ingresar un numero de ticket valido',
  'usaForms.cancellation-in-24hs.validation.ticket-numbers-required':
    'Debes ingresar un número/s de ticket/s para cancelar',
  'usaForms.cancellation-in-24hs.validation.reservation-code-pattern':
    'Solo 6 letras y sin caracteres especiales',
  'usaForms.cancellation-in-24hs.validation.reservation-code-required':
    'Debes ingresar un código de reserva',
  'usaForms.cancellation-in-24hs.validation.email-pattern': 'Verificá que sea un email válido.',
  'usaForms.cancellation-in-24hs.validation.email-required':
    'Debes ingresar un correo electrónico para contactarte.',
  'usaForms.cancellation-in-24hs.validation.phone-pattern':
    'Verificá que el teléfono contenga sólo números.',
  'usaForms.cancellation-in-24hs.validation.phone-required': 'Debes ingresar un telefono.',
  'usaForms.cancellation-in-24hs.validation.accept-conditions-required':
    'Debes aceptar las condiciones.',
  'usaForms.cancellation-in-24hs.result-success.title':
    'Nuestro equipo está procesando tu solicitud.',
  'usaForms.cancellation-in-24hs.result-success.description':
    'Te enviaremos un e-mail con la resolución de tu caso.',
  'usaForms.cancellation-in-24hs.result-success.go-to-home-button': 'Volver a inicio',
  'usaForms.cancellation-in-24hs.feedback.error-title':
    'Oops, hubo un error al procesar tu solicitud',
  'usaForms.cancellation-in-24hs.success-title': '¡Solicitud realizada con éxito!',
  'usaForms.contact-us.contact.label': 'Contacto',
  'usaForms.contact-us.title': 'Contactate con nosotros',
  'usaForms.contact-client-attention.title': 'Atención al cliente',
  'usaForms.contact-us.origin.label': 'Origen',
  'usaForms.contact-us.origin.placeholder': 'Ciudad de origen',
  'usaForms.contact-us.destiny.label': 'Destino',
  'usaForms.contact-us.destiny.placeholder': 'Ciudad de destino',
  'usaForms.contact-us.flight-number.label': 'Número de vuelo',
  'usaForms.contact-us.flight-number.placeholder': 'Ingresar número de vuelo',
  'usaForms.contact-us.flight-date': 'Fecha de vuelo',
  'usaForms.contact-us.company.label': 'Compañia',
  'usaForms.contact-us.select': 'Seleccionar',
  'usaForms.contact-us.lastName.label': 'Apellido',
  'usaForms.contact-us.lastName.placeholder': 'Ingresar apellido',
  'usaForms.contact-us.name.label': 'Nombre',
  'usaForms.contact-us.name.placeholder': 'Ingresar nombre',
  'usaForms.contact-us.documentType.label': 'Tipo de documento',
  'usaForms.contact-us.documentNumber.label': 'Número de documento',
  'usaForms.contact-us.documentNumber.placeholder': 'Ingresa el número de documento',
  'usaForms.contact-us.email.label': 'E-mail',
  'usaForms.contact-us.email.placeholder': 'Ingresar E-mail',
  'usaForms.contact-us.phoneNumber.label': 'Número de teléfono',
  'usaForms.contact-us.phoneNumber.placeholder': 'Ingresa el número de teléfono',
  'usaForms.contact-us.whatsappContact.label': 'Contacto WhatsApp',
  'usaForms.contact-us.whatsappContact.placeholder': 'Ingresa el número de WhatsApp',
  'usaForms.contact-us.isPartner.label': '¿Sos socio de Aerolíneas Argentinas?',
  'usaForms.contact-us.isPartner-yes.label': 'Si',
  'usaForms.contact-us.isPartner-no.label': 'No',
  'usaForms.contact-us.typePartner.label': 'Tipo de socio',
  'usaForms.contact-us.partnerNumber.label': 'Número de socio Aerolíneas Plus',
  'usaForms.contact-us.partnerNumber.placeholder': 'Ingresar número de socio',
  'usaForms.contact-us.irregularity.label': 'Irregularidad',
  'usaForms.contact-us.typeLuggage.label': 'Tipo de equipaje',
  'usaForms.contact-us.claimNumber.label': 'Número de reclamo',
  'usaForms.contact-us.claimNumber.placeholder': 'Ingresar número de reclamo',
  'usaForms.contact-us.amountLuggage.label': 'Cantidad de equipaje',
  'usaForms.contact-us.amountLuggage.placeholder': 'Ingresar cantidad de equipaje',
  'usaForms.contact-us.baggageCheckNumber.label': 'Número de comprobante de equipaje',
  'usaForms.contact-us.baggageCheckNumber.placeholder': 'Ingresar número de comprobante',
  'usaForms.contact-us.add-irregularity.label': 'Agregar irregularidad',
  'usaForms.contact-us.remove-irregularity.label': 'Quitar irregularidad',
  'usaForms.contact-us.add-attachments.title':
    'Podés adjuntar a continuacción las imágenes que quieras agregar a tu reclamo',
  'usaForms.contact-us.add-attachments.description':
    'Recordá que sólo podés cargar archivos JPG, PNG o PDF de no más de 3MB.',
  'usaForms.contact-us.add-attachments.label': 'Adjuntar archivo',
  'usaForms.contact-us.suggestion.label': 'Motivo de contacto',
  'usaForms.contact-us.suggestion.placeholder': 'Sugerencia',
  'usaForms.contact-us.experiencePlace.label': 'Lugar de experiencia',
  'usaForms.contact-us.about.label': 'Acerca de',
  'usaForms.contact-us.residenceCountry.label': 'País de residencia',
  'usaForms.contact-us.residenceCountry.placeholder': 'Ingresa el país de residencia',
  'usaForms.contact-us.reservation-code.label': 'Código de reserva',
  'usaForms.contact-us.reservation-code.placeholder': 'Ingresar código de reserva',
  'usaForms.contact-us.remarks.label': 'Consulta o sugerencia',
  'usaForms.contact-us.claim.label': 'Detalle de la reclamación del consumidor',
  'usaForms.contact-us.claim-address.label': 'Dirección del reclamante',
  'usaForms.contact-us.parents-info.label': 'Información de los padres (en caso de ser menor)',
  'usaForms.contact-us.request.label': 'Detalle del pedido del consumidor',
  'usaForms.contact-us.product-identification.label': 'Identificación del producto',
  'usaForms.contact-us.associated-service.label': 'Servicio asociado',
  'usaForms.contact-us.remarks.placeholder': 'Comentarios (máximo {{max}} caracteres)',
  'usaForms.contact-us.claim-address.placeholder': 'Ingrese dirección del reclamante',
  'usaForms.contact-us.parents-info.placeholder': 'Ingrese información de los padres',
  'usaForms.contact-us.product-identification.placeholder': 'Ingrese identificación del producto',
  'usaForms.contact-us.associated-service.placeholder': 'Ingrese servicio asociado',
  'usaForms.contact-us.label-no-options': 'No hay opciones',
  'usaForms.contact-us.type-form-cac': 'Centro de atención al cliente',
  'usaForms.contact-us.type-form-equipament-claim': 'Reclamo de equipajes',
  'usaForms.contact-us.i-accept-the': 'Acepto los',
  'usaForms.contact-us.terms-and-conditions': 'Términos y Condiciones.',
  'usaForms.contact-us.newsletter': 'Los invitamos a subscribirse a nuestro newsletter',
  'usaForms.contact-us.button-send': 'Enviar',
  'usaForms.contact-us.terms-1558-01':
    'Mediante el presente dejo constancia que he sido debidamente informado respecto de la finalidad para la cual han sido recabados mis datos personales y quiénes pueden ser sus destinatarios, como así también del derecho que me asiste a tener acceso a los mismos, como así a peticionar su rectificación y/o supresión, todo ello conforme a lo normado en la Ley Nacional N° 25326 (Protección de Datos Personales) y su Decreto Reglamentario N° 1558/01.',
  'usaForms.contact-us.about-ssl-certificates': 'ABOUT SSL CERTIFICATES',
  'usaForms.contact-us.validation.origin-required': 'Debes ingresar origen.',
  'usaForms.contact-us.validation.destiny-required': 'Debes ingresar destino.',
  'usaForms.contact-us.validation.min-required': 'Deben ser mínimo {{value}} digitos',
  'usaForms.contact-us.validation.max-required': 'Deben ser máximo {{value}} digitos.',
  'usaForms.contact-us.validation.flightNumber.typeError': 'Debe ingresar caracteres númericos',
  'usaForms.contact-us.validation.flightNumber.max-length': 'El número debe ser menor a {{max}}',
  'usaForms.contact-us.validation.remarks.max-length': 'Debes ingresar {{max}} caracteres máximo.',
  'usaForms.contact-us.validation.flightNumber.min-length':
    'El número debe ser mayor o igual a {{min}}',
  'usaForms.contact-us.validation.flightNumber-required': 'No olvides indicar el número de vuelo',
  'usaForms.contact-us.validation.flight-date-required': 'Debes ingresar fecha de vuelo',
  'usaForms.contact-us.validation.company-required': 'Debe ingresar una compañia',
  'usaForms.contact-us.validation.lastName-pattern': 'Ingresa solo caracteres alfabeticos',
  'usaForms.contact-us.validation.lastName-required': 'No olvides indicar el apellido',
  'usaForms.contact-us.validation.partnerNumber-pattern': 'Ingresa un numero de socio valido',
  'usaForms.contact-us.validation.name-pattern': 'Ingresa solo caracteres alfabeticos',
  'usaForms.contact-us.validation.name-required': 'No olvides indicar el nombre',
  'usaForms.contact-us.validation.documentType-required': 'No olvides indicar el tipo de documento',
  'usaForms.contact-us.validation.documentNumber-pattern':
    'Debes ingresar un número de documento válido.',
  'usaForms.contact-us.validation.phone-pattern': 'Debes ingresar un número de teléfono válido.',
  'usaForms.contact-us.validation.whatsappContact-pattern':
    'Debe ingresar un máximo de {{max}} caracteres númericos.',
  'usaForms.contact-us.validation.phone-required': 'No olvides indicar el número de teléfono.',
  'usaForms.contact-us.validation.email-pattern': 'Debes ingresar un E-mail válido.',
  'usaForms.contact-us.validation.email-required': 'No olvides indicar el E-mail.',
  'usaForms.contact-us.validation.type-partner-required': 'No olvides el tipo de socio.',
  'usaForms.contact-us.validation.partner-number-required': 'No olvides el número de socio.',
  'usaForms.contact-us.validation.min-ar-number-required': 'Deben ser mínimo 7 digitos',
  'usaForms.contact-us.validation.max-ar-number-required': 'Deben ser máximo 8 digitos',
  'usaForms.contact-us.validation.irregularity-required': 'No olvides indicar una irregularidad.',
  'usaForms.contact-us.validation.type-luggage-required': 'No olvides indicar el tipo de equipaje.',
  'usaForms.contact-us.validation.claim-number-required': 'No olvides indicar número de reclamo.',
  'usaForms.contact-us.validation.amount-luggage-required':
    'No olvides indicar la cantidad equipaje.',
  'usaForms.contact-us.validation.amount-luggage-pattern': 'Debes ingresar valores númericos.',
  'usaForms.contact-us.validation.baggage-check-number-required':
    'No olvides indicar el número de comprobante.',
  'usaForms.contact-us.validation.is-partner-required': 'No olvides indicar si eres socio.',
  'usaForms.contact-us.validation.reservation-code-pattern':
    'Solo 6 letras y sin caracteres especiales',
  'usaForms.contact-us.validation.reservation-code-required':
    'No olvides indicar el código de reserva',
  'usaForms.contact-us.type-form-reservations-and-services': 'Reservas y servicios',
  'usaForms.contact-us.validation.formType-required': 'No olvides indicar el tipo de contacto',
  'usaForms.contact-us.validation.suggestion-required': 'No olvides indicar el motivo de contacto',
  'usaForms.contact-us.validation.experiencePlace-required':
    'No olvides indicar el lugar de experiencia',
  'usaForms.contact-us.validation.about-required': 'No olvides indicar Acerca de',
  'usaForms.contact-us.validation.residenceCountry-required':
    'No olvides indicar el país de recidencia',
  'usaForms.contact-us.validation.remarks-required': 'No olvides indicar un comentario.',
  'usaForms.contact-us.validation.accept-conditions-required':
    'Debes aceptar los términos y Condiciones.',
  'usaForms.contact-us.validation.associated-service.required':
    'Debes ingresar el servicio asociado',
  'usaForms.contact-us.validation.claim-address.required':
    'Debes ingresar la dirección del reclamante',
  'usaForms.contact-us.validation.product-identification.required':
    'Debes ingresar la identificación del producto',
  'usaForms.contact-us.result-success.title': 'Nuestro equipo está procesando tu solicitud.',
  'usaForms.contact-us.result-success.description':
    'Te enviaremos un e-mail con la resolución de tu caso.',
  'usaForms.contact-us.result-cancellation-in-24hs-success.description':
    'En breve recibirás un mail con el número de tu pedido. Tené en cuenta que debido a la crisis sanitaria por Covid 19, el volumen de devoluciones ha aumentado considerablemente y es por esto que el proceso de reembolso solicitado podrá sufrir demoras de hasta 60 días.',
  'usaForms.contact-us.result-branch-offices-success.description':
    'Te enviaremos un e-mail con la resolución de tu caso. branch-offices',
  'usaForms.contact-us.result-faq-aerolineas-success.description':
    'Te enviaremos un e-mail con la resolución de tu caso. faq-aerolineas',
  'usaForms.contact-us.result-clamId-success.description':
    'Te enviaremos un e-mail con la resolución de tu caso. Número de seguimiento: {{KEY}}',
  'usaForms.contact-us.result-clamId-cac-success.description':
    'Su {{form}} ha quedado registrada con los siguientes números: {{claim_number}}\n\nLe informamos que tanto las sugerencias como las felicitaciones son elevadas a los sectores involucrados para su conocimiento',
  'usaForms.contact-us.result-clamId-baggage-success.description':
    'Muchas gracias por su contacto. Su número de gestión es {{claim_number}}. Le responderemos a la brevedad a la casilla de mail indicada. Nuestro horario de atención al cliente de la casilla de email es de Lunes a Viernes de 9:00 a 17:00hs',
  'usaForms.contact-us.feedback.error-title': 'Oops, hubo un error al procesar tu solicitud',
  'usaForms.contact-us.feedback.success-title': '¡Solicitud realizada con éxito!',
  'usaForms.cac-claim.informative-description-1':
    ' Si desea dejarnos una "Sugerencia", elevar una "Felicitación" o efectuar un "Reclamo", por favor seleccionar la opción correspondiente. Si se trata de un Reclamo se deberían completar de forma obligatoria todos los datos requeridos para la investigación y análisis del mismo. En caso de viajar con acompañantes favor ingresar sus datos en el mismo reclamo.',
  'usaForms.cac-claim.informative-description-2':
    'Al finalizar la transacción, el sistema le proporcionará un número de identificación individual a cada pasajero para su trámite.',
  'usaForms.cac-claim.informative-description-3':
    'Agradecemos su contacto, su aporte contribuirá a mejorar nuestro servicio.',
  'usaForms.cac-claim.informative-description-4':
    'En el caso de querer realizar una devolución dirigirse a',
  'usaForms.cac-claim.link-returns': 'devoluciones',
  'usaForms.reservation-and-service-form.aereolinas-com': 'aereolinas.com',
  'usaForms.reservation-and-service-form.informative-description-1':
    'Consultas sobre reservas y compras en',
  'usaForms.reservation-and-service-form.informative-description-2':
    'Este formulario de contacto es válido sólo para consultas referentes al proceso de reserva o compra de aerolineas.com.',
  'usaForms.reservation-and-service-form.informative-description-3':
    'Si su consulta es por cambio de vuelos, o si su compra fue realizada en otro canal de venta le sugerimos llamar a nuestro Call Center + 54 (11) 5199 3555 o dirigirse a alguna de nuestras oficinas.',
  'usaForms.branch-offices.title':
    'Desde aquí podrás consultar las direcciones y contactos de nuestras sucursales y aeropuertos.',
  'usaForms.branch-offices.description':
    'Seleccione sucursal o aeropuerto, el país y la ciudad/provincia a continuación',
  'usaForms.branch-offices.branchtype.airport': 'Aeropuerto',
  'usaForms.branch-offices.branchtype.office': 'Sucursal',
  'usaForms.branch-offices.branchtype.label': 'Sucursal/aeropuerto',
  'usaForms.branch-offices.country.title': 'Sucursales y Aeropuertos',
  'usaForms.branch-offices.country.label': 'País',
  'usaForms.branch-offices.province.label': 'Ciudad/provincia',
  'usaForms.branch-offices.filter.label': 'Buscar por nombre o dirección',
  'usaForms.contact-us.offices.filter.placeholder': 'Ingrese el nombre o la dirección',
  'usaForms.branch-offices.button.accept': 'Aceptar',
  'usaForms.branch-offices.validation.branchtype-required':
    'No olvides indicar sucursal o aeropuerto',
  'usaForms.branch-offices.validation.country-required': 'No olvides indicar el país',
  'usaForms.branch-offices.validation.province-required': 'No olvides indicar la ciudad/provincia',
  'usaForms.branch-offices.address.label': 'Dirección:',
  'usaForms.branch-offices.opening-hours.label': 'Horario de atención:',
  'usaForms.branch-offices.information.label': 'Información:',
  'usaForms.branch-offices.contact-title.label': 'Contactos:',
  'usaForms.branch-offices.whatsapp-number.label': 'WhatsApp:',
  'usaForms.branch-offices.contact-center.label': 'Centro de contacto:',
  'usaForms.branch-offices.email.label': 'Email:',
  'usaForms.refund-form.flight-date': 'Fecha de vuelo',
  'usaForms.contact-us.partnerTypeArPlus.label': 'Tipo de socio Aerolíneas Plus',
  'usaForms.contact-us.partnerNumberArPlus.label': 'Número de socio Aerolíneas Plus',
  'usaForms.contact-us.partnerTypeSkyTeam.label': 'Tipo de socio SkyTeam',
  'usaForms.contact-us.partnerNumberSkyTeam.label': 'Número de socio SkyTeam',
  'usaForms.contact-us.companySkyTeam.label': 'Socio SkyTeam Compañía',
  'usaForms.contact-us.flightType.label': 'Tipo de vuelo',
  'usaForms.contact-us.conflictScale.label': 'Escala conflicto',
  'usaForms.contact-us.conflictScale.placeholder': 'Ingresar escala conflicto',
  'usaForms.contact-us.cabin.label': 'Cabina',
  'usaForms.contact-us.ticketNumber.label': 'Número de ticket',
  'usaForms.contact-us.ticketNumber.placeholder': 'Ingresar número de ticket',
  'usaForms.contact-us.hyperVulnerablePassenger.label': 'Condición del pasajero',
  'usaForms.contact-us.add-passengers.label': 'Agregar otro pasajero',
  'usaForms.contact-us.remove-passengers.label': 'Eliminar pasajero',
  'usaForms.contact-us.validation.ticketNumber-required': 'No olvides indicar el número de ticket.',
  'usaForms.contact-us.validation.conflictScale-required':
    'Debe seleccionar una escala de conflicto.',
  'usaForms.contact-us.validation.documentNumber-required':
    'No olvides indicar el número de documento.',
  'usaForms.contact-us.baggage-complaints': 'Reclamos de equipaje',
  'usaForms.contact-us.terms-conditions-peru':
    'Declaro ser titular del servicio y acepto el contenido del presente formulario manifestando bajo declaración jurada la veracidad de los hechos descritos.',
  'usaForms.contact-us.indecopi-peru':
    'La formulación del reclamo no impide acudir a otras vías de solución de controversias ni es requisito previo para interponer una denuncia ante el INDECOPI. El proveedor debe dar respuesta al reclamo o queja en un plazo no mayor a quince (15) días hábiles, el cual es improrrogable.'
}
