/* eslint-disable import/no-cycle */
import axios from 'axios'

import CONFIG from 'config'
import { DESCRIPTION, PROCESS_TYPE } from 'constants/log'
import { logError } from 'helpers/log'
import ROUTES from 'server/utils/routes'
import {
  retrieveTokenFromCookies,
  isTokenExpired,
  canSaveCookiesInClient
} from 'server/utils/tokenUtils'

export const negotiateToken = async () => {
  // Step 1: Check cookies from parameters or windows
  const currentCookies = canSaveCookiesInClient && document.cookie

  // Step 2: Check for token in cookies from parameters
  if (currentCookies) {
    const tokenFromCookies = retrieveTokenFromCookies(currentCookies)
    if (tokenFromCookies) {
      return Promise.resolve({
        access_token: tokenFromCookies.access_token,
        expires_in: tokenFromCookies.expires_in
      })
    }
  }

  // Step 3: Check for token in `window` global variables (for client-side context)
  if (canSaveCookiesInClient && window.__ACCESS_TOKEN__ && window.__TOKEN_EXPIRATION__) {
    if (!isTokenExpired(window.__TOKEN_EXPIRATION__)) {
      return Promise.resolve({
        access_token: window.__ACCESS_TOKEN__,
        expires_in: window.__TOKEN_EXPIRATION__
      })
    }
  }

  return axios
    .get(`${CONFIG.DOMAIN_URL}${ROUTES.NEGOTIATE_TOKEN.URL}`)
    .then(({ data }) => data)
    .catch(error => {
      // Log the error and return undefined (app will handle rendering without throwing error)
      error.fileName = 'auth.js'
      error.lineNumber = '44'
      logError(PROCESS_TYPE.TOKEN_GENERATION, DESCRIPTION.TOKEN_GENERATION_ERROR, error)

      return undefined
    })
}

export const applyAuthorizationHeaders = async headers => {
  const currentAuthorization = headers['Authorization']?.trim()

  // Check if the Authorization header is invalid
  const isInvalidAuthorization =
    !currentAuthorization ||
    currentAuthorization === 'Bearer' ||
    currentAuthorization === 'Bearer undefined'

  if (isInvalidAuthorization) {
    try {
      const token = await negotiateToken()
      // Update the Authorization header directly
      headers['Authorization'] = `Bearer ${token?.access_token || ''}`
    } catch (error) {
      error.fileName = 'auth.js'
      error.lineNumber = '66'
      logError(PROCESS_TYPE.TOKEN_GENERATION, DESCRIPTION.TOKEN_GENERATION_ERROR, error)
      // Set an invalid Authorization state in case of error
      headers['Authorization'] = 'Bearer' // Default fallback
    }
  }

  // Return the modified headers
  return headers
}
