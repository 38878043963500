/* eslint-disable import/no-cycle */
const PAYMENT_IFRAME = 'PAYMENT_IFRAME'
import { authBookingPost } from 'actions/authBooking'
import mediaQueries from 'components/media-queries'
import { PAYMENT_FLOW_TYPE } from 'constants/payment'
import { isCheckoutRoute, isExchangesRoute, isAncillariesRoute } from 'helpers/routeMatchers'
import { getBookingToken } from 'helpers/sessionStorage'

import { generateExternalPaymentRoute } from './url/localization'

const parseHTML = html => (html || '').replace('<form', '<form target="_self" ')

const injectProps = (iframe, language) => {
  const { screenWidth, screenHeight, width, height } = window.screen || {}
  const location = window.location

  const parseWidth = screenWidth || width
  const parseHeight = screenHeight || height

  const isCheckout = isCheckoutRoute(location)
  const isExchanges = isExchangesRoute(location)
  const isAncillaries = isAncillariesRoute(location)

  if (!iframe.src) {
    const paymentFlowType = isCheckout
      ? PAYMENT_FLOW_TYPE.THIRD_PARTY_CHECKOUT
      : isExchanges
      ? PAYMENT_FLOW_TYPE.THIRD_PARTY_EXCHANGE
      : PAYMENT_FLOW_TYPE.THIRD_PARTY_ANCILLARIES

    iframe.src = generateExternalPaymentRoute(language, paymentFlowType)
  }

  iframe.title = PAYMENT_IFRAME
  iframe.width = parseWidth
  iframe.height = parseHeight
  iframe.style = `
      height: 100%;
      width: 100%;
      border-style: none;
      position: absolute;
      top: ${isCheckout || isExchanges ? '80px' : isAncillaries ? '110px' : '0'};
      left: 0%;
      backdrop-filter: blur(50px);
      background-color: #ffffff;
      z-index: 999999;
      overflow-y: auto;

      ${mediaQueries.mobile`
        top: ${isCheckout || isExchanges ? '60px' : isAncillaries ? '110px' : '0'};
      `};
      `

  toggleHeaders(false)
  hiddenBodyScroll()
}

const parseIframe = (html, language) => {
  const iframe = getIframe()

  if (!iframe) return

  injectProps(iframe, language)

  const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document

  if (iframeDocument) {
    iframeDocument.open()
    iframeDocument.write(html)
    iframeDocument.close()
  }

  iframe.onload = () => {
    const iframeRedirect = getIframe()
    const contentWindow = iframeRedirect?.contentWindow

    if (contentWindow) {
      const state = contentWindow?.__PRELOADED_STATE__

      if (!state) return

      const purchasingChallenge = state?.purchasingChallenge
      const purchase = state?.purchase

      if (purchasingChallenge?.data) {
        window.parent.postMessage(purchasingChallenge, window.location.href)
      } else if (purchase?.error) {
        window.parent.postMessage(purchase, window.location.href)
      }
    }
  }
}

const hiddenBodyScroll = () => {
  if (typeof window === 'undefined') return
  window?.scrollTo(0, 0)

  document.querySelector('body').style.height = '100%'
  document.querySelector('body').style.overflowY = 'hidden'
}

export const getIframe = () =>
  typeof window !== 'undefined' && window.document.getElementById('REDIRECT_FORM_ID')

export const removeIframe = () => getIframe()?.remove()

export const toggleHeaders = (active = true) => {
  const headers = document.getElementsByTagName('header')

  if (!headers) return

  for (let i = 0; i < headers.length; i++) {
    headers[i].style.cursor = active ? 'default' : 'not-allowed'

    const firstChild = headers[i].firstChild

    if (firstChild) {
      firstChild.style.pointerEvents = active ? 'auto' : 'none'
    }
  }
}

export const resetBodyScroll = () => {
  document.querySelector('body').style.height = ''
  document.querySelector('body').style.overflowY = ''
}

export const createIframe = () => {
  const iframeChallenge = window.document.createElement('iframe')
  iframeChallenge.id = 'REDIRECT_FORM_ID'
  window.document?.body.append(iframeChallenge)
}

export const showPurchasingChallenge = (response, language) => {
  const { iframe, redirectForm } = response || {}

  if (redirectForm) {
    // first verification
    createIframe()
    parseIframe(parseHTML(redirectForm), language)
  } else if (iframe) {
    // second verification
    const html = iframe.encodedChallengeForm && iframe.encodedChallengeForm

    parseIframe(parseHTML(html), language)
  }
}

export const extractReservationIds = ({ response, b64 }) => {
  let reservationCode = ''
  let lastName = ''
  let preferenceId = ''

  if (b64) {
    try {
      const data = JSON.parse(window.atob(window.atob(b64 || '') || ''))

      reservationCode = data?.PNR || ''
      lastName = data?.LAST_NAME || ''
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error parsing base64 data:', error)
    }
  } else if (response) {
    const { data } = response

    reservationCode = data?.reservationCode || data?.bookingMetadata?.reservationCode || ''
    lastName = data?.lastName || data?.passengersData?.[0]?.lastName || ''
    preferenceId = data.payments?.[0]?.externalInformation?.preferenceId || ''
  }

  return { reservationCode, lastName, preferenceId }
}

export const checkBookingToken = async ({ reservationCode, lastName, preferenceId, dispatch }) => {
  if (!getBookingToken() && reservationCode && lastName) {
    await dispatch(authBookingPost({ lastName, reservationCode, preferenceId }))
  }
}

export const parseDataLog = payload => {
  let parsePayload = { ...payload }

  if (parsePayload.creditCard) {
    delete parsePayload.creditCard
  }

  if (parsePayload.creditCards) {
    delete parsePayload.creditCards
  }

  if (parsePayload.documents) {
    delete parsePayload.documents
  }

  return parsePayload
}
