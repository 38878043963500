/* eslint-disable import/no-cycle */
import axios from 'axios'

import CONFIG from 'config'

import api, { specialErrorTreatment } from './index'

//TODO: Review the logic of token management in ARPLUS since it is also used getLSValue('access_token')
export const __ACCESS_TOKEN_ARPLUS__ = '__ACCESS_TOKEN_ARPLUS__'

const getArPlusToken = () => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(__ACCESS_TOKEN_ARPLUS__)
  }

  return ''
}

const apiArPlus = axios.create({
  baseURL: CONFIG.API_BASE_URL,
  headers: {
    common: {
      Authorization: `Bearer ${getArPlusToken()}`
    },
    get: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    },
    post: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    }
  }
})

//APIARPLUS instance interceptors
const setApiArPlusInterceptors = initializedStore => {
  apiArPlus.interceptors.response.use(
    response => response,
    async error => {
      specialErrorTreatment(initializedStore, error.response.status)

      const originalRequest = error.config

      if (error.response.status === 401 && !originalRequest.skipRetry) {
        originalRequest.skipRetry = true

        const accessToken = getArPlusToken()
        if (typeof window !== 'undefined') {
          window.localStorage.setItem(__ACCESS_TOKEN_ARPLUS__, accessToken)
        }

        api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`

        return axios(originalRequest)
      }

      return Promise.reject(error)
    }
  )
}

export { apiArPlus as default, setApiArPlusInterceptors }
