/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import {
  setBookingSessionLastname,
  setBookingSessionReservationCode,
  setBookingToken,
  setBookingSessionPreferenceId
} from 'helpers/sessionStorage'
import api from 'services/api'

const { authBookingStart, authBookingSuccess, authBookingError } = createActions({
  AUTH_BOOKING_START: () => {},
  AUTH_BOOKING_SUCCESS: data => ({ data }),
  AUTH_BOOKING_ERROR: error => ({ error })
})

const authBookingPost = data => async dispatch => {
  dispatch(authBookingStart())
  try {
    const response = await api.post(apiRoutes.BOOKING_AUTH, data)
    setBookingToken(Object.values(response.data).join(''))
    setBookingSessionLastname(data.lastName)
    setBookingSessionReservationCode(data.reservationCode)
    data.preferenceId && setBookingSessionPreferenceId(data.preferenceId)
    dispatch(authBookingSuccess(response.data))
  } catch (error) {
    dispatch(authBookingError(error.response ? error.response.data : error))
  }
}

export { authBookingPost, authBookingStart, authBookingSuccess, authBookingError }
