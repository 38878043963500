/* eslint-disable import/no-cycle */
import axios from 'axios'

import CONFIG from 'config'
import { sublosProtectedRoutes } from 'constants/sublos/routes'
import { getSublosToken } from 'helpers/sublos/auth'
import { getShoppingIdFromLogin } from 'helpers/sublos/userData'
import { negotiateToken } from 'server/services/auth'

import { setLanguageHeaders, retryRequest } from './index'

const apiSublos = axios.create({
  baseURL: CONFIG.API_BASE_URL,
  headers: {
    common: {
      Authorization: `Bearer ${getSublosToken()}`,
      'X-Channel-Id': CONFIG.DEFAULT_SUBLOS_CHANNEL_ID
    },
    get: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    },
    post: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    }
  }
})

const getToken = async input => {
  if (Object.values(sublosProtectedRoutes).includes(input)) {
    return await negotiateToken()
  }
  return { access_token: getSublosToken() }
}

const handleRequest = async config => {
  const TOKEN = await getToken(config?.url)

  setLanguageHeaders(config)
  config.headers.Authorization = `Bearer ${TOKEN.access_token}`
  config.headers['X-Channel-Id'] = CONFIG.DEFAULT_SUBLOS_CHANNEL_ID

  if (config.method === 'get') {
    config.params = { ...config.params, shoppingId: getShoppingIdFromLogin() }
  }

  return config
}

const handleResponseError = async (error, initializedStore) => {
  return retryRequest({
    api: apiSublos,
    initializedStore,
    error
  })
}

// apiSublos instance interceptors
const setApiSublosInterceptors = initializedStore => {
  apiSublos.interceptors.request.use(handleRequest)
  apiSublos.interceptors.response.use(
    response => response,
    error => handleResponseError(error, initializedStore)
  )
}

export { apiSublos as default, setApiSublosInterceptors }
