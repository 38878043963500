/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import { setPaymentStatus } from 'actions/paymentStatus'
import { changeCurrentStep } from 'actions/stepBarAction'
import { mapIssueReservation } from 'api-data-io/gds/reservation'
import apiRoutes from 'constants/apiRoutes'
import CHECKOUT_STEPS from 'constants/checkoutStepBar'
import GDS_MESSAGES from 'constants/gdsMessages'
import { PROCESS_TYPE, LEVEL, DESCRIPTION } from 'constants/log'
import MISSING_INFO_STEPS from 'constants/missingInfoStepBar'
import { PAYMENT_METHODS } from 'constants/payment'
import PAYMENT_STATUS from 'constants/paymentStatus'
import { getDigitCount } from 'helpers/creditCard'
import { handleError } from 'helpers/handleActionErrors'
import log from 'helpers/logs'
import { extractReservationIds, checkBookingToken, parseDataLog } from 'helpers/payment'
import { removeBookingSessionInfo } from 'helpers/sessionStorage'
import {
  getBrowserMetadata,
  resetCreditCardData,
  removeSublosPersistValidation,
  removeIsExternalPayment3pExchange,
  setIsExternalPayment3pExchange
} from 'helpers/utils'
import { getSecondPaymentMethodSelectionSubState } from 'selectors/paymentMethodSelection'
import { negotiateToken } from 'server/services/auth'
import api, { apiBooking, apiSublos, setTokenHeader } from 'services/api'

const {
  fetchReservationStart,
  fetchReservationSuccess,
  fetchReservationError,
  clearReservation,
  clearReservationError,
  purchaseStart,
  purchaseSuccess,
  purchaseDataTemp,
  clearPurchaseDataTemp,
  purchaseError,
  clearPurchase,
  clearPurchaseError,
  paymentBookingStart,
  paymentBookingSuccess,
  paymentBookingError,
  resetPaymentBookingError,
  resetSecondPaymentBookingError,
  setPaymentData,
  resetPaymentData,
  resetSecondPaymentData,
  setPassengersFormikData,
  resetPassengersFormikData,
  exchangesPaymentStart,
  exchangesPaymentSuccess,
  exchangesPaymentError,
  clearExchangesPayment,
  clearExchangesPurchaseError,
  addReservationEmailStart,
  addReservationEmailSuccess,
  addReservationEmailError,
  clearEmailAddition,
  setIsUnticketedPurchase
} = createActions({
  FETCH_RESERVATION_START: () => { },
  FETCH_RESERVATION_SUCCESS: data => ({ data }),
  FETCH_RESERVATION_ERROR: error => ({ error }),
  CLEAR_RESERVATION: () => { },
  CLEAR_RESERVATION_ERROR: () => { },
  PURCHASE_START: () => { },
  PURCHASE_SUCCESS: data => ({ data }),
  PURCHASE_DATA_TEMP: data => ({ data }),
  CLEAR_PURCHASE_DATA_TEMP: () => ({}),
  PURCHASE_ERROR: error => ({ error }),
  CLEAR_PURCHASE: () => { },
  CLEAR_PURCHASE_ERROR: () => { },
  PAYMENT_BOOKING_START: () => { },
  PAYMENT_BOOKING_SUCCESS: data => ({ data }),
  PAYMENT_BOOKING_ERROR: error => ({ error }),
  RESET_PAYMENT_BOOKING_ERROR: () => { },
  RESET_SECOND_PAYMENT_BOOKING_ERROR: () => { },
  SET_PAYMENT_DATA: data => ({ data }),
  RESET_PAYMENT_DATA: () => { },
  RESET_SECOND_PAYMENT_DATA: () => { },
  SET_PASSENGERS_FORMIK_DATA: data => ({ data }),
  RESET_PASSENGERS_FORMIK_DATA: () => { },
  EXCHANGES_PAYMENT_START: () => { },
  EXCHANGES_PAYMENT_SUCCESS: data => ({ data }),
  EXCHANGES_PAYMENT_ERROR: error => ({ error }),
  CLEAR_EXCHANGES_PAYMENT: () => { },
  CLEAR_EXCHANGES_PURCHASE_ERROR: () => ({}),
  ADD_RESERVATION_EMAIL_START: () => { },
  ADD_RESERVATION_EMAIL_SUCCESS: data => ({ data }),
  ADD_RESERVATION_EMAIL_ERROR: error => ({ error }),
  CLEAR_EMAIL_ADDITION: () => { },
  SET_IS_UNTICKETED_PURCHASE: warnings => ({ warnings })
})

const fetchReservation = (reservationCode, lastName, b64) => {
  return async dispatch => {
    dispatch(fetchReservationStart())

    try {
      let reservationIds = null

      if (!reservationCode || !lastName) {
        reservationIds = extractReservationIds({ b64 })
      }

      let passengerLastName = lastName || reservationIds?.lastName
      let pnrCode = reservationCode || reservationIds?.reservationCode

      await checkBookingToken({
        reservationCode: pnrCode,
        lastName: passengerLastName,
        dispatch
      })

      const response = await apiBooking.get(apiRoutes.RESERVATIONS)

      dispatch(fetchReservationSuccess(response.data))
    } catch (error) {
      removeBookingSessionInfo()

      dispatch(fetchReservationError(error.response ? error.response.data : error.message))
    }
  }
}

const reservationExternal = (reservationCode, lastName, preferenceId) => {
  return async dispatch => {
    dispatch(fetchReservationStart())

    try {
      await checkBookingToken({ reservationCode, lastName, preferenceId, dispatch })

      const response = await apiBooking.get(
        apiRoutes.RESERVATION_EXTERNAL(reservationCode, lastName, preferenceId)
      )

      dispatch(fetchReservationSuccess(response.data))
      return response.data
    } catch (error) {
      dispatch(fetchReservationError(error.response ? error.response.data : error.message))
    }
  }
}

const fetch3pReservation = shoppingId => {
  return async dispatch => {
    dispatch(purchaseStart())

    const apiRoute = apiRoutes.GLOBAL_RESERVATIONS(shoppingId)
    const description = DESCRIPTION.CHECK_RESERVATION
    const processType = PROCESS_TYPE.CHECK_3P_RESERVATION
    const pnrOrShoppingId = shoppingId

    log.register({
      level: LEVEL.INFO,
      apiRoute,
      description,
      processType,
      pnrOrShoppingId
    })

    try {
      const { access_token } = await negotiateToken()
      setTokenHeader(api.defaults.headers.common, access_token)

      const response = await api.get(apiRoute)

      const { reservationCode, lastName } = extractReservationIds({ response })

      await checkBookingToken({ reservationCode, lastName, dispatch })

      log.register({
        level: LEVEL.SUCCESS,
        apiRoute,
        description,
        processType,
        data: response,
        pnrOrShoppingId: reservationCode ? `${reservationCode}/${pnrOrShoppingId}` : pnrOrShoppingId
      })

      dispatch(purchaseSuccess(response.data))
      return { data: response.data }
    } catch (error) {
      let parseError = error.response ? error.response.data : error.message

      if (parseError?.statusCode === 404) {
        if (parseError?.errorMessage === 'PENDING') return

        if (parseError?.errorMessage === GDS_MESSAGES.PURCHASE_RESPONSE_NOT_FOUND) {
          parseError = {
            ...parseError,
            description: GDS_MESSAGES.PURCHASE_RESPONSE_NOT_FOUND
          }
        }
      }

      log.register({
        level: LEVEL.ERROR,
        apiRoute,
        description,
        processType,
        pnrOrShoppingId,
        data: error
      })

      dispatch(purchaseError(parseError))
      return { error: parseError }
    }
  }
}

const fetchMercadoPagoReservation = (reservationCode, lastName) => {
  return async dispatch => {
    dispatch(purchaseStart())

    const apiRoute = apiRoutes.BOOKING_EXTERNAL_MERCADO_PAGO(reservationCode, lastName)
    const description = DESCRIPTION.CHECK_RESERVATION
    const processType = PROCESS_TYPE.CHECK_MP_RESERVATION
    const pnrOrShoppingId = reservationCode

    log.register({
      level: LEVEL.INFO,
      apiRoute,
      description,
      processType,
      pnrOrShoppingId
    })

    try {
      await checkBookingToken({ reservationCode, lastName, dispatch })

      const response = await apiBooking.get(apiRoute)

      log.register({
        level: LEVEL.SUCCESS,
        apiRoute,
        description,
        processType,
        pnrOrShoppingId,
        data: response
      })

      dispatch(purchaseSuccess(response.data))
      return { data: response.data }
    } catch (error) {
      const parseError = error.response ? error.response.data : error.message

      log.register({
        level: LEVEL.ERROR,
        apiRoute,
        description,
        processType,
        pnrOrShoppingId,
        data: error
      })

      dispatch(purchaseError(parseError))
      return { error: parseError }
    }
  }
}

export const maskCardData = paymentData => {
  return (dispatch, getState) => {
    const { selectedType } =
      getState().paymentMethodSelection || getSecondPaymentMethodSelectionSubState()

    if (selectedType !== PAYMENT_METHODS.OFFLINE) {
      const {
        paymentValidations,
        selectedPaymentMethod,
        masks: { creditCard }
      } = getState().paymentMethodSelection || getSecondPaymentMethodSelectionSubState()

      const digitCount = getDigitCount(paymentValidations, selectedPaymentMethod)

      dispatch(setPaymentData(resetCreditCardData(paymentData, creditCard, digitCount)))
    }
  }
}

/** @todo Abstract purchase steps (specially for booking purchases) in one method, to include:
 *
 * exchangesPurchase
 * ancillariesPurchase
 * and future booking purchases
 *
 * generateReservation (maybe?)
 * */

/** @param formData is stored in Redux to show in case of error,
 * is separate from payload because of different field names among form and payload field
 * @param payload of reservation post
 * */
const generateReservation = (formData, payload) => {
  return async dispatch =>
    dispatch(purchase(formData, payload, null, PROCESS_TYPE.GENERATE_RESERVATION))
}

/** @param formData is mapped to payload and is not stored in Redux as other methods
 * @param pnr of previously generated reservation for global payment flows
 * */
const retryGlobalExternalPayment = (formData, pnr) => {
  const payload = mapIssueReservation(formData, pnr)
  return async dispatch =>
    dispatch(purchase(null, payload, pnr, PROCESS_TYPE.RETRY_GLOBAL_EXTERNAL_PAYMENT))
}

/** @param formData is stored in Redux to show in case of error,
 * is separate from payload because of different field names among form and payload field
 * @param pnr of previously generated reservation for global payment flows
 * */
const retryGlobalPayment = (formData, payload, pnr) => {
  return async dispatch =>
    dispatch(purchase(formData, payload, pnr, PROCESS_TYPE.RETRY_GLOBAL_PAYMENT))
}

const sublosPurchase = ({
  companyCode,
  reservationCode,
  employeeCode,
  shoppingId,
  payload
}) => async dispatch => {
  dispatch(purchaseStart())
  removeSublosPersistValidation()
  payload && maskCardData(payload)
  Object.assign(payload, getBrowserMetadata())

  const apiRoute = apiRoutes.SUBLOS_RESERVATIONS_CONFIRMATION({
    companyCode,
    reservationCode,
    employeeCode
  })
  const processType = PROCESS_TYPE.SUBLOS
  const pnrOrShoppingId = reservationCode ? `${reservationCode}/${shoppingId}` : shoppingId

  log.startPaymentClient({
    apiRoute,
    processType,
    pnrOrShoppingId,
    data: parseDataLog(payload)
  })

  try {
    const response = await apiSublos.post(apiRoute, payload)

    log.successPaymentClient({
      apiRoute,
      processType,
      pnrOrShoppingId,
      data: parseDataLog(response)
    })

    dispatch(purchaseSuccess(response.data))
  } catch (error) {
    log.errorPaymentClient({
      apiRoute,
      processType,
      pnrOrShoppingId,
      data: error
    })

    dispatch(purchaseError(error.response ? error.response.data : error))
  }
}

const purchase = (formData, payload, pnr, processType = PROCESS_TYPE.PURCHASE) => {
  return async dispatch => {
    formData && maskCardData(formData)
    Object.assign(payload, getBrowserMetadata())

    let apiRoute = ''
    let response = null
    let apiActionInstance = null
    let pnrOrShoppingId = pnr || payload.reservationCode || payload.shoppingId

    if (pnr) {
      apiActionInstance = api.put
      apiRoute = apiRoutes.GLOBAL_PAYMENT(pnr)
    } else {
      apiActionInstance = api.post
      apiRoute = payload.holdOptionId ? apiRoutes.HOLD_OPTIONS : apiRoutes.RESERVATIONS
    }

    const payloadLog = parseDataLog(payload)

    log.startPaymentClient({
      apiRoute,
      processType,
      pnrOrShoppingId,
      data: payloadLog
    })

    dispatch(purchaseStart())
    dispatch(setPaymentStatus(null))
    dispatch(purchaseDataTemp(payloadLog))

    try {
      response = await apiActionInstance(apiRoute, payload)

      if (payload.isMercadoPagoSelected) {
        const { reservationCode, lastName, preferenceId } = extractReservationIds({ response })
        await checkBookingToken({ reservationCode, lastName, preferenceId, dispatch })
      }

      log.successPaymentClient({
        apiRoute,
        processType,
        pnrOrShoppingId,
        data: parseDataLog(response)
      })

      dispatch(purchaseSuccess(response.data))
    } catch (error) {
      log.errorPaymentClient({
        apiRoute,
        processType,
        pnrOrShoppingId,
        data: error
      })

      handleError(error, purchaseError, dispatch, true)
    }
  }
}

/** @FormData is stored in Redux to show in case of error,
 * is separate from payload because of different field names among form and payload field
 * */
const generatePaymentBooking = (payload, formData) => {
  return async dispatch => {
    maskCardData(formData)
    Object.assign(payload, getBrowserMetadata())

    const apiRoute = apiRoutes.BOOKING_PAYMENT
    const processType = PROCESS_TYPE.GENERATE_PAYMENT_BOOKING
    const pnrOrShoppingId = payload.reservationCode || payload.shoppingId

    log.startPaymentClient({
      apiRoute,
      processType,
      pnrOrShoppingId,
      data: parseDataLog(payload)
    })

    dispatch(paymentBookingStart())
    try {
      const response = await api.post(apiRoute, payload)
      if (
        response.data?.authorization &&
        response.data?.authorization?.messageCode === PAYMENT_STATUS.FAILURE
      ) {
        const error = {
          statusCode: 400,
          errorMessage: response.data?.authorization?.details
        }

        log.errorPaymentClient({
          apiRoute,
          processType,
          pnrOrShoppingId,
          data: {
            status: error.statusCode,
            message: error.errorMessage
          }
        })

        dispatch(paymentBookingError(error))
      } else {
        log.successPaymentClient({
          apiRoute,
          processType,
          pnrOrShoppingId,
          data: parseDataLog(response)
        })

        dispatch(paymentBookingSuccess(response.data))
      }
    } catch (error) {
      const err = error.response ? error.response.data : error

      log.errorPaymentClient({
        apiRoute,
        processType,
        pnrOrShoppingId,
        data: error
      })

      dispatch(paymentBookingError(err))
    }
  }
}

/** @FormData is stored in Redux to show in case of error,
 * is separate from payload because of different field names among form and payload field
 * */
const generateExchangesPayment = (payload, formData) => {
  return async dispatch => {
    maskCardData(formData)
    Object.assign(payload, getBrowserMetadata())

    const apiRoute = apiRoutes.EXCHANGES
    const processType = PROCESS_TYPE.EXCHANGES
    const pnrOrShoppingId = payload.reservationCode || payload.shoppingId

    log.startPaymentClient({
      apiRoute,
      processType,
      pnrOrShoppingId,
      data: parseDataLog(payload)
    })

    dispatch(exchangesPaymentStart())
    try {
      const response = await api.post(apiRoute, payload)

      if (
        response.data?.authorization &&
        response.data?.authorization?.messageCode === PAYMENT_STATUS.FAILURE
      ) {
        const error = {
          statusCode: 400,
          errorMessage: response.data?.authorization?.details
        }

        log.errorPaymentClient({
          apiRoute,
          processType,
          pnrOrShoppingId,
          data: {
            status: error.statusCode,
            message: error.errorMessage
          }
        })

        dispatch(exchangesPaymentError(error))
        removeIsExternalPayment3pExchange()
      } else {
        setIsExternalPayment3pExchange()

        log.successPaymentClient({
          apiRoute,
          processType,
          pnrOrShoppingId,
          data: parseDataLog(response)
        })

        dispatch(exchangesPaymentSuccess(response.data))
      }
    } catch (error) {
      const err = error.response ? error.response.data : error

      log.errorPaymentClient({
        apiRoute,
        processType,
        pnrOrShoppingId,
        data: error
      })

      removeIsExternalPayment3pExchange()
      dispatch(exchangesPaymentError(err))
    }
  }
}

const addReservationEmail = (pnr, email) => {
  return async dispatch => {
    dispatch(addReservationEmailStart())

    try {
      const response = await api.put(apiRoutes.RESERVATION_PNR(pnr), { email })

      dispatch(addReservationEmailSuccess(response.data))
    } catch (error) {
      const err = error.response ? error.response.data : error

      dispatch(addReservationEmailError(err))
    }
  }
}

const setReservationSuccessfulPayment = warningMessages => {
  return dispatch => {
    dispatch(setIsUnticketedPurchase(warningMessages))
    dispatch(resetPaymentData())
    dispatch(changeCurrentStep(CHECKOUT_STEPS.CONFIRM, 2))
  }
}

const setReservationSuccessfulPaymentMissingInfo = () => {
  return dispatch => {
    dispatch(resetPaymentData())
    dispatch(changeCurrentStep(MISSING_INFO_STEPS.CONFIRM, 2))
  }
}

export {
  fetchReservationStart,
  fetchReservationSuccess,
  fetchReservationError,
  fetchReservation,
  reservationExternal,
  sublosPurchase,
  clearReservation,
  clearReservationError,
  purchaseStart,
  purchaseSuccess,
  purchaseDataTemp,
  purchaseError,
  resetPaymentBookingError,
  resetSecondPaymentBookingError,
  generateReservation,
  paymentBookingStart,
  paymentBookingError,
  paymentBookingSuccess,
  generatePaymentBooking,
  setPaymentData,
  resetPaymentData,
  resetSecondPaymentData,
  setPassengersFormikData,
  resetPassengersFormikData,
  exchangesPaymentStart,
  exchangesPaymentError,
  exchangesPaymentSuccess,
  generateExchangesPayment,
  clearExchangesPayment,
  clearExchangesPurchaseError,
  addReservationEmailStart,
  addReservationEmailSuccess,
  addReservationEmailError,
  addReservationEmail,
  setIsUnticketedPurchase,
  setReservationSuccessfulPayment,
  setReservationSuccessfulPaymentMissingInfo,
  clearPurchase,
  clearPurchaseError,
  clearEmailAddition,
  retryGlobalPayment,
  retryGlobalExternalPayment,
  clearPurchaseDataTemp,
  fetch3pReservation,
  fetchMercadoPagoReservation
}
