/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import { authBookingPost } from 'actions/authBooking'
import { setPaymentStatus } from 'actions/paymentStatus'
import { maskCardData } from 'actions/reservation'
import apiRoutes from 'constants/apiRoutes'
import { getBookingToken } from 'helpers/sessionStorage'
import { getBrowserMetadata } from 'helpers/utils'
import api, { apiBooking } from 'services/api'

const {
  fetchAncillariesStart,
  fetchAncillariesSuccess,
  fetchAncillariesError,
  selectAncillariesStart,
  selectAncillariesSuccess,
  selectAncillariesError,
  purchaseAncillariesStart,
  purchaseAncillariesSuccess,
  purchaseAncillariesError,
  updateAncillaries,
  clearAncillariesSelect,
  clearAncillariesPurchase,
  clearAncillaries,
  clearAncillariesPurchaseError
} = createActions({
  FETCH_ANCILLARIES_START: () => {},
  FETCH_ANCILLARIES_SUCCESS: data => ({ data }),
  FETCH_ANCILLARIES_ERROR: error => ({ error }),
  SELECT_ANCILLARIES_START: () => ({}),
  SELECT_ANCILLARIES_SUCCESS: data => ({ data }),
  SELECT_ANCILLARIES_ERROR: error => ({ error }),
  PURCHASE_ANCILLARIES_START: () => ({}),
  PURCHASE_ANCILLARIES_SUCCESS: data => ({ data }),
  PURCHASE_ANCILLARIES_ERROR: error => ({ error }),
  UPDATE_ANCILLARIES: data => ({ data }),
  CLEAR_ANCILLARIES_SELECT: () => ({}),
  CLEAR_ANCILLARIES_PURCHASE: () => ({}),
  CLEAR_ANCILLARIES: () => ({}),
  CLEAR_ANCILLARIES_PURCHASE_ERROR: () => ({})
})

const fetchAncillariesCheckout = shoppingId => {
  return async dispatch => {
    dispatch(fetchAncillariesStart())
    try {
      const response = await api.get(`${apiRoutes.ANCILLARIES}?shoppingId=${shoppingId}`)
      dispatch(fetchAncillariesSuccess(response.data))
    } catch (error) {
      dispatch(fetchAncillariesError(error.response ? error.response.data : error))
    }
  }
}

const fetchAncillariesBooking = (reservationCode, lastName) => {
  return async dispatch => {
    dispatch(fetchAncillariesStart())
    try {
      if (!getBookingToken()) {
        await dispatch(authBookingPost({ lastName, reservationCode }))
      }
      const response = await apiBooking.get(apiRoutes.ANCILLARIES)
      dispatch(fetchAncillariesSuccess(response.data))
    } catch (error) {
      dispatch(fetchAncillariesError(error.response ? error.response.data : error))
    }
  }
}

const selectAncillaries = data => {
  return async dispatch => {
    dispatch(selectAncillariesStart())
    try {
      const response = await api.post(apiRoutes.ANCILLARIES, data)
      dispatch(selectAncillariesSuccess(response.data))
    } catch (error) {
      dispatch(selectAncillariesError(error.response ? error.response.data : error))
    }
  }
}

/** @FormData is stored in Redux to show in case of error,
 * is separate from payload because of different field names among form and payload field
 * */
const purchaseAncillaries = (payload, formData) => {
  return async dispatch => {
    maskCardData(formData)

    dispatch(purchaseAncillariesStart())
    dispatch(setPaymentStatus(null))

    try {
      const { lastName, reservationCode } = payload
      if (lastName && reservationCode) {
        await dispatch(authBookingPost({ lastName, reservationCode }))
      }
      Object.assign(payload, getBrowserMetadata())
      const response = await api.post(apiRoutes.ANCILLARIES_PURCHASE, payload)

      dispatch(purchaseAncillariesSuccess(response.data))
    } catch (error) {
      dispatch(purchaseAncillariesError(error.response ? error.response.data : error))
    }
  }
}

const clearAncillariesStates = () => {
  return dispatch => {
    dispatch(clearAncillaries())
    dispatch(clearAncillariesSelect())
    dispatch(clearAncillariesPurchase())
  }
}

export {
  fetchAncillariesCheckout,
  fetchAncillariesBooking,
  fetchAncillariesStart,
  fetchAncillariesSuccess,
  fetchAncillariesError,
  selectAncillaries,
  selectAncillariesStart,
  selectAncillariesSuccess,
  selectAncillariesError,
  purchaseAncillaries,
  purchaseAncillariesStart,
  purchaseAncillariesSuccess,
  purchaseAncillariesError,
  updateAncillaries,
  clearAncillariesSelect,
  clearAncillariesStates,
  clearAncillariesPurchase,
  clearAncillaries,
  clearAncillariesPurchaseError
}
