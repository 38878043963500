/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  fiscalCreditStart,
  fiscalCreditSuccess,
  fiscalCreditError,
  clearFiscalCredit
} = createActions({
  FISCAL_CREDIT_START: () => {},
  FISCAL_CREDIT_SUCCESS: data => ({ data }),
  FISCAL_CREDIT_ERROR: error => ({ error }),
  CLEAR_FISCAL_CREDIT: () => ({})
})

const fiscalCreditPost = formValues => async dispatch => {
  dispatch(fiscalCreditStart())
  try {
    const response = await api.post(apiRoutes.FISCAL_CREDIT, formValues)
    dispatch(fiscalCreditSuccess(response.data))
  } catch (error) {
    dispatch(fiscalCreditError(error.response ? error.response.data : error))
  }
}

export {
  fiscalCreditPost,
  fiscalCreditStart,
  fiscalCreditSuccess,
  fiscalCreditError,
  clearFiscalCredit
}
