/* eslint-disable import/no-cycle */
import loadableDelay from 'hoc/loadableDelay'

export const SublosLoginPage = loadableDelay(() => import('./LoginPage'))

export const SublosMainPage = loadableDelay(() => import('./MainPage'))

export const SublosErrorPage = loadableDelay(() => import('./ErrorPage'))

export const SublosWebSiteInProgressPage = loadableDelay(() => import('./WebSiteInProgress'))
