/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { LogOut } from 'helpers/flightOffers'
import { setLSValue } from 'helpers/localStorage'
import api from 'services/api'

const { milesLoginStart, milesLoginSuccess, milesLoginError, clearMilesLogin } = createActions({
  MILES_LOGIN_START: () => {},
  MILES_LOGIN_SUCCESS: data => ({ data }),
  MILES_LOGIN_ERROR: error => ({ error }),
  CLEAR_MILES_LOGIN: () => ({})
})

const milesLoginPost = (shoppingId, values) => async dispatch => {
  dispatch(milesLoginStart())
  try {
    const response = await api.post(apiRoutes.MILES_LOGIN, {
      username: values.userName,
      password: values.password,
      ...(shoppingId && { shoppingId: shoppingId })
    })
    if (response.data?.baseMetadata.warnMessages.length > 0) {
      const unsubscribeProcessMessage = {
        description: response.data?.baseMetadata.warnMessages
      }
      dispatch(milesLoginError(unsubscribeProcessMessage))
      return Promise.reject(unsubscribeProcessMessage)
    } else {
      dispatch(milesLoginSuccess(response.data))
      setLSValue('shoppingId', response.data.shoppingId)
      response.data.accessToken && setLSValue('access_token', response.data.accessToken)
    }
  } catch (error) {
    dispatch(milesLoginError(error.response ? error.response.data : error))
    return Promise.reject(error.response?.data)
  }
}

const fetchMilesLoginData = shoppingId => async dispatch => {
  dispatch(milesLoginStart())
  try {
    const response = await api.get(apiRoutes.LOGIN_PROFILE + shoppingId)
    dispatch(milesLoginSuccess(response.data))
  } catch (error) {
    const err = error.response ? error.response.data : error
    dispatch(milesLoginError(err))
    LogOut()
    return Promise.reject(err)
  }
}

export {
  milesLoginPost,
  milesLoginStart,
  milesLoginSuccess,
  milesLoginError,
  fetchMilesLoginData,
  clearMilesLogin
}
