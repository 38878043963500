import { handleActions } from 'redux-actions'

import {
  travelAssistanceStart,
  travelAssistanceSuccess,
  travelAssistanceError,
  clearTravelAssistance
} from 'actions/travelAssistance'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [travelAssistanceStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [travelAssistanceSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [travelAssistanceError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error: error,
      isFetching: false
    }),
    [clearTravelAssistance]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
