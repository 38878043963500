/* eslint-disable import/no-cycle */

export const handleError = (error, errorAction, dispatch, isPurchase) => {
  let stateChange
  const errorMessage = getErrorMessage(isPurchase)
  let data = { errorMessage }

  if (error.response && error.response.status !== 0) {
    stateChange = errorAction(error.response.data)
  } else if (error.response?.status === 0) {
    stateChange = errorAction({
      status: error.response.status,
      requestError: true
    })
  } else {
    stateChange = errorAction(data)
  }
  dispatch(stateChange)
}

function getErrorMessage(isPurchase) {
  //TODO: create translation key
  if (isPurchase) {
    return 'There was an error processing your purchase. Please try again.'
  } else {
    return 'There was an error processing your request. Please try again.'
  }
}
