/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  newsletterRegistrationStart,
  newsletterRegistrationSuccess,
  newsletterRegistrationError,
  clearNewsletterRegistration,
  newsletterUnsubscribeStart,
  newsletterUnsubscribeSuccess,
  newsletterUnsubscribeError,
  clearNewsletterUnsubscribe
} = createActions({
  NEWSLETTER_REGISTRATION_START: () => {},
  NEWSLETTER_REGISTRATION_SUCCESS: data => ({ data }),
  NEWSLETTER_REGISTRATION_ERROR: error => ({ error }),
  CLEAR_NEWSLETTER_REGISTRATION: () => ({}),
  NEWSLETTER_UNSUBSCRIBE_START: () => {},
  NEWSLETTER_UNSUBSCRIBE_SUCCESS: data => ({ data }),
  NEWSLETTER_UNSUBSCRIBE_ERROR: error => ({ error }),
  CLEAR_NEWSLETTER_UNSUBSCRIBE: () => ({})
})

const newsletterRegistrationPost = formValues => async dispatch => {
  dispatch(newsletterRegistrationStart())
  try {
    const response = await api.post(apiRoutes.NEWSLETTER_REGISTRATION, formValues)
    dispatch(newsletterRegistrationSuccess(response.data))
  } catch (error) {
    dispatch(newsletterRegistrationError(error.response ? error.response.data : error))
  }
}

const newsletterUnsubscribe = ({ email }) => async dispatch => {
  dispatch(newsletterUnsubscribeStart())
  try {
    const response = await api.delete(apiRoutes.NEWSLETTER_UNSUBSCRIBE(email))
    dispatch(newsletterUnsubscribeSuccess(response.data || true))
  } catch (error) {
    dispatch(newsletterUnsubscribeError(error.response ? error.response.data : error))
  }
}

export {
  newsletterRegistrationPost,
  newsletterUnsubscribe,
  newsletterRegistrationStart,
  newsletterRegistrationSuccess,
  newsletterRegistrationError,
  clearNewsletterRegistration,
  newsletterUnsubscribeStart,
  newsletterUnsubscribeSuccess,
  newsletterUnsubscribeError,
  clearNewsletterUnsubscribe
}
