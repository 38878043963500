// eslint-disable-next-line import/no-cycle
import { langPathRegex } from 'constants/routes'

export default {
  PAGO_MIS_CUENTAS: {
    URL: '/pago-mis-cuentas',
    disallowIndex: true
  },
  MERCADO_PAGO: {
    URL: `${langPathRegex}/mercado-pago-confirmation`,
    disallowIndex: true
  },
  GLOBAL_3P_PAYMENT: {
    URL: `${langPathRegex}/global-redirect`,
    disallowIndex: true
  },
  VALIDATE_RECAPTCHA: {
    URL: '/recaptcha/validate',
    disallowIndex: true
  },
  EXCHANGES_FROM_MOBILE: {
    URL: '/mobile/exchanges',
    disallowIndex: true
  },
  SUBLOS_LOGIN_WITH_TOKEN: {
    URL: '/sublos/login',
    disallowIndex: true
  },
  HEALTH_CHECK: {
    URL: '/health-check',
    disallowIndex: true
  },
  NEGOTIATE_TOKEN: {
    URL: '/auth/token',
    disallowIndex: true
  },
  SITEMAP: {
    URL: /^\/sitemap(?:_(index)|_([a-z]{2})-([a-z]{2})(?:(_cms))?).xml$/,
    disallowIndex: false
  }
}
