import React from 'react'

import { Helmet } from 'react-helmet'

import { useConfigMetaTags } from './hooks'

const ConfigMetaTags = () => {
  const { loadMetaTagData } = useConfigMetaTags()

  const { title, description, og_description, og_url, og_image } = loadMetaTagData()

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {og_description && <meta property="og:description" content={og_description} />}
      {og_url && <meta property="og:url" content={og_url} />}
      {og_image && <meta property="og:image" content={og_image} />}

      <script>
        {`
          const link = document.createElement("link");
          link.rel = "stylesheet";
          link.href = "/assets/fonts.css";
          document.head.appendChild(link);
        `}
      </script>
    </Helmet>
  )
}

export default ConfigMetaTags
