import { HOLD_OPTION_MATCH } from 'constants/payment'

/**
 * The only value here that returns boolean true, is explicit 'true' string.
 * All other values will give a boolean false.
 *
 * BTW: Can't import this helper from utils file as it makes config to weirdly crash. (Thx razzle)
 * @param {string} booleanString
 */
const booleanStringToPrimitive = booleanString => booleanString === 'true'

/**
 * Fixes unexpected issues. AAW-9646 for details.
 */
const parseReloadVariable = value => (parseInt(value) > 0 ? parseInt(value) : false)

const checkEnableTabs = enableTabs => {
  const parseString = (enableTabs || '').trim()
  return !enableTabs
    ? [HOLD_OPTION_MATCH.PAYMENT_NOW.CONFIG]
    : parseString.slice(1, parseString.length - 1).split(' ')
}

/*
 * Environment Variables need to start with the prefix "RAZZLE_" to function correctly
 */
export default {
  DOMAIN_URL: process.env.RAZZLE_DOMAIN_URL,
  API_BASE_URL: process.env.RAZZLE_API_BASE_URL,
  API_SSR_BASE_URL: process.env.RAZZLE_API_SSR_BASE_URL,
  CMS_API_BASE_URL: process.env.RAZZLE_CMS_API_BASE_URL,
  CONTENT_CDN_BASE_URL: process.env.RAZZLE_CONTENT_CDN_BASE_URL,
  BUNDLE_CDN_BASE_URL: process.env.RAZZLE_BUNDLE_CDN_BASE_URL,
  CMS_SSR_BASE_URL: process.env.RAZZLE_CMS_SSR_BASE_URL,
  WEB_CHECKIN_URL: process.env.RAZZLE_WEB_CHECKIN_URL,
  DEFAULT_LOCALE: 'es-AR',
  DEFAULT_CHANNEL_ID: 'WEB_AR',
  DEFAULT_SUBLOS_CHANNEL_ID: 'SUBLOS_AR',
  GA_ID: process.env.RAZZLE_GA_ID,
  PAYMENT_METHODS_ASSETS: 'media/paymentmethods',
  AIRLINES_ASSETS: 'media/airlines',
  FINANCING_CONDITIONS_URL:
    'https://www.aerolineas.com.ar/es-ar/reservasservicios/bancos?utm_source=Website&utm_medium=Banners%20fijos%20inferiores&utm_campaign=Hola%20Cuotas&artid=aeswbic008',
  SUGGESTIONS_URL: 'https://www.aerolineas.com.ar/es-ar/contacto/contactoatencioncliente',
  FISCAL_CREDIT_URL: 'https://www.aerolineas.com.ar/es-ar/laempresa/constanciacreditofiscal',
  BANKS_URL: 'https://www.aerolineas.com.ar/es-ar/reservasservicios/bancos',
  FARE_CONDITIONS_URL: 'https://www.aerolineas.com.ar/es-ar/reservasservicios/condiciones',
  BAGGAGE_CONDITIONS_URL: 'https://www.aerolineas.com.ar/es-ar/equipaje/franquicia',
  CHECKIN_CONDITIONS_URL:
    'https://www.aerolineas.com.ar/landingsespeciales/landings/232_condiciones-checkin-online',
  FRAUD_IP_ADDRESS_URL: 'https://ipapi.co/json/?key=19cbe0cdd48ed77a6750073744207276a840fa11',
  CMS_LANGCODE: process.env.RAZZLE_CMS_LANGCODE || 'es-AR',
  GENERAL_CMS_LANGCODE: process.env.RAZZLE_GENERAL_CMS_LANGCODE || 'es',
  COOKIES_TERMS_CONDITIONS: 'https://www.aerolineas.com.ar/legales/terminos-y-condiciones',
  FOOTER_FACEBOOK: 'https://www.facebook.com/aerolineas.argentinas/',
  FOOTER_WHATSAPP: 'https://api.whatsapp.com/send?phone=541149404798',
  FOOTER_AIRPORTS: 'https://www.aerolineas.com.ar/es-ar/contacto/sucursalesaeropuertos',
  INFORMATION_SECURE_FLIGHT_URL: 'https://www.dhs.gov/dhs-trip',
  WHATSAPP_API_CONTACT: 'https://api.whatsapp.com/send?phone=541149404798',
  EXTERNAL_CHAT_BOT: 'https://api.wcx.cloud/widget/v6?id=f1e92af809cc4af596b8e15009725100',
  ENABLE_CORPORATE_AGREEMENTS: true,
  ENABLE_EVENTS_PAGE: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_EVENTS_PAGE),
  ENABLE_CHECKOUT_TABS: checkEnableTabs(process.env.RAZZLE_ENABLE_CHECKOUT_TABS),
  ENABLE_BO_TRANSLATIONS: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_BO_TRANSLATIONS),
  BO_TRANSLATIONS_SERVER_RELOAD_INTERVAL: parseReloadVariable(
    process.env.RAZZLE_BO_TRANSLATIONS_SERVER_RELOAD_INTERVAL
  ),
  BO_TRANSLATIONS_DEBUG_ON: booleanStringToPrimitive(process.env.RAZZLE_BO_TRANSLATIONS_DEBUG_ON),
  ENABLE_MILES: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_MILES),
  GOOGLE_TAG_MANAGER_ID: process.env.RAZZLE_GOOGLE_TAG_MANAGER_ID,
  ENABLE_NEWRELIC: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_NEWRELIC),
  ENABLE_PASSENGERS_MISSING_INFO: booleanStringToPrimitive(
    process.env.RAZZLE_ENABLE_PASSENGERS_MISSING_INFO
  ),
  ENABLE_FORGOTTEN_MAIL: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_FORGOTTEN_MAIL),
  ENABLE_UNSUBSCRIBE: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_UNSUBSCRIBE),
  ENABLE_TWO_CARDS_SHOPPING: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_TWO_CARDS_SHOPPING),
  ENABLE_MERCADO_PAGO: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_MERCADO_PAGO),
  ENABLE_PROMO_CODE: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_PROMO_CODE),
  ENABLE_SUBLOS: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_SUBLOS),
  ARPLUS_DISABLE_TRANSLATIONS_MEMBER_CODE:
    process.env.RAZZLE_ARPLUS_DISABLE_TRANSLATIONS_MEMBER_CODE,
  ENABLE_EXTERNAL_CHAT_BOTS: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_EXTERNAL_CHAT_BOTS),
  ENABLE_CHILE_REDIRECTION_PAYMENT_GATEWAY: booleanStringToPrimitive(
    process.env.RAZZLE_ENABLE_CHILE_REDIRECTION_PAYMENT_GATEWAY
  )
}
