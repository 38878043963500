/* eslint-disable import/no-named-as-default-member */
import React, { Fragment } from 'react'

import ReactGA from 'react-ga'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { accountEvaluationGet } from 'actions/arplus/account-evaluation-actions/getAccountEvaluation'
import { fetchMilesLoginData } from 'actions/milesLogin'
import Footer from 'components/footer'
import { TransitionLoader } from 'components/loaders'
import MessageCookies from 'components/message-cookies'
import { default as SublosHeder } from 'components/sublos/header'
import CONFIG from 'config'
import COMPONENTS from 'constants/headerComponents'
import HeaderContainer from 'containers/header'
import { isUserLogged } from 'helpers/flightOffers'
import { getLSValue } from 'helpers/localStorage'
import {
  isRouteWithFullMenu,
  isRouteWithoutHeader,
  isRouteWithStepBar,
  isRouteWithoutFooter,
  isRouteWithDarkSiteStyle,
  isFAQResultsRoute,
  isRouteWithMenuAndLanguage,
  getLandingsRelativePath,
  isRouteWithSublosHeader,
  isSublosRoute
} from 'helpers/routeMatchers'
import {
  isExternalPayment3PAncillaries,
  isExternalPayment3PCheckout,
  isExternalPayment3PExchange
} from 'helpers/url/externalPayment'
import { generateArPlusLoginRoute } from 'helpers/url/localization'
import { isEmpty, isMobileOrTablet, shouldInsertGtmTags } from 'helpers/utils'
import i18n from 'localization/i18n'
import { getAccountEvaluation } from 'selectors/arplus-accountEvaluation'
import { getUrlLandings } from 'selectors/cms/landings'
import { getLogin, getLoginIsFetching } from 'selectors/login'
import { mapMediaType } from 'selectors/mediaType'
import { getProfileData } from 'selectors/milesLogin'
import { isSublosSessionExpiredError } from 'selectors/sublos/errors'

class PageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      header: this.getHeaderComponents()
    }

    shouldInsertGtmTags(i18n.language) &&
      ReactGA.initialize(CONFIG.GA_ID, { testMode: process.env.NODE_ENV === 'test' })

    this.props.history.listen(() => this.onRouteChange())
  }

  componentDidMount() {
    if (isUserLogged()) {
      if (isEmpty(this.props.milesLogin)) {
        this.props
          .fetchMilesLoginData(getLSValue('shoppingId'))
          .then(() => {})
          .catch(() => {
            this.props.history.replace(generateArPlusLoginRoute(this.props.i18n.language))
          })
      } else if (isEmpty(this.props.memberInfo.data) && getLSValue('access_token')) {
        this.props.accountEvaluationGet(this.props.milesLogin.membershipNumber)
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.milesLogin &&
      this.props.milesLogin &&
      isEmpty(this.props.memberInfo.data) &&
      getLSValue('access_token')
    ) {
      this.props.accountEvaluationGet(this.props.milesLogin.membershipNumber)
    }
  }

  onRouteChange() {
    const { location } = this.props

    ReactGA.pageview(location.pathname + location.search)

    this.setState(
      {
        header: this.getHeaderComponents()
      },
      () => {
        !isFAQResultsRoute(location) && this.scrollToTop()
      }
    )

    if (!isRouteWithDarkSiteStyle(location)) {
      document.body.style = ''
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  getHeaderComponents() {
    // it needs muted location
    const { location } = this.props.history

    const isArplus = location.pathname.includes('/arplus')

    if (isArplus) {
      return [COMPONENTS.MENU, COMPONENTS.LOGIN, COMPONENTS.LANGUAGE]
    }

    if (
      (this.props.landings || []).find(
        ({ url }) => url === getLandingsRelativePath(location.pathname)
      )
    ) {
      return [COMPONENTS.MENU, COMPONENTS.LANGUAGE, COMPONENTS.LOGIN]
    }

    if (isRouteWithStepBar(location)) {
      if (this.props.milesLogin) return [COMPONENTS.STEP_BAR, COMPONENTS.LOGIN]

      return [COMPONENTS.STEP_BAR]
    }

    if (isRouteWithFullMenu(location)) {
      return [COMPONENTS.MENU, COMPONENTS.LANGUAGE, COMPONENTS.LOGIN]
    }

    if (isRouteWithSublosHeader(location)) {
      return [COMPONENTS.LOGIN]
    }

    if (isRouteWithMenuAndLanguage(location)) {
      return [COMPONENTS.MENU, COMPONENTS.LANGUAGE]
    }

    if (getLSValue('shoppingId')) {
      return [COMPONENTS.LOGIN]
    }

    return []
  }

  renderHeader = () => {
    const { memberInfo, milesLogin, location, isMobile, isSublosSessionExpired } = this.props

    /* Make the milesLogin object contain the point balance from PSW
    so the point balance always comes from 1 service */
    const milesLoginPSW = {
      ...milesLogin,
      accountBalance: memberInfo.data?.points
    }

    const isPaymentRoute =
      isExternalPayment3PCheckout(location) ||
      isExternalPayment3PExchange(location) ||
      isExternalPayment3PAncillaries(location)

    const isWindowUndefined = typeof window === 'undefined'
    const isIframe = !isWindowUndefined && window !== window.parent

    if (isPaymentRoute && (isWindowUndefined || isIframe)) return
    /* For exhanges url is always the same, only query params change.
      Main exchanges page doesn't have header, but exchanges offers does */
    return isSublosRoute(location) ? (
      !isSublosSessionExpired && (isMobile || isRouteWithSublosHeader(location)) ? (
        <SublosHeder components={this.state.header} />
      ) : (
        <></>
      )
    ) : (
      (!isRouteWithoutHeader(location) || isRouteWithStepBar(location)) && (
        <HeaderContainer
          components={this.state.header}
          milesLogin={milesLogin ? milesLoginPSW : ''}
        />
      )
    )
  }

  render() {
    // milesLogin is info that comes from Sabre. memberInfo comes from PSW.
    const { login, loginFetching, landings, location } = this.props
    const isLandingPage = (landings || []).find(
      ({ url }) => url === getLandingsRelativePath(location.pathname)
    )

    return (
      <Fragment>
        {this.props.showCookiesMessage && (
          <MessageCookies
            onButtonClick={this.props.onButtonClick}
            acceptEssentialCookies={this.props.acceptEssentialCookies}
            showCookiesMessage={this.props.showCookiesMessage}
          />
        )}
        {this.renderHeader()}
        {login && loginFetching && <TransitionLoader />}
        <main id="main">{this.props.children}</main>
        {!isRouteWithoutFooter(location) && <Footer isLandingPage={!isEmpty(isLandingPage)} />}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  login: getLogin(state),
  loginFetching: getLoginIsFetching(state),
  landings: getUrlLandings(state),
  milesLogin: getProfileData(state),
  memberInfo: getAccountEvaluation(state),
  isMobile: isMobileOrTablet(mapMediaType(state)),
  isSublosSessionExpired: isSublosSessionExpiredError(state)
})
const mapDispatchToProps = {
  fetchMilesLoginData,
  accountEvaluationGet
}
export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PageContainer))
)
