import appLink from './appLink'
import arPlus from './arPlus'
import checkout from './checkout'
import common from './common'
import contests from './contests'
import corporateAgreements from './corporateAgreements'
import events from './events'
import exchanges from './exchanges'
import fiscalCredit from './fiscalCredit'
import flightOffers from './flightOffers'
import home from './home'
import internationalRefundForm from './internationalRefundForm'
import myReservation from './myReservation'
import newsletter from './newsletter'
import passengers from './passengers'
import payment from './payment'
import refund from './refund'
import scheduledFlights from './scheduledFlights'
import searchBox from './searchBox'
import sublos from './sublos'
import usaForms from './usaForms'

export default {
  ...appLink,
  ...arPlus,
  ...checkout,
  ...common,
  ...contests,
  ...corporateAgreements,
  ...events,
  ...exchanges,
  ...fiscalCredit,
  ...flightOffers,
  ...home,
  ...myReservation,
  ...newsletter,
  ...passengers,
  ...payment,
  ...refund,
  ...scheduledFlights,
  ...searchBox,
  ...sublos,
  ...usaForms,
  ...internationalRefundForm
}
