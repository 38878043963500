export default {
  'label.no-suggestions-found': 'No se encontraron resultados que coincidan con {{term}}',
  'label.one-way-trip': 'Ida',
  'label.return-trip': 'Vuelta',
  'label.from': 'Desde',
  'label.to': 'Hasta',
  'label.round-trip': 'Ida y vuelta',
  'label.multidestiny-trip': 'Multidestinos',
  'label.full-date': '{{dayName}} de {{monthYear}}',
  'label.more-information': 'Más Información',
  'label.no-flights': 'Sin vuelos',
  'label.leg': 'Tramo',
  'label.leg-with-number': 'TRAMO {{legNumber}}',
  'label.one-way-trip.uppercase': 'IDA',
  'label.return-trip.uppercase': 'VUELTA',
  'label.adult': 'Adulto',
  'label.adult.other': 'Adultos',
  'label.child': 'Niño',
  'label.child.other': 'Niños',
  'label.infant': 'Bebé',
  'label.infant.other': 'Bebés',
  'label.passenger': 'Pasajero',
  'label.passenger.other': 'Pasajeros',
  'label.passenger-amount': '{{count}} Pasajero',
  'label.passenger-amount.other': '{{count}} Pasajeros',
  'label.adult-amount': '{{count}} Adulto',
  'label.adult-amount.other': '{{count}} Adultos',
  'label.child-amount': '{{count}} Niño',
  'label.child-amount.other': '{{count}} Niños',
  'label.infant-amount': '{{count}} Bebé',
  'label.infant-amount.other': '{{count}} Bebés',
  'label.from.error-message': 'No olvides ingresar el origen',
  'label.to.error-message': 'No olvides ingresar el destino',
  'label.date-from.error-message': 'Ingresa una fecha válida',
  'label.date-to.error-message': 'Ingresa una fecha válida',
  'label.yes': 'Sí',
  'label.no': 'No',
  'label.of': 'de',
  'label.next': 'Siguiente',
  'label.buy': 'Comprar',
  'label.accept': 'Aceptar',
  'label.accept-only-essential-cookies': 'Aceptar solo las esenciales',
  'label.cancel': 'Cancelar',
  'label.home': 'Volver a la página de inicio',
  'label.close-account': 'Dar de baja',
  'label.select': 'Seleccionar',
  'label.selected': 'Seleccionado',
  'label.no-options': 'No hay opciones',
  'label.itinerary': 'Itinerario',
  'label.zip-code': 'Código postal',
  'label.phone': 'Teléfono',
  'label.apartment': 'Depto',
  'label.floor': 'Piso',
  'label.number': 'Número',
  'label.telephone-number': 'Número de Teléfono',
  'label.street-name': 'Nombre de la calle',
  'label.house': 'Casa',
  'label.street': 'Calle',
  'label.city': 'Ciudad',
  'label.city-placeholder': 'Ingresá la ciudad',
  'label.country': 'País',
  'label.province': 'Provincia',
  'label.state-province-department': 'Estado / Provincia / Depto',
  'label.address': 'Domicilio',
  'label.name': 'Nombre',
  'label.lastname': 'Apellido',
  'label.dni': 'DNI',
  'label.passport': 'Pasaporte',
  'label.email': 'Email',
  'label.cellphone': 'Celular',
  'label.fullName': 'Nombre y Apellido',
  'label.area-code': 'Cód. Área',
  'label.document-type': 'Tipo de Documento',
  'label.document-number': 'Número de Documento',
  'label.go-back-to-myb-message': 'Volver a mi reserva',
  'label.cuil': 'CUIL',
  'label.cuit': 'CUIT',
  'label.cdi': 'CDI',
  'label.cuitfact': 'CUIT Empresa',
  'label.nonres': 'No Residente',
  'label.nit': 'NIT',
  'label.ruc': 'RUC',
  'label.undeclared': 'NO DECLARA',
  'label.months-0': 'enero',
  'label.months-1': 'febrero',
  'label.months-2': 'marzo',
  'label.months-3': 'abril',
  'label.months-4': 'mayo',
  'label.months-5': 'junio',
  'label.months-6': 'julio',
  'label.months-7': 'agosto',
  'label.months-8': 'septiembre',
  'label.months-9': 'octubre',
  'label.months-10': 'noviembre',
  'label.months-11': 'diciembre',
  'label.months-short-0': 'ene',
  'label.months-short-1': 'feb',
  'label.months-short-2': 'mar',
  'label.months-short-3': 'abr',
  'label.months-short-4': 'may',
  'label.months-short-5': 'jun',
  'label.months-short-6': 'jul',
  'label.months-short-7': 'ago',
  'label.months-short-8': 'sep',
  'label.months-short-9': 'oct',
  'label.months-short-10': 'nov',
  'label.months-short-11': 'dic',
  'label.weekdays-0': 'Domingo',
  'label.weekdays-1': 'Lunes',
  'label.weekdays-2': 'Martes',
  'label.weekdays-3': 'Miércoles',
  'label.weekdays-4': 'Jueves',
  'label.weekdays-5': 'Viernes',
  'label.weekdays-6': 'Sábado',
  'label.weekdays-short-0': 'Dom',
  'label.weekdays-short-1': 'Lun',
  'label.weekdays-short-2': 'Mar',
  'label.weekdays-short-3': 'Mié',
  'label.weekdays-short-4': 'Jue',
  'label.weekdays-short-5': 'Vie',
  'label.weekdays-short-6': 'Sáb',
  'label.weekdays-letter-0': 'D',
  'label.weekdays-letter-1': 'L',
  'label.weekdays-letter-2': 'M',
  'label.weekdays-letter-3': 'M',
  'label.weekdays-letter-4': 'J',
  'label.weekdays-letter-5': 'V',
  'label.weekdays-letter-6': 'S',
  'label.month': 'Mes',
  'label.year': 'Año',
  'label.miles': 'Millas',
  'label.pay-with-miles': 'Pagar en Millas',
  'label.miles.ffcurrency': '{{amount}} Millas',
  'label.miles.lcurrency': '{{currency}} {{amount}}',
  'label.flight-summary': 'Resumen de tu vuelo',
  'label.paid-seat-selection': 'Resumen de asientos',
  'label.warning': 'Atención',
  'label.important-colon': 'Importante:',
  'label.processing-request': 'Estamos procesando tu solicitud.',
  'label.view-itinerary': 'Ver itinerario',
  'label.hide-itinerary': 'Ocultar itinerario',
  'label.booking-summary': 'Resumen de tu reserva',
  'label.see-details': 'Ver Detalle',
  'label.hide-details': 'Ocultar Detalle',
  'label.login': 'INGRESAR',
  'label.full-date-with-year': '{{day}} de {{month}} de {{year}}',
  'label.reservation-code': 'Código de reserva',
  'label.we-are-sorry': '¡Lo sentimos!',
  'label.currencies.local-currency': '{{localCurrency}} {{amount}}',
  'label.currencies.ffcurrency': '{{miles}} Millas',
  'label.currencies.both-currencies': '{{miles}} Millas + {{localCurrency}} {{amount}}',
  'label.all': 'Todos',
  'label.expire-day': 'Día de vencimiento',
  'label.expire-month': 'Mes de vencimiento',
  'label.expire-year': 'Año de vencimiento',
  'label.airline': 'Aerolinea',
  'label.document-passport': 'Número de documento/pasaporte',
  'label.assist-card-select': 'Contratar asistencia',
  'general.assist.card.ensure-your-trip': '¡Asegurá tu viaje con Assist Card!',
  'general.assist.card.ensure-your-trip-subtitle':
    'Contratá tu asistencia de viaje hoy mismo y viajá con tranquilidad.',
  'general.validation.remaining-characters': 'Restan {{value}} caracteres',
  'general.validation.remaining-character': 'Resta {{value}} carácter',
  'general.validation.address-city.required': 'Debes indicar una ciudad.',
  'general.validation.address-city.pattern': 'Verificá que la ciudad indicada sea válida.',
  'general.validation.address-street.required': 'No olvides indicar la calle.',
  'general.validation.complete-address-street.required':
    'No olvides ingresar la dirección completa.',
  'general.validation.address-street.pattern': 'Verificá que la calle sea válida.',
  'general.validation.address-zipcode.required': 'No olvides indicar el Código Postal.',
  'general.validation.address-zipcode.pattern': 'Verificá que sea un dato válido.',
  'general.validation.address-province.required': 'No olvides indicar la Provincia.',
  'general.validation.email.pattern': 'Verificá que sea un email válido.',
  'general.validation.email.required': 'Debes ingresar un correo electrónico para contactarte.',
  'general.validation.country-code.required': 'Debes indicar un país.',
  'general.validation.country.required': 'No olvides indicar el país.',
  'general.validation.area-code.required': 'Debes ingresar el cód. área.',
  'general.validation.province.required': 'Debes indicar una provincia.',
  'general.validation.phone-number.required': 'No olvides indicar el número.',
  'general.validation.phone-number.pattern': 'Verificá que el teléfono contenga sólo números.',
  'general.validation.terms-and-conditions.required':
    'No olvides aceptar las condiciones de reserva y equipaje permitido.',
  'general.validation.terms-and-conditions-short.required':
    'No olvides aceptar los términos y condiciones.',
  'general.validation.full-name.pattern':
    'Verificá que el nombre y apellido contengan sólo letras.',
  'general.validation.full-name.required': 'No olvides ingresar el nombre y apellido.',
  'general.validation.relationship.pattern': 'Verificá que el parentesco contenga sólo letras.',
  'general.validation.relationship.required': 'No olvides indicar el parentesco.',
  'general.validation.first-name.required': 'No olvides indicar el nombre del pasajero.',
  'general.validation.first-name.pattern': 'Verificá que el nombre contenga sólo letras.',
  'general.validation.first-name.length': 'El nombre debe tener entre 2 y 30 caracteres.',
  'general.validation.last-name.required': 'No olvides indicar el apellido del pasajero.',
  'general.validation.last-name.pattern': 'Verificá que el apellido contenga sólo letras.',
  'general.validation.last-name.length': 'El Apellido debe contener entre 2 y 29 caracteres.',
  'general.validation.document-type-pattern': 'El tipo de documento ingresado es inválido',
  'general.validation.document-type-required': 'Debes indicar el tipo de documento.',
  'general.validation.document-number-required': 'No olvides indicar el número de documento.',
  'general.validation.document-number-pattern': 'Debes ingresar un número de documento válido.',
  'general.header-help-center': 'Centro de ayuda',
  'general.header-login': '    Iniciá sesión       Aerolíneas Plus',
  'general.header-sign-up': 'o Registrate',
  'general.header-choose-country': 'Selecciona tu país de residencia',
  'general.header.language-selector.title': 'Selecciona tu país de residencia',
  'general.header.enter-these-sections': 'Para ingresar a estas secciones, necesitás ',
  'general.header.sign-up': 'registrate ',
  'general.header.login': ' iniciar sesión.',
  'general.header.hello': 'Hola ',
  'general.header.or': 'o',
  'general.header.skip': 'Ir a contenido principal',
  'general.cookies-message.this-site':
    'Este sitio utiliza cookies para ofrecer una mejor experiencia de navegación. Hacé ',
  'general.cookies-message.click-here.url': `<a href="https://www.aerolineas.com.ar/legales/terminos-y-condiciones">click aquí</a>`,
  'general.cookies-message.policy': ' para conocer la política de cookies.',
  'general.cookies-message.accepted': 'Se han guardado tus preferencias en cuanto a cookies. ',
  'general.dark-site.button.press-notice': 'Ver comunicado de prensa',
  'general.dark-site.title.press-notice': 'Comunicado de Prensa',
  'general.dark-site.title.press-notice-english': 'Press release',
  'general.dark-site.go-back': 'Volver',
  'general.footer.call-center': 'Centro de ayuda | Call Center: 54 (11) 5199 3555',
  'general.footer.phone-label': 'Teléfono',
  'general.footer.legal-and-conditions': 'Legales y Condiciones Generales de Transporte',
  'general.footer.official-site': 'Sitio Oficial de Aerolineas Argentinas S.A.',
  'general.footer.find-us': 'Encontranos',
  'general.footer.institutional': 'Institucional',
  'general.footer.download-app': '¡Descargá la app mobile!',
  'general.footer.best-promotions': '',
  'general.footer.best-financing': 'La mejor financiación',
  'general.footer.see-more-banks': `<a href="https://www.aerolineas.com.ar/es-ar/reservasservicios/bancos" target="_blank">Ver más bancos</a>`,
  'general.footer.help-center':
    'Encontrá toda la información que necesitás en nuestro centro de ayuda.',
  'general.footer.national-flights': 'Volá por Argentina',
  'general.footer.international-flights': 'Volá por el mundo',
  'general.footer.regional-flights': 'Volá al Caribe',
  'general.footer.need-help': '¿Necesitas ayuda con tu viaje?',
  'general.footer.call-center-number':
    '54 (11) 5199 3555 Lun a Vie de 8 a 20hs. Sáb, Dom y feriados de 8 a 14 hs.',
  'general.footer.newsletter.suscribe-to-get-our-promos':
    'Suscribite para recibir nuestras promociones',
  'general.footer.newsletter.suscribe-me.url': 'https://www.aerolineas.com.ar/es-ar/newsletter',
  'general.footer.newsletter.suscribe-me.text': 'Suscribirme',
  'general.footer.contact-info.communicate-with-us': 'Comunicate con nosotros',
  'general.footer.contact-info.whatsapp': 'WhatsApp',
  'general.footer.contact-info.whatsapp-number': '+54 11 49404798',
  'general.footer.contact-info.facebook': 'Facebook',
  'general.footer.contact-info.facebook-click-here': `<a href="https://www.facebook.com/aerolineas.argentinas/" target="_blank">haciendo click acá</a>`,
  'general.footer.contact-info.email': 'Email para Agentes de Viaje',
  'general.footer.contact-info.email-click-here': `<a href="mailto:salessupport@aerolineas.com.ar" target="_blank">Ingrese aquí</a>`,
  'general.footer.contact-info.branches-and-airports': 'Sucursales y aeropuertos',
  'general.footer.contact-info.airports-enter-here': `<a href="https://www.aerolineas.com.ar/es-ar/contacto/sucursalesaeropuertos" target="_blank">ingresando acá</a>`,
  'general.page-not-found.main.title': 'Ups',
  'general.page-not-found.main.subline': 'Página no encontrada',
  'general.page-not-found.secondary.title': '¡Lo sentimos!',
  'general.page-not-found.secondary.subline': 'Parece que no pudimos encontrar lo que buscabas.',
  'general.page-not-found.button': 'Ir al inicio',
  'general.error-pages.page-not-found': 'Página no encontrada',
  'general.error-pages.secondary-subline': 'Parece que no pudimos encontrar lo que buscabas.',
  'general.error-pages.oops': 'Ups',
  'general.error-pages.go-to-home-page': 'Ir al inicio',
  'general.error-pages.we-are-sorry': '¡Lo sentimos!',
  'general.error-pages.common-subline':
    'No pudimos encontrar la página. Podés volver al inicio y buscar más vuelos.',
  'general.error-pages.purchase-error':
    'No se pudo realizar tu compra. Podés volver a tus resultados o realizar una nueva búsqueda.',
  'general.re-captcha-title-error': 'Ha ocurrido un error',
  'general.re-captcha-description-error': 'Por favor, refresque la página e intente nuevamente.',
  'general.re-captcha-reload-button': 'Refrescar',
  'general.error-pages.my-results-button': 'Mis resultados',
  'general.error-pages.home-button': 'Buscar vuelos',
  'general.error-pages.something-went-wrong': '¡Algo salió mal!',
  'general.error-pages.try-later': 'Te pedimos disculpas, volvé a intentar más tarde.',
  'general.error-pages.booking-secondary-button': 'Ir a mi reserva',
  'general.error-pages.booking-message': 'No se pudo completar tu selección',
  'general.error.insufficient-miles': 'Millas insuficientes para realizar la compra',
  'general.date-picker.date': 'Fecha',
  'general.date-picker.date-format': 'dd/mm/aaaa',
  'general.date-picker.date-from': 'Partida',
  'general.date-picker.date-to': 'Regreso',
  'general.date-picker.choose-travel-dates': 'Seleccioná tus fechas de viaje',
  'general.date-picker.birthday': 'Seleccioná tu fecha de nacimiento',
  'general.date-picker.expiration-date': 'Seleccioná la fecha de expiración',
  'general.flight-status.error.flight-number-not-available':
    '¡Lo sentimos! El estado del vuelo {{flightNumber}} no está disponible.',
  'general.flight-status.error.flight-schedule-not-available': `¡Lo sentimos! El estado del vuelo no está disponible.`,
  'general.flight-status.error.call-for-more-info':
    'Por favor contáctate al número de Whatsapp +54 9 11 4940 4798 para obtener más información.',
  'general.flight-status.error.check-email-for-more-info':
    'En este momento no hemos podido obtener la información de su vuelo. Recoerdá revisar tu casilla de mails ya que todo cambio es informado vía correo electrónico. Cualquier duda contactate con nuestro Call Center al 54 (11) 5199 3555.',
  'general.flight-status.search.route': 'Ruta',
  'general.flight-status.search.submit': 'Consultar',
  'general.flight-status.search.look-for-flight-status': 'Consultá el estado de vuelo',
  'general.flight-status.search.flight-number': 'Número de vuelo',
  'general.flight-status.search.flight-number-short': 'N° de vuelo',
  'general.flight-status.search.flight-number-example': '1234',
  'general.flight-status.banner.title': 'Estado de tu vuelo',
  'general.flight-status.banner.search': 'Estos son los resultados de tu búsqueda.',
  'general.flight-status.flight-itinerary.duration': 'Duración {{hours}} hs.',
  'general.flight-status.flight-itinerary.terminal': 'Terminal',
  'general.flight-status.flight-itinerary.gate': 'Puerta',
  'general.flight-status.flight-itinerary.see-status': 'VER ESTADO',
  'general.flight-status.flight-itinerary.hide-status': 'OCULTAR ESTADO',
  'general.flight-status.search.validation.flight-number.required': 'Ingrese número de vuelo',
  'general.flight-status.search.validation.flight-date.required': 'Ingrese una fecha válida',
  'general.flight-status.search.validation.origin.required': 'Ingrese origen',
  'general.flight-status.search.validation.destination.required': 'Ingrese destino',
  'general.itinerary.take-off': 'Sale',
  'general.itinerary.arrives': 'Llega',
  'general.itinerary.details': 'Detalle de itinerario',
  'general.itinerary.first-scale': '1º escala en',
  'general.itinerary.waiting-time': 'de espera',
  'general.itinerary.airport-change': 'cambio de aeropuerto',
  'general.itinerary.airplane-change': 'cambio de avión',
  'general.itinerary.class': 'Clase',
  'general.itinerary.cabin': 'Cabina',
  'general.itinerary.rate': 'Tarifa',
  'general.itinerary.booking-data': 'Datos de tu reserva',
  'general.itinerary.class-combination': 'Combinación de Clases',
  'general.modal-time-out.title': 'Se ha excedido tu tiempo de espera.',
  'general.modal-time-out.back-button': 'Volver a resultados',
  'general.modal-time-out.new-search-button': 'Nueva búsqueda',
  'general.loader.request': 'Por favor, no cierres esta pantalla mientras trabajamos en tu pedido.',
  'general-loader.title.request': 'Estamos procesando tu solicitud.',
  'general.fares.base-fare': 'Tarifa',
  'general.fares.ancillaries': 'Adicionales',
  'general.fares.adt': 'Importe por adulto',
  'general.fares.chd': 'Importe por niño',
  'general.fares.inf': 'Importe por infante',
  'general.fares.taxes': 'Tasas e Impuestos',
  'general.fares.quantity': 'Cantidad',
  'general.fares.fare': 'Tarifa',
  'general.fares.surcharges': 'Cargos',
  'general.fares.discount': 'Descuentos',
  'general.fares.financial-cost': 'Costo Financiero',
  'general.payment-box.credit-card-number': 'Número:',
  'general.payment-box.operation-number': 'Número de operación:',
  'general.payment-box.pay-reservation': 'Pagá tu reserva',
  'general.payment-box.total-fare': 'Precio total final ',
  'general.payment-box.amount': 'Monto:',
  'general.payment-box.installment-two-cards': ' en 1 cuota',
  'general.payment-box.tax-receipt-link': '¿Necesitás constancia de crédito fiscal?',
  'general.payment-box.offline-payment-label': 'Éste es tu código para abonar tu compra.',
  'general.payment-box.payment-in-cash': 'Pago en efectivo por',
  'general.payment-box.installment': '{{count}} cuota',
  'general.payment-box.installment.other': '{{count}} cuotas',
  'general.payment-box.creditCard': 'tarjeta de crédito {{card}}',
  'general.payment-box.debitCard': 'tarjeta de débito {{card}}',
  'general.payment-box.paymentWith': 'Pago con',
  'general.payment-box.travel-assistance': 'Assist Card',
  'general.payment-box.download-policy': 'Descargar póliza',
  'general.payment-box.see-detail': 'Ver detalle de cobertura',
  'general.payment-box.paymentWith.two-cards': ' 2 Tarjetas',
  'general.payment-box.paymentIn': 'Pago en',
  'general.payment-box.fixed': 'fija',
  'general.payment-box.fixed.other': 'fijas',
  'general.payment-box.interest': 'Interés: {{interestRate}}% = ${{totalInterest}}',
  'general.payment-box.no-interest': 'Interés: -',
  'general.payment-box.interestFree': 'sin interés',
  'general.payment-box.installments-of': 'Cuota de',
  'general.payment-box.installments-of.other': 'Cuotas de',
  'general.payment-box.travel-assistance.error':
    'Tu producto se encuentra en proceso de emisión. Te enviaremos el E-Voucher al correo asociado a tu reserva en las próximas 24hs hábiles',
  'general.purchase-info.exchanges-old-itinerary': 'Vuelo original',
  'general.purchase-info.exchanges-new-itinerary': 'Nuevo itinerario',
  'general.purchase-info.booking-detail': 'Detalle de reserva',
  'general.purchase-info.continue-watching-fares': 'Seguir viendo tarifas',
  'general.purchase-info.total-fare': 'Precio total final',
  'general.purchase-info.with-discount-fare.label': 'Ver tarifa con descuento',
  'general.purchase-info.without-discount-fare.label': 'Ver tarifa sin descuento',
  'general.purchase-detail.keep-rate': 'Mantener Tarifa por {{time}} hs.',
  'general.purchase-detail.purchase-information-title': 'Información de tu compra',
  'general.purchase-detail.reservation-rate.pay-now': 'Aboná ahora',
  'general.purchase-detail.reservation-rate.keep-flight-rate-until':
    'y tu reserva se mantendrá hasta el',
  'general.purchase-detail.reservation-rate.at': 'a las',
  'general.purchase-detail.reservation-rate.hours-short': 'hs.',
  'general.purchase-detail.reservation-rate.due-date': '{{day}} de {{month}}',
  'general.purchase-detail.reservation-rate.subtitle': `Reservar por {{timeLimit}} hs.`,
  'general.purchase-detail.reservation-rate.free-price': 'Gratis',
  'general.ancillaries-selection.title': 'Servicios Adicionales',
  'general.ancillaries-close.section': 'Cerrar sección Servicios Adicionales',
  'general.ancillaries-purchase.title': 'Pagar mi reserva',
  'general.travel-assistance.title': 'Selecciona tu producto',
  'travel-assistance.payment-link.voucher-issued-error-title': 'Tu servicio ya está contratado',
  'travel-assistance.payment-link.voucher-issued-error':
    'Esta reserva ya posee asistencia de viajes contratada.',
  'general.ancillaries-purchase.side-bar-passengers': 'Servicios Adicionales',
  'general.ancillaries-selection.error-message':
    'Si ya realizaste el Check-in online, podrás realizar la compra de servicios adicionales para ese vuelo a través de nuestro <a href="https://api.whatsapp.com/send?phone=541149404798">WhatsApp</a> o en el aeropuerto al valor correspondiente. Para mayor información consulte <a href="https://www.aerolineas.com.ar/es-ar/equipaje/equipaje_adicional">aquí</a>',
  'general.ancillaries-selection.BG.error-message':
    'Lo sentimos, actualmente el servicio de Equipaje Adicional no está disponible.',
  'general.ancillaries-selection.SP.error-message':
    'Lo sentimos, actualmente el servicio de Equipaje Especial no está disponible.',
  'general.ancillaries-selection.LG.error-message':
    'Lo sentimos, actualmente el servicio de Salón Cóndor no está disponible.',
  'general.ancillaries-purchase.not-all-successful-alert':
    'En este momento no es posible realizar la compra de los siguientes adicionales seleccionados:',
  'general.ancillaries-purchase.not-successful-alert':
    'En este momento no es posible realizar la compra de los adicionales seleccionados',
  'general.ancillaries.group-BG-title': 'Equipaje adicional',
  'general.ancillaries.group-SP-title': 'Equipaje especial',
  'general.ancillaries.group-LG-title': 'Salón Cóndor (Ezeiza)',
  'general.ancillaries.group-EM-title': 'Equipaje de mano',
  'general.ancillaries.group-TS-title': 'Asistencia de viaje',
  'general.ancillaries.logo-assist-card.alt': 'Assist card ancillaries',
  'general.ancillaries.assist-card.e-voucher': 'Descargar E-Voucher',
  'general.ancillaries.assist-card.logo-title': 'Viajá con Assist Card',
  'general.ancillaries.assist-card.logo-description': 'Seleccioná el plan perfecto para tu viaje',
  'general.ancillaries.assist-card.see-policy': 'Ver políticas',
  'general.ancillaries.assist-card.see-policy.url':
    'https://ecommerceapi.assistcard.com/Api/DocumentHandler/GetDocument?recordId=184',
  'general.ancillaries.assist-card.final-total-price': 'Precio total final',
  'general.ancillaries.assist-card.button.remove-coverage': 'Eliminar asistencia',
  'general.ancillaries.assist-card.button.Select-coverage': 'Seleccionar',
  'general.ancillaries.assist-card.see-coverage-details': 'Ver detalle de cobertura',
  'general.ancillaries.assist-card-contact': 'Contactar a Assist Card:',
  'general.ancillaries-banner.improve-your-experience': '¡Mejorá la experiencia de tu vuelo!',
  'general.ancillaries-banner.improve-your-experience-subtitle':
    'Podés elegir valijas adicionales, equipaje especial y acceso al salón VIP.',
  'general.reservation-header-close.section': 'Cerrar sección Solicitud de cambio de vuelo',
  'general.terms-and-conditions.i-have-read-accept': 'He leído y acepto',
  'general.terms-and-conditions.fare-conditions': 'las condiciones de tarifa',
  'general.terms-and-conditions.booking-conditions': 'las condiciones de reserva',
  'general.terms-and-conditions.and': 'y',
  'general.terms-and-conditions.allowed-baggage': 'equipaje permitido',
  'general.terms-and-conditions.knowledge': 'Conozco la información sobre',
  'general.terms-and-conditions.assistance': 'Asistencias',
  'general.faq.menu.most-frequent-questions': 'Consultas más frecuentes',
  'general.faq.search.how-can-we-help': '¿Cómo podemos ayudarte?',
  'general.faq.search.search-by-key': 'Buscá por palabra clave o frase…',
  'general.faq.menu.go-back-to-help-center': 'Volver al centro de ayuda',
  'general.reservation-alert.success-message':
    'Tu ticket se emitió correctamente. A continuación te mostramos el detalle. ¡Gracias por elegir Aerolíneas Argentinas!',
  'general.reservation-alert.pending-payment-message': `Tu reserva está pendiente de ser abonada. ¡No olvides hacerlo!
  <strong>Precio total final: {{totalAmount}}.</strong>`,
  'general.reservation-alert.pending-payment-message.date-time': `Tu reserva está pendiente de ser abonada. ¡No olvides hacerlo!
      <strong>Tenés tiempo hasta el {{date}} a las {{time}} hs para pagar.</strong>
  <strong>Precio total final: {{totalAmount}}.</strong>`,
  'general.reservation-alert.bnpl-payment-message': '¡No olvides pagar tu reserva!',
  'general.reservation-alert.bnpl-payment-message.date-time': `¡No olvides pagar tu reserva! <strong>La misma vence el dia {{date}} a las {{time}} hs.</strong>`,
  'general.reservation-alert.exchange-success-message':
    'Tu cambio fue procesado exitosamente. Te enviaremos los tickets a tu email.',
  'general.reservation-alert.exchange-ancillaries-warning':
    'El cambio de fecha de tus vuelos ya ha sido realizado, pero por favor comunicate con nosotros al WhatsApp <strong>+54 11 49404798</strong> para confirmar tus productos opcionales. Muchas gracias.',
  'general.reservation-alert.delayed-segment':
    'Alguno de los tramos no está confirmado, por favor contáctate al número de WhatsApp +54 9 11 4940 4798.',
  'general.reservation-alert.cancelled-segment.singular':
    'El vuelo <strong>{{cancelledFlightsNumbersMsg}}</strong> está cancelado. Por favor elegí la opción de Cambio de vuelo para poder autogestionar tu nuevo vuelo.',
  'general.reservation-alert.cancelled-segment.other':
    'Los vuelos <strong>{{cancelledFlightsNumbersMsg}}</strong> están cancelados. Por favor elegí la opción de Cambio de vuelo para poder autogestionar tu nuevo vuelo.',
  'general.reservation-alert.unticketed':
    '<b>¡Lo sentimos!</b> Tuvimos un inconveniente para terminar de concretar tu reserva. Por favor contáctate al número de WhatsApp +54 9 11 4940 4798',
  'general.reservation-alert.not-issued.default':
    'Tu reserva no está emitida, por favor contáctate al número de WhatsApp +54 9 11 4940 4798',
  'general.reservation-alert.not-issued.missing-info':
    'La reserva aún no se ha emitido. <strong>Por favor, complete los datos faltantes para comprar su vuelo.</strong>',
  'general.reservation-alert.not-issued.missing-info-button': 'Finalizar reserva',
  'general.reservation-alert.ancillaries-purchase':
    'Tu compra fue procesada exitosamente. Te enviaremos el ticket a tu mail.',
  'general.reservation-alert.pay-booking': 'Pagar reserva',
  'general.reservation-alert.booking-status.is-external': `Para compra de servicios adicionales (equipaje adicional,
        equipaje especial, Acceso al Salón Cóndor, etc.) por favor hacé click
        <a href={{url}}>acá</a>`,
  'general.reservation-alert.failed-seat-selection':
    'Lo sentimos, no pudimos seleccionar tus asientos. Ingresá a tu reserva para hacerlo sin costo. Tené en cuenta las indicaciones si viajás con infantes.',
  'general.booking.reservation-code.title': 'Código de tu reserva',
  'general.booking-loader.do-not-close-page':
    'Por favor, no cierres esta pantalla mientras cargamos la reserva.',
  'general.confirmation-itinerary-header': 'Tu itinerario',
  'general.passengers-info.title': 'Pasajeros',
  'general.passengers-info.seats-label': 'Asiento',
  'general.passengers-info.no-seat-selected-label': 'Sin selección',
  'general.passengers-info.some-seats-not-selected-label': 'Hay tramos sin asientos seleccionados',
  'general.passengers-info.leg-label': ' / {{origin}} - {{destination}}',
  'general.passengers-info.seat-selection.leg-label': ' / {{origin}} - {{destination}}',
  'general.passengers-info.unavailable-data': 'No informado',
  'general.passengers-info.blue.frequent.number': 'Número de Pasajero Frecuente',
  'general.passengers-info.blue.ticket.number': 'Número de Ticket',
  'general.email-administrator.input-placeholder': 'Este correo quedará asociado a tu reserva.',
  'general.email-administrator.validation.email-pattern': 'Por favor ingrese un email valido',
  'general.email-administrator.title': 'Mail asociado a tu reserva',
  'general.email-administrator.description': 'Te enviaremos las novedades de tu vuelo a:',
  'general.email-administrator.add-email-checking': 'Comprobando...',
  'general.email-administrator.add-email-error':
    'Disculpas. No hemos podido agregar este mail. Intentá más tarde.',
  'general.email-administrator.add-email-success':
    'El correo electronico que agregaste fue añadido exitosamente',
  'general.email-administrator.add-email': 'Agregar otro email',
  'general.sticky-footer.total': 'Total',
  'general.sticky-footer.all-passengers-included': 'Todos los pasajeros incluidos',
  'general.sticky-footer.show-link': 'Mostrar detalle',
  'general.sticky-footer.hide-link': 'Ocultar detalle',
  'general.sticky-footer.additional-services': 'Servicios adicionales',
  'general.sticky-footer.mobile-rate-modal.title': 'Desglose de tarifa',
  'general.sticky-footer.with-discount-fare.label': 'Ver tarifa con descuento',
  'general.sticky-footer.without-discount-fare.label': 'Ver tarifa sin descuento',
  'general.sticky-footer.seat-selection': 'Asientos',
  'general.400-error-page.main.title': 'Ups',
  'general.400-error-page.secondary.title': '¡Lo sentimos!',
  'general.400-error-page.default-message': 'Ocurrió un error inesperado',
  'general.400-error-page.button': 'Ir al inicio',
  'general.terms-conditions-modal.title': 'Términos y Condiciones',
  'general.terms-conditions-modal-link': 'Términos y Condiciones',
  'general.flight-offers.fare-conditions': 'Condiciones de Tarifa',
  'general.flight-offers.fare-conditions.compare-conditions': 'Comparar condiciones',
  'general.flight-offers.fare-conditions.empty': 'Condiciones de Tarifa no disponibles',
  'general.flight-offers.fare-conditions.information': 'Información para que tengas en cuenta',
  'general.flight-offers.fare-conditions.with-seat-selection': 'Con selección de asientos',
  'general.flight-offers.fare-conditions.without-seat-selection': 'Sin selección de asientos',
  'general.flight-offers.fare-conditions.with-checked-baggage': 'Incluye equipaje de bodega',
  'general.flight-offers.fare-conditions.without-checked-baggage': 'No incluye equipaje de bodega',
  'general.flight-offers.fare-conditions.with-carry-on-baggage': 'Incluye equipaje de mano',
  'general.flight-offers.fare-conditions.without-carry-on-baggage': 'No incluye equipaje de mano',
  'general.flight-offers.fare-conditions.with-exchanges': 'Con cambio de pasajes',
  'general.flight-offers.fare-conditions.without-exchanges': 'Sin cambio de pasajes',
  'general.flight-offers.fare-conditions.with-miles': 'Sumás millas Aerolíneas Plus',
  'general.flight-offers.fare-conditions.without-miles': 'No sumás millas Aerolíneas Plus',
  'general.flight-offers.fare-conditions.with-ticket-return': 'Con devolución de pasajes',
  'general.flight-offers.fare-conditions.without-ticket-return': 'Sin devolución de pasajes',
  'general.flight-offers.fare-conditions.seat-selection': 'Selección de asiento',
  'general.flight-offers.fare-conditions.checked-baggage': 'Equipaje en bodega',
  'general.flight-offers.fare-conditions.carry-on-baggage': 'Equipaje de mano',
  'general.flight-offers.fare-conditions.exchanges': 'Cambio de pasajes',
  'general.flight-offers.fare-conditions.ticket-returns': 'Devolución de pasajes',
  'general.flight-offers.fare-conditions.miles': 'Millas Aerolíneas Plus',
  'general.flight-offers.fare-conditions.disclaimer': 'Condiciones previas al 2 de septiembre',
  'general.flight-offers.fare-conditions.sky-priority.enjoy': 'Disfrutá de',
  'general.flight-offers.fare-conditions.sky-priority': 'Sky Priority',
  'general.flight-offers.fare-conditions.vipLounge': 'Salón VIP',
  'general.flight-offers.fare-conditions.with-vipLounge': 'Incluye salón VIP',
  'general.flight-offers.fare-conditions.without-vipLounge': 'No incluye salón VIP',
  'general.search-box.destination': 'Destino',
  'general.search-box.origin': 'Origen',
  'general.press-releases.card-summary.button.read-statement': 'Leer Comunicado',
  'general.press-releases.nav-bar.main-text': 'Noticias SkyTeam',
  'general.press-releases.nav-bar.go-back-text': 'Volver a los comunicados',
  'general.press-releases.main-banner.title': 'Comunicados de prensa',
  'general.press-releases.search-input.placeholder': 'Buscar comunicado',
  'general.press-releases.press-releases-pager.button.prev': 'Anterior',
  'general.press-releases.press-releases-pager.button.next': 'Siguiente',
  'general.press-releases.single-press-release.not-found.title': 'Lo sentimos!',
  'general.press-releases.single-press-release.not-found.text':
    'No pudimos encontrar el comunicado de prensa.',
  'general.flight-offer-card.no-stops': 'Sin escalas',
  'general.flight-offer-card.stops-amount': '{{count}} escala',
  'general.flight-offer-card.stops-amount.other': '{{count}} escalas',
  'general.booking.reservation-link.message': 'Podés administrar tu reserva',
  'general.booking.reservation-link.message-anchor': 'ingresando acá.',
  'general.landing-pages.carousel.see-photos': 'Ver fotos',
  'general.search-box-container.check-in.read-and-agree': 'He leído y acepto las ',
  'general.search-box-container.check-in.conditions': 'condiciones',
  'general.search-box-container.check-in.commodity':
    ' sobre Mercancías peligrosas, Check-in Online y Equipaje de mano.',
  'general.search-box-container.check-in.dont-forget': 'No olvides aceptar las condiciones',
  'general.refund-page.main-banner.title': 'Devolucion de tickets',
  'general.refund-page.info-text':
    'La devolución por arrepentimiento de compra podrás pedirla únicamente:',
  'general.refund-page.info-text.details-0':
    'El botón de arrepentimiento que regula la Resolución 424/20 de la secretaria de Comercio, no aplica a los servicios de transporte aéreo de pasajeros, equipajes y carga, de acuerdo a lo dispuesto por la Resolución Anac 329/2020.',
  'general.refund-page.info-text.details-1':
    'Sólo podrás pedir la devolución dentro del período de validez del ticket (12 meses desde su fecha de emisión).',
  'general.refund-page.info-text.details-2':
    'Sólo se procesarán devoluciones de tickets adquiridos a través de bocas de venta propias de Aerolíneas (web, Call center, WhatsApp, Aeropuertos y Sucursales). Si compraste el ticket en una agencia de viajes, es necesario que la contactes para solicitar la devolución.',
  'general.refund-page.info-text.details-3':
    'La devolución se procesará únicamente si la tarifa emitida lo permite y se aplicarán siempre las penalidades según las condiciones de la tarifa que hubieras adquirido. Tené en cuenta que ante una combinación de distintos tipos de tarifas en un mismo billete, se aplicarán las condiciones de devolución de la tarifa más restrictiva.',
  'general.refund-page.info-text.details-4':
    'Si adquiriste tu ticket vía web deberás completar el formulario seleccionando siempre el país de residencia, que debe coincidir con el elegido al momento de la compra.',
  'general.refund-page.info-text.details-5':
    'Es importante que ingreses tus datos en el formulario con exactitud. En caso de surgir alguna consulta puntual, te contactaremos a tu casilla de e-mail.',
  'general.refund-page.info-text.details-6':
    'La devolución de la percepción a cuenta del Impuesto a los Bienes Personales/Ganancias (S5) que fuera derogada por la RG 5463/23 sólo puede reclamarse a la AFIP.',
  'general.refund-form.feedback.error-title': 'Oops, hubo un error al procesar tu solicitud',
  'general.refund-form.feedback.success-title': '¡Solicitud realizada con éxito!',
  'general.refund-form.feedback.button-text': 'Cerrar',
  'general.refund-form.full-name.label': 'Apellido y nombre de los pasajeros a devolver',
  'general.refund-form.full-name.placeholder': 'Nombre de los pasajeros',
  'general.refund-form.ticket-numbers': '13 números solamente',
  'general.refund-form.ticket-numbers.label': 'Número/s de ticket/s para reembolso',
  'general.refund-form.ticket-numbers.placeholder': 'Número/s de ticket/s',
  'general.refund-form.multiple-inputs-info': 'En caso de ser más de uno separar con una barra “/”',
  'general.refund-form.reservation-code.label': 'Código de reserva',
  'general.refund-form.reservation-code.placeholder': 'Ingresar código',
  'general.refund-form.section-return.label': 'Tramo sobre el que solicita la devolución',
  'general.refund-form.section-return.placeholder': 'Nombre de la ciudad',
  'general.refund-form.payer-name.label': 'Nombre del pagador',
  'general.refund-form.payer-name.placeholder': 'Nombre del pagador',
  'general.refund-form.payer-lastName.label': 'Apellido del pagador',
  'general.refund-form.payer-lastName.placeholder': 'Apellido del pagador',
  'general.refund-form.city.label': 'Lugar de residencia',
  'general.refund-form.city.placeholder': 'Ingresar ciudad',
  'general.refund-form.email.label': 'Email',
  'general.refund-form.email.placeholder': 'Dirección de email',
  'general.refund-form.phone-number.label': 'Teléfono',
  'general.refund-form.phone-number.placeholder': 'Número de teléfono',
  'general.refund-form.observations.label': 'Observaciones',
  'general.refund-form.observations.placeholder': 'Observaciones (máximo 150 caracteres)',
  'general.refund-form.reason-for-return.label': 'Seleccionar',
  'general.refund-form.reason-for-return.label-no-options': 'No hay opciones',
  'general.refund-form.refund-reasons.cancel': 'Cancelación/reprogramación de vuelo involuntario',
  'general.refund-form.refund-reasons.personal-issues': 'Motivo personal',
  'general.refund-form.refund-reasons.other': 'Otros',
  'general.refund-form.refund-reasons.reschedule': 'Reprogramación de vuelo',
  'general.refund-form.refund-reasons.airport-change': 'Cambios de aeropuerto',
  'general.refund-form.refund-reasons.sickness': 'Familiar enfermo',
  'general.refund-form.refund-reasons.death': 'Fallecimiento',
  'general.refund-form.refund-reason': 'Motivo por el cual solicita la devolución',
  'general.refund-form.reservation-code': '6 letras solamente',
  'general.refund-form.full-ticket': 'Billete completo',
  'general.refund-form.disclaimer':
    'Conozco y acepto las posibles penalidades o retenciones de tasas que puedan existir para la devolución del billete.',
  'general.refund-form.disclaimer-contact-text-left':
    '(de lo contrario deberá contactarse a través de nuestro',
  'general.refund-form.disclaimer-contact-text-right':
    'de Lun a Vie de 9 a 18hs o bien al Call Center: 54 (11) 5199 3555 de Lun a Vie de8 a 20hs. Sáb, Dom y feriados de 8 a 14 hs.) (Hora local de Argentina)',
  'general.refund-form.disclaimer-contact-link-whatsapp-text': 'Whatsapp +',
  'general.refund-form.disclaimer-contact-link-whatsapp-number': '54911 4940 4798',
  'general.refund-form.disclaimer-contact':
    '(De lo contrario deberá contactarse con el Call Center local).',
  'general.refund-form.disclaimer-link': 'Condiciones',
  'general.refund-form.submit-button.label': 'Aceptar',
  'general.refund-form.status': 'Estado: ',
  'general.refund-form.status-pending': 'Pendiente ',
  'general.refund-form.status-refund': 'Devuelto ',
  'general.refund-form.status-dismissed': 'Desestimado ',
  'general.refund-form.status-not-found': 'No encontrado ',
  'general.refund-form.status-unknown': 'Desconocido ',
  'general.refund-form.discard-button.label': 'Desestimar',
  'general.refund-form.back-to-home.label': 'Volver al inicio',
  'general.refund-form.dismissed-modal-text':
    'Su solicitud ha sido desestimada. Presioná aceptar para volver a la página principal.',
  'general.refund-form.confirmation-modal-text':
    '¿Estás seguro de que querés desestimar la  solicitud de devolución número {{ refundId }}, relacionada con el/los tickets {{ ticketNumbers }}?',
  'general.refund-form.validation.full-name-required':
    'Debes ingresar el nombre y apellido del pasajero/s',
  'general.refund-form.validation.ticket-numbers-required':
    'Debes ingresar un número/s de ticket/s para reembolso',
  'general.refund-form.validation.reservation-code-required': 'Debes ingresar un código de reserva',
  'general.refund-form.validation.reservation-code-pattern':
    'Solo 6 letras y sin caracteres especiales',
  'general.refund-form.validation.flight-date-required': 'Debes ingresar fecha de vuelo',
  'general.refund-form.validation.refund-reason-required':
    'Debes ingresar un motivo por el cual solicita la devolución',
  'general.refund-form.validation.legs-required':
    'Debes ingresar el tramo sobre el que solicita la devolución',
  'general.refund-form.validation.email-pattern': 'Verificá que sea un email válido.',
  'general.refund-form.validation.email-required':
    'Debes ingresar un correo electrónico para contactarte.',
  'general.refund-form.validation.payer-name-required': 'Debes ingresar el nombre del pagador',
  'general.refund-form.validation.payer-name-pattern': 'Ingresa sólo letras en el nombre',
  'general.refund-form.validation.payer-last-name-required':
    'Debes ingresar el apellido del pagador',
  'general.refund-form.validation.payer-last-name-pattern': 'Ingresa sólo letras en el apellido',
  'general.refund-form.validation.residence-required': 'Debes ingresar el lugar de residencia',
  'general.refund-form.validation.phone-required': 'Debes ingresar un telefono.',
  'general.refund-form.validation.phone-pattern': 'Verificá que el teléfono contenga sólo números.',
  'general.refund-form.validation.accept-conditions-required': 'Debes aceptar las condiciones.',
  'general.refund-form.validation.observations.max-length': 'Debes ingresar 150 caracteres máximo.',
  'general.refund-form.validation.observations-required': 'No olvides indicar un comentario.',
  'general.refund-form.result-success.title': 'Nuestro equipo está procesando tu solicitud.',
  'general.refund-form.result-success.description':
    'Te enviaremos un e-mail con la resolución de tu caso.',
  'general.refund-form.result-success.go-to-home-button': 'Volver a inicio',
  'general.refund-form.plus-partner-number.label': 'Número de Socio Plus',
  'general.refund-form.plus-partner-number.placeholder': 'Número de Socio Plus',
  'general.refund-form.add-passenger': 'Agregar pasajero',
  'general.refund-form.flight-date': 'Fecha de vuelo',
  'general.refund-form.flight-date.placeholder': 'DD/MM/AAAA',
  'general.refund-form.bank-data-description-1':
    'Solicitamos por favor complete los siguientes datos bancarios para poder realizar el reembolso de su dinero, en caso de tener alguna dificultad para reintegrarlo en la forma de pago original.',
  'general.refund-form.bank-data-description-2':
    'Sugerimos copiar/pegar desde la información suministrada por el banco, a fin de evitar errores de tipeo.',
  'general.refund-form.confirmation-email.label': 'Confirmar e-mail',
  'general.refund-form.confirmation-email.placeholder': 'Dirección de e-mail',
  'general.refund-form.cellphone-number.label': 'Teléfono Móvil',
  'general.refund-form.cellphone-number.placeholder': 'Teléfono Móvil',
  'general.refund-form.dni.label': 'DNI del pagador',
  'general.refund-form.dni.placeholder': 'DNI del pagador',
  'general.refund-form.bank-entity.label': 'Entidad bancaria',
  'general.refund-form.bank-entity.placeholder': 'Seleccionar',
  'general.refund-form.cbu-number.label': 'Número de CBU',
  'general.refund-form.cbu-number.placeholder': 'Número de CBU',
  'general.refund-form.alias.label': 'Alias',
  'general.refund-form.alias.placeholder': 'Alias',
  'general.refund-form.account-number.label': 'Número de cuenta',
  'general.refund-form.account-number.placeholder': 'Número de cuenta',
  'general.refund-form.account-type.label': 'Tipo de cuenta (Caja de Ahorro, Cta. Cte.)',
  'general.refund-form.account-type.placeholder': 'Seleccionar',
  'general.refund-form.document-type.label': 'CUIT / CUIL / CDI',
  'general.refund-form.non-resident.label': 'No residente',
  'general.refund-form.modal-refund-error.title': '¡Atención!',
  'refund.form.person-consultation.emails-not-match': 'Los emails ingresados no coinciden.',
  'general.refund-form.modal-refund-error.accept': 'Aceptar',
  'general.refund-form.refund-success.title':
    'Hemos registrado su pedido bajo el número {{requestNumber}}',
  'general.refund-form.refund-success.description':
    'En breve recibirá un mail con el número de su pedido. En función a lo solicitado, analizaremos su caso y procederemos, de corresponder, al reembolso correspondiente. En caso de requerir algún dato adicional, lo estaremos contactando a su mail.',
  'general.refund-form.refund-success.button': 'Volver al inicio',
  'refund.form.document-vcr-validator.ticket-already-exists':
    'El número de ticket ha sido ingresado previamente y pendiente de procesamiento. A la brevedad lo estaremos contactando vía e-mail. Gracias',
  'refund.form.segment-status.not-valid': 'El tramo seleccionado no es válido',
  'refund.form.reason.not-null-or-empty': 'Deberás completar campo motivo de devolución',
  'refund.form.payer-data.not-null': 'Deberás completar campo DNI del pagador',
  'refund.form.bank-data.not-null': 'Deberás completar campo entidad bancaria.',
  'refund.form.unique-identification-key.not-null-or-empty':
    'Deberás completar campo CUIT/CUIL/DNI',
  'refund.form.unique-identification-key.pattern':
    'El campo CUIT deberá tener solo valores numéricos.',
  'refund.form.unique-identification-key.size':
    'El campo CUIT deberá tener 11 caracteres numéricos.',
  'refund.form.passenger-data.fullName.not-null-or-empty':
    'El nombre y apellido deberá estar completo',
  'refund.form.passenger-data.ticket-number.not-null-or-empty':
    'Deberás ingresar el número de ticket completo que comienza con 044 y tiene 13 dígitos.',
  'refund.form.passenger-data.ticket-number.pattern':
    'Deberás ingresar el número de ticket completo que comienza con 044 y tiene 13 dígitos.',
  'refund.form.passenger-data.ticket-number.size':
    'Deberás ingresar el número de ticket completo que comienza con 044 y tiene 13 dígitos',
  'refund.form.passenger-data.reservation-code.not-null-or-empty':
    'Deberás ingresar el código de reserva de 6 letras',
  'refund.form.passenger-data.reservation-code.pattern-or-size':
    'Deberás ingresar el código de reserva de 6 letras',
  'refund.form.payer-data.name.not-null-or-empty': 'Deberás completar campo nombre del pagador.',
  'refund.form.payer-data.name.pattern':
    'Deberás completar campo nombre del pagador solo con letras.',
  'refund.form.payer-data.document-number.not-null-or-empty': 'Deberás completar DNI del pagador.',
  'refund.form.payer-data.residence-place.not-null-or-empty':
    'Deberás completar lugar de residencia del pagador.',
  'refund.form.payer-data.email.not-null-or-empty': 'Deberás completar email del pagador.',
  'refund.form.payer-data.email.invalid-format': 'Deberás ingresar un formato de email válido.',
  'refund.form.payer-data.email-confirmation.not-null-or-empty':
    'Deberás completar email del pagador.',
  'refund.form.payer-data.email-confirmation.invalid-format':
    'Deberás ingresar un formato de email válido.',
  'refund.form.payer-data.phone.not-null-or-empty': 'Deberás completar campo teléfono del pagador.',
  'refund.form.payer-data.phone.pattern':
    'Deberás ingresar valores numéricos en campo teléfono del pagador.',
  'refund.form.payer-data.data.cellphone.not-null-or-empty':
    'Deberás ingresar teléfono móvil del pagador.',
  'refund.form.payer-data.cellphone.pattern':
    'Deberás ingresar valores numéricos en campo teléfono móvil del pagador.',
  'refund.form.bank-data.entity.not-null-or-empty':
    'Deberás ingresar entidad bancaria del pagador.',
  'refund.form.bank-data.entity.pattern':
    'Deberás seleccionar la entidad bancaria correspondiente.',
  'refund.form.bank-data.cbu-number.not-null-or-empty':
    'Deberás ingresar 22 dígitos en el campo CBU.',
  'refund.form.bank-data.cbu-number.pattern':
    'Deberás ingresar sólo 22 dígitos sin caracteres especiales en campo CBU.',
  'refund.form.bank-data.cbu-number.size':
    'Deberás ingresar sólo 22 dígitos sin caracteres especiales.',
  'refund.form.bank-data.alias.not-null-or-empty': 'Deberás completar campo alias',
  'refund.form.bank-data.account-number.not-null-or-empty':
    'Deberás completar sólo caracteres numéricos en campo número de cuenta.',
  'refund.form.bank-data.account-number.pattern':
    'Deberás completar sólo caracteres numéricos en campo número de cuenta.',
  'refund.form.bank-data.account-type.not-null-or-empty': 'Deberás seleccionar tipo de cuenta.',
  'refund.form.person-consultation.cuit-not-valid':
    'El CUIT ingresado no es válido. Deberás ingresar un CUIT de 11 digitos.',
  'refund.form.dismiss-refund-case.status-not-pending':
    'Tu solicitud de devolución se encuentra en proceso.',
  'refund.form.record-service.find-one.invalid-data': 'Verificá el número de solicitud ingresado.',
  'refund.form.document-vcr-validator.ticket-exceeded-year-of-issue':
    'No es posible gestionar la devolucion de la tarifa ya que se ha vencido el plazo anual de vigencia de tu ticket. Únicamente tenés disponible el reintegro de tasas e impuestos. Si queres continuar con la solicitud contactanos a consulta.devolucion@aerolineas.com.ar',
  'refund.form.validate-vcr-request.fullName.not-null-or-empty':
    'Deberás completar el campo nombre y apellido.',
  'refund.form.validate-vcr-request.ticket-number.not-null-or-empty':
    'Deberás completar los 13 dígitos del número de ticket.',
  'refund.form.validate-vcr-request.ticket-number.pattern':
    'Deberás completar los 13 dígitos del número de ticket.',
  'refund.form.validate-vcr-request.ticket-number.size':
    'Deberás completar los 13 dígitos del número de ticket.',
  'refund.form.validate-vcr-request.reservation-code.not-null-or-empty':
    'Deberás completar las 6 letras del código de reserva.',
  'general.refund-status-form.ticket-number.placeholder': 'Número de ticket',
  'general.refund-request-form.title':
    'Procedimiento de devolución de tasa de uso de aeroestación internacional',
  'general.refund-request-form.description-0':
    'De acuerdo a la resolución 706-E/2017 de la Administración Nacional de Aviación Civil (ANAC), aquellos pasajeros que compraron pasajes aéreos internacionales durante el año 2016 hasta el 30 de noviembre del 2016 e iniciaron su viaje con posterioridad al 31/12/2016 podrán solicitar la devolución de la diferencia entre el valor de la tasa de uso de aeroestación internacional (TUAI) abonada al momento de adquisición del pasaje (según valor aprobado por Resolución ORSNA N° 168/15) y el valor de la TUAI vigente a la fecha de inicio del viaje (según valor aprobado por Resolución ORSNA N° 101/16).',
  'general.refund-request-form.description-1':
    'Para solicitar el reintegro de tal diferencia, los pasajeros alcanzados por la referida resolución ANAC, deberán completar el formulario que está a continuación.',
  'general.refund-request-form.description-2':
    'La devolución, en caso de corresponder, será efectuada a la cuenta bancaria indicada por el pasajero, dentro de los 30 días de recibida la solicitud en forma completa y correcta.',
  'general.refund-request-form.status.placeholder': 'Seleccione...',
  'general.refund-request-form.passenger.booking-passenger': 'Soy pasajero de la reserva',
  'general.refund-request-form.passenger.not-booking-passenger': 'No soy pasajero de la reserva',
  'general.refund-request-form.account-type.current-account': 'Cuenta corriente',
  'general.refund-request-form.account-type.savings-bank': 'Caja de ahorros',
  'general.refund-request-form.passenger-name.placeholder': 'Nombre y apellido',
  'general.refund-request-form.ticket-number.placeholder': 'Número de ticket',
  'general.refund-request-form.document-type.placeholder': 'Tipo de documento',
  'general.refund-request-form.document-number.placeholder': 'Número de documento',
  'general.refund-request-form.cbu-number.placeholder': 'CBU',
  'general.refund-request-form.account-type.placeholder': 'Tipo de cuenta',
  'general.refund-request-form.account-number.placeholder': 'Número de cuenta',
  'general.refund-request-form.cuit.placeholder': 'CUIT',
  'general.refund-request-form.email.placeholder': 'Email',
  'general.refund-request-form.add-passenger': 'Agregar pasajero',
  'general.refund-request-form.delete-passenger': 'Eliminar pasajero',
  'general.refund-request-form.accept-btn': 'Aceptar',
  'general.refund-request-form.status-required': 'Elija una opción.',
  'general.refund-request-form.status-passenger-required': 'Ingrese un pasajero',
  'general.refund-request-form.holder-name-required': 'Ingresa un nombre y apellido.',
  'general.refund-request-form.document-type-required': 'Seleccione un tipo de documento.',
  'general.refund-request-form.document-number-required': 'Ingrese un número de documento.',
  'general.refund-request-form.document-number.max-number': 'Maximo 10 dígitos.',
  'general.refund-request-form.cbu-number-required': 'Ingrese un número de CBU.',
  'general.refund-request-form.validation.cbu-number': 'El CBU debe contener 22 dígitos.',
  'general.refund-request-form.account-type-required': 'Seleccione un tipo de cuenta.',
  'general.refund-request-form.account-number-required': 'Ingrese un número de cuenta.',
  'general.refund-request-form.account-number.max-numbers':
    'El número de cuenta debe tener hasta 22 dígitos.',
  'general.refund-request-form.cuit-required': 'Ingrese un número de cuit.',
  'general.refund-request-form.cuit-validation': 'El número de cuit debe tener 11 dígitos.',
  'general.refund-request-form.email-required': 'Ingrese un email.',
  'general.refund-request-form.email-pattern': 'Verificá que sea un email válido.',
  'general.refund-request-form.ticket-number-required': 'Ingrese un número de ticket.',
  'general.refund-request-form.passenger-name-required': 'Ingrese un nombre y apellido.',
  'general.refund-request-form.ticket-number-min-numbers':
    'El número de ticket debe tener 13 dígitos.',
  'general.refund-request-form.ticket-number-max-numbers':
    'El número de ticket debe tener 13 dígitos.',
  'general.refund-request-form.ticket-vcr-validation':
    'El número de ticket/VCR debe comenzar con 044',
  'general.refund-request-form.refund-success.title': 'Hemos registrado su pedido con exito.',
  'general.refund-request-form.refund-success.description':
    'En breve recibirá un mail con el número de su pedido. En función a lo solicitado, analizaremos su caso y procederemos, de corresponder, al reembolso correspondiente. En caso de requerir algún dato adicional, lo estaremos contactando a su mail.',
  'general.refund-request-form.refund-success.button': 'Volver al inicio',
  'loyalty.service.claim.id-claim-number-and-status-exception':
    'Lo sentimos. Ha ocurrido un error!',
  'general.punctuality-index.label': 'Índice de puntualidad',
  'general.punctuality-index.flight.label': 'Vuelo',
  'general.punctuality-index.flight-origin.label': 'Origen',
  'general.punctuality-index.flight-destiny.label': 'Destino',
  'general.punctuality-index.flight-cancelation.label': 'Cancelación',
  'general.punctuality-index.flight-delay.30-min.label': 'Demoras superiores a 30min',
  'general.punctuality-index.flight-delay.60-min.label': 'Demoras superiores a 60min',
  'general.header.arplus.title.label': 'Mi cuenta Plus',
  'general.header.arplus.title.arplus-program': 'Programa Aerolíneas Plus',
  'general.header.arplus.title.arplus-disactivate-keys': 'Activar/desactivar keys',
  'general.header.arplus.link.logout': 'Cerrar sesión',
  'general.file-upload-max-length':
    'El archivo "{{fileName}}" tiene un tamaño de ({{fileUpload}}),' +
    ' tamaño debe ser menor a ({{fileSize}}).',
  'general.file-upload-no-allowed':
    '{{fileName}} el tipo de archivo ({{fileType}}) no es permitido.',
  'general.file-upload-error': 'Verifica que los tipos de archivos adjuntos sean validos',
  'general.file-upload-error-convert-b64':
    'Tenemos problemas al intentar enviar los archivos adjuntos, porfabor intenta nuevamente.',
  'general.dfile-upload.attachments-link.delete': 'Eliminar',
  'general.text.total-per-passenger': 'Total por pasajero',
  'general.validation.max-required': 'La cantidad de dígitos debe ser menor a {{value}}.',
  'general.radio-group.label': 'Opciones',
  'general.new': 'Nuevo',
  'general.external-purchase-loader.title': 'Estamos procesando tu compra…',
  'general.external-purchase-loader.description': '¡Por favor no salgas de esta pantalla!',
  'general.modal-assist-card.title': '¡Tu cambio se ha realizado exitosamente!',
  'general.modal-assist-card.confirm-button-label': 'Entendido',
  'general.modal-redirection-payment-gateway.title': 'Aviso de redirección',
  'general.modal-redirection-payment-gateway.description':
    'Debido a inconvenientes técnicos temporales con nuestros proveedores de pagos en Chile, para adquirir su ticket será redirigido a nuestro sitio Web de Uruguay. Podrá pagar con tarjetas de crédito y débito en dólares estadounidenses (USD).',
  //accessibility
  'alt.whatsapp': 'Chat Whatsapp',
  'alt.ar-plus': 'Aerolíneas Plus',
  'alt.minus-btn': 'Quitar uno',
  'alt.plus-btn': 'Agregar uno',
  'alt.close-modal': 'Cerrar alerta',
  'alt.close': 'Cerrar',
  'aria-label.where-to-find-cvc': 'Donde encontrar el código de seguridad',
  'aria-label.checked': 'Incluido',
  'aria-label.not-checked': 'No incluido',
  'aria-label.select-month': 'Seleccionar mes',
  'aria-label.select-year': 'Seleccionar año',
  'alt.card-gold': 'Aerolineas plus oro',
  'alt.card-diamond': 'Aerolineas plus diamante',
  'alt.card-platinum': 'Aerolineas plus platino',
  'alt.card-classic': 'Aerolineas plus clásico',
  'alt.carousel-pause': 'Pausar carrusel',
  'alt.carousel-play': 'Reiniciar carrusel',
  // URLs with no link tags
  'mission-vision-values.url': 'https://www.aerolineas.com.ar/es-ar/laempresa/mision',
  'sky-team.url': 'https://www.aerolineas.com.ar/es-ar/servicioscliente/codeshare',
  'branches.url': 'https://www.aerolineas.com.ar/es-ar/contacto/sucursalesaeropuertos',
  'human-resources.url': 'https://www.aerolineas.com.ar/es-ar/la_empresa/recursos_humanos',
  'our-history.url': 'https://www.aerolineas.com.ar/es-ar/laempresa/nuestrahistoria',
  'suppliers.url': 'http://ww1.aerolineas.com.ar/arg/main.asp?idSitio=AR&idPagina=152&idIdioma=es',
  'our-aircrafts.url': 'https://www.aerolineas.com.ar/es-ar/la-empresa/informe-financiero',
  'aa-group-companies.url': 'https://www.aerolineas.com.ar/es-ar/laempresa/empresasdelgrupo',
  'aa-mobile.url': 'https://www.aerolineas.com.ar/es-ar/aerolineas_movil',
  'taxes-information.url':
    'https://www.aerolineas.com.ar/landingsespeciales/landings/322_informacion-financiera',
  'ar-plus.url': 'https://www.aerolineas.com.ar/es-ar/aerolineas_plus',
  'suggestions.url': 'https://www.aerolineas.com.ar/es-ar/contacto/contactoatencioncliente',
  'fiscal-credit.url': 'https://www.aerolineas.com.ar/laempresa/constanciacreditofiscal',
  'banks.url': 'https://www.aerolineas.com.ar/es-ar/reservasservicios/bancos',
  'fare-conditions.url': 'https://www.aerolineas.com.ar/es-ar/reservasservicios/condiciones',
  'booking-conditions.url': 'https://www.aerolineas.com.ar/es-ar/reservasservicios/condiciones',
  'baggage-conditions.url': 'https://www.aerolineas.com.ar/es-ar/equipaje/franquicia',
  'assistance-conditions.url': 'https://www.aerolineas.com.ar/asistencia/asistencias',
  'checkin-conditions.url':
    'https://www.aerolineas.com.ar/landingsespeciales/landings/232_condiciones-checkin-online',
  'cookies-terms-conditions.url': 'https://www.aerolineas.com.ar/legales/terminos-y-condiciones',
  'footer-facebook.url': 'https://www.facebook.com/aerolineas.argentinas/',
  'footer-whatsapp.url': 'https://api.whatsapp.com/send?phone=541149404798',
  'footer-airports.url': 'https://www.aerolineas.com.ar/es-ar/contacto/sucursalesaeropuertos',
  'information-secure-flights.url': 'https://www.dhs.gov/dhs-trip',
  'whatsapp-api-contact.url': 'https://api.whatsapp.com/send?phone=541149404798',
  'special-flights.url':
    'https://www.aerolineas.com.ar/es-ar/landingsespeciales/landings/790_vuelos-especiales',
  'fare-conditions-landing.url':
    'https://www.aerolineas.com.ar/landingsespeciales/landings/480_condiciones-tarifarias',
  'skyteam-press-release.url': 'https://www.skyteam.com/en/General-information/covid19',
  'external-booking.br.url': 'https://www.aerolineas.com.ar/pt-br/servicios_adicionales',
  'external-booking.cl.url': 'https://www.aerolineas.com.ar/es-cl/servicios_adicionales',
  'external-booking.default.url': 'https://www.aerolineas.com.ar/es-ar/servicios_adicionales',
  /*These keys come from gds responses so they cannot be modified*/
  // Flight Status
  'gds.flight.status.onTime': 'En horario',
  'gds.flight.status.delayed': 'Demorado',
  'gds.flight.status.arrived': 'Aterrizó',
  'gds.flight.status.cancelled': 'Cancelado',
  'gds.flight.status.departed': 'Despegó',
  'gds.flight.status.deflected': 'Desviado',
  'gds.flight.status.rescheduled': 'Reprogramado',
  // Seat selection
  'gds.booking.info.ancillariesMTOConfirmationsuccess':
    'Los asientos se han podido seleccionar correctamente',
  // Purchase Warnings
  'gds.booking.warn.ssrFailedToAddSomeSeats':
    'Algunos asientos no se pudieron seleccionar correctamente',
  'gds.booking.warn.errorAddingSpecialMealType':
    'Lo sentimos, se produjo un error al agregar <strong>comidas especiales</strong>. Podés agregarlas desde ',
  'gds.booking.warn.errorSpecialsServices':
    'Lo sentimos, se produjo un error al agregar <strong>servicios especiales</strong>. Podés agregarlas desde ',
  'gds.booking.warn.errorAddingSpecialMealTypeAndSpecialsServices':
    'Lo sentimos, se produjo un error al agregar <strong>comidas especiales</strong> y <strong>servicios especiales</strong>. Podés agregarlas desde ',
  'gds.passenger.special-service-request.not-allowed':
    'No es posible seleccionar servicios especiales en este momento porque faltan menos de 48hs para alguno de los vuelos. Realizá la compra sin solicitar servicios especiales.',
  'gds.passenger.meal-preference.not-allowed':
    'No es posible seleccionar comidas especiales en este momento porque faltan menos de 72hs para alguno de los vuelos. Realizá la compra sin solicitar comidas especiales.',
  'gds.passenger.special-service-request.not-allowed.booking':
    'No es posible seleccionar servicios especiales en este momento porque faltan menos de 48hs para alguno de los vuelos.',
  'gds.passenger.meal-preference.not-allowed.booking':
    'No es posible seleccionar comidas especiales en este momento porque faltan menos de 72hs para alguno de los vuelos.',
  'gds.checkout.error.seatMapUnavailable':
    'En este momento, el mapa de asientos no está disponible.'
  /********************************************************************************/
}
