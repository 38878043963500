/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { apiSublos } from 'services/api'

const {
  validateSublosPassengersDocumentationStart,
  validateSublosPassengersDocumentationSuccess,
  validateSublosPassengersDocumentationError,
  validateSublosPassengersDocumentationClear
} = createActions({
  VALIDATE_SUBLOS_PASSENGERS_DOCUMENTATION_START: () => {},
  VALIDATE_SUBLOS_PASSENGERS_DOCUMENTATION_SUCCESS: data => ({ data }),
  VALIDATE_SUBLOS_PASSENGERS_DOCUMENTATION_ERROR: error => ({ error }),
  VALIDATE_SUBLOS_PASSENGERS_DOCUMENTATION_CLEAR: () => {}
})

const validateSublosPassengersDocumentation = ({
  companyCode,
  reservationCode,
  employeeCode,
  payload
}) => async dispatch => {
  dispatch(validateSublosPassengersDocumentationStart())
  try {
    await apiSublos.post(
      apiRoutes.SUBLOS_RESERVATIONS_PASSENGER_DETAILS({
        companyCode,
        reservationCode,
        employeeCode
      }),
      payload
    )
    dispatch(validateSublosPassengersDocumentationSuccess({ success: true }))
  } catch (error) {
    dispatch(
      validateSublosPassengersDocumentationError(error.response ? error.response.data : error)
    )
  }
}

export {
  validateSublosPassengersDocumentation,
  validateSublosPassengersDocumentationStart,
  validateSublosPassengersDocumentationSuccess,
  validateSublosPassengersDocumentationError,
  validateSublosPassengersDocumentationClear
}
