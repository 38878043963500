/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import { getOrderIdParams } from 'components/sublos/main/steps/bookingConfirmation/helper'
import apiRoutes from 'constants/apiRoutes'
import { removeSublosPersistValidation, setSublosPersistValidation } from 'helpers/utils'
import { apiSublos } from 'services/api'

const {
  sublosBookingValidationStart,
  sublosBookingValidationSuccess,
  sublosBookingValidationError,
  sublosReservationStart,
  sublosReservationSuccess,
  sublosReservationError,
  sublosCancelReservationStart,
  sublosCancelReservationSuccess,
  sublosCancelReservationError,
  clearSublosBookingValidation,
  clearSublosReservation,
  clearSublosCancelReservation
} = createActions({
  SUBLOS_BOOKING_VALIDATION_START: () => {},
  SUBLOS_BOOKING_VALIDATION_SUCCESS: data => ({ data }),
  SUBLOS_BOOKING_VALIDATION_ERROR: error => ({ error }),
  SUBLOS_RESERVATION_START: () => {},
  SUBLOS_RESERVATION_SUCCESS: data => ({ data }),
  SUBLOS_RESERVATION_ERROR: error => ({ error }),
  SUBLOS_CANCEL_RESERVATION_START: () => {},
  SUBLOS_CANCEL_RESERVATION_SUCCESS: data => ({ data }),
  SUBLOS_CANCEL_RESERVATION_ERROR: error => ({ error }),
  CLEAR_SUBLOS_BOOKING_VALIDATION: () => {},
  CLEAR_SUBLOS_RESERVATION: () => {},
  CLEAR_SUBLOS_CANCEL_RESERVATION: () => {}
})

const fetchSublosBookingValidation = ({ companyCode, employeeCode, payload }) => async dispatch => {
  dispatch(sublosBookingValidationStart())

  try {
    const response = await apiSublos.post(
      apiRoutes.SUBLOS_BOOKING_CONFIRMATION({ companyCode, employeeCode }),
      payload
    )

    setSublosPersistValidation(getOrderIdParams(response?.data?.details))

    dispatch(sublosBookingValidationSuccess(response?.data))
  } catch (error) {
    dispatch(sublosBookingValidationError(error.response ? error.response.data : error))
  }
}

const createSublosReservation = ({ companyCode, employeeCode, payload }) => async dispatch => {
  dispatch(sublosReservationStart())

  try {
    const response = await apiSublos.post(
      apiRoutes.SUBLOS_RESERVATIONS({ companyCode, employeeCode }),
      payload
    )

    dispatch(sublosReservationSuccess(response.data))
  } catch (error) {
    dispatch(sublosReservationError(error.response ? error.response.data : error))
  }
}

const sublosCancelReservation = ({
  companyCode,
  employeeCode,
  shoppingId,
  orderId
}) => async dispatch => {
  dispatch(sublosCancelReservationStart())

  try {
    await apiSublos.delete(
      apiRoutes.SUBLOS_CANCEL_BOOKING_CONFIRMATION({
        companyCode,
        employeeCode,
        shoppingId,
        orderId
      })
    )

    removeSublosPersistValidation()
    dispatch(sublosCancelReservationSuccess({ data: { success: true } }))
  } catch (error) {
    dispatch(sublosCancelReservationError(error.response ? error.response.data : error))
  }
}

export {
  fetchSublosBookingValidation,
  sublosCancelReservation,
  createSublosReservation,
  sublosBookingValidationStart,
  sublosBookingValidationSuccess,
  sublosBookingValidationError,
  sublosReservationStart,
  sublosReservationSuccess,
  sublosReservationError,
  sublosCancelReservationStart,
  sublosCancelReservationSuccess,
  sublosCancelReservationError,
  clearSublosBookingValidation,
  clearSublosCancelReservation,
  clearSublosReservation
}
