export default {
  'shopping.search-box.destination': 'Destino',
  'shopping.search-box.origin': 'Origen',
  'shopping.search-box.award-booking': 'Búsqueda en millas',
  'shopping.search-box.have-flex-dates': 'Tengo fechas flexibles',
  'shopping.search-box.label.class': 'Clase',
  'shopping.search-box.class-passengers': 'Clase / Pasajeros',
  'shopping.search-box.multidestiny.leg-title': '{{leg}} Tramo',
  'shopping.search-box.campaign-field.error': 'Campo no válido',
  'shopping.search-box.passengers.required': 'No olvides indicar la cantidad de pasajeros',
  'shopping.search-box.config-passenger.date-range-adult': '+ 12 años',
  'shopping.search-box.config-passenger.date-range-children': '2 a 11 años',
  'shopping.search-box.config-passenger.date-range-infant': '0 a 1 años',
  'shopping.search-box.validation.promo-code.required':
    'El codigo promocional debe tener entre 3 y 20 caracteres',
  'shopping.search-box.validation.promo-code-pattern': 'Verificá que sea un código válido',
  'shopping.search-box.promo-code.checkbox-label': 'Tengo código de promoción',
  'shopping.search-box.promo-code.input-label': 'Ingresá tu código promocional',
  'shopping.search-box.promo-code.input-placeholder': 'Ej: 234ABC',
  'shopping.search-box.add-leg-link': 'Agregar otro tramo',
  'shopping.search-box.remove-leg-link': 'Eliminar tramo',
  'shopping.search-box.passengers-disclaimer.baby': 'bebé',
  'shopping.search-box.passengers-disclaimer.baby.other': 'bebés',
  'shopping.search-box.passengers-disclaimer':
    'Podes hacer una búsqueda para un máximo de {{maxPassengers}} {{- passengerLabel, lowercase}}.Cada adulto puede llevar hasta {{maxInfPerAdt}} {{babyLabel}}.',
  'shopping.search-box.search-flights': 'Buscar vuelos',
  'shopping.search-box.recent-searchs': 'Búsquedas Recientes',
  'shopping.search-box.terms-and-conditions-events.label': 'Términos y Condiciones',
  'shopping.search-box.maintenance-modal.title': 'Lo sentimos, el sitio está en mantenimiento',
  'shopping.search-box.maintenance-modal.subtitle':
    'Estamos trabajando para mejorar tu experiencia. Por favor, visitanos nuevamente a partir del 26/04/2024 o comunicate con nuestro call center para realizar tus operaciones.'
}
