export default {
  ARPLUS_CREDIT_MILES_HAS_INSUFFICIENT_INFORMATION:
    'loyalty.redeem-points.insufficient-information',
  ARPLUS_INCORRECT_CARD: 'loyalty.issue-emd.incorrect-credit-card',
  AUTH_BOOKING_SESSION_EXPIRED: 'gds.authorization.booking-session.expired',
  BOOKING_FAILURE_ISSUE_RESERVATION_FAILURE_REFUND: 'gds.booking.refund-payment.error',
  BOOKING_FAILURE_ISSUE_RESERVATION_SUCCESSFUL_REFUND: 'gds.booking.issue-reservation.error',
  BOOKING_ALREADY_ISSUED: 'gds.booking.booking-already-issued',
  BOOKING_MERCADO_PAGO_RESPONSE_NOT_FOUND: 'gds.booking.error.mercado-pago.response-not-found',
  BOOKING_PURCHASE_CUIT_INVALID: 'booking.document.document-number.not-valid',
  BOOKING_RESERVATION_CODE_NOT_EMPTY: 'booking.reservation-code.not-empty',
  BOOKING_SESSION_EXPIRED: 'gds.booking.session.expired',
  CHECKOUT_SESSION_EXPIRED: 'gds.checkout.session.expired',
  CL_GLOBAL_PAYMENT_ERROR_INVALID_CARD: 'gds.booking.error.unableToRetrieveTransaction',
  ERROR_PARSE_PURCHASE_RESPONSE: 'gds.booking.error.parse-purchase-response',
  EXCHANGES_ANCILLARIES_WARNING: 'gds.booking.warn.missing-ancillaries',
  EXCHANGES_CHANGE_FEE_WAIVER: 'gds.flight.info.changeFeeWaived',
  EXCHANGES_PRICE_DIFFERENCE_WAIVER: 'gds.flight.info.priceDifferenceWaived',
  FLIGHT_INVALID_REQUEST: 'gds.flight.error.invalidRequest',
  FLIGHT_SESSION_EXPIRED: 'gds.flight.session.expired',
  GENERAL_REQUEST_ERROR: 'GENERAL_REQUEST',
  GLOBAL_PAYMENT_ERROR_INVALID_CARD: 'gds.booking.error.unableToCreateCardDebt',
  ISSUE_INVALID_CARD: 'gds.reservation.issue-vcr.invalid-card',
  PASSENGER_ERROR_MEAL_NOT_ALLOWED: 'gds.passenger.meal-preference.not-allowed',
  PASSENGER_ERROR_SSR_NOT_ALLOWED: 'gds.passenger.special-service-request.not-allowed',
  PAYMENT_ERROR_AUTH_FAILURE: 'gds.booking.error.authorizationFailure',
  PAYMENT_ERROR_DUPLICATED_ATTEMPT: 'gds.booking.error.duplicate-attempt',
  PAYMENT_ERROR_INSUFFICIENT_FUNDS: 'gds.booking.error.insufficientFunds',
  PAYMENT_ERROR_INVALID_CARD: 'gds.booking.error.invalid-card-number',
  PAYMENT_ERROR_WRONG_AMOUNT: 'gds.booking.error.wrongAmount',
  PAYMENT_FAILURE: 'gds.booking.error.payment-failure',
  PURCHASE_RESPONSE_NOT_FOUND: 'gds.purchase-response.not-found', // 'Generic-error'
  PURCHASING_CHALLENGE_FAILURE: 'gds.booking.error.purchasing-challenge-failure',
  TRAVEL_ASSISTANCE_NO_VOUCHERS_ERROR: 'gds.booking.travel-assistance-vouchers.not-found',
  VALIDATION_ERROR: 'VALIDATION_ERROR',

  /**** Arplus ****/
  ARPLUS_DECLINED_CC_CHANGED: 'payment-connector.declined.card-has-changed', // 'DECLINED - Credit Card has changed'
  ARPLUS_DECLINED_EXCEEDED: 'payment-connector.declined.limit-exceeded', // "DECLINED - Not Authorized. Limit exceeded",
  ARPLUS_DECLINED_EXPIRED: 'payment-connector.declined.card-expired', // "DECLINED - Not Authorized. javax.smartcardio.Card expired"
  ARPLUS_DECLINED_INVALID_CARD: 'payment-connector.declined.invalid-card', // 'DECLINED - Not Authorized. Invalid card',
  ARPLUS_DECLINED_INVALID_SECURITY_CODE: 'payment-connector.declined.invalid-security-code', //"DECLINED - Not Authorized. Invalid security code"
  ARPLUS_DECLINED_NOT_AUTHORIZED: 'payment-connector.declined.not-authorized', // 'DECLINED - Not Authorized'
  ARPLUS_DECLINED_PREFIX: 'payment-connector.declined',
  ARPLUS_PASSWORD_SIZE_ERROR: 'loyalty.login.login-request.password.size',
  ARPLUS_REJECTED_EXPIRES: 'payment-connector.rejected.expired', //'REJECTED - Expired'
  ARPLUS_REJECTED_FRAUD_SCREENING: 'payment-connector.rejected.fraud-screening', //'REJECTED - By Fraud Screening'
  ARPLUS_REJECTED_INVALID_MERCHANT_REQUEST: 'payment-connector.rejected.invalid-merchant-request', // 'REJECTED - Invalid Merchant Request'
  ARPLUS_REJECTED_NOT_AUTHENTICATED: 'payment-connector.rejected.not-authenticated', // 'REJECTED - Not Authenticated'
  ARPLUS_REJECTED_PREFIX: 'payment-connector.rejected',
  ARPLUS_REJECTED_PROCESSING_ERROR_PSP: 'payment-connector.rejected.psp-error', // 'REJECTED - Processing Error at PSP'
  ARPLUS_REJECTED_PROCESSING_ERROR_SP: 'payment-connector.rejected.card-out-of-rank', //'REJECTED - Processing Error at SP',
  ARPLUS_REJECTED_REVERSED: 'payment-connector.rejected.reversed', // 'REJECTED - Reversed'
  ARPLUS_REJECTED_TIMEOUT: 'payment-connector.rejected.timed-out', // 'REJECTED - Timed Out'

  // ALL PENDING ERRORS SHOULD NOT ALLOW PAYMENT RETRIAL
  ARPLUS_PENDING_AT_CUSTOMEER_BANK: 'payment-connector.pending.at-customer.bank-payment', //  'PENDING - At Customer (Bank Payment)'
  ARPLUS_PENDING_AT_CUSTOMER_CASH: 'payment-connector.pending.at-customer-cash-payment', //'PENDING - At Customer (Cash Payment)'
  ARPLUS_PENDING_AT_CUSTOMER_NPS: 'payment-connector.pending.at-customer', //'PENDING - At Customer (NPS Website)'
  ARPLUS_PENDING_AT_ISSUER: 'payment-connector.pending.at-issuer', // 'PENDING - At Issuer'
  ARPLUS_PENDING_AT_MERCHANT: 'payment-connector.pending.at-merchant', // 'PENDING - At Merchant'
  ARPLUS_PENDING_TRANSACTION_IN_PROGRESS: 'payment-connector.pending.transaction-in-progress', //'PENDING - Transaction in progress'

  /**** Flex Calendar ****/
  FLEX_CALENDAR_ARRIVAL_UNAVAILABLE: 'gds.flight.flex-calendar.not-arrival-offers',
  FLEX_CALENDAR_DEPARTURE_UNAVAILABLE: 'gds.flight.flex-calendar.not-departure-offers',
  FLEX_CALENDAR_DISCOUNT_NOT_APPLIED: 'gds.flights.warn.discount-not-applied',
  FLEX_CALENDAR_INVALID_PROMO_CODE: 'gds.shopping.warn.invalid-promo-code'
}
