export default {
  'shopping.promo-code.invalid':
    'Las tarifas mostradas no tienen aplicado el código que ingresaste. ¡Lo sentimos! El código es inválido, no aplica para las fechas / rutas seleccionadas o fue utilizado anteriormente.',
  'shopping.branded-fares.no-availability': 'Sin<br>disponibilidad',
  'shopping.alternate-offers.no-availability': 'Sin disponibilidad',
  'shopping.flex-calendar.no-availability':
    'No hay disponibilidad de vuelos en las fechas que elegiste.',
  'shopping.flex-calendar.no-availability-for-flight-type': `No hay disponibilidad de vuelos el {{day}} de {{month}} para tu {{tripType}}.`,
  'shopping.flex-calendar.choose-another-date': 'Podés seleccionar cualquier día del calendario.',
  'shopping.flex-calendar.disclaimer':
    'Los valores están expresados en ARS= Pesos argentinos. Las tarifas están sujetas a ' +
    'disponibilidad y no están garantizadas sino hasta la compra final.',
  'shopping.flex-calendar.month-best-price': 'Mejor precio del mes',
  'shopping.flex-calendar.discount-rate': 'Tarifa con descuento',
  'shopping.flex-calendar.references': 'Referencias',
  'shopping.flex-calendar.view-flights': 'Ver vuelos',
  'shopping.flex-calendar.continue': 'Continuar',
  'shopping.flex-calendar.change': 'Cambiar',
  //Pais tax keys remain empty but will not be deleted in case they are reactivated
  'shopping.flex-calendar.pais-tax.warning': '',
  'shopping.flex-calendar.pais-tax.more-information': '',
  'shopping.flex-calendar.pais-tax.important': '',
  'shopping.flex-calendar.pais-tax.details': '',
  'shopping.flight-offers.change': 'Cambiar',
  'shopping.flight-offers.rate-per-passenger': 'Tarifa por pasajero',
  'shopping.flight-offers.compare-conditions.info-message-0':
    '(*) Un (1) cambio permitido sin penalidad ni diferencia de tarifa para emisiones hasta el 31 de marzo de 2021, siempre que se respete la temporada del ticket original y el regreso sea antes del 15 de diciembre de 2021. No aplica en vuelos especiales. Ver resto de las condiciones <a href="https://www.aerolineas.com.ar/es-ar/landingsespeciales/landings/791_politica-comercial" target="_blank">aquí</a>.',
  'shopping.flight-offers.compare-conditions.info-message-1':
    '(**) <a href="https://www.aerolineas.com.ar/es-ar/landingsespeciales/landings/790" target="_blank">En vuelos especiales</a>: Cambios con penalidad, para vuelos Internacionales de USD 350/EUR 400, Regionales de USD 150. Devolución: Sólo en vuelos especiales la devolución será del 10% de la tarifa. Para el resto de los vuelos no está permitida.',
  'shopping.flight-offers.compare-conditions.info-message-2': '(***) Salón Vip',
  'shopping.flight-offers.no-availability.no-flights-available':
    'No tenemos vuelos disponibles para vos en esos días. <a href="https://www.aerolineas.com.ar/scheduled-flights" target="_blank">Podrás verificar aquí días de operación de vuelos</a>',
  'shopping.flight-offers.no-availability.we-suggest': 'Te sugerimos que ',
  'shopping.flight-offers.no-availability.change-your-flight-dates':
    'cambies las fechas de tu búsqueda.',
  'shopping.flight-offers.loading-text': 'Estamos buscando resultados para vos...',
  'shopping.flight-offers.we-cannot-process-your-request':
    'En este momento, no es posible procesar tu solicitud. Por favor, intentá más tarde.',
  'shopping.flight-offers.we-cannot-process-your-request.reacomm':
    'El cambio no pudo realizarse. Si el ticket lo compraste en una Agencia de Viajes por favor contactate con esta para realizar cualquier gestión, si lo adquiriste directamente en Aerolíneas te pedimos te comuniques con nuestro Call Center al 054 (11) 5199 3555 de Lun a Vie de 8 a 20hs. Sáb, Dom y feriados de 8 a 14 hs. (Hora local de Argentina)',
  'shopping.flight-offers.filter.no-results':
    'No tenemos resultados para vos con este último filtro que aplicaste.',
  'shopping.flight-offers.filter.clean-filters': 'Borrar filtros aplicados',
  'shopping.flight-offers.fare.adult': 'Tarifa por adulto',
  'shopping.flight-offers.fare.compare': 'Comparar condiciones',
  'shopping.flight-offers.fare.adult.detail': 'Las tarifas mostradas pertenecen a un adulto',
  'shopping.flight-offers.order.by': 'Ordenar por',
  'shopping.flight-offers.we-offer-best-flight': '¡Te ofrecemos el mejor vuelo!',
  'shopping.flight-offers.not-combinable-with-your-outbound':
    'Este vuelo no es combinable con tu ida.',
  'shopping.flight-offers.not-combinable-with-your-inbound':
    'Este vuelo no es combinable con tu vuelta.',
  'shopping.flight-offers.compare-conditions': 'Comparar condiciones',
  'shopping.flight-offers.best-offer': '¡Ahorrá y viajá más rápido!',
  'shopping.flight-offers.pagination.results': `{{offersLeft}} resultados`,
  'shopping.flight-offers.pagination.more.we-have-other': 'Tenemos otros',
  'shopping.flight-offers.pagination.more.for-you': 'para vos. ¿Queres verlos?',
  'shopping.flight-offers.pagination.more.show-more': 'Mostrar más vuelos.',
  'shopping.flight-offers.pagination.less.we-showed-other': 'Te mostramos otros',
  'shopping.flight-offers.pagination.less.for-you': 'para vos. ¿Queres ocultarlos?',
  'shopping.flight-offers.pagination.less.show-less': 'Mostrar menos vuelos.',
  'shopping.flight-offers-filter.results': 'resultados',
  'shopping.flight-offers-filter.sort-by': 'ordenados por',
  'shopping.flight-offers-filter.filter-applied': 'Aplicaste un filtro ',
  'shopping.flight-offers-filter.delete-filter': 'Borra el filtro para ver otras duraciones.',
  'shopping.flight-offers-filter.sorting-placeholder': 'Seleccionar una opción',
  'shopping.flight-offers-filter.sorting-empty-message': 'No hay opciones',
  'shopping.flight-offers-filter.apply': 'Aplicar',
  'shopping.flight-offers-filter.clear-filters': 'Borrar filtros',
  'shopping.flight-offers-filter.filter': 'Filtrar',
  'shopping.flight-offers-filter.order-and-filter': 'Filtrar y Ordenar',
  'shopping.flight-offers-filter.show-rates': 'Mostrar tarifas en días cercanos',
  'shopping.passengers.infants.max':
    'La cantidad de pasajeros adultos debe ser igual o mayor a la de bebés',
  'shopping.passengers.infants.max-count':
    'La cantidad total de pasajeros debe ser mayor a 0 y menor a 10',
  'shopping.flight.prices.format': 'Precio debe ser mayor a precio desde',
  'shopping.flight.departing-prices.format':
    'Departing price to must be greater than departing price from',
  'shopping.flight.returning-prices.format':
    'Returning price to must be greater than returning price from',
  'shopping.flight.prices-type.format':
    'El filtro de precio de ida/vuelta no puede ser combinado con filtros de precios simples',
  'shopping.flight.duration-type.format':
    'El filtro de duración de ida/vuelta no puede ser combinado con filtros de duración simples',
  'shopping.flight.time-type.format':
    'El filtro de horario de ida/vuelta no puede ser combinado con filtros de horarios simples',
  'shopping.flight.airport-type.format':
    'El filtro de aeropuerto de ida/vuelta no puede ser combinado con filtros de aeropuertos simples',
  'shopping.flight.scale-type.format':
    'El filtro de escalas de ida/vuelta no puede ser combinado con filtros de escalas simples',
  'shopping.matrix.legs.round-trip.required':
    'Debe ser un viaje de ida-vuelta para la búsqueda de fechas alternativas y fechas flexibles.',
  'shopping.search.legs.round-trip-required':
    'Debe ser un viaje de ida-vuelta para la búsqueda de fechas alternativas y fechas flexibles.',
  'shopping.matrix.legs.date-past': 'Todos los tramos deben incluir una fecha futura',
  'shopping.search.legs.past-date': 'Todos los tramos deben incluir una fecha futura',
  'shopping.matrix.legs.date-format': 'Todos los tramos deben incluir una fecha válida',
  'shopping.search.legs.date-format': 'Todos los tramos deben incluir una fecha válida',
  'shopping.matrix.legs.past-date': 'Todos los tramos deben tener un origen y destino diferentes',
  'shopping.matrix.legs.format': 'Todos los tramos deben tener un origen y destino diferentes',
  'shopping.flight.promotion.required': 'Los campos de promoción y campaña son obligatorios',
  'shopping.search.legs.not-empty': 'Al menos un tramo es obligatorio',
  'shopping.selection.selected-flights.not-empty': 'Al menos un shopping id es obligatorio',
  'shopping.selection.shopping-id.not-empty': 'Shopping id es obligatorio',
  'shopping.summary.shopping-id.not-empty': 'Shopping id es obligatorio',
  'shopping.multiple-status.flight-numbers.not-empty': 'El número de vuelo es obligatorio',
  'shopping.schedule.origin.not-empty': 'El campo origen es obligatorio',
  'shopping.schedule.destination.not-empty': 'El campo destino es obligatorio',
  'shopping.flight.point-of-sale.not-empty':
    'El campo Accept-Language es obligatorio en los headers',
  'shopping.search.legs.size': 'La búsqueda de fechas flexibles permite solo uno o dos tramos',
  'shopping.matrix.search-legs.size': 'La búsqueda de fechas alternativas debe incluir dos tramos',
  'shopping.matrix.legs.pattern': 'El formato del tramo debe ser origen-destino-fecha (yyyyMMdd)',
  'shopping.search.legs.pattern': 'El formato del tramo debe ser origen-destino-fecha (yyyyMMdd)',
  'shopping.passengers.departing-schedule.pattern': 'El formato de fecha de ida debe ser yyyyMMdd',
  'shopping.passengers.returning-schedule.pattern':
    'El formato de fecha de vuelta debe ser yyyyMMdd',
  'shopping.multiple-status.departure-date.pattern':
    'El formato de fecha de despegue debe ser (yyyyMMdd)',
  'shopping.schedule.departure-date.pattern': 'El formato de fecha de despegue debe ser (yyyyMMdd)',
  'shopping.flight.times.pattern': 'El campo tiempo debe ser un código válido',
  'shopping.departing-flight.times.pattern': 'El campo tiempo debe ser un código válido',
  'shopping.flight.returning-times.pattern': 'El campo tiempo debe ser un código válido',
  'shopping.flight.airports.pattern': 'El campo aeropuerto debe ser un código válido',
  'shopping.flight.departing-airports.pattern': 'El campo aeropuerto debe ser un código válido',
  'shopping.flight.returning-airports.pattern': 'El campo aeropuerto debe ser un código válido',
  'shopping.selection.selected-flights.range':
    'El campo Shopping Id debe ser un número entre -2147483648 y 2147483647',
  'shopping.flight.shopping-id.pattern': 'El campo Shopping Id debe ser un código uuid',
  'shopping.summary.shopping-id.pattern': 'El campo Shopping Id debe ser un código uuid',
  'shopping.multiple-status.flight-number.pattern': 'El número de vuelo debe ser solo númerico',
  'shopping.multiple-status.flight-number.range':
    'El número de vuelo debe ser un número entre 1 y 2147483647',
  'shopping.schedule.origin.pattern': 'El formato del campo Origen debe ser by 3 letras',
  'shopping.schedule.destinition.pattern': 'El formato del campo Destino debe ser by 3 letras',
  'shopping.passengers.departing-schedule.not-empty': 'El horario de sálida debe ser vacío',
  'shopping.passengers.returning-schedule.null': 'El horario de vuelta debe ser vacío',
  'shopping.flight.price-from.null': 'El campo Precio Desde debe ser vacío',
  'shopping.flight.price-to.null': 'El campo Precio Hasta debe ser vacío',
  'shopping.flight.departing-price-from.null': 'El campo Precio de Ida Desde debe ser vacío',
  'shopping.flight.departing-price-to.null': 'El campo Precio de Ida Hasta debe ser vacío',
  'shopping.flight.returning-price-from.null': 'El campo Precio de Vuelta Desde debe ser vacío',
  'shopping.flight.returning-price-to.null': 'El campo Precio de Vuelta Hasta debe ser vacío',
  'shopping.flight.duration-from.null': 'El campo Duración Desde debe ser vacío',
  'shopping.flight.duration-to.null': 'El campo Duración Hasta debe ser vacío',
  'shopping.flight.departing-duration-from.null': 'El campo Duración de Ida Desde debe ser vacío',
  'shopping.flight.departing-duration-to.null': 'El campo Duración de Ida Hasta debe ser vacío',
  'shopping.flight.returning-duration-from.null':
    'El campo Duración de Vuelta Desde debe ser vacío',
  'shopping.flight.returning-duration-to.null': 'El campo Duración de Vuelta Hasta debe ser vacío',
  'shopping.flight.times.size': 'El campo Horarios debe ser vacío',
  'shopping.flight.departing-times.size': 'El campo Horario de Ida debe ser vacío',
  'shopping.flight.returning-times.size': 'El campo Horario de Vuelta debe ser vacío',
  'shopping.flight.airports.size': 'El campo Aeropuertos debe ser vacío',
  'shopping.flight.departing-airports.size': 'El campo Aeropuerto de Ida debe ser vacío',
  'shopping.flight.returning-airports.size': 'El campo Aeropuerto de Vuelta debe ser vacío',
  'shopping.flight.scales.size': 'El campo Escalas debe ser vacío',
  'shopping.flight.departing-scales.size': 'El campo Escalas de Ida debe ser vacío',
  'shopping.flight.returning-scales.size': 'El campo Escalas de Vuelta debe ser vacío',
  'shopping.exchange.leg.not-empty':
    'La búsqueda para pedido de cambios debe contener al menos un tramo',
  'shopping.exchange.departing-leg.pattern':
    'El tramo de Ida debe ser Origen-Destino-Fecha (yyyyMMdd)',
  'shopping.exchange.returning-leg.pattern':
    'El tramo de Vuelta debe ser Origen-Destino-Fecha (yyyyMMdd)',
  'shopping.exchange.reservation-code.pattern':
    'El campo de Código de Reserva debe ser alfanumérico de 6 caracteres',
  'shopping.flight.promotion-fields.pattern':
    'El capo de Código de Promoción debe ser alfanumérico',
  'shopping.exchange.reservation-code.not-empty': 'El Código de reserva es obligatorio',
  'shopping.exchange.round-trip.format':
    'El tramo de ida y vuelta deben conformar un viaje de ida-vuelta',
  'shopping.exchange.departing-leg.valid-date': 'El tramo de ida debe contener una fecha válida',
  'shopping.exchange.returning-leg.valid-date': 'El tramo de vuelta debe contener una fecha válida',
  'shopping.exchange.departing-leg.past-date': 'El tramo de ida debe contener una fecha futura',
  'shopping.exchange.returning-leg.past-date': 'El tramo de vuelta debe contener una fecha futura',
  'shopping.exchange.departing-leg.format':
    'El tramo de ida debe contener un origen y destino distintos',
  'shopping.exchange.returning-leg.format':
    'El tramo de vuelta debe contener un origen y destino distintos',
  'shopping.exchange.last-name.not-empty': 'El campo Apellido es obligatorio',
  'shopping.exchange.last-name.pattern': 'El campo Apellido debe contener solo letras',
  'shopping.passengers.departure-date.past': 'La vuelta debe contener una fecha futura',
  'shopping.promotion-code.applied.message-0': `¡Tenemos estas tarifas con descuento para vos!
    Tu Código de Promoción  <b>{{promoCode}}</b> fue aplicado exitosamente.`,
  'shopping.promotion-code.applied.message-1': `¡Tenemos estas tarifas con descuento para vos!
  Tu Código de Promoción fue aplicado exitosamente.`,
  'shopping.promotion-code.not-applied.message':
    '¡Tenemos estas tarifas para vos! Tené en cuenta que no pudimos aplicar el <b>código de promoción</b> indicado.',
  'shopping.flight-offer-card.low-availability': `¡Queda {{count}} lugar!`,
  'shopping.flight-offer-card.low-availability.other': `¡Quedan {{count}} lugares!`,
  'shopping.flight-offer-card.leg-number': 'TRAMO {{legNumber}}',
  'shopping.flight-offer-card.from': 'Desde',
  'shopping.re-search-box.close-search': 'Cerrar búsqueda',
  'shopping.re-search-box.edit-search': 'Editar búsqueda',
  'shopping.re-search-box.unavailable-flight': 'No tenemos vuelos disponibles para vos ese día.',
  'shopping.re-search-box.unavailable-flight.other':
    'No tenemos vuelos disponibles para vos en esos días.',
  'shopping.re-search-box.make-new-search': 'Te sugerimos realizar una nueva búsqueda.',
  'shopping.flight-offers.baggage-modal.outbound':
    'El equipaje únicamente está incluído en el vuelo de ida',
  'shopping.flight-offers.baggage-modal.inbound':
    'El equipaje únicamente está incluído en el vuelo de vuelta',
  'shopping.flight-offers.baggage-modal.all': 'El equipaje no está incluido en los vuelos',
  'shopping.flight-offers.baggage-modal.all-subline-0':
    'Recordá que la tarifa que seleccionaste para alguno de tus vuelos no ',
  'shopping.flight-offers.baggage-modal.all-subline-1':
    'incluye equipaje en bodega. Podrás adquirirlo anticipadamente antes de finalizar',
  'shopping.flight-offers.baggage-modal.all-subline-2':
    'tu compra o luego ingresando a "MI RESERVA o Servicios Adicionales".',
  'shopping.flight-offers.baggage-modal.one-way': 'El equipaje no está incluido en tu vuelo',
  'shopping.flight-offers.baggage-modal.all-legs-promo-subline-0':
    'Recordá que la tarifa que seleccionaste para tus vuelos no incluye equipaje',
  'shopping.flight-offers.baggage-modal.all-legs-promo-subline-1':
    'de mano ni en bodega. Podrás adquirirlo con un descuento preferencial antes',
  'shopping.flight-offers.baggage-modal.all-legs-promo-subline-2':
    'de finalizar tu compra o luego ingresando a "Servicios Adicionales".',
  'shopping.flight-offers.baggage-modal.all-legs-promo-national-subline-0':
    'Tenés incluido solo un equipaje de mano de hasta 3 kilos (bolso, mochila, cartera).',
  'shopping.flight-offers.baggage-modal.all-legs-promo-national-subline-1':
    'Para agregar más equipaje podrás adquirirlo antes de finalizar tu compra o',
  'shopping.flight-offers.baggage-modal.all-legs-promo-national-subline-2':
    'luego ingresando a "Servicios Adicionales".',
  'shopping.flight-offers.baggage-modal.all-legs-promo-not-national-subline-0':
    'Recordá que la tarifa que seleccionaste para tus vuelos no incluye equipaje',
  'shopping.flight-offers.baggage-modal.all-legs-promo-not-national-subline-1':
    'de mano ni en bodega. Podrás adquirirlo con un descuento preferencial antes',
  'shopping.flight-offers.baggage-modal.all-legs-promo-not-national-subline-2':
    'de finalizar tu compra o luego ingresando a "Servicios Adicionales".',
  'shopping.flight-offers.baggage-modal.some-leg-promo-national-subline-0':
    'Una de las tarifas que seleccionaste en alguno de tus vuelos solo incluye un equipaje de mano',
  'shopping.flight-offers.baggage-modal.some-leg-promo-national-subline-1':
    'de hasta 3 kilos (bolso, mochila, cartera). Para agregar más equipaje podrás adquirirlo antes',
  'shopping.flight-offers.baggage-modal.some-leg-promo-national-subline-2':
    'de finalizar tu compra o luego ingresando a "Servicios Adicionales".',
  'shopping.flight-offers.baggage-modal.some-leg-promo-not-national-subline-0':
    'Recordá que la tarifa que seleccionaste para alguno de tus vuelos no incluye equipaje',
  'shopping.flight-offers.baggage-modal.some-leg-promo-not-national-subline-1':
    'de mano ni en bodega. Podrás adquirirlo con un descuento preferencial antes',
  'shopping.flight-offers.baggage-modal.some-leg-promo-not-national-subline-2':
    'de finalizar tu compra o luego ingresando a "Servicios Adicionales".',
  'shopping.flight-offers.baggage-modal.all-legs-base-subline-0':
    'Recordá que la tarifa que seleccionaste para tus vuelos no incluye',
  'shopping.flight-offers.baggage-modal.all-legs-base-subline-1':
    ' equipaje en bodega. Podrás adquirirlo con un descuento preferencial antes',
  'shopping.flight-offers.baggage-modal.all-legs-base-subline-2':
    'de finalizar tu compra o luego ingresando a "Servicios Adicionales".',
  'shopping.flight-offers.baggage-modal.some-leg-base-subline-0':
    'Recordá que la tarifa que seleccionaste para alguno de tus vuelos no incluye',
  'shopping.flight-offers.baggage-modal.some-leg-base-subline-1':
    ' equipaje en bodega. Podrás adquirirlo con un descuento preferencial antes',
  'shopping.flight-offers.baggage-modal.some-leg-base-subline-2':
    'de finalizar tu compra o luego ingresando a "Servicios Adicionales".',
  'shopping.flight-offers.baggage-modal.international':
    'No aplica para Estados Unidos que si incluye una pieza.',
  'shopping.flight-offers.modal-time-out.message':
    'Podés iniciar una nueva búsqueda o actualizar los resultados que encontraste',
  'shopping.flight-offers.modal-time-out.full-height-title': 'Búsqueda de vuelos',
  'shopping.flight-offers.modal-time-out.update-button': 'Actualizar resultados',
  'shopping.flight-offers.modal-login-award-booking.description':
    'Para continuar con la compra en millas debés estar logueado. ¿Deseas logearte?',
  'shopping.flight-offers.modal-login-award-booking.accept-button.label': 'Sí',
  'shopping.flight-offers.modal-login-award-booking.cancel-button.label': 'No',
  'shopping.flight-offers.modal-call-center-booking.description':
    'Para poder realizar este cambio de vuelo, te pedimos que te comuniques con nuestro Call Center al 054 (11) 5199 3555.',
  'shopping.search.legs.repeats':
    'No es posible reservar / comprar tramos aéreos repetidos. La compra deberá realizarse en reservas separadas.',
  /*** These keys are from gds errors, cannot be modified **/
  // Flight Offers Filters
  'gds.flight.facet.stops': 'Escalas',
  'gds.flight.facet.departureStops': 'IDA',
  'gds.flight.facet.returningStops': 'VUELTA',
  'gds.flight.facet.stopsAll': 'Todas las escalas',
  'gds.flight.facet.stopsNone': 'Sin escalas',
  'gds.flight.facet.stopsOne': '1 escala',
  'gds.flight.facet.stopsMoreThanOne': '2 o más escalas',
  'gds.flight.facet.airports': 'Aeropuerto',
  'gds.flight.facet.departingAirports': 'IDA',
  'gds.flight.facet.returningAirports': 'VUELTA',
  'gds.flight.facet.airportsAll': 'Todos los Aeropuertos',
  'gds.flight.facet.times': 'Horario de viaje',
  'gds.flight.facet.departingTimes': 'IDA',
  'gds.flight.facet.returningTimes': 'VUELTA',
  'gds.flight.facet.timesAll': 'Todos los horarios',
  'gds.flight.facet.timesEarlyMorning': 'Madrugada (00 a 06 hrs)',
  'gds.flight.facet.timesMorning': 'Mañana (06 a 12 hrs)',
  'gds.flight.facet.timesAfternoon': 'Tarde (12 a 19 hrs)',
  'gds.flight.facet.timesNight': 'Noche (19 a 24 hrs)',
  'gds.flight.facet.duration': 'Duración de viaje',
  'gds.flight.facet.departingDuration': 'IDA',
  'gds.flight.facet.returningDuration': 'VUELTA',
  'gds.flight.facet.prices': 'Precio',
  'gds.flight.facet.departingPrices': 'IDA',
  'gds.flight.facet.returningPrices': 'VUELTA',
  'gds.reservation.missing-info.add-pq.error':
    '¡Ha ocurrido un error al cotizar la reserva! Por favor comuníquese con nuestro contact center.',
  // Sorting Options
  'gds.flights.sorting.lessScales': 'Menos escalas',
  'gds.flights.sorting.lowestPrice': 'Precio más bajo',
  'gds.flights.sorting.lessDuration': 'Menor duración',
  'gds.flights.sorting.firstDeparture': 'Primera salida',
  'gds.flights.sorting.firstArrival': 'Primera llegada',
  // Fare Conditions
  'gds.flight.error.invalid-conference-fields': 'Por favor, ingresa un código válido.',
  'gds.flight.error.internalServer': 'Hubo un error mientras realizabamos tu busqueda',
  // Flex Calendar
  'gds.flight.flex-calendar.not-departure-offers':
    'No hay vuelos disponibles para la IDA seleccionada, ',
  'gds.flight.flex-calendar.not-arrival-offers':
    'No hay vuelos disponibles para la VUELTA seleccionada, '
  /***/
}
