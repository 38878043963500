/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  fetchHoldBookingOptionsStart,
  fetchHoldBookingOptionsSuccess,
  fetchHoldBookingOptionsError,
  setSelectedHoldBookingId,
  resetSelectedHoldBookingId,
  clearHoldBookingOptions
} = createActions({
  FETCH_HOLD_BOOKING_OPTIONS_START: () => {},
  FETCH_HOLD_BOOKING_OPTIONS_SUCCESS: data => ({ data }),
  FETCH_HOLD_BOOKING_OPTIONS_ERROR: error => ({ error }),
  SET_SELECTED_HOLD_BOOKING_ID: id => ({ id }),
  RESET_SELECTED_HOLD_BOOKING_ID: () => {},
  CLEAR_HOLD_BOOKING_OPTIONS: () => {}
})

const fetchHoldBookingOptions = shoppingId => {
  return async dispatch => {
    dispatch(fetchHoldBookingOptionsStart())

    try {
      const params = `?shoppingId=${shoppingId}`

      const holdBookingOptions = await api.get(`${apiRoutes.HOLD_OPTIONS}${params}`)

      dispatch(fetchHoldBookingOptionsSuccess(holdBookingOptions))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchHoldBookingOptionsError(err))
    }
  }
}

export {
  fetchHoldBookingOptions,
  fetchHoldBookingOptionsStart,
  fetchHoldBookingOptionsSuccess,
  fetchHoldBookingOptionsError,
  clearHoldBookingOptions,
  setSelectedHoldBookingId,
  resetSelectedHoldBookingId
}
