/* eslint-disable import/no-cycle */
import { parse } from 'query-string-object'

import { getDayJsObject } from 'helpers/dates'
import { buildSearchBoxTabs, isEmpty } from 'helpers/utils'

import { parseUrlsInResponse } from './cms'

/**@todo deprecate and replace this parse method to avoid query-string-object dependency */
export default url => {
  const params = parse(parseUrlsInResponse(url))

  if (Array.isArray(params.leg)) {
    const mappedParams = params.leg.map(leg => {
      const [origin, destination, departure] = leg.split('-')

      return {
        origin: origin.toUpperCase(),
        destination: destination.toUpperCase(),
        departure
      }
    })

    const sortedLegs = mappedParams.sort((firstLeg, secondLeg) => {
      const firstDeparture = getDayJsObject(firstLeg.departure)
      const secondDeparture = getDayJsObject(secondLeg.departure)

      return firstDeparture.diff(secondDeparture)
    })

    params.legs = sortedLegs
  } else if (!isEmpty(params.leg)) {
    const [origin, destination, departure] = params.leg.split('-')

    params.legs = [
      {
        origin: origin.toUpperCase(),
        destination: destination.toUpperCase(),
        departure
      }
    ]
  }

  delete params.leg

  return params
}

export const getUrlParams = search => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&')
  const params = {}

  hashes.forEach(hash => {
    const [key, val] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })

  return params
}

export const getBasePath = location => {
  const { pathname } = location
  const basePath = pathname.split('/')[1]

  return `/${basePath}`
}

export const arrayToUrlParams = (array, param) => `${param}=${array.join(`&${param}=`)}`

export const valueToUrlParam = (value, param) => `${param}=${value}`

export const objectToUrlParams = object => {
  const urlParams = []

  Object.keys(object).map(key => {
    const value = object[key]

    urlParams.push(
      Array.isArray(value) ? arrayToUrlParams(value, key) : valueToUrlParam(value, key)
    )
  })

  return `?${urlParams.join('&')}`
}

export const buildURLEncoded = (url, params) => {
  if (!params) {
    return url
  }

  // Filter out undefined or null parameters
  const filteredParams = {}
  Object.keys(params).forEach(key => {
    const value = params[key]
    if (value !== undefined && value !== null) {
      filteredParams[key] = value
    }
  })

  const serializedParams = new URLSearchParams(filteredParams).toString()

  // Replace '+' with '%20' to match the desired encoding behavior
  const encodedParams = serializedParams.replace(/\+/g, '%20')

  return `${url}?${encodedParams}`
}

export const getSearchBoxTab = (_url, lng) => {
  const params = getUrlParams(_url)
  const tabs = buildSearchBoxTabs(lng)

  return Object.entries(tabs)
    .map(tab => ({ url: tab[1]?.url?.split('&').pop(), tab: tab[1]?.url, index: tab[1]?.index }))
    .find(({ tab }) => tab?.includes(params.activeTab))
}

export const parseLangCode = langCode => {
  const parseLangCode = (langCode || '').replace('/', '').split('-')

  return parseLangCode?.length > 1
    ? `${parseLangCode[0]}-${parseLangCode[1]?.toUpperCase()}`
    : undefined
}

export const getPnrFromPath = location => {
  const { pathname } = location
  const basePath = pathname.split('/')[2]

  return `${basePath}`
}
