/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'

import { isEmpty } from 'helpers/utils'

const getMilesLoginState = state => state.milesLogin

export const getMilesLoginData = createSelector([getMilesLoginState], milesLogin => milesLogin.data)

export const getUserUnsubscribedProcess = createSelector(
  [getMilesLoginState],
  milesLogin => milesLogin.data?.baseMetadata?.warnMessages
)

export const getMilesLoginError = createSelector(
  [getMilesLoginState],
  milesLogin => milesLogin.error
)

export const getProfileData = createSelector([getMilesLoginData], milesLogin => {
  if (milesLogin) {
    return {
      firstName: milesLogin.passengersData?.[0]?.firstName,
      accountBalance: milesLogin.passengersData?.[0].frequentFlyerInformation?.accountBalance,
      membershipNumber: milesLogin.passengersData?.[0].frequentFlyerInformation?.number
    }
  }
  return
})

export const getMembershipNumber = createSelector([getProfileData], profileData =>
  profileData ? profileData.membershipNumber : null
)

export const getAccountBalance = createSelector([getProfileData], profileData =>
  profileData ? profileData.accountBalance : null
)

export const getMilesLoginExpirationDate = createSelector([getMilesLoginData], loginData =>
  loginData ? loginData.milesExpirationDate : null
)

export const getContactInformation = createSelector([getMilesLoginData], milesLoginData => {
  if (milesLoginData) {
    return {
      address: {
        country: milesLoginData.passengersData[0]?.residentialAddress.country,
        province: milesLoginData.passengersData[0]?.residentialAddress.state
      },
      mail: !isEmpty(milesLoginData.contactInformation?.emails)
        ? milesLoginData.contactInformation?.emails[0]
        : '',
      phone: {
        areaCode: !isEmpty(milesLoginData.contactInformation?.phones)
          ? milesLoginData.contactInformation?.phones[0].areaCode
          : '',
        number: !isEmpty(milesLoginData.contactInformation?.phones)
          ? milesLoginData.contactInformation?.phones[0].number
          : ''
      }
    }
  }
  return
})

export const getMilesLoginIsFetching = createSelector(
  [getMilesLoginState],
  milesLogin => milesLogin && milesLogin.isFetching
)
