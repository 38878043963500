/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  fetchAncillariesEvaluationStart,
  fetchAncillariesEvaluationSuccess,
  fetchAncillariesEvaluationError,
  resetAncillariesEvaluation
} = createActions({
  FETCH_ANCILLARIES_EVALUATION_START: () => {},
  FETCH_ANCILLARIES_EVALUATION_SUCCESS: data => ({ data }),
  FETCH_ANCILLARIES_EVALUATION_ERROR: error => ({ error }),
  RESET_ANCILLARIES_EVALUATION: () => {}
})

const fetchAncillariesEvaluation = routes => {
  return async dispatch => {
    dispatch(fetchAncillariesEvaluationStart())
    try {
      const ancillariesEvaluation = await api.post(apiRoutes.ANCILLARIES_EVALUATION, routes)

      dispatch(fetchAncillariesEvaluationSuccess(ancillariesEvaluation?.data))
    } catch (error) {
      dispatch(fetchAncillariesEvaluationError(error))
    }
  }
}

export {
  fetchAncillariesEvaluation,
  fetchAncillariesEvaluationStart,
  fetchAncillariesEvaluationSuccess,
  fetchAncillariesEvaluationError,
  resetAncillariesEvaluation
}
