/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { buildURLEncoded } from 'helpers/url'
import api from 'services/api'

const { fetchCitiesStart, fetchCitiesSuccess, fetchCitiesError } = createActions({
  FETCH_CITIES_START: () => {},
  FETCH_CITIES_SUCCESS: cities => cities,
  FETCH_CITIES_ERROR: error => error
})

const fetchCities = pageInfo => {
  const queryParams = buildURLEncoded('', pageInfo)

  return async dispatch => {
    dispatch(fetchCitiesStart())

    try {
      const cities = await api.get(`${apiRoutes.CITIES}${queryParams}`)
      dispatch(fetchCitiesSuccess(cities))
    } catch (error) {
      dispatch(fetchCitiesError(error))
    }
  }
}

const getCountryCitiesWithBranches = (type, country) => {
  return async dispatch => {
    dispatch(fetchCitiesStart())

    try {
      const cities = await api.get(`${apiRoutes.CITIES_BRANCHES(type, country)}`)
      dispatch(fetchCitiesSuccess(cities))
    } catch (error) {
      dispatch(fetchCitiesError(error))
    }
  }
}

export {
  fetchCitiesStart,
  fetchCitiesSuccess,
  fetchCitiesError,
  fetchCities,
  getCountryCitiesWithBranches
}
