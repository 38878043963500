/* eslint-disable import/no-cycle */
import CryptoJS from 'crypto-js'

import { SALT, ITERATION, KEY_ZISE } from 'constants/sublos/auth'
import { __ACCESS_TOKEN_SUBLOS__, __SUBLOS_ERROR__ } from 'constants/sublos/localStorageKeys'
import { delete_cookie, getCookie } from 'helpers/cookies'
import COOKIE_NAMES from 'server/utils/cookieNames'

import { getSublosUserData, removeSublosUserData } from './userData'

export const getSublosToken = () => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(__ACCESS_TOKEN_SUBLOS__)
  }
  return ''
}

export const setSublosToken = accessToken => {
  localStorage.setItem(__ACCESS_TOKEN_SUBLOS__, accessToken)
}

export const logout = () => {
  localStorage.removeItem(__ACCESS_TOKEN_SUBLOS__)
  removeSublosUserData()
}

export const isLoggedIn = () => {
  const token = localStorage.getItem(__ACCESS_TOKEN_SUBLOS__)
  const userData = getSublosUserData()
  return Boolean(token) && Boolean(userData)
}

export const getSublosLoginErrorWithToken = () => {
  if (typeof window !== 'undefined') {
    const cookie = document.cookie
    const error = getCookie(cookie, __SUBLOS_ERROR__)
    return error ? JSON.parse(error) : null
  }
}

export const isLoggedInAeroweb = () => {
  if (typeof window !== 'undefined') {
    const cookie = document.cookie
    const accessToken = getCookie(cookie, COOKIE_NAMES.SUBLOS_TOKEN)

    if (accessToken) {
      delete_cookie(COOKIE_NAMES.SUBLOS_TOKEN)
      return accessToken
    }

    return
  }
}

export function getAerowebToken(dataEncrypted) {
  const key = decryptKey(SALT, ITERATION, KEY_ZISE).toString()
  const base64_original = replaceSmbolsCipherCodeAes(dataEncrypted)

  return aes_decrypt(key, base64_original)
}

function aes_decrypt(password, data) {
  const ivCiphertext = CryptoJS.enc.Base64.parse(data)
  const iv = CryptoJS.lib.WordArray.create(ivCiphertext.words.slice(0, 16 / 4))
  const payload = CryptoJS.lib.WordArray.create(ivCiphertext.words.slice(16 / 4))
  const password_wrod = CryptoJS.enc.Hex.parse(password)
  const b64Strinkg = CryptoJS.enc.Base64.stringify(payload)
  const bytes = CryptoJS.AES.decrypt(b64Strinkg, password_wrod, { iv })
  const token = bytes.toString(CryptoJS.enc.Utf8)

  return token
}

function decryptKey(salt, iterations, keySize) {
  return CryptoJS.PBKDF2(process.env.RAZZLE_SUBLOS_TOKEN_SECRET_KEY, CryptoJS.enc.Hex.parse(salt), {
    keySize,
    iterations
  })
}

function replaceSmbolsCipherCodeAes(encripted) {
  return encripted
    .replaceAll('-', '+')
    .replaceAll('_', '/')
    .replaceAll('%3D', '=')
}
